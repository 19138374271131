import {AmenityObject} from "../constants/AmenityObject.ts";

export const useGetAmenityObject = () => {

    const getAmenityObjectIcon = (id: number) => {
        return AmenityObject[id].icon
    }

    return {
        getAmenityObjectIcon
    }
}