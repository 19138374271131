import React, {ReactNode, useState} from 'react';
import GenericCheckbox from "./checkbox/GenericCheckbox";

interface CheckboxProps {
    children?: ReactNode,
    onChange?: (e: React.MouseEvent<HTMLButtonElement>) => void | null,
    isChecked?: boolean,
    className?: string,
}

function Checkbox({children, onChange, isChecked = false, className = ""}: CheckboxProps) {
    const [checked, setChecked] = useState(isChecked)
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onChange) {
            setChecked(!checked);
            onChange(event)
        }
    }

    return (
        <GenericCheckbox
            className={className}
            checked={checked}
            onClick={onClick}
        >
            {children}
        </GenericCheckbox>
    );
}

export default Checkbox;

