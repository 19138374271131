import {useState, ChangeEvent} from "react"

interface ToggleSwitchProps {
    id?: string
    isChecked?: boolean
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void | null
    disabled?: boolean
    label?: string
    minimize?: boolean
}

function ToggleSwitch({id, isChecked, onChange, disabled, label, minimize}: ToggleSwitchProps) {
    const className = `gb--switch ${minimize && "gb--switch-small"}`

    const [switchState, setSwitchState] = useState(isChecked)

    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        if (onChange && !disabled) {
            setSwitchState(!switchState)
            onChange(e)
        }
    }

    return (
        <div className="gb--toggle-switch">
            <label
                className={className}
                htmlFor={id}
            >
                <input
                    id={id}
                    type="checkbox"
                    onChange={handleOnChange}
                    checked={switchState}
                    readOnly={disabled}
                />
                <span>{label}</span>
            </label>
        </div>
    )
}

export default ToggleSwitch
