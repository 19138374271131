import Column from "../../../../features/containers/components/Column.tsx"
import Row from "../../../../features/containers/components/Row.tsx"
import VehicleType from "../../../../types/VehicleType.type.ts";
import BookingMobileTransferDetails from "./Mobile/BookingMobileTransferDetails.tsx";

interface BookingVehicleTypePreviewProps {
    vehicleType: VehicleType
    returnTrip: boolean
}

const BookingVehicleTypePreview = (props: BookingVehicleTypePreviewProps) => {
    const {returnTrip} = props

    return (
        <div className="gb--preview__container">
            <Column>
                <Row>
                    <Column
                        justify
                        className="gb--preview__type"
                    >
                        <BookingMobileTransferDetails returnTrip={returnTrip} />
                    </Column>
                </Row>
            </Column>
        </div>
    )
}

export default BookingVehicleTypePreview
