import React, {ReactNode} from 'react';

interface ButtonProps {
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    children: ReactNode,
    additionalClasses?: string,
    style?: object,
    type?: 'button' | 'submit' | 'reset',
    className:
        'green' |
        'green-outline' |
        'blue' |
        'blue-darker' |
        'orange' |
        'orange-dark' |
        'gray' |
        'red' |
        'border' |
        'inline' |
        'inactive' |
        'inactive-green' |
        'compact' |
        'disabled' |
        'mobile' |
        'dismiss',
}


const Button = ({children, className, onClick, additionalClasses = "", style, type = 'button'}: ButtonProps) => (
    <button
        type={type}
        onClick={onClick}
        style={style}
        className={`btn btn--${className} ${additionalClasses}`}
    >
        {children}
    </button>
)

export default Button;