import {useLazyLogErrorQuery} from "../../../api/reducers/apiSlice.ts";
import {LogErrorType} from "../types/LogErrorType.ts";

export const useLogError = () => {
    const [query] = useLazyLogErrorQuery();

    const sendLog = ({trace, message, info, url}: LogErrorType) => {
        query({
            trace,
            url,
            message,
            info
        })
    };

    return {
        sendLog
    }
};