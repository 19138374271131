import IconSuitcaseV2 from "../icons/IconSuitcaseV2"
import Column from "../../../features/containers/components/Column"
import {Link} from "react-router-dom"
import Translation from "../../../features/translation/components/Translation"
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute"
import useLocale from "../../../hooks/selector/useLocale"
import Row from "../../../features/containers/components/Row"
import useIsDesktop from "../../../hooks/selector/useIsDesktop"
import IconPax from "../icons/IconPax"
import datasetParser from "../../../utils/dataset/datasetParser"
import VehicleCardPerks from "./VehicleCardPerks.tsx";
import {useToggle} from "@getbybus/gbb-green";
import StaticDatePicker from "../../../pages/staticPages/stationToStation/components/StaticDatePicker.tsx";
import {useState} from "react";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {toInteger} from "lodash";
import PillLabel from "../PillLabel.tsx";
import {useGetAmenityObject} from "../../../pages/bookingForm/features/BookingAmenities/hooks/useGetAmenityObject.ts";
import IconStarRating from "../icons/IconStarRating.tsx";

interface VehicleCardProps {
    vehicleGroup?: string
    vehicleGroupId?: number
    vehicleImagePath?: string
    vehicleMinPaxCapacity?: number
    vehicleMaxPaxCapacity?: number
    vehiclePrice?: string
    vehicleAmenities?: number[]
    vehicleRating: number,
    vehicleModelMake?: string
    vehicleRatingCount: number
}

const VehicleCard = (props: VehicleCardProps) => {
    const {
        vehicleGroup,
        vehicleGroupId,
        vehicleImagePath,
        vehicleMinPaxCapacity,
        vehicleMaxPaxCapacity,
        vehiclePrice,
        vehicleAmenities,
        vehicleRating,
        vehicleModelMake,
        vehicleRatingCount,
    } = props

    const {getAmenityObjectIcon} = useGetAmenityObject()
    const [integerPart, decimalPart] = vehiclePrice.split('.');

    const {
        searchUrl: {
            fromId,
            fromSlug,
            toId,
            toSlug,
            pickUpDate,
            // noOfPersons
        },
    } = datasetParser({id: "vehicle-group-portal"})

    const locale = useLocale()
    const {getPath} = getTranslatedRoute()
    const bookingSlugTranslation = getPath("booking")
    const bookingData = {
        locale: locale,
        booking: bookingSlugTranslation,
        fromSlug: `${fromSlug}-${fromId}`,
        toSlug: `${toSlug}-${toId}`,
        passengers: vehicleMinPaxCapacity,
        vehicleGroupId: vehicleGroupId,
    };
    const {value, set} = useToggle()
    const [isOpen, setIsOpen] = useState(false)

    const handleMouseOver = () => {
        set(true)
    }

    const handleMouseOut = () => {
        set(false)
    }

    const setOpen = () => setIsOpen(!isOpen)

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    return (
        <>
            <StaticDatePicker
                closeModal={setOpen}
                isOpen={isOpen}
                params={bookingData}
            />
            <div
                key={vehicleGroupId}
                className="card__vehicle--sts"
                onClick={setOpen}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                <Column
                    justify
                    center
                    className="gb--card card__vehicle"
                >
                    <Column center className={'card__header'}>
                        <div className={'card__ratings'}>
                            <p>({vehicleRatingCount})</p>
                            {Array.from({ length: 5 }).map((_, index) => {
                                const ratingValueWidth = (vehicleRating - index) * 100 > 0 ? (vehicleRating - index) * 100 : 0;
                                return (<IconStarRating
                                    width={16}
                                    height={16}
                                    color={'#7ed321'}
                                    key={index}
                                    ratingValueWidth={ratingValueWidth}
                                    fill={'#ddd'}
                                />)
                            })}
                        </div>
                        <p className="gb--emphasize card__title">{vehicleGroup}</p>
                        <p className='card__subtitle'>
                            {vehicleModelMake}
                            {' '}
                            <Translation translationKey={'booking.chooseVehicle.orSimilar'}/>
                        </p>
                    </Column>
                    <Column
                        wrap
                        className="vehicle-group__desc"
                    >
                        <img
                            width="auto"
                            height="auto"
                            src={vehicleImagePath}
                            alt={vehicleGroup}
                            onError={handleImageError}
                        />
                        <Row justify className={'card__perks'}>
                            <PillLabel
                                color='blue'
                                label={
                                    <Row className={'card__capacity'}>
                                        <div>
                                            <IconPax />
                                            {vehicleMinPaxCapacity} - {vehicleMaxPaxCapacity}
                                        </div>
                                        &#9679;
                                        <div>
                                            <IconSuitcaseV2 />
                                            {vehicleMaxPaxCapacity}
                                        </div>
                                    </Row>
                                }
                            />
                            <div className={'card__amenities'}>
                                {vehicleAmenities?.map((amenity, _index) => {
                                    if (_index < 3) {
                                        const IconComponent = getAmenityObjectIcon(amenity)
                                        return <IconComponent width={24} height={24} key={amenity}/>
                                    }
                                })}
                                {3 < vehicleAmenities?.length && <p>+{vehicleAmenities?.length - 3}</p>}
                            </div>
                        </Row>
                    </Column>
                    <Row className="card__action">
                        <Column alignEnd>
                        <span className="vehicle-group__price">
                            <span className="euro-icon">&#8364;</span>
                            {integerPart},
                            {decimalPart && <sup>{decimalPart}</sup>}
                        </span>
                        </Column>
                        <button
                            type="button"
                            className={`btn btn--blue-darker`}
                        >
                            <Translation translationKey="booking.chooseVehicle.selectDate" />
                        </button>
                    </Row>
                </Column>
            </div>
        </>
    )
}

export default VehicleCard
