import MobileOverlay from "../../../components/MobileOverlay.tsx";
import MobilePlaceInputButton from "../../../../../layout/form/features/stations/components/MobilePlaceInputButton.tsx";
import DateTimeField from "../../../../../layout/form/features/dates/components/DateTimeField.tsx";
import ReturnDateTimeField from "../../../../../layout/form/features/dates/components/ReturnDateTimeField.tsx";
import PassengerPicker from "../../../../../layout/form/features/passengers/components/PassengerPicker.tsx";
import useDismissAllOverlays from "../../../hooks/useDismissAllOverlays.ts";
import GetByApsLogomark from "../../../../../components/generic/icons/logotypes/GetByApsLogomark.tsx";
import GetByApsLogo from "../../../../../components/generic/icons/logotypes/GetByApsLogo.tsx";
import {Button, Row} from "@getbybus/gbb-green";
import useSubmitSearch from "../../../../../layout/form/hooks/useSubmitSearch.ts";
import Translation from "../../../../translation/components/Translation.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";


function MobileSearchFormOverlay() {
    const dismissAllOverlays = useDismissAllOverlays()
    const {submitForm} = useSubmitSearch()

    const updateSearch = () => {
        dismissAllOverlays()
        submitForm()
    }

    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form))

    return (
        <MobileOverlay
            title={(
                <Row gap center>
                    <GetByApsLogomark height="32" width="32"/>
                    <GetByApsLogo height="25" width="141"/>
                </Row>
            )}
            wrapperClassName="incomplete-search-form-overlay"
            isActionSheet
            backClick={dismissAllOverlays}
        >
            <div className="incomplete-search-form-overlay--content">
                <MobilePlaceInputButton
                    direction="departure"
                    showIsIncomplete={!!fromPlace && !fromPlace.placeId}
                />
                <MobilePlaceInputButton
                    direction="destination"
                    showIsIncomplete={!!toPlace && !toPlace?.placeId}
                />
                <div className="search__select search__select--datepicker gb--row gb--row-middle">
                    <DateTimeField/>
                    <ReturnDateTimeField/>
                </div>
                <PassengerPicker/>
                <Button
                    width="full"
                    style={{marginTop: "1rem"}}
                    onClick={updateSearch}
                >
                    <Translation translationKey="booking.search.updateSearch"/>
                </Button>
            </div>
        </MobileOverlay>
    )
}

export default MobileSearchFormOverlay