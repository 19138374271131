import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import {activateReturnCalendar} from "../../../../../reducers/mobileSlice.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useDispatch} from "react-redux";
import {setReturnDate} from "../../../../../reducers/formSlice.ts";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useState} from "react";
import format from "date-fns/format";
import {add} from "date-fns";

export default function useReturnDateSelectHandlers(){
    const {returnDate} = useAppSelector((state) => state?.form)
    const dispatch = useDispatch();
    const {value: isDropdownOpen, set: setIsDropdownOpen} = useToggle()
    const isMobile = useIsMobile()
    const [departure, setDeparture] = useState<string | null>(returnDate || add(new Date(), {hours: 2}).toISOString())
    const activeDate = isDropdownOpen ? departure || returnDate : returnDate
    const dateDisplay = activeDate ? format(new Date(activeDate), 'd MMM | HH:mm') : '';
    const {departureDate} = useAppSelector((state) => state?.form)

    const {
        returningOn: departingOnLabel,
        optional: dateLabel,
    } = useTranslation('search.form') as Record<string, string>


    const handleToggle = (value: boolean) => {
        setIsDropdownOpen(value)
        if (!value) {
            setDeparture(null)
        } else {
            setDeparture(returnDate || departureDate || new Date().toISOString())
        }
    }


    const selectReturnDate = (value: Date) => {
        if (value) {
            dispatch(setReturnDate(format(value, "yyyy-MM-dd\'T\'HH:mm:ss")));
        } else {
            dispatch(setReturnDate(null));
        }
    }

    const activateMobileOverlayDatePicker = () => {
        if (isMobile) {
            dispatch(activateReturnCalendar(true))
        }
    }

    return {
        departure,
        setDeparture,
        departureDate,
        departingOnLabel,
        isDropdownOpen,
        setIsDropdownOpen,
        handleToggle,
        dateDisplay,
        dateLabel,
        returnDate,
        selectReturnDate,
        activateMobileOverlayDatePicker,
    }
}