import {Provider} from "react-redux";
import App from "./App";
import {createRoot} from "react-dom/client";
import store from "./store"
import TranslationsProvider from "./context/components/TranslationsProvider.tsx";

const container = document.getElementById('root');
const root = createRoot(container!) // This container should always exist, since body on the server side is populated seperated from the root div


root.render(
    <Provider store={store}>
        <TranslationsProvider>
            <App/>
        </TranslationsProvider>
    </Provider>
);