import useGoBack from "../hooks/useGoBack";
import IconTimes from "../../components/generic/icons/IconTimes.tsx";
import IconArrowBack from "../../components/generic/icons/IconArrowBack.tsx";

interface BackButtonProps {
    backClick?: (arg0?: any) => any,
    icon?: 'times' | 'back'
    fill?: string
}

const BackButton = ({backClick, icon = 'times', fill}: BackButtonProps) => {
    const {goBack: defaultBackClick} = useGoBack()
    const goBack = backClick ? () => backClick() : () => defaultBackClick()


    return (
        <button onClick={() => goBack()} type="submit" className="gb--mobile-overlay__content-back-button">
            { icon === 'times'
                ? <IconTimes fill={fill}/>
                : <IconArrowBack fill={fill}/>
            }
        </button>
    )
}

export default BackButton;