import {isValid} from "date-fns";

export const getDateRequestFormat = (date: string) => {
    const paramDate = date.split('-').map((dateSegment) => (parseInt(dateSegment, 10)));
    if (paramDate.length >= 3) {
        const newDate = isValid(new Date(paramDate[2], paramDate[1] - 1, paramDate[0]))
            ? new Date(paramDate[2], paramDate[1] - 1, paramDate[0])
            : new Date();

        return newDate.toISOString()
    }

    return null
}