import {ConfirmPaymentData, StripeElements} from "@stripe/stripe-js";

export default function getConfirmPaymentElement(elements: StripeElements, isoCode: string): {
    elements: StripeElements,
    confirmParams?: Partial<ConfirmPaymentData> | undefined,
    redirect: "if_required"
} {
    return {
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required",
        confirmParams: {
            payment_method_data: {
                billing_details: {
                    address: {
                        country: isoCode,
                    },
                },
            },
        },
    }
}