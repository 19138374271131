import {LocaleType} from "../../../../../../../types/Locale.type.ts";
import languageFlagPositionGetter from "../functions/languageFlagPositionGetter.ts";

interface LanguageFlagProps {
    lang: string,
    langKey: LocaleType,
}

const LanguageFlag = ({lang, langKey}: LanguageFlagProps) => {
    const languageFlagPosition = languageFlagPositionGetter(langKey)
    return (
        <span>
            <i
                className="gb--lang-flag"
                style={{backgroundPosition: languageFlagPosition }}
            />
            {lang}
        </span>
    )
}

export default LanguageFlag;