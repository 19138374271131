import {useState} from "react";

export const useToggle = (initialValue: boolean = false) => {
    const [value, setValue] = useState(initialValue);

    const handleToggle = () => {
        setValue(!value);
        return !value
    };

    // @ts-ignore
    const set = (value): any => {
        setValue(value);
    }

    return {
        value,
        onChange: handleToggle,
        set,
    };
};