import format from "date-fns/format";
import IconChevron from "../../../components/generic/icons/IconChevron.tsx";
import Row from "../../containers/components/Row.tsx";
import useTranslation from "../../translation/hooks/useTranslation.ts";

interface TimeDisplayProps {
    selectedTime?: string
    toggleActive?: (tag: "time" | "date") => void
    toggleable?: boolean
    additionalClass?: string
}

const TimeDisplay = (props: TimeDisplayProps) => {

    const {
        selectedTime,
        toggleActive = () => {},
        toggleable = true,
        additionalClass
    } = props

    const timeDisplay = selectedTime ? format(new Date(selectedTime), 'HH:mm (h:mm aa)') : '00:00 (0:00 AM/PM)'
    const leavingAt = useTranslation("search.form.leavingAt") as string


    return (
        <Row className={`gb--datetime-time ${additionalClass}`}>
            <span>{leavingAt}</span>
            <div
                onClick={() => {
                    if (toggleable) {
                        toggleActive('time')
                    }
                }}
            >
                {timeDisplay}
                <IconChevron chevronOrientation={"down"}/>
            </div>
        </Row>
    );
};
export default TimeDisplay;