import React, {ReactNode} from "react";

interface GenericCheckboxProps {
    className: string,
    checked: boolean,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    children: ReactNode,
}

function GenericCheckbox({className, checked, onClick, children}: GenericCheckboxProps) {
    return (
        <div className={`${className}`}>
            <input
                type="checkbox"
                checked={checked}
                // Return null to silence react error.
                // we're using label as change handler instead of checkbox
                onChange={() => null}
            />
            <button
                type="button"
                className="gb--input-label"
                onClick={onClick}
            >
                {children}
            </button>
        </div>
    );
}

export default GenericCheckbox;