import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useRouteMatch from "../../../../../routing/hooks/useRouteMatch.ts";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {setBookingNavigatePath} from "../../../../../reducers/pageSlice.ts";

export default function useAcceptCookiesAndContinueToBooking() {
    const {
        vehicleBookingPath,
    } = useAppSelector((state) => (state.page))

    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const isVehicleSelect = useRouteMatch("vehicle")

    const goFromVehicleSelectToBooking = () => {
        if (!!vehicleBookingPath && isVehicleSelect) {
            navigate(vehicleBookingPath)
        }
    }

    const removeVehicleSelectNavigateData = () => {
        if (isVehicleSelect) {
            dispatch(setBookingNavigatePath(undefined))
        }
    }

    return {
        goFromVehicleSelectToBooking,
        removeVehicleSelectNavigateData,
    }
}