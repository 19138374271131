import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconSuitcaseV2(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#4a4a4a',
        viewBox = '0 0 20 18',
        title = 'IconSuitcaseV2',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <path d="m13.677419 2.580645h-9.806451" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <path d="m2.32258065 2.58064516h-1.5483871c-.4276129 0-.77419355.34658065-.77419355.77419355v11.35483869c0 .4276129.34658065.7741936.77419355.7741936h15.99999995c.427613 0 .7741936-.3465807.7741936-.7741936v-11.35483869c0-.4276129-.3465806-.77419355-.7741936-.77419355h-1.548387" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <path d="m3.87096774 15.483871v-12.90322584c0-.14245161-.1156129-.25806451-.25806451-.25806451h-1.03225807c-.14245161 0-.25806451.1156129-.25806451.25806451v12.86554834" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <g fill="#4a4a4a" fillRule="nonzero">
                        <path d="m15.225807 15.225807h1.290323v1h-1.290323z"/>
                        <path d="m1.032258 15.225807h1.290323v1h-1.290323z"/>
                        <circle cx="4.129032" cy="13.935484" r="1"/>
                        <circle cx="13.419355" cy="13.935484" r="1"/>
                        <circle cx="16.516129" cy="13.935484" r="1"/>
                        <circle cx="15.483871" cy="13.935484" r="1"/>
                        <path d="m10.5806452 2.58064516c0-.4276129.3465806-.77419355.7741935-.77419355s.7741936.34658065.7741936.77419355"/>
                        <path d="m5.41935484 2.58064516c0-.4276129.34658064-.77419355.77419355-.77419355.4276129 0 .77419355.34658065.77419355.77419355"/>
                    </g>
                    <path d="m6.19354839 2.06451613v-1.03225807c0-.4276129.34658064-.77419354.77419355-.77419354h3.61290326c.4276129 0 .7741935.34658064.7741935.77419354v1.03225807" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <path d="m9.80645161.77419355h-2.06451613c-.28516129 0-.51612903-.23096774-.51612903-.51612903v-.25806452h3.09677415v.25806452c0 .28516129-.2309677.51612903-.51612899.51612903z" fill="#4a4a4a" fillRule="nonzero"/>
                    <path d="m15.2258065 15.483871v-12.90322584c0-.14245161-.115613-.25806451-.2580646-.25806451h-1.032258c-.1424516 0-.2580645.1156129-.2580645.25806451v12.86632254" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <path d="m15.225807 4.129032h-1.548388" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <path d="m3.870968 4.129032h-1.548387" stroke="#4a4a4a" strokeWidth=".888889"/>
                    <circle cx="1.032258" cy="13.935484" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="2.064516" cy="13.935484" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="4.129032" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="13.419355" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="16.516129" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="15.483871" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="2.064516" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                    <circle cx="1.032258" cy="4.129032" fill="#4a4a4a" fillRule="nonzero" r="1"/>
                </g>
            )}
        />

    );
}

export default IconSuitcaseV2;
