import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import FloatingLabelInputField from "../../../../../components/generic/formElements/FloatingLabelInputField.tsx";
import IconClock from "../../../../../components/generic/icons/IconClock.tsx";
import TimeSelect from "../../../../../features/dateTime/components/TimeSelect.tsx";
import {useTimeSelect} from "../hooks/useTimeSelect.ts";


const ReturnTimePicker = (props) => {
    const {
        returnPickUpErrorMessageString,
        returnPickUpErrorDisplay,
        handleSelectReturnPickUpTime,
        minReturnPickUpTime,
        selectedReturnPickUpTime,
        displayReturnPickUpTime,
        isDropdownOpen,
        setIsDropdownOpen,
        returnPickUpTimeLabel
    } = useTimeSelect();

    return (
        <CustomDropdown
            field={
                <FloatingLabelInputField
                    id="time-input"
                    label={returnPickUpTimeLabel}
                    background={'white'}
                    value={displayReturnPickUpTime}
                    icon={<IconClock/>}
                    displayError={returnPickUpErrorDisplay}
                    errorMessage={returnPickUpErrorMessageString}
                    type="text"
                    readOnly
                    container={false}
                    autoComplete={"off"}
                />
            }
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            childrenWrapperClass={'gb--select-dropdown-single-item'}
            children={
                <TimeSelect
                    selectedTime={selectedReturnPickUpTime}
                    minTime={minReturnPickUpTime}
                    handleSubmit={(value: Date) => {
                        handleSelectReturnPickUpTime(value)
                        setIsDropdownOpen(false)
                    }}
                    changeTime={(value: Date) => {
                        handleSelectReturnPickUpTime(value)
                        setIsDropdownOpen(false)
                    }}
                    isMobile={false}
                />
            }
        />
    )
}
export default ReturnTimePicker