import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import BookingAmenitySelectorMobile from "./BookingAmenitySelectorMobile.tsx";
import BookingAmenitySelectorDesktop from "./BookingAmenitySelectorDesktop.tsx";
import {BookingAmenityType} from "../../../reducers/types/BookingAmenity.type.ts";

interface BookingAmenitySelectorProps {
    price: number
    title: string
    quantity: number
    amenityId: number
    returnTrip: boolean
    description: string
    returnQuantity: number
    currentAmenities: Record<number, BookingAmenityType>
    handleSave: (count: number, returnCount: number, key: number) => void
    handleClose: () => void
    handleIncrement: () => void
    handleDecrement: () => void
    handleReturnIncrement: () => void
    handleReturnDecrement: () => void
}

const BookingAmenitySelector = (props: BookingAmenitySelectorProps) => {
    const {
        title,
        price,
        quantity,
        amenityId,
        returnTrip,
        description,
        returnQuantity,
        currentAmenities,
        handleSave,
        handleClose,
        handleIncrement,
        handleDecrement,
        handleReturnIncrement,
        handleReturnDecrement
    } = props

    const isMobile = useIsMobile()

    return (
        isMobile ? (
            <BookingAmenitySelectorMobile
                title={title}
                price={price}
                amenityId={amenityId}
                returnTrip={returnTrip}
                handleSave={handleSave}
                description={description}
                handleClose={handleClose}
                currentAmenities={currentAmenities}
            />
        ) : (
            <BookingAmenitySelectorDesktop
                price={price}
                quantity={quantity}
                returnTrip={returnTrip}
                returnQuantity={returnQuantity}
                handleIncrement={handleIncrement}
                handleDecrement={handleDecrement}
                handleReturnIncrement={handleReturnIncrement}
                handleReturnDecrement={handleReturnDecrement}
            />
        )
    );
};

export default BookingAmenitySelector;