import {useParams} from "react-router";
import {usePrevious} from "@getbybus/gbb-green";
import {useEffect} from "react";

export default function useEffectOnParamsChanged(effect: () => void) {
    const params = useParams()
    const previousParams = usePrevious(params)

    useEffect(() => {
        if (JSON.stringify(previousParams) !== JSON.stringify(params)) {
            effect();
        }
    }, [params]);
}