import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import {MouseEvent} from "react"
import IconPlus from "../../../../components/generic/icons/IconPlus"

interface BookingAddReturnButtonProps {
    handleClick: () => void
}
const BookingAddReturnButton = (props: BookingAddReturnButtonProps) => {
    //TODO insert translation
    const leaveComment = useTranslation("booking.form.leaveAComment") as string
    const {handleClick} = props

    const addReturnClick = (event: MouseEvent) => {
        event.preventDefault()
        handleClick()
    }

    return (
        <>
            <div className="gb--return__button">
                <button
                    type="button"
                    className="btn btn--icon btn--green-light return-button"
                    onClick={addReturnClick}
                >
                    <div className="btn--icon-white">
                        <IconPlus
                            backgroundFill="transparent"
                            fill="#363636"
                        />
                    </div>
                    {leaveComment}
                </button>
            </div>
        </>
    )
}

export default BookingAddReturnButton
