import {usePrevious} from "@getbybus/gbb-green";
import {useEffect} from "react";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function useUpdatePlaceholderOnPageRefresh(name: "departure" | "destination", placePlaceholder: string, setPlacePlaceholder: (value: string) => void) {
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => state?.form)

    const previousFromPlace = usePrevious(fromPlace)
    const previousToPlace = usePrevious(toPlace)

    useEffect(() => {
        if (name === "departure" && placePlaceholder === "" && !!fromPlace && previousFromPlace !== fromPlace) {
            setPlacePlaceholder(fromPlace.placeName)
        }
        if (name !== "departure" && placePlaceholder === "" && !!toPlace && previousToPlace !== toPlace) {
            setPlacePlaceholder(toPlace.placeName)
        }
    }, [fromPlace, toPlace]);
}