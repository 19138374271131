import Navbar from "./Navbar";
import Logo from "./Logo";
import SearchForm from "../../../../form/components/SearchForm.tsx";
import HamburgerBar from "./HamburgerBar.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import MobileOverlayContainer from "../../../../../features/mobileOverlays/components/MobileOverlayContainer.tsx";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";
import {useMatches} from "react-router";

import {bookingInfoRouteId} from "../../../../../routing/constants/routeIds.ts";
import useRouteMatch from "../../../../../routing/hooks/useRouteMatch.ts";
import {allRouteIds} from "../../../../../routing/constants/allRouteIds.ts";

function TopBar() {
    const matches = useMatches();
    const isBookingForm = matches[matches.length - 1].id === allRouteIds.bookingInfo;
    const isBooking = matches.find((match) => match.id === "booking");
    const isSmallDisplay = useIsDesktop();

    const {
        mobileOverlayActive,
    } = useAppSelector((state) => (state.mobile))

    if (mobileOverlayActive) {
        return (
            <MobileOverlayContainer/>
        )
    }

    if (!(isBooking && !isSmallDisplay)) {
        return (
            <div className="gb--navigation">
                <header className="gb--header gb--row gb--row-middle gb--row-justify">
                    <Logo/>
                    {isSmallDisplay ? (
                        <Navbar/>
                    ) : (
                        <HamburgerBar/>
                    )}
                </header>
                {!isBookingForm &&(
                    <SearchForm/>
                )}
            </div>
        )
    }

    return (
        <></>
    )
}

export default TopBar;