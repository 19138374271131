/**
 * Component that shows standard GBB loader.
 */

interface LoaderProps {
    /** Do we want small loader or standard big one. Pass true for small (false or don't pass prop for big) */
    small?: boolean
}

function Loader({small}: LoaderProps) {
    return (
        <div className="dots-loader--wrapper">
            <div className="dots-loader">
                <ul className={`loading ${small ? 'small' : ''}`}>
                    <li/>
                    <li/>
                    <li/>
                </ul>
            </div>
        </div>
    );
}
export default Loader;
