import {NavigateOptions, useNavigate} from "react-router";
import useIsClientsideRoutingSupported from "./useIsClientsideRoutingSupported.ts";

export default function useGoTo(forceRedirect = false) {
    const navigate = useNavigate()
    const shouldNavigate = useIsClientsideRoutingSupported()
    const goTo = (path: string, options?: NavigateOptions) => {
        if (!shouldNavigate || forceRedirect) {
            window.location.href = path
        } else {
            navigate(path, options)
        }
    }

    return {goTo}
}