import Modal from "../../../components/generic/modal/Modal.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useEffect, useState} from "react";
import useSubmitSearch from "../../form/hooks/useSubmitSearch.ts";
import { Column, Row} from "@getbybus/gbb-green";
import IconHourglassTimeout from "../../../components/generic/icons/IconHourglassTimeout.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useDebounce from "../../../hooks/effects/useDebounce.ts";
import {useMatches} from "react-router";

const ExpiredResults = () => {
    const {
        searchFinishedTimestamp
    } = useAppSelector((state) => state?.form)
    const [expired, setExpired] = useState(false)
    const {refreshResults, isFetching} = useSubmitSearch()
    const title = useTranslation('booking.expired.title') as string
    const message = useTranslation('booking.expired.message') as string
    const button = useTranslation('booking.expired.button') as string
    const {debounce} = useDebounce(() => setExpired(true), 900000)
    const matches = useMatches()


    useEffect(() => {
        debounce()
        setExpired(false)
    }, [searchFinishedTimestamp])

    const handleRefresh = () => {
        refreshResults()
    }

    const shouldDisplay = () => {
        return matches[matches.length - 1]?.id === 'vehicle' || matches[matches.length - 1]?.id === 'booking-info'
    }


    return expired && shouldDisplay() && (
            <Modal
                className={"gb--expired"}
                message={
                    <Column center style={{maxWidth: "400px", margin: 'auto'}} gap>
                        <Row>
                            <IconHourglassTimeout/>
                        </Row>
                        <Row>
                            <h2>
                                {title}
                            </h2>
                        </Row>
                        <Row>
                            <p>
                                {message}
                            </p>
                        </Row>
                        <Row style={{justifyContent: "center"}} >
                            <div>
                                {!isFetching ? (
                                    <button className={"btn btn--blue-darker"} type={'button'}
                                            onClick={handleRefresh}>
                                        {button}
                                    </button>
                                ) : (
                                    <LoaderButton style={{height: '5rem'}} className={'btn btn--blue-darker search__select--action'}/>
                                )}
                            </div>
                        </Row>
                    </Column>
                }
            />
    );
};

export default ExpiredResults;