import {translationsContext} from "../functions/translationsContext.ts";
import {ReactNode} from "react";
import {translationsContextInitialValue} from "../constants/translationsContextInitialValue.ts";

const {Provider} = translationsContext

interface TranslationProviderProps {
    children: ReactNode,
}

function TranslationsProvider({children}: TranslationProviderProps) {
    return (
        <Provider value={translationsContextInitialValue}>
            {children}
        </Provider>
    )
}

export default TranslationsProvider