import {ContactFormPayloadType} from "../types/ContactFormPayload.type.ts";

const contactFormPayload = {
    value: '',
    isValid: false,
    displayError: false,
}
export const contactFormFirstNamePayload: ContactFormPayloadType = {
    ...contactFormPayload,
    type: 'firstName',
}

export const contactFormLastNamePayload: ContactFormPayloadType = {
    ...contactFormPayload,
    type: 'lastName',
}

export const contactFormEmailPayload: ContactFormPayloadType = {
    ...contactFormPayload,
    type: 'email',
}

export const contactFormPhoneNumberPayload: ContactFormPayloadType = {
    ...contactFormPayload,
    type: 'phoneNumber',
}