import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";


interface IconMinusType extends IconType {
    backgroundFill?: string,
}

function IconMinus(props: IconMinusType) {
    const {
        width = '24',
        height = '24',
        fill = '#4a79e2',
        backgroundFill = '#f6f8fd',
        viewBox = '0 0 24 24',
        title = 'IconMinus',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <circle cx="12" cy="12" fill={backgroundFill} r="12"/>
                    <path
                        d="m17.03125 13c.2708333 0 .5-.0989583.6875-.296875s.28125-.4322917.28125-.703125-.09375-.5052083-.28125-.703125-.4166667-.296875-.6875-.296875h-10.0625c-.27083333 0-.5.0989583-.6875.296875s-.28125.4322917-.28125.703125.09375.5052083.28125.703125.41666667.296875.6875.296875z"
                        fill={fill}
                        fillRule="nonzero"
                    />
                </g>
            )}
        />

    );
}

export default IconMinus;
