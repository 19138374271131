import {LocaleType} from "../../../../../../../types/Locale.type.ts";

export default function languageFlagPositionGetter(langKey: LocaleType) {
    const languageData: any = {
        'en': {
            langSpritePosition: 100,
        },
        'fr': {
            langSpritePosition: 200,
        },
        'de': {
            langSpritePosition: 60,
        },
        'it': {
            langSpritePosition: 260,
        },
        'es': {
            langSpritePosition: 140,
        }
    };
    return (`0 ${-languageData[langKey]?.langSpritePosition}px`)
}