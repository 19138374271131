// @ts-ignore
import {getTranslatedRoute} from "../../../../routing/functions/getTranslatedRoute.ts";
import {useNavigate, useParams} from "react-router";
import {useDispatch} from "react-redux";
import DatePickerWrapper from "../../../../features/dateTime/components/DatePickerWrapper.tsx";
import Dialog from "../../../../features/dialog/components/Dialog.tsx";
import {generatePath} from "react-router-dom";
import {handleBookingDateFormat} from "../../../searchResults/functions/handleBookingDateFormat.ts";
import Modal from "../../../../components/generic/modal/Modal.tsx";
import {Column, Row} from "@getbybus/gbb-green";
import IconHourglassTimeout from "../../../../components/generic/icons/IconHourglassTimeout.tsx";
import LoaderButton from "../../../../components/generic/loaders/LoaderButton.tsx";
import IconTimesCircle from "../../../../components/generic/icons/IconTimesCircle.tsx";
import IconTimes from "../../../../components/generic/icons/IconTimes.tsx";
import BookingAmenityCounter from "../../../bookingForm/features/BookingAmenities/components/BookingAmenityCounter.tsx";
import useIsDesktop from "../../../../hooks/selector/useIsDesktop.ts";
import SlideInContainer from "../../../../features/containers/components/SlideInContainer.tsx";
import MobileOverlay from "../../../../features/mobileOverlays/components/MobileOverlay.tsx";
import {useState} from "react";
import Button from "../../../../components/generic/Button.tsx";
import Translation from "../../../../features/translation/components/Translation.tsx";

interface StaticDatePickerProps {
    isOpen: boolean
    closeModal: () => void
    params: any
}

function StaticDatePicker(props: StaticDatePickerProps) {
    const {
        isOpen,
        closeModal,
        params
    } = props;
    const {getPath} = getTranslatedRoute();
    const isDesktop = useIsDesktop();
    const [date, setDate] = useState<string>();

    const handleSelectDate = (value: Date) => {
        setDate(value.toISOString());
    };

    const handleSubmit = () => {
        if (date) {
            const path = generatePath(`/:locale/${getPath("booking")}/:fromSlug/:toSlug/:date/:passengers/:vehicleGroupId`, {
                ...params,
                date: handleBookingDateFormat(date, '-'),
            });
            window.location.href = `${path}?from_sts=true`;
        }
    }

    const minDate = new Date();
    if (!isDesktop) {
        return isOpen && (
            <MobileOverlay
                backClick={closeModal}
                title={<Translation translationKey={'booking.chooseVehicle.selectDepartureDate'}/>}
                slideInDirection={'zoom'}
                wrapperClassName={'page-static__modal'}
            >
                <DatePickerWrapper
                    onChange={handleSelectDate}
                    useWeekdaysShort
                    inline
                    minDate={minDate}
                />
                <button
                    type={'button'}
                    onClick={handleSubmit}
                    className={"btn btn--blue-darker btn--mobile gb--datetime-submit"}
                >
                    <Translation translationKey={'booking.form.button'}/>
                </button>
            </MobileOverlay>
        )
    }
    return isOpen && (
        <Modal
            className={'page-static__modal'}
            message={
                <div>
                    <div className={'page-static__modal-close'}>
                        <button onClick={(e) => {
                            e.preventDefault()
                            closeModal()
                        }}>
                            <IconTimesCircle
                                height={24}
                                width={24}
                                backgroundFill={'white'}
                                fill="#9b9b9b"
                            />
                        </button>
                    </div>
                    <p className={'page-static__modal-header'}><b>
                        <Translation translationKey={'booking.chooseVehicle.selectDepartureDate'}/>
                    </b></p>
                    <DatePickerWrapper
                        onChange={handleSelectDate}
                        useWeekdaysShort
                        inline
                        minDate={minDate}
                    />
                    <button
                        type={'button'}
                        onClick={handleSubmit}
                        className={"btn btn--blue-darker btn--mobile gb--datetime-submit"}
                    >
                        <Translation translationKey={'booking.form.button'}/>
                    </button>
                </div>
            }
        />
    )
}

export default StaticDatePicker