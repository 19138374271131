import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {toggleIsCookieModalOpen} from "../../../reducers/pageSlice.ts";
import {useEffect} from "react";

export default function useHandleManageCookiesFooterClick() {
    const dispatch = useAppDispatch()

    const handleClickOnElementOutsideOfReact = ({target: {id}}: any) => {
        if (id === 'activate-cookie-preferences') {
            dispatch(toggleIsCookieModalOpen())
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOnElementOutsideOfReact);

        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClickOnElementOutsideOfReact);
        };
    }, []);
}