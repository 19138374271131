import {handleBookingNavigationItemClass} from "../functions/handleBookingNavClass.ts";
import MatchType from "../../../types/Match.type.ts";

interface BookingNavigationItemProps {
    id: string
    matches: MatchType[]
    title: string
    order: number
}

const BookingNavigationItemDesktop = (props: BookingNavigationItemProps) => {
    const {
        id,
        matches,
        title,
        order
    } = props

    return (
        <div
            className={handleBookingNavigationItemClass(id, matches)}
        >
            <div>
                <span>{order}</span>
            </div>
            {title}
        </div>
    );
};

export default BookingNavigationItemDesktop;