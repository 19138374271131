import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts"
import BookingPriceDisplay from "./BookingPriceDisplay.tsx"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import BookingAmenitiesBreakdown from "./BookingAmenitiesBreakdown.tsx";
import BookingTotalPriceDisplay from "./BookingTotalPriceDisplay.tsx";
import BookingPrepaymentDetails from "./BookingPrepaymentDetails.tsx";
import {PaymentMethods} from "../../../../constants/PaymentMethods.ts";

const BookingPriceInfo = ({isReturn}: {isReturn: boolean}) => {
    const {price} = useAppSelector((state) => state?.vehicleType)

    const {
        paymentTransferForm,
        amenities: bookingAmenities
    } = useAppSelector((state) => state?.bookingForm)

    const {
        amenities: vehicleAmenities,
        depositPrice
    } = useAppSelector((state) => state?.vehicleType)

    const paymentMethod = paymentTransferForm.paymentMethod.value
    const totalPrice = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id]?.count + bookingAmenities[amenity.id]?.returnCount) * Number(amenity?.price);
        return acc + subtotal;
    }, Number(price))

    const amenitiesPrepayment = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id]?.count + bookingAmenities[amenity.id]?.returnCount) * Number(amenity?.depositPrice);
        return acc + subtotal;
    }, 0)

    const priceLabel = useTranslation("booking.form.transferPriceBreakdown") as string

    return (
        <div className={'gb--booking-item'}>
            <CollapsableComponent label={priceLabel} hrClassName={'gb--divider__hr'}>
                <div className="gb--booking__price">
                    {paymentMethod !== 0 ? (
                        <>
                            <BookingPriceDisplay
                                isReturn={isReturn}
                                displayPrice={Number(price)!}
                                paymentMethod={paymentMethod}
                            />

                        </>
                    ) : null}
                    <BookingAmenitiesBreakdown/>
                    <hr className="dashed"/>
                    <BookingTotalPriceDisplay
                        isReturn={isReturn}
                        totalPrice={Number(totalPrice)}
                    />
                    {paymentMethod === PaymentMethods.PREPAYMENT && (
                        <BookingPrepaymentDetails
                            totalPrice={Number(totalPrice)}
                            depositPrice={Number(depositPrice)}
                            amenitiesDepositPrice={Number(amenitiesPrepayment)}
                        />
                    )}
                </div>
            </CollapsableComponent>
        </div>
    )
}

export default BookingPriceInfo
