interface ImageProps {
    alt?: string
    width?: string
    height?: string
    imageFileName: string
    imageFileFormat?: "jpg" | "jpeg" | "png" | "svg"
}

function Image({alt, width, height, imageFileName, imageFileFormat = "jpg"}: ImageProps) {
    const imagePath = `/public/${imageFileName}.${imageFileFormat}`
    return (
        <img
            width={width ?? "auto"}
            height={height ?? "auto"}
            src={imagePath}
            alt={alt ?? imageFileName}
        />
    )
}

export default Image
