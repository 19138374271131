import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {activateMobileMenu} from "../../../../../reducers/mobileSlice.ts";
import IconHamburgerMenu from "../../../../../components/generic/icons/menuIcons/IconHamburgerMenu.tsx";

function HamburgerBar() {
    const dispatch = useAppDispatch()
    return (
        <button type="button" onClick={() => {
            dispatch(activateMobileMenu(true))
        }}>
            <IconHamburgerMenu/>
        </button>
    )
}

export default HamburgerBar