import {ReactNode} from 'react';

interface ModalBodyProps {
    /** Content of modal */
    message: ReactNode | string,
    /** Optional */
    messageIcon?: string,
    isInnerHtml?: boolean,
}

function ModalBody({message, messageIcon, isInnerHtml}: ModalBodyProps) {
    return (
        <div className="modal-body gb-modal-body">
            {!!messageIcon && (
                <svg width="25" height="25">
                    <use href={messageIcon} xlinkHref={messageIcon}/>
                </svg>
            )}
            {isInnerHtml ? (
                <div dangerouslySetInnerHTML={{__html: (`${message}`)}}/>
            ) : (
                message
            )}
        </div>
    );
}

export default ModalBody;
