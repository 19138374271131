/**
 * Returns notification class based on params passed
 * @param type {'orange', 'blue', 'red', 'large', 'green', 'suggestion'}
 * @param isRow {boolean}
 * @param isBorder {boolean}
 * @param isLarge {boolean}
 * @param isFullWidth {boolean}
 * @param additionalClass {string}
 * @returns {`${string} gb--notification ${string}gb--notification-${string}${string}`}
 */
interface NotificationClassAggregatorAttributes {
    type: 'orange' | 'blue' | 'red' | 'large' | 'green' | 'suggestion',
    isRow: boolean,
    isBorder: boolean,
    isLarge: boolean,
    isFullWidth: boolean,
    additionalClass: string,
}

export default function notificationClassAggregator(props: NotificationClassAggregatorAttributes): string {
    const {
        type,
        isRow,
        isBorder,
        isLarge,
        isFullWidth,
        additionalClass,
    } = props

    const rowClass = isRow ? 'gb--row gb--row-middle' : ''
    const borderClass = isBorder ? '-border gb--notification-border' : ''
    const largeClass = isLarge ? 'gb--notification-large ' : ''
    const fullWidthClass = isFullWidth ? 'gb--notification-full-width ' : ''

    return `${rowClass} gb--notification ${largeClass}${fullWidthClass}gb--notification-${type}${borderClass} ${additionalClass}`
}