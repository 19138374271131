import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

interface IconPlusType extends IconType {
    backgroundFill?: string,
}

function IconPlus(props: IconPlusType) {
    const {
        width = '24',
        height = '24',
        fill = '#4a79e2',
        backgroundFill = '#f6f8fd',
        viewBox = '0 0 24 24',
        title = 'IconPlus',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <circle cx="12" cy="12" fill={backgroundFill} r="12"/>
                    <path
                        d="m12 6c.2708333 0 .5052083.09375.703125.28125s.296875.41666667.296875.6875v4.03125h4.03125l.1319444.0082465c.2141204.0274885.3993056.1236979.5555556.2886285.1875.1979167.28125.4322917.28125.703125s-.09375.5052083-.28125.703125-.4166667.296875-.6875.296875h-4.03125v4.03125l-.0082465.1319444c-.0274885.2141204-.1236979.3993056-.2886285.5555556-.1979167.1875-.4322917.28125-.703125.28125s-.5052083-.09375-.703125-.28125-.296875-.4166667-.296875-.6875v-4.03125h-4.03125l-.13194444-.0082465c-.21412037-.0274885-.39930556-.1236979-.55555556-.2886285-.1875-.1979167-.28125-.4322917-.28125-.703125s.09375-.5052083.28125-.703125.41666667-.296875.6875-.296875h4.03125v-4.03125l.0082465-.13194444c.0274885-.21412037.1236979-.39930556.2886285-.55555556.1979167-.1875.4322917-.28125.703125-.28125z"
                        fill={fill}
                        fillRule="nonzero"
                    />
                </g>
            )}
        />

    );
}

export default IconPlus;
