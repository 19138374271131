import Card from "../Card.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import LinePlaceholder from "../../../features/placeholders/components/LinePlaceholder.tsx";

const VehicleTypeCardLoader = () => {
    const additionalClass = 'gb--vehicle gb--card-enlarge__small';
    return (
        <div>
            <div className='gb--row gb--row-justify' key={"vehicle-loader"}>
                <div className='gb--column gb--column-center card-width'>
                    <Card additionalClass={additionalClass}>
                        <Row justify center className='gb--vehicle__content'>
                            <Column className='gb--vehicle__column'>
                                <Row justify center>
                                    <Column justify className='gb--vehicle__mobileInfo'>
                                        <Row className='gb--vehicle__info'>
                                            <Column>
                                                <Column className='gb--vehicle__container'>
                                                    <div className={`gb--vehicle__info`}>
                                                        <Column className='gb--vehicle__name'>
                                                            <LinePlaceholder height={'40px'}/>
                                                        </Column>
                                                        <Column className='gb--vehicle__image'>
                                                            <LinePlaceholder placeholderClassName={'gb--vehicle-loader__image'} height={'70px'}/>
                                                        </Column>
                                                    </div>
                                                </Column>
                                            </Column>
                                        </Row>
                                        <Row>
                                            <LinePlaceholder placeholderClassName={'gb--vehicle-loader__amenities'} height={'30px'}/>
                                        </Row>
                                    </Column>

                                </Row>
                            </Column>
                            <Column className='gb--vehicle__paymentInfo'>
                                <Row center justify>
                                    <Column>
                                        <div>
                                            <LinePlaceholder placeholderClassName={'gb--vehicle-loader__price'} height={'50px'}/>
                                        </div>
                                    </Column>
                                </Row>
                                <Row>
                                    <LinePlaceholder placeholderClassName={'gb--vehicle__button gb--vehicle-loader__button'} height={'20px'}/>
                                </Row>
                            </Column>
                        </Row>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default VehicleTypeCardLoader;