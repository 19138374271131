import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {setNumberOfPassengers} from "../../../../../reducers/formSlice.ts";
import useQuantityAwareTranslation from "../../../../../features/translation/hooks/useQuantityAwareTranslation.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";

interface PassengerPickerOptionProps {
    setIsDropdownOpen: (value?: boolean) => void,
    option: number,
}

function PassengerPickerOption({setIsDropdownOpen, option}: PassengerPickerOptionProps) {
    const dispatch = useAppDispatch()
    const isMobile = useIsMobile()
    const handleSelectPax = (value: number) => {
        dispatch(setNumberOfPassengers(value))
    }

    const passengerLabel = useQuantityAwareTranslation('search.form.passenger', option)

    return (
        <div
            key={option}
            onClick={() => {
                handleSelectPax(option)
                setIsDropdownOpen(false)
            }}
            style={isMobile ? {
                height: '5rem',
                padding: '1.1rem 2rem',
                borderBottom: '0.3px solid #dde3e8',
                cursor: 'pointer',
                userSelect: 'none'
            } : {}}
        >
            {option} {passengerLabel}
        </div>
    )
}

export default PassengerPickerOption