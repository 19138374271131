import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconWiFi(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconWiFi',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} fillRule="nonzero">
                    <g transform="translate(1 2)">
                        <path
                            d="m7 6.52684827c.24201021 0 .47915934.03973506.7098865.10087041-.78897713.58125811-1.33587303 1.41951507-1.48414087 2.37330966l-1.20165296-1.02000994c-.34466482-.29226241-.24358186-.78809659.19725218-.96369213.54051306-.2160718 1.14267484-.490478 1.77865515-.490478zm.00435247-3.25353669c1.48676193 0 2.86961657.59652669 4.04821583 1.21489908.5330547.28013514.6502774.88490606.269535 1.29159965l-.0828123.0785428-.018953.01600773c-.2915508-.05535389-.5910684-.08563177-.898415-.08563177-.2321149 0-.4597646.01726931-.68146025.05046316-.78607232-.39764341-1.68170541-.78724484-2.63611028-.78724484-1.0697947 0-2.07362115.49099431-2.92510472.93150311-.42117902.21817933-.95888423.17845646-1.30776084-.11620421-.48084605-.40612184-.3853788-1.08556072.19304038-1.38733593 1.17438749-.61362936 2.56008291-1.20659878 4.03982518-1.20659878zm-.00435247-3.27331158c2.51865051 0 4.8310475 1.09003701 6.6428193 2.34093039.4506615.31153201.4773362.89111261.0659847 1.24017143-.342559.29068379-.883774.32940192-1.2719607.08339289-1.5190523-.9631879-3.39833687-1.87750417-5.4368433-1.87750417s-3.91754259.91431627-5.4365949 1.87809984c-.3874847.24541336-.9294017.2072909-1.27196063-.0833929-.41135151-.34905881-.3853788-.92863941.06598471-1.24017143 1.81177173-1.25148904 4.12392031-2.34152605 6.64257082-2.34152605z"
                            fill="#58627c"/>
                        <path
                            d="m11.8533842 7.14661889c.1954877.19549186.1954877.51147869 0 .70697054l-1.99987504 1.99991667c-.09749391.09749594-.22548591.1464939-.35347791.1464939s-.255984-.04899796-.35347791-.1464939l-.9999375-.99995833c-.19548779-.19549186-.19548779-.51147869 0-.70697054.19548778-.19549186.51146803-.19549186.70695581 0l.6464596.64647306 1.64639705-1.6464314c.1954878-.19549185.5114681-.19549185.7069559 0z"/>
                    </g>
                    <path
                        d="m11 9c-1.38071429 0-2.5 1.1192857-2.5 2.5s1.11928571 2.5 2.5 2.5c1.3807143 0 2.5-1.1192857 2.5-2.5s-1.1192857-2.5-2.5-2.5zm1.3239286 2.0382143-1.4285715 1.4285714c-.0696428.0696429-.1610714.1046429-.2525.1046429-.0914285 0-.1828571-.035-.2525-.1046429l-.71428567-.7142857c-.13964286-.1396429-.13964286-.3653571 0-.505s.36535717-.1396429.50499997 0l.4617857.4617857 1.1760715-1.1760714c.1396428-.1396429.3653571-.1396429.505 0 .1396428.1396428.1396428.3653571 0 .505z"
                        fill="#b5b7d1"/>
                </g>
            )}
        />

    );
}

export default IconWiFi;












