import {LineWidthType} from "../types/LineWidth.type.ts";

export const lineWidths: Record<LineWidthType, number> = {
    "empty": 0,
    "very small": 10,
    "small": 30,
    "medium": 50,
    "long": 70,
    "very long": 90,
    "full": 100,
}