import {toLower} from "lodash";
import useLocale from "../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../types/Locale.type.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {apiSlice} from "../../../api/reducers/apiSlice.ts";
import {useMemo} from "react";
import getCityFromSlug from "../functions/getCityFromSlug.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../types/SearchParams.type.ts";
import {getStationIdFromParam} from "../functions/getStationIdFromParam.ts";
import {getDateRequestFormat} from "../functions/getDateRequestFormat.ts";

export default function useCachedData(getLocationsFromParams: boolean | undefined = false) {
    const {
        successfulSubmitTimestamp,
        searchFinishedTimestamp,
    } = useAppSelector((state) => (state?.form));

    const {
        currentSearch
    } = useAppSelector((state) => state?.searchResults)

    let searchData = apiSlice.endpoints?.initialSearch.useQueryState(currentSearch);

    const {
        data,
        error,
        isError,
        isLoading,
        isFetching,
        isUninitialized,
        status,
        originalArgs
    } = useMemo(() => (searchData), [successfulSubmitTimestamp, searchFinishedTimestamp])


    return {
        fetchedGbtData: data,
        fetchedGbtError: error,
        fetchedGbtIsError: isError,
        fetchedGbtIsLoading: isFetching || isLoading,
        fetchedGbtIsUninitialized: isUninitialized,
        fetchedGbtStatus: status,
        fetchedGbtOriginalArgs: originalArgs
    }
}