import {useContext} from "react";
import {translationsContext} from "../../../context/functions/translationsContext.ts";
import get from "lodash/get"
import stringReplacer from "../functions/stringReplacer.ts";

export default function useTranslation(key: string, variableReplacements: object = {}, exact: boolean = false) {
    // this might be against hooks usage rules, but it saves memory (I believe)
    const translation: string | Record<string, string> = get(useContext(translationsContext), key) ?? key

    if (Object.entries(variableReplacements)?.length > 0 && typeof translation === "string") {
        return stringReplacer(translation, variableReplacements, exact)
    }
    return translation
}