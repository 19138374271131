import {toggleMobileLanguages} from "../../../reducers/mobileSlice";
import MobileOverlay from "./MobileOverlay";
import {useDispatch} from "react-redux";
import LanguageDropdown
    from "../../../layout/header/features/topBar/features/languageSwitcher/components/LanguageDropdown.tsx";

function MobileLanguageSwitcher() {
    const dispatch = useDispatch()
    const backClick = () => dispatch(toggleMobileLanguages())
    return (
        <MobileOverlay title='Choose a language' backClick={backClick} slideInDirection="down">
            <LanguageDropdown/>
        </MobileOverlay>
    );
}

export default MobileLanguageSwitcher;