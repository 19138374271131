import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {setAmenities} from "../../../reducers/functions/bookingSlice.ts";
import {cloneDeep} from "lodash";
import {useToggle} from "@getbybus/gbb-green";
import {useState} from "react";

export const useBookingAmenity = (amenityId: number) => {
    const {
        amenities
    } = useAppSelector((state) => state?.bookingForm)
    const dispatch = useAppDispatch();
    const currentAmenities = cloneDeep(amenities)
    const {value, onChange} = useToggle(false)

    const [count, setCount] = useState<number>(currentAmenities[amenityId] ? cloneDeep(currentAmenities[amenityId]?.count) : 0)
    const [returnCount, setReturnCount] = useState<number>(currentAmenities[amenityId] ? cloneDeep(currentAmenities[amenityId]?.returnCount) : 0)



    const handleIncrement = (key: number) => {
        if (amenities[key].maxQuantity > amenities[key].count) {
            currentAmenities[key].count = currentAmenities[key].count + 1
            dispatch(setAmenities(currentAmenities))
        }
    }

    const handleDecrement = (key: number) => {
        if (amenities[key].count > 0) {
            currentAmenities[key].count -= 1
            dispatch(setAmenities(currentAmenities))
            if (currentAmenities[key].count === 0 &&  currentAmenities[key].returnCount === 0) {
                onChange()
            }
        }
    }

    const handleReturnIncrement = (key: number) => {
        if (amenities[key].maxQuantity > amenities[key].returnCount) {
            currentAmenities[key].returnCount = currentAmenities[key].returnCount + 1
            dispatch(setAmenities(currentAmenities))
        }
    }

    const handleReturnDecrement = (key: number) => {
        if (amenities[key].returnCount > 0) {
            currentAmenities[key].returnCount -= 1
            dispatch(setAmenities(currentAmenities))
            if (currentAmenities[key].count === 0 &&  currentAmenities[key].returnCount === 0) {
                onChange()
            }
        }
    }

    const handleInitialIncrement = (key: number, isReturn: boolean) => {
        if (amenities[key].maxQuantity > amenities[key].count) {
            currentAmenities[key].count = 1
            if (isReturn) {
                currentAmenities[key].returnCount = 1
            }
            dispatch(setAmenities(currentAmenities))
        }
    }

    const handleIntegrationDecrement = (key: number, isReturn: boolean) => {
        if (amenities[key].count > 0) {
            currentAmenities[key].count = 0

            if (isReturn) {
                currentAmenities[key].returnCount = 0
            }
            dispatch(setAmenities(currentAmenities))
        }
    }

    const handleSave = (count: number, returnCount: number, key: number) => {
        currentAmenities[key].count = count
        currentAmenities[key].returnCount = returnCount
        dispatch(setAmenities(currentAmenities))
    }

    const handleMobileIncrement = () => {
        if (count < currentAmenities[amenityId].maxQuantity) {
            setCount(count + 1)
        }
    }

    const handleMobileDecrement = () => {
        if (count > 0) {
            setCount(count - 1)
        }
    }

    const handleMobileReturnIncrement = () => {
        if (returnCount < currentAmenities[amenityId].maxQuantity) {
            setReturnCount(returnCount + 1)
        }
    }

    const handleMobileReturnDecrement = () => {
        if (returnCount > 0) {
            setReturnCount(returnCount - 1)
        }
    }

    return {
        handleIncrement,
        handleDecrement,
        handleReturnIncrement,
        handleReturnDecrement,
        handleInitialIncrement,
        handleIntegrationDecrement,
        handleMobileIncrement,
        handleMobileDecrement,
        handleMobileReturnIncrement,
        handleMobileReturnDecrement,
        handleSave,
        count,
        returnCount,
        value,
        onChange,
        currentAmenities
    }
}