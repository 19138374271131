import {ForwardedRef, ReactNode} from 'react';
import BackButton from "../../../routing/components/BackButton";

import {Column, Row} from "@getbybus/gbb-green";

interface MobileAppTitleProps {
    title: string | ReactNode,
    subtitle?: string | ReactNode,
    filtersButtonVisible?: boolean,
    backClick?: (arg0: any) => (any),
    className?: string,
    subtitleClassName?: string,
    titleRef?: ForwardedRef<null>,
    fitContent: boolean,
}

function MobileAppTitle(props: MobileAppTitleProps) {
    const {
        title,
        subtitle,
        backClick = undefined,
        className = "gb--mobile-overlay__content-title gb--emphasize",
        subtitleClassName = "gb--mobile-overlay__content-subtitle",
        titleRef = null,
        fitContent,
    } = props;
    return (
        <div className="gb--mobile__app-title" ref={titleRef}>
            {fitContent && (
                <div className="gb--mobile-overlay__content-slide"/>
            )}
            <Column className="gb--app-title" style={{gap: "1rem"}}>
                <BackButton backClick={backClick}/>
                <Row>
                    <span className={className}>
                        {title}
                    </span>
                </Row>
                {!!subtitle && (
                    <Row>
                         <span className={subtitleClassName}>
                            {subtitle}
                        </span>
                    </Row>
                )}
            </Column>
        </div>
    );
}

export default MobileAppTitle