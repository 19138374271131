import useLandingOnStaticContentEvent from "./useLandingOnStaticContentEvent.ts";
import {useEffect} from "react";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import useLandingOnHomepageEvent from "./useLandingOnHomepageEvent.ts";
import {
    homepageRouteId,
    staticPagesRouteId,
    successfulRouteId
} from "../../../routing/constants/routeIds.ts";
import {useMatches} from "react-router";
import useLandingOnSuccessfulPaymentEvent from "./useLandingOnSuccessfulPaymentEvent.ts";

export default function useGbtLandingEvents() {
    const landingOnStaticContentEventDispatcher = useLandingOnStaticContentEvent()
    const landingOnHomepageEventDispatcher = useLandingOnHomepageEvent()
    const landingOnSuccessfulBookingEventDispatcher = useLandingOnSuccessfulPaymentEvent()

    // const isBooking = useIsPage("booking")
    const isStaticPage = useRouteMatch(staticPagesRouteId)
    const isHomepage = useRouteMatch(homepageRouteId)
    const isSuccessfulBooking = useRouteMatch(successfulRouteId)

    const matches = useMatches();

    const events = {
        [`${isHomepage}`]: landingOnHomepageEventDispatcher,
        [`${isStaticPage}`]: landingOnStaticContentEventDispatcher,
        [`${isSuccessfulBooking}`]: landingOnSuccessfulBookingEventDispatcher,
    }

    const handleEvent = () => {
        if (events?.true) {
            events.true()
        }
    }

    useEffect(() => {
        handleEvent()
    }, [matches])

}