import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import useCookie from "./useCookie.ts"
import {setCookieState} from "../../../reducers/pageSlice.ts"

export default function useCookies() {
    const dispatch = useAppDispatch()

    const cookiesConsent = useCookie("cookiesConsent")
    // const eventCookie = useCookie("event_cookie")
    const hotjarCookie = useCookie("performanceCookies")

    const acceptAllCookies = () => {
        cookiesConsent.set(true)
        hotjarCookie.set(true)
        // eventCookie.set(eventCookie.isActive)
        dispatch(
            setCookieState({
                name: "cookiesConsent",
                value: true,
            }),
        )
        dispatch(
            setCookieState({
                name: "performanceCookies",
                value: true,
            }),
        )
    }

    const rejectAllCookies = () => {
        cookiesConsent.set(true)
        hotjarCookie.set(false)

        dispatch(
            setCookieState({
                name: "cookiesConsent",
                value: true,
            }),
        )
        dispatch(
            setCookieState({
                name: "performanceCookies",
                value: false,
            }),
        )
    }

    return {
        cookiesConsent,
        // eventCookie,
        hotjarCookie,
        acceptAllCookies,
        rejectAllCookies,
    }
}
