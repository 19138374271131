import {MutableRefObject, ReactNode} from "react";

import IconTimes from "../../../components/generic/icons/IconTimes.tsx";

interface DialogProps {
    dialogRef: MutableRefObject<HTMLDialogElement | null>
    background?: "white" | "gray"
    children?: ReactNode,
    position?: "absolute" | "fixed",
    close?: () => void,
}

function Dialog(props: DialogProps) {
    const {
        dialogRef,
        children,
        background = "white",
        close = () => {
            dialogRef.current?.close()
        }
    } = props

    return (
        <>
            <dialog
                ref={dialogRef}
                className={`gb--dialog gb--dialog-${background}`}
            >
                <button onClick={close} type="button" className="gb--dialog-close">
                    <IconTimes/>
                </button>
                {children}
            </dialog>

        </>
    )
}

export default Dialog