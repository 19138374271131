import Modal from "../../../../../components/generic/modal/Modal.tsx";
import IconTimes from "../../../../../components/generic/icons/IconTimes.tsx";
import BookingAmenityCounter from "./BookingAmenityCounter.tsx";
import {BookingAmenityType} from "../../../reducers/types/BookingAmenity.type.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useBookingAmenity} from "../hooks/useBookingAmenity.ts";

interface BookingAmenitySelectorMobileProps {
    price: number,
    title: string
    amenityId: number
    handleSave: (count: number, returnCount: number, key: number) => void
    returnTrip: boolean
    handleClose: () => void
    description?: string
    currentAmenities: Record<number, BookingAmenityType>
}

const BookingAmenitySelectorMobile = (props: BookingAmenitySelectorMobileProps) => {
    const outboundTransfer = useTranslation('booking.title.outboundTransfer') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const addAmenity = useTranslation('booking.form.addAmenity') as string;

    const {
        handleClose,
        title,
        description,
        handleSave,
        price,
        amenityId,
        returnTrip
    } = props

    const {
        handleMobileIncrement,
        handleMobileDecrement,
        handleMobileReturnIncrement,
        handleMobileReturnDecrement,
        count,
        returnCount,
    } = useBookingAmenity(amenityId);

    const [num, fraction] = price.toFixed(2).toString().split(".")

    return (
            <Modal
                className="modal-dialog gb-modal-dialog gb--stripe__modal gb--slide-in-container gb--slide-in-up"
                message={
                    <div
                        className={"gb--amenities-mobile"}
                    >
                        <div>
                            <div>
                                <button onClick={handleClose}>
                                    <IconTimes fill="#9b9b9b"/>
                                </button>
                            </div>
                            <div className={"gb--amenities-mobile-header"}>
                                <div className={'gb--amenities-mobile-title'}>
                                    <span>{title}</span>
                                </div>
                                <div className={'gb--amenities-mobile-price'}>
                                    <b>
                                        <span style={{fontSize: "1.3em", paddingBottom: "1rem"}}>+</span>
                                        <span style={{marginTop: "auto", fontSize: "1.2em"}}>{num},</span>
                                        <sup style={{fontSize: ".7em", paddingBottom: ".5rem"}}>{fraction}</sup>
                                        <span style={{marginTop: "auto", fontSize: "larger"}}> &#8364;</span>
                                    </b>
                                </div>
                            </div>
                            <hr className={'gb--amenities-mobile-divider'}/>
                            <div>
                                {description}
                            </div>
                        </div>
                        <div>
                            <BookingAmenityCounter
                                price={price}
                                carRotation={'right'}
                                quantity={count}
                                handleDecrement={handleMobileDecrement}
                                handleIncrement={handleMobileIncrement}
                                description={outboundTransfer}
                            />
                            {returnTrip && (
                                <BookingAmenityCounter
                                    price={price}
                                    description={returnTransfer}
                                    handleIncrement={handleMobileReturnIncrement}
                                    handleDecrement={handleMobileReturnDecrement}
                                    quantity={returnCount}
                                    carRotation={'left'}
                                />
                            )}
                        </div>
                        <button
                            className='btn btn--blue-darker btn--mobile return-button-submit return-button'
                            onClick={() => {
                                handleSave(count, returnCount, amenityId)
                                handleClose()
                            }}
                        >
                            {addAmenity.replace('%Amenity%', title)}
                        </button>
                    </div>
                }
            >
            </Modal>
    );
};

export default BookingAmenitySelectorMobile;