import {capitalize} from "lodash";

export default function getCityFromSlug(citySlug: string, type: "slug" | "display" = "slug") {
    const cityData = citySlug.split("-").slice(0, -1)
    const formatCity = {
        [`${type === "slug"}`]: cityData.join("-"),
        [`${type === "display"}`]: cityData.map((word) => (capitalize(word))).join(" ")
    }

    return formatCity.true
}