import {ReactNode, useState} from 'react';
import IconChevron from "./icons/IconChevron.tsx";

interface CollapsableComponentProps {
    label: string | ReactNode;
    description?: string;
    children: ReactNode;
    collapsable?: boolean;
    closed?: boolean;
    customButton?: ReactNode;
    wrapper?: boolean;
    wrapperClassName?: string;
    hrClassName?: string;
    customDividerClassName?: string;
}

const CollapsableComponent = (props: CollapsableComponentProps) => {
    const {
        label,
        description,
        children,
        collapsable,
        wrapperClassName = "",
        customDividerClassName = 'gb--divider',
        customButton,
        closed = false,
        wrapper,
        hrClassName
    } = props;
    const [isOpen, setIsOpen] = useState(!closed)

    const handleOpen = () => {
        if (collapsable) {
            setIsOpen(!isOpen);
        }
    }

    return (
        <>
            <div onClick={handleOpen} className={customDividerClassName}>
                <div className='gb--divider__content'>
                    <span>{label}</span>
                    {description ? description : null}
                </div>
                { collapsable &&
                    <div>
                        { isOpen
                            ? <IconChevron chevronOrientation='up' fill='#777' width='23' height='23'/>
                            : <IconChevron chevronOrientation='down' fill='#777' width='23' height='23'/>
                        }

                    </div>
                }
                { customButton
                    ? customButton
                    : null
                }
            </div>
            <hr className={hrClassName}/>
            { isOpen &&
                <div className={wrapper ? 'gb--preview__container' : wrapperClassName}>
                    {children}
                </div>

            }
        </>
    );
};

export default CollapsableComponent;