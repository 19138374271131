import {secondsToHoursAndMinutes} from "../../../../../utils/dateTime/secondsToHoursAndMinutes.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

export default function useDisplayDuration(duration: number) {
    const around = useTranslation("booking.form.around") as string
    const {hours, minutes} = secondsToHoursAndMinutes(duration)

    const durationDisplay = `${hours ? `${hours}h` : ``} ${minutes}min`

    return `${around} ~ ${durationDisplay}`
}