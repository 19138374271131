import MobileMenuOverlay from "../features/mobileMenuOverlay/components/MobileMenuOverlay.tsx";
import MobilePassengerPickerOverlay from "./MobilePassengerPickerOverlay.tsx";
import MobileLanguageSwitcher from "./MobileLanguageSwitcher";
import MobileDatePicker from "./MobileDatePicker";
import MobilePlaceInputOverlay from "./MobilePlaceInputOverlay.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector";
import ReturnMobileDatePicker from "./ReturnMobileDatePicker.tsx";
import IncompleteSearchFormDataOverlayWrapper
    from "../features/incompleteSearchFormDataOverlay/components/IncompleteSearchFormDataOverlayWrapper.tsx";
import MobileSearchFormOverlayWrapper
    from "../features/mobileSearchFormOverlay/components/MobileSearchFormOverlayWrapper.tsx";
import MobileStripeOverlay from "./MobileStripeOverlay.tsx";
import StaticMobileDatePicker from "./StaticMobileDatePicker.tsx";

function MobileOverlayContainer() {
    const {
        mobileMenuActive,
        mobileLanguagesActive,
        mobilePassengerPickerActive,
        singleCalendarActive,
        returnCalendarActive,
        staticCalendarActive,
        departurePlaceInputActive,
        destinationPlaceInputActive,
        incompleteSearchFormDataOverlayActive,
        mobileSearchFormOverlayActive,
        stripeMobileOverlay
    } = useAppSelector((state) => state?.mobile)

    const overlaySubtree = {
        [`${mobileSearchFormOverlayActive}`]: <MobileSearchFormOverlayWrapper/>,
        [`${incompleteSearchFormDataOverlayActive}`]: <IncompleteSearchFormDataOverlayWrapper/>,
        [`${stripeMobileOverlay}`]: <MobileStripeOverlay/>
    }

    const singleOverlays = {
        [`${mobileMenuActive}`]: <MobileMenuOverlay/>,
        [`${mobileLanguagesActive}`]: <MobileLanguageSwitcher/>,
        [`${mobilePassengerPickerActive}`]: <MobilePassengerPickerOverlay/>,
        [`${singleCalendarActive}`]: <MobileDatePicker/>,
        [`${departurePlaceInputActive || destinationPlaceInputActive}`]: <MobilePlaceInputOverlay/>,
        [`${returnCalendarActive}`]: <ReturnMobileDatePicker/>,
        [`${staticCalendarActive}`]: <StaticMobileDatePicker/>,
    }
    return overlaySubtree?.true ?? singleOverlays?.true ?? <></>
}

export default MobileOverlayContainer;