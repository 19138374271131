import {useEffect} from "react";
import {useLogError} from "../../../hooks/useLogError.ts";
import {useMatches, useRouteError} from "react-router";
import IconSomethingWentWrong from "../../../../../components/generic/icons/errorIcons/IconSomethingWentWrong.tsx";
import rootExtractor from "../../../../../utils/dataset/rootExtractor.ts";
import {LocaleType} from "../../../../../types/Locale.type.ts";
import UrlObjectType from "../../../../../types/UrlObject.type.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import Column from "../../../../containers/components/Column.tsx";

function ErrorBoundaryDefault({shouldLogError = false}: {shouldLogError?: boolean}) {
    const urlObjects= rootExtractor('urlsObject', true) as Record<LocaleType, UrlObjectType[]>;
    const locale = useLocale() as LocaleType;
    const matches = useMatches();
    const {sendLog} = useLogError();
    const error = useRouteError();

    useEffect(() => {
        if (shouldLogError) {
            sendLog({
                trace: matches[matches.length - 1].id,
                url: window.location.pathname,
                message: error.message,
                info: {
                    trace: error.stack
                }
            })
        }
    }, []);

    return (
        <>
            <div className="page__main-content gb-container gb--error-page">
                <h1>Oops! Something went wrong :(</h1>
                <p>Try to refresh the page or use one of the links below.</p>
                <IconSomethingWentWrong height={'200'} width={'100%'}/>
                <Column>
                    <span> Try thesre links instead:</span>
                    <span>
                        <a href={urlObjects[locale].homePage.url}>{urlObjects[locale].homePage.text}</a>
                        {' '} | {' '}
                        <a href={urlObjects[locale].contact.url}>{urlObjects[locale].contact.text}</a>
                    </span>
                </Column>
            </div>
        </>
    )
}

export default ErrorBoundaryDefault
