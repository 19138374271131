import {createSlice} from '@reduxjs/toolkit'

interface FormSliceStateType {
    publishableKey: string;
    clientSecret: string;
    bookingVerificationKey: string;
    bookingId: string;
}


const initialState: FormSliceStateType = {
    publishableKey: '',
    clientSecret: '',
    bookingVerificationKey: '',
    bookingId: ''
}

const stripeSlice = createSlice({
    name: 'stripe',
    initialState,
    reducers: {
        setPublishableKey: (state, action) => (
            {...state, publishableKey: action.payload}
        ),
        setClientSecret: (state, action) => (
            {...state, clientSecret: action.payload}
        ),
        setBookingId: (state, action) => (
            {...state, bookingId: action.payload}
        ),
        setBookingVerificationKey: (state, action) => (
            {...state, bookingVerificationKey: action.payload}
        ),
        clear: () => initialState,
    },
})

export const {
    setPublishableKey,
    setClientSecret,
    setBookingVerificationKey,
    setBookingId,
    clear
} = stripeSlice.actions

export default stripeSlice.reducer