import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";
import {AmenityType} from "../../../../../types/Amenity.type.ts";
import {useGetAmenityObject} from "../../../../bookingForm/features/BookingAmenities/hooks/useGetAmenityObject.ts";
import {useState} from "react";
import useIsDesktop from "../../../../../hooks/selector/useIsDesktop.ts";

interface VehicleCapacityInfoProps {
    vehicleType: VehicleType;
    amenities?: AmenityType[]
}

const VehicleAmenities = (props: VehicleCapacityInfoProps) => {
    const isDesktop = useIsDesktop()
    const {
        amenities
    } = props

    const {getAmenityObjectIcon} = useGetAmenityObject()
    let displayAmenity = 0
    let amenitiesCount = 0
    const [displayMore, setDisplayMore] = useState(false)

    return (
        <Row className='gb--vehicle__capacity'>
            {amenities?.map((amenity) => {
                const AmenityIcon = getAmenityObjectIcon(amenity.id)

                if (displayAmenity + amenity.name.length < 50 && amenitiesCount < 3) {
                    amenitiesCount += 1
                    displayAmenity += amenity.name.length
                    return (
                        <Column key={amenity.id}>
                            <Row justify>
                                <AmenityIcon
                                    fill={'#58627c'}
                                    width={'20'}
                                    height={'18'}
                                    className='gb--vehicle__icon'
                                />
                                {isDesktop && amenity.name}
                            </Row>
                        </Column>
                    )
                } else if (!displayMore) {
                    setDisplayMore(true)
                }
            })}

            {displayMore && (
                <Column>
                    <Row>
                        <Column>
                            <Row justify>
                                More
                                <IconChevron
                                    chevronOrientation={"right"}
                                    height={'15px'}
                                    width={'15px'}
                                    className='gb--vehicle__icon'
                                />
                            </Row>
                        </Column>
                    </Row>
                </Column>
            )}
        </Row>
    );
};

export default VehicleAmenities;