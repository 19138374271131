import {ReactNode} from 'react';
import getSlideInContainerClassName from "../functions/getSlideInContainerClassName.ts";

interface SlideInContainerProps {
    children: ReactNode,
    className?: string,
    slideInDirection?: 'up' | 'down' | 'left' | 'right'
}

const SlideInContainer = ({children, className = "", slideInDirection = "down"}: SlideInContainerProps) => (
    <div className={`${getSlideInContainerClassName(slideInDirection)} ${className} `}>
        {children}
    </div>
);

export default SlideInContainer;