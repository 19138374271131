import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconPassengersAdd(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#070c63',
        viewBox = '0 0 16 16',
        title = 'IconPassengersAdd',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="matrix(-1 0 0 1 15 1)">
                    <path
                        d="m8.140625 5.5c-.05208333-.25-.078125-.5-.078125-.75 0-.13541667.00520833-.265625.015625-.390625-.39583333-.05208333-.7265625-.23177083-.9921875-.5390625s-.3984375-.6640625-.3984375-1.0703125c0-.44791667.15885417-.83072917.4765625-1.1484375s.70052083-.4765625 1.1484375-.4765625c.29166667 0 .5625.07291667.8125.21875s.44270833.33854167.578125.578125c.34375-.1875.703125-.3125 1.078125-.375-.2291667-.46875-.5651042-.84375-1.0078125-1.125-.44270833-.28125-.9296875-.421875-1.4609375-.421875-.76041667 0-1.40885417.26822917-1.9453125.8046875s-.8046875 1.18489583-.8046875 1.9453125c0 .72916667.25 1.359375.75 1.890625s1.109375.81770833 1.828125.859375z"
                        transform="matrix(-1 0 0 1 16.34375 0)"
                    />
                    <path
                        d="m5.03125 2c.76041667 0 1.40885417.26822917 1.9453125.8046875s.8046875 1.18489583.8046875 1.9453125-.26822917 1.40885417-.8046875 1.9453125-1.18489583.8046875-1.9453125.8046875-1.40625-.265625-1.9375-.796875c-.54166667-.54166667-.8125-1.19270833-.8125-1.953125s.26822917-1.40885417.8046875-1.9453125 1.18489583-.8046875 1.9453125-.8046875zm0 1.125c-.44791667 0-.83072917.15885417-1.1484375.4765625s-.4765625.70052083-.4765625 1.1484375.16145833.83333333.484375 1.15625c.3125.3125.69270833.46875 1.140625.46875s.83072917-.15885417 1.1484375-.4765625.4765625-.70052083.4765625-1.1484375-.15885417-.83072917-.4765625-1.1484375-.70052083-.4765625-1.1484375-.4765625z"
                    />
                    <path
                        d="m8.234375 11.5625c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375c0-1.20833333.42708333-2.23958333 1.28125-3.09375.8541667-.85416667 1.8854167-1.28125 3.09375-1.28125.1145833 0 .234375.00520833.359375.015625-.25-.34375-.421875-.72395833-.515625-1.140625-.9791667.03125-1.8776042.29427083-2.6953125.7890625-.81770833.49479167-1.46354167 1.15885417-1.9375 1.9921875s-.7109375 1.7395833-.7109375 2.71875c0 .15625.0546875.2890625.1640625.3984375s.2421875.1640625.3984375.1640625z"
                        transform="matrix(-1 0 0 1 21.203124 0)"
                    />
                    <path
                        d="m3.1875 13.5625c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375c0-1.2083333.42708333-2.2395833 1.28125-3.09375.85416667-.85416667 1.88541667-1.28125 3.09375-1.28125.29166667 0 .58333333.03125.875.09375.45833333-.34375.96875-.56770833 1.53125-.671875-.76041667-.36458333-1.5625-.546875-2.40625-.546875-1.52083333 0-2.81770833.53645833-3.890625 1.609375-1.07291667 1.0729167-1.609375 2.3697917-1.609375 3.890625 0 .15625.0546875.2890625.1640625.3984375s.2421875.1640625.3984375.1640625z"
                        transform="matrix(-1 0 0 1 13.15625 0)"
                    />
                    <path
                        d="m2.5 13.6875c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375v-1.375h1.375c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375-.0546875-.2890625-.1640625-.3984375-.2421875-.1640625-.3984375-.1640625h-1.375v-1.375c0-.15625-.0546875-.2890625-.1640625-.3984375s-.2421875-.1640625-.3984375-.1640625-.2890625.0546875-.3984375.1640625-.1640625.2421875-.1640625.3984375v1.375h-1.375c-.15625 0-.2890625.0546875-.3984375.1640625s-.1640625.2421875-.1640625.3984375.0546875.2890625.1640625.3984375.2421875.1640625.3984375.1640625h1.375v1.375c0 .15625.0546875.2890625.1640625.3984375s.2421875.1640625.3984375.1640625z"
                        transform="matrix(-1 0 0 1 5 0)"
                    />
                </g>
            )}
        />

    );
}

export default IconPassengersAdd;
