import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

interface IconCabCarProps extends IconType {
    chevronOrientation?:  'right' | 'left',
}

function IconCabCar(props: IconCabCarProps) {
    const {
        width = '20',
        height = '10',
        fill = 'none',
        viewBox = '0 0 20 10',
        title = 'IconCabCar',
        chevronOrientation = "right",
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "right"}`]: "0deg",
        [`${chevronOrientation === "left"}`]: "180deg",
    }

    const style = {verticalAlign: "middle", transform: `rotateX(${orientation.true}) rotate(${orientation.true}`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            style={style}
            {...rest}
            svgIconPath={(
                <g fill={fill} fillRule="evenodd" stroke="#4a4a4a" strokeWidth=".612903" transform="translate(.5)">
                    <g strokeLinejoin="round">
                        <circle cx="3.726452" cy="8.433548" r="1.372903" strokeLinecap="round"/>
                        <circle cx="15.886452" cy="8.433548" r="1.372903" strokeLinecap="round"/>
                        <path
                            d="m1.56903226 3.92258065.41304774-1.49411097c.45933419-1.22462968 1.63022452-2.03621161 2.93840516-2.03621161h5.87759484c.594271 0 1.1375484.3357729 1.4034994.86728258l1.1351948 2.66304"
                            strokeLinecap="round"/>
                        <path d="m9.021935 3.922581v-3.530323" strokeLinecap="round"/>
                        <path d="m5.491613 3.922581v-3.530323" strokeLinecap="round"/>
                        <path
                            d="m17.2593548 8.62967742h1.5690323v-2.74580645c0-.43344516-.351071-.78451613-.7845161-.78451613v-.43344516c0-.20005162-.1502349-.36793807-.3491097-.38990452l-4.3579871-.35342451h-10.98322581c-1.38624 0-2.35354839 1.08380903-2.35354839 2.87329032v1.44154838l2.35354839.39225807"/>
                    </g>
                    <path d="m5.099355 8.629677h9.414193"/>
                </g>
            )}
        />

    );
}

export default IconCabCar;












