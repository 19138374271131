import {PlaceType} from "../../../../../types/Place.type.ts";
import IconLocation from "../../../../../components/generic/icons/bookingIcons/IconLocation.tsx";
import Loader from "../../../../../components/generic/loaders/Loader.tsx";
import {GetPlacesResponseType} from "../../../../../api/types/GetPlacesResponse.type.ts";

interface PlaceSelectOptionsProps {
    isLoading: boolean,
    data: GetPlacesResponseType | undefined,
    handleSelect: (value: PlaceType) => void,
}

function PlaceSelectOptions({isLoading, data, handleSelect}: PlaceSelectOptionsProps) {
    if (isLoading) {
        return (
            <Loader small/>
        )
    }
    return (
        <>
            {data?.data.map((option: PlaceType) => (
                <div
                    className="gb--select-dropdown__places"
                    key={option.placeId}
                    onClick={() => handleSelect(option)}
                >
                    <div>
                        <IconLocation/>
                    </div>
                    {option.placeName ? `${option.placeName}` : `${option}`}
                </div>
            ))}
        </>
    )
}

export default PlaceSelectOptions