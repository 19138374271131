import VehicleInfo from "./VehicleInfo.tsx";
import VehicleAmenities from "./VehicleAmenities.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";

interface VehicleCardVehicleInfoProps {
    vehicleType: VehicleType;
}

const VehicleCardVehicleInfo = (props: VehicleCardVehicleInfoProps) => {
    const { vehicleType } = props;

    return (
        <Column className='gb--vehicle__column'>
            <Row justify center>
                <Column justify className='gb--vehicle__mobileInfo'>
                    <Row className='gb--vehicle__info'>
                        <Column>
                            <VehicleInfo vehicleType={vehicleType}/>
                        </Column>
                    </Row>
                    {vehicleType.amenities?.length > 0 && (
                        <Row>
                            <VehicleAmenities
                                vehicleType={vehicleType}
                                amenities={vehicleType.amenities}
                            />
                        </Row>
                    )}
                </Column>

            </Row>
        </Column>
    );
};

export default VehicleCardVehicleInfo;