import {handleBookingDateFormat} from "../../searchResults/functions/handleBookingDateFormat.ts";
import {handleDisplayTime} from "./handleDisplayTime.ts";

export default function handleDisplayDate(date: string, time: string | null = null, appendSeconds = 0) {
    const startSeconds = new Date(date).getSeconds();
    const startMinutes = new Date(time ?? date).getMinutes();
    const startHours = new Date(time ?? date).getHours();
    const startDay = new Date(date).getDate();
    const startMonth = new Date(date).getMonth();
    const startYear = new Date(date).getFullYear();

    const newDate = new Date(
        new Date(startYear, startMonth, startDay, startHours, startMinutes, startSeconds)
            .setSeconds(startSeconds + Number(appendSeconds))
    );

    return `${handleBookingDateFormat(newDate.toISOString(), '/')} ${time ? ` | ${handleDisplayTime(newDate.toISOString(), ':')}` : ''}`
}