/**
 * Generic array to object conversion where we require objects
 * Added any as return value to enable casting the type on function return
 */
export const convertArrayToObject = (array: any[], key?: string): any => (
    array.reduce((obj: any, item: any) => {
        if (key) {
            return {
                ...obj,
                [item[key]]: item,
            };
        }
        return {
            ...obj,
            ...item,
        };
    }, {})
)