import {
    bookingInfoRouteId,
    bookingRouteId,
    contactRouteId,
    faqRouteId,
    homepageRouteId, privacyPolicyRouteId,
    staticPagesRouteId,
    stationToStationRouteId,
    successfulRouteId, termsAndConditionsRouteId,
    transferRouteId,
    vehicleRouteId
} from "./routeIds.ts";

export const allRouteIds = {
    homepage: homepageRouteId,
    staticPages: staticPagesRouteId,
    stationToStation: stationToStationRouteId,
    booking: bookingRouteId,
    vehicle: vehicleRouteId,
    bookingInfo: bookingInfoRouteId,
    successful: successfulRouteId,
    contact: contactRouteId,
    faq: faqRouteId,
    transfer: transferRouteId,
    privacyPolicy: privacyPolicyRouteId,
    termsAndConditions: termsAndConditionsRouteId,
}