import Card from "../../../../../components/generic/Card.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import {FilterCategoryType} from "../types/FilterCategory.type.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {setPreselectCategory, setSelectedVehicleCategory} from "../../../reducers/functions/searchResultsSlice.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {ceil} from "lodash";

interface FilterCategoryProps {
    vehicleCategory: FilterCategoryType
}

const FilterCategory = (props: FilterCategoryProps) => {
    const {
        vehicleCategory
    } = props;
    const {
        selectedVehicleCategory,
        categoryPrices
    } = useAppSelector((state) => state?.searchResults);
    const dispatch = useAppDispatch();
    const from = useTranslation("booking.chooseVehicle.from") as string;
    const seaterLabel = useTranslation("booking.chooseVehicle.seater") as string;

    const selectCategory = () => {
        dispatch(setPreselectCategory(false));
        if (selectedVehicleCategory === vehicleCategory.id) {
            dispatch(setSelectedVehicleCategory(0));
        } else {
            dispatch(setSelectedVehicleCategory(vehicleCategory.id));
        }
    }

    return (
        <div
            className={'gb--category'}
            onClick={selectCategory}
            key={vehicleCategory.id}
        >
            <Card additionalClass={'gb--category-card gb--card-enlarge'}>
                <Column>
                    <Row className={'gb--category-card-info'} wrap>
                        <img
                            width={'75px'}
                            height={'40px'}
                            src={vehicleCategory.image}
                        />
                        <Column>
                            <b>{vehicleCategory.name}</b>
                            <span>{from} {ceil(categoryPrices[vehicleCategory.id]).toFixed(0)}  €</span>
                        </Column>
                    </Row>
                    <span className={`gb--category-card-capacity ${(selectedVehicleCategory === vehicleCategory.id) ? 'gb--category-card-capacity__selected' : ''}`}>
                        {vehicleCategory.seater} {seaterLabel}
                    </span>
                </Column>

            </Card>
        </div>
    );
};

export default FilterCategory;