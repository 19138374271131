import {useState} from "react";
import AccordionItem from "./AccordionItem";
import Translation from "../../../features/translation/components/Translation";

interface AccordionDataProps {
    data?: {
        question: string,
        answer: string
    }[]
    translated?: boolean
}

const Accordion = ({data, translated = false}: AccordionDataProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleItemClick = (index: number) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            {data?.map((item, index) => (
                <AccordionItem
                    key={index}
                    question={translated ? <span dangerouslySetInnerHTML={{ __html: item.question }} /> : <Translation translationKey={`faqPage.${item.question}`}/>}
                    answer={translated ? <span dangerouslySetInnerHTML={{ __html: item.answer }} /> : <Translation translationKey={`faqPage.${item.answer}`}/>}
                    isOpen={activeIndex === index}
                    onClick={() => handleItemClick(index)}
                />
            ))}
        </>
    );
};

export default Accordion;
