import useNavigateToSearchResults from "../../../routing/navigation/useNavigateToSearchResults.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../types/SearchParams.type.ts";
import {useState} from "react";

export default function useNavigateToSearch() {
    const {navigateToSearchResults: navigate} = useNavigateToSearchResults()
    const [errorNavigatingToSearch, setErrorNavigatingToSearch] = useState(false)

    const {
        fromSlug,
        toSlug,
        date,
        passengers,
    } = useParams<SearchParamsType>()
    const navigateToSearchResults = () => {
        if (!!fromSlug && !!toSlug && !!date && !!passengers) {
            navigate(fromSlug, toSlug, date, passengers)
        } else {
            setErrorNavigatingToSearch(true)
        }
    }

    return {
        navigateToSearchResults,
        errorNavigatingToSearch,
    }
}