import {PagesType} from "../types/Pages.type.ts";
import MobileMenuItem from "./MobileMenuItem.tsx";
import MobileMenuIcons from "./MobileMenuIcons.tsx";
import rootExtractor from "../../../../../utils/dataset/rootExtractor.ts";
import UrlObjectType from "../../../../../types/UrlObject.type.ts";
import {shouldBeInMenu} from "../functions/shouldBeInMenu.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../../../types/Locale.type.ts"

function MobileMenuItemsList() {
    const urlObjects= rootExtractor('urlsObject', true) as Record<LocaleType, UrlObjectType[]>;
    const locale = useLocale();

    return (
        <>
            {Object.entries(urlObjects[locale]).map(([key, urlObject]) => (
                shouldBeInMenu(key as PagesType, urlObject) && (
                    <MobileMenuItem
                        text={urlObject.text}
                        icon={<MobileMenuIcons tag={key as PagesType}/>}
                        to={urlObject.url}
                        reload={urlObject.routeName === 'app_homepage'}
                    />
                )
            ))}
        </>
    )
}

export default MobileMenuItemsList