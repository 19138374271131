import {ReactNode} from 'react';

interface BookingAmenityInfoProps {
    AmenityIcon: ReactNode
    description?: string
    name: string
}


const BookingAmenityInfo = (props: BookingAmenityInfoProps) => {
    const {
        AmenityIcon,
        name,
        description
    } = props


    return (
        <div className={"gb--amenities-info"}>
            <div className={"gb--amenities-main"}>
                <AmenityIcon
                    width={'20'}
                    height={'20'}
                />
            </div>
            <div className={"gb--amenities-secondary"}>
                <div className={"gb--amenities-name"}>
                    {name}
                </div>
                {description && (
                    <>
                        <div className={"gb--amenities-description"}>
                            {description}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BookingAmenityInfo;