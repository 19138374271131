import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import RadioField from "../../../../components/generic/RadioField.tsx"
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts"
import useBookingInfoInput from "../bookingInfoInputField/hooks/useBookingInfoInput.ts"
import {Notification} from "@getbybus/gbb-green"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import ScrollToElement from "../../../../features/scroll/components/ScrollToElement.tsx"
import {useEffect} from "react";

const BookingPaymentSelect = () => {
    const paymentLabel = useTranslation("booking.form.selectPaymentMethod") as string

    const {
        paymentMethods,
        isIntegration
    } = useAppSelector((state) => state?.vehicleType)

    const {paymentTransferForm, submitTimestamp} = useAppSelector((state) => state?.bookingForm)

    const {displayError, errorMessage, updateStore} = useBookingInfoInput({
        formType: "paymentTransferForm",
        field: "paymentMethod",
        fieldValidationMethod: () => paymentTransferForm.paymentMethod.value !== 0,
    })

    const errorMessageString = useTranslation(errorMessage) as string

    useEffect(() => {
        // If the selected vehicle is native and there is only one payment method, select it by default
        if (!isIntegration) {
            updateStore(Number(Object.keys(paymentMethods)[Object.keys(paymentMethods).length - 1]), true, false)
        }
    }, [paymentMethods]);

    return (
        <div className={'gb--booking-item'}>
            <CollapsableComponent label={paymentLabel} hrClassName={'gb--divider__hr'}>
                <div className="gb--booking__payment">
                    {Object.keys(paymentMethods as Record<1 | 2 | 3, string>).map((key) => (
                        <RadioField
                            key={key}
                            label={paymentMethods[key]}
                            selected={paymentTransferForm.paymentMethod.value === Number(key)}
                            value={key}
                            required
                            passengerId={1}
                            onClick={() => {
                                updateStore(Number(key), true, false)
                            }}
                        />
                    ))}
                    {displayError && (
                        <ScrollToElement condition={submitTimestamp}>
                            <Notification
                                style={{marginTop: "1rem"}}
                                isLarge
                                notificationType="red"
                            >
                                {" "}
                                {errorMessageString}{" "}
                            </Notification>
                        </ScrollToElement>
                    )}
                </div>
            </CollapsableComponent>
        </div>
    )
}

export default BookingPaymentSelect
