import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

export const getIncompleteSearchFormDialogContent = (isComplete: boolean, dateSelected: boolean) => {
    if (!isComplete && !dateSelected) {
        return {
            title: useTranslation('booking.search.addMissingDetails') as string,
            description: useTranslation('booking.search.missingCityAndPickUpTime') as string
        }
    } else if (!isComplete && dateSelected) {
        return {
            title: useTranslation('booking.search.addMissingAddress') as string,
            description: useTranslation('booking.search.enterCityAddress') as string
        }
    }

    return {
        title: useTranslation('booking.search.addMissingDateTime') as string,
        description: useTranslation('booking.search.enterPickUpTime') as string
    }
}