// @ts-ignore
import DatePicker from "react-datepicker";
import useDismissAllOverlays from "../hooks/useDismissAllOverlays";
import MobileOverlay from "./MobileOverlay";
import {useDispatch} from "react-redux";
import {generatePath} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute.ts";
import {dismissAllOverlays} from "../../../reducers/mobileSlice.ts";
import {handleBookingDateFormat} from "../../../pages/searchResults/functions/handleBookingDateFormat.ts";

interface StaticMobileDatePickerProps {
    params: any
}

function StaticMobileDatePicker(props: StaticMobileDatePickerProps) {
    const {
        params
    } = props
    const navigate = useNavigate();
    const {getPath} = getTranslatedRoute()
    const dispatch = useDispatch();
    const backClick = useDismissAllOverlays();

    const handleSelectDate = (value: Date) => {
        dispatch(dismissAllOverlays());
        const path = generatePath(`/:locale/${getPath("booking")}/:fromSlug/:toSlug/:date/:passengers/:vehicleGroupId`, {
            ...params,
            date: handleBookingDateFormat(value.toISOString(), '-'),
        });
        navigate(path)
    };

    const minDate = new Date();
    return (
        <MobileOverlay title='Select Date' backClick={backClick}>
            <DatePickerWrapper
                onChange={handleSelectDate}
                useWeekdaysShort
                monthsShown={12}
                inline
                showYearDropdown
                minDate={minDate}
            />
        </MobileOverlay>
    );
}
export default StaticMobileDatePicker