import IconFire from "../../../components/generic/icons/IconFire.tsx";
import {Notification} from "@getbybus/gbb-green";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";

function PricesMayVaryNotification() {
    const {
        forSomeOfTheOffers: forSomeOfTheOffersLabel,
        fixedRate: fixedRateLabel,
        eligibleForImmediateBooking: eligibleForImmediateBookingLabel,
    } = useTranslation("booking.search.notifications.pricesMayVary") as Record<string, string>
    return (
        <Notification
            notificationType="redBorder"
            additionalClass="gb--vehicle__results-fixed-prices-notification gb--card"
        >
            <p>
                {forSomeOfTheOffersLabel}
            </p>
            <span className="gb--vehicle__results-fixed-prices-notification_fixed-rate">
                <IconFire/>
                &nbsp;
                {fixedRateLabel}
            </span>
            &nbsp;
            <span>
                {eligibleForImmediateBookingLabel}
            </span>
        </Notification>
    )
}

export default PricesMayVaryNotification