import Row from "../../features/containers/components/Row.tsx";

interface TabsProps {
    tabs: {
        id: string,
        label: string,
        onClick: () => void,
        isActive: boolean,
    }[]
    wrapperClassName?: string,
    tabClassName?: string,
    activeTabClassName?: string,
}

const Tabs = (props: TabsProps) => {
    const {
        tabs,
        wrapperClassName = "gb--pill-container",
        tabClassName = "gb--pill",
        activeTabClassName = "gb--pill__active"
    } = props

    return (
        <Row className={wrapperClassName}>
            {tabs.map(({isActive, onClick, label, id}) => (
                <div
                    className={`${tabClassName} ${isActive ? activeTabClassName : ""}`}
                    onClick={onClick}
                    key={id}
                >
                    {label}
                </div>
            ))}
        </Row>
    );
};

export default Tabs;