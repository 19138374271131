import Row from "../../../../features/containers/components/Row.tsx"
import Column from "../../../../features/containers/components/Column.tsx"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import PriceDisplay from "./PriceDisplay.tsx";

interface BookingCashPriceDisplayProps {
    displayPrice: number
}

const BookingCashPriceDisplay = (props: BookingCashPriceDisplayProps) => {
    const {displayPrice} = props

    const cashLabel = useTranslation("booking.form.paymentMethodCash") as string

    return (
        <>
            <Row
                justify
                style={{width: "100%"}}
            >
                <Column>{cashLabel}</Column>
                <Column>
                    <PriceDisplay price={displayPrice}/>
                </Column>
            </Row>
        </>
    )
}

export default BookingCashPriceDisplay
