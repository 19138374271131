import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconInfo(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#454b54',
        viewBox = '0 0 16 16',
        title = 'IconInfo',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(-1)">
                    <path
                        d="m9 .45283019c4.1660377 0 7.5471698 3.38113207 7.5471698 7.54716981 0 4.1660377-3.3811321 7.5471698-7.5471698 7.5471698-4.16603774 0-7.54716981-3.3811321-7.54716981-7.5471698 0-4.16603774 3.38113207-7.54716981 7.54716981-7.54716981"/>
                    <path
                        d="m1.88307651.09433962c-.238879 0-.49368327.015-.73256228.045-.0477758 0-.11147687.015-.15925267.015 3.91761566.465 6.95934164 3.615 6.95934164 7.44 0 3.82499998-3.04172598 6.97499998-6.95934164 7.43999998.07962634.015.15925267.015.25480427.03.19110321.015.39813168.03.58923488.03h.1433274c4.39537367 0 8.17761189-3.4140708 7.96263345-7.49999998-.21497842-4.08592917-3.56725978-7.5-7.96263345-7.5-.03185053 0-.06370107 0-.0955516 0z"
                        fill="#7ed321" transform="matrix(-1 0 0 1 10.941262 0)"/>
                    <g fill={fill}>
                        <path
                            d="m9 16c-4.40754717 0-8-3.5924528-8-8 0-4.40754717 3.59245283-8 8-8 4.4075472 0 8 3.59245283 8 8 0 4.4075472-3.5924528 8-8 8zm0-15.09433962c-3.90943396 0-7.09433962 3.18490566-7.09433962 7.09433962 0 3.909434 3.18490566 7.0943396 7.09433962 7.0943396 3.909434 0 7.0943396-3.1849056 7.0943396-7.0943396 0-3.90943396-3.1849056-7.09433962-7.0943396-7.09433962z"/>
                        <path
                            d="m9 4.78490566c-.25660377 0-.45283019.19622642-.45283019.45283019s.19622642.45283019.45283019.45283019.45283019-.19622642.45283019-.45283019-.19622642-.45283019-.45283019-.45283019z"/>
                        <path
                            d="m9 12.3773585c-.25660377 0-.45283019-.1962264-.45283019-.4528302v-4.18113207c0-.25660378.19622642-.45283019.45283019-.45283019s.45283019.19622641.45283019.45283019v4.18113207c0 .2566038-.19622642.4528302-.45283019.4528302z"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconInfo;
