import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconPax(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#4a4a4a',
        viewBox = '0 0 18 16',
        title = 'IconPax',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m9 8.015625c.96875 0 1.7916667-.34375 2.46875-1.03125.6875-.6875 1.03125-1.51302083 1.03125-2.4765625s-.3411458-1.7890625-1.0234375-2.4765625-1.5078125-1.03125-2.4765625-1.03125-1.79427083.34375-2.4765625 1.03125-1.0234375 1.51302083-1.0234375 2.4765625.34114583 1.7890625 1.0234375 2.4765625 1.5078125 1.03125 2.4765625 1.03125zm0-1.125c-.65625 0-1.21614583-.23177083-1.6796875-.6953125s-.6953125-1.02604167-.6953125-1.6875.23177083-1.22395833.6953125-1.6875 1.0234375-.6953125 1.6796875-.6953125 1.2161458.23177083 1.6796875.6953125.6953125 1.02604167.6953125 1.6875-.234375 1.2265625-.703125 1.6953125c-.4583333.45833333-1.015625.6875-1.671875.6875zm-5.4375 7.734375c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375v-.03125c0-1.3541667.4765625-2.5078125 1.4296875-3.4609375s2.1015625-1.4296875 3.4453125-1.4296875c.60416667 0 1.1822917.109375 1.734375.328125.4791667-.28125.9947917-.4375 1.546875-.46875-.9895833-.65625-2.0833333-.984375-3.28125-.984375-1.08333333 0-2.0859375.26822917-3.0078125.8046875s-1.65104167 1.265625-2.1875 2.1875c-.4828125.8296875-.74835938 1.7289844-.79664063 2.6978906l-.00804687.3255469v.03125c0 .15625.0546875.2890625.1640625.3984375s.2421875.1640625.3984375.1640625zm8.9375.375c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375v-1.375h1.375c.15625 0 .2890625-.0546875.3984375-.1640625s.1640625-.2421875.1640625-.3984375-.0546875-.2890625-.1640625-.3984375-.2421875-.1640625-.3984375-.1640625h-1.375v-1.375c0-.15625-.0546875-.2890625-.1640625-.3984375s-.2421875-.1640625-.3984375-.1640625-.2890625.0546875-.3984375.1640625-.1640625.2421875-.1640625.3984375v1.375h-1.375c-.15625 0-.2890625.0546875-.3984375.1640625s-.1640625.2421875-.1640625.3984375.0546875.2890625.1640625.3984375.2421875.1640625.3984375.1640625h1.375v1.375c0 .15625.0546875.2890625.1640625.3984375s.2421875.1640625.3984375.1640625z"/>
            )}
        />

    );
}

export default IconPax;
