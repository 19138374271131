import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useEffect} from "react";

export const usePlacePlaceholderUpdateOnInitialSearch = (name: "departure" | "destination", setPlacePlaceholder: (value: string) => void) => {
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => state?.form)

    useEffect(() => {
        if (name === "departure" && !!fromPlace) {
            setPlacePlaceholder(fromPlace.placeName)
        }
        if (name !== "departure" && !!toPlace) {
            setPlacePlaceholder(toPlace.placeName)
        }
    }, [fromPlace, toPlace]);
}