import IconChild from "../../../../../components/generic/icons/IconChild.tsx";
import IconInfant from "../../../../../components/generic/icons/IconInfant.tsx";
import IconBag from "../../../../../components/generic/icons/IconBag.tsx";
import IconSkis from "../../../../../components/generic/icons/IconSkis.tsx";
import IconWheelchair from "../../../../../components/generic/icons/IconWheelchair.tsx";
import IconNameSign from "../../../../../components/generic/icons/IconNameSign.tsx";
import IconStopwatch from "../../../../../components/generic/icons/IconStopwatch.tsx";
import IconWiFi from "../../../../../components/generic/icons/IconWiFi.tsx";
import IconBicycle from "../../../../../components/generic/icons/IconBicycle.tsx";
import IconDrink from "../../../../../components/generic/icons/IconDrink.tsx";
import IconAirplane from "../../../../../components/generic/icons/bookingIcons/IconAirplane.tsx";
import IconPets from "../../../../../components/generic/icons/IconPets.tsx";
import IconLuggageAssistance from "../../../../../components/generic/icons/IconLuggageAssistance.tsx";
import IconMultilingual from "../../../../../components/generic/icons/IconMultilingual.tsx";
import IconMusicNote from "../../../../../components/generic/icons/IconMusicNote.tsx";

export const AmenityObject = {
    1: {
        icon: IconChild
    },
    2: {
        icon: IconInfant
    },
    3: {
        icon: IconChild
    },
    4: {
        icon: IconBag
    },
    5: {
        icon: IconSkis
    },
    6: {
        icon: IconWheelchair
    },
    7: {
        icon: IconNameSign
    },
    8: {
        icon: IconStopwatch
    },
    9: {
        icon: IconStopwatch
    },
    10: {
        icon: IconStopwatch
    },
    11: {
        icon: IconStopwatch
    },
    12: {
        icon: IconWiFi
    },
    13: {
        icon: IconBicycle
    },
    14: {
        icon: IconDrink
    },
    15: {
        icon: IconAirplane
    },
    16: {
        icon: IconPets
    },
    17: {
        icon: IconLuggageAssistance
    },
    18: {
        icon: IconMultilingual
    },
    19: {
        icon: IconMusicNote
    }
}