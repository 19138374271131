import Row from "../../../../features/containers/components/Row.tsx"
import Column from "../../../../features/containers/components/Column.tsx"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import PriceDisplay from "./PriceDisplay.tsx";

interface BookingPrepaymentPriceDisplayProps {
    displayPrice: number;
    isReturn: boolean;
}

const BookingPrepaymentPriceDisplay = (props: BookingPrepaymentPriceDisplayProps) => {
    const {displayPrice, isReturn} = props
    const outboundTransfer = useTranslation('booking.title.outboundTransfer') as string;
    const price = useTranslation('email.bookingInfo.price') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;

    const displayText = isReturn ? returnTransfer : outboundTransfer;

    return (
        <>
            <Row
                justify
                style={{width: "100%"}}
            >
                <Column>
                    {displayText}  {price.charAt(0).toUpperCase() + price.slice(1)}
                </Column>
                <Column>
                    <PriceDisplay price={displayPrice}/>
                </Column>
            </Row>
        </>
    )
}

export default BookingPrepaymentPriceDisplay
