import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useEffect} from "react";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";

export default function useStopAllExistingBookings() {
    const {bookingId} = useAppSelector((state) => (state?.stripe))
    const {navigateToHome} = useNavigateToHome(true)

    // Use effect for initial search, so it runs only once
    // redirect if the booking id is present ... booking was complete then
    useEffect(() => {
        if (bookingId) {
            navigateToHome()
        }
    }, [])
}