import HeroVectorLarge from "./HeroVectorLarge";
import HeroVectorSmall from "./HeroVectorSmall";
import useRouteMatch from "../../../../../routing/hooks/useRouteMatch.ts";

function HeroBackgroundMain() {
    const isHomepage = useRouteMatch('homepage');

    if (isHomepage) {
        return <HeroVectorLarge/>;
    }

    return <HeroVectorSmall />;
}

export default HeroBackgroundMain
