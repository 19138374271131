import {convertArrayToObject} from "../array/convertArrayToObject";

/**
 * Returns all dataset attributes from an element, does not require manual dataset querying
 * @param id
 * @param className
 * @param tagName
 * @param index
 * @return {object}
 */

interface DatasetParserAttributes {
    id?: string,
    className?: string,
    tagName?: string,
    index?: number,
}

export default function datasetParser({id, className, index}: DatasetParserAttributes): Record<any, any> {
    if ((!!className) && typeof index === "undefined") {
        throw new TypeError(
            `Index must be defined if element is selected via tag or class, error in ${className}`
        );
    }

    const getterMethod = () => {
        if (id) {
            const element = document.getElementById(id)
            if (!element) {
                throw new ReferenceError(
                    `Element with id: ${id} does not exist`
                );
            }
            return element
        }

        if (className && index) {
            const element = document.getElementsByClassName(className)[index]
            if (!element) {
                throw new ReferenceError(
                    `Element with ${id} does not exist`
                );
            }

            if (element instanceof HTMLElement) {
                return element
            } else {
                throw new TypeError(
                    `Element with classname: ${className} at index: ${index} is not an instance of HTMLElement`
                );
            }
        }
    }


    const element = getterMethod()

    if (element) {
        const keys = Object.keys(element.dataset)
        const parsed = keys.map((key) => {
            const dataset = element.dataset[key]
            if (dataset) {
                const parsedKey = dataset.includes("{") || dataset.includes("[")
                    ? JSON.parse(dataset)
                    : dataset
                return {[key]: parsedKey}
            }
        })

        return convertArrayToObject(parsed)
    }

    return {}
}