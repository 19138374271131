import {PlaceInitialState} from "./PlaceInitialState.ts";

export const searchFormInitialState = {
    fromPlace: PlaceInitialState,
    toPlace: PlaceInitialState,
    fromStation: null,
    toStation: null,
    departureDate: '',
    returnDate: null,
    numberOfPassengers: 1,
    formErrors: {
        departureError: null,
        destinationError: null,
        dateError: null,
        passengerError: null,
    },
    submitTimestamp: null,
    successfulSubmitTimestamp: null,
    searchFinishedTimestamp: null,
}