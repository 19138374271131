import differenceInMinutes from "date-fns/differenceInMinutes";
import getDateWithTimezone from "./getDateWithTimezone";

/**
 * Returns difference in timezone between Europe/London & Europe/Zagreb
 */
export default function getServerTimeZoneDifferenceInMinutes(): number {
    const utcTime = getDateWithTimezone("Europe/London")
    const serverTime = getDateWithTimezone("Europe/Zagreb")

    return differenceInMinutes(serverTime, utcTime)
}