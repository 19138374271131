import Portal from "../../../../components/generic/portal/Portal";
import VehicleCard from "../../../../components/generic/card/VehicleCard";
import datasetParser from "../../../../utils/dataset/datasetParser";
import {useHandleFormParams} from "../hooks/useHandleFormParams.ts";
import {useEffect} from "react";
import Row from "../../../../features/containers/components/Row.tsx";
import {getTranslatedRoute} from "../../../../routing/functions/getTranslatedRoute.ts";
import useLocale from "../../../../hooks/selector/useLocale.ts";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";
import IcounDoubleChevron from "../../../../components/generic/icons/IcounDoubleChevron.tsx";

interface VehicleCardsPortalType {
    type: string,
    typeId: number,
    minNoOfPersons: number,
    maxNoOfPersons: number,
    discountedPrice: string,
    returnPrice: string,
    photoUrl: string,
    info: string[]
}

function VehicleCardsPortal() {
    const {vehicles} = datasetParser({id: "vehicle-group-portal"}) as Record<string, VehicleCardsPortalType>;
    const placeholder = document.getElementById('vehicle-placeholder');
    const {
        populateSearchForm
    } = useHandleFormParams();
    const {getPath} = getTranslatedRoute();
    const locale = useLocale();
    const tomorrow = useTranslation('booking.path_params.tomorrow');
    const seeAllResults = useTranslation('booking.chooseVehicle.seeAllResults');

    useEffect(() => {
        placeholder?.remove();
        populateSearchForm();
    }, []);

    const {
        searchUrl: {
            fromId,
            fromSlug,
            toId,
            toSlug,
        },
    } = datasetParser({id: "vehicle-group-portal"})

    return (
        <Portal
            domNodeId="vehicle-group-portal"
        >
            {!!Object.entries(vehicles).length && (
                <Row alignEnd className={'card__seeAll'}>
                    <a href={`/${locale}/${getPath("booking")}/${fromSlug}-${fromId}/${toSlug}-${toId}/${tomorrow}`}>
                        {seeAllResults}
                        <IcounDoubleChevron fill={'#4A79E2'} chevronOrientation={"right"}/>
                    </a>
                </Row>
            )}
            <Row justify wrap gap className={'card__vehicle--list'}>
                {Object.entries(vehicles).map(([key, vehicle]) => {
                    return (
                        <VehicleCard
                            key={key}
                            vehicleGroup={vehicle.type}
                            vehicleGroupId={vehicle.typeId}
                            vehicleImagePath={vehicle.photoUrl}
                            vehicleMinPaxCapacity={vehicle.minNoOfPersons}
                            vehicleMaxPaxCapacity={vehicle.maxNoOfPersons}
                            vehiclePrice={vehicle.discountedPrice}
                            vehicleAmenities={vehicle.amenities}
                            vehicleRating={vehicle.rating}
                            vehicleModelMake={vehicle.modelMake}
                            vehicleRatingCount={vehicle.ratingCount}
                        />
                    )
                })}
            </Row>
        </Portal>
    )
}

export default VehicleCardsPortal;