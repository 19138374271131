import Row from "../../../../features/containers/components/Row.tsx"
import Column from "../../../../features/containers/components/Column.tsx"
import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts"
import VehicleType from "../../../../types/VehicleType.type.ts";

interface BookingVehicleTypePreviewProps {
    vehicleType: VehicleType
    returnTrip?: boolean
}
const BookingTransferPreviewPrice = (props: BookingVehicleTypePreviewProps) => {
    const transferCost = useTranslation("booking.form.transferCost") as string
    const oneWayLabel = useTranslation("booking.form.oneWay") as string
    const returnLabel = useTranslation("booking.form.return") as string
    const feesAndTaxes = useTranslation("booking.form.feesAndTaxes") as string;
    const {vehicleType, returnTrip} = props

    const displayPrice = vehicleType.price
    const trip = returnTrip ? returnLabel : oneWayLabel

    return (
        <CollapsableComponent
            label={transferCost}
            wrapper
            hrClassName={'gb--divider__hr'}
        >
            <Row justify>
                <Column>{trip}</Column>
                <Column>{(displayPrice || 0)} EUR</Column>
            </Row>
            <Row>
                <Column>
                    <p style={{color: "#777"}}>{feesAndTaxes}</p>
                </Column>
            </Row>
        </CollapsableComponent>
    )
}

export default BookingTransferPreviewPrice
