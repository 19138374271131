import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts"
import useLocale from "../../../hooks/selector/useLocale.ts"
import {LocaleType} from "../../../types/Locale.type.ts"
import {getCookie, useSingleTrackingEvent} from "@getbybus/gbb-green"

export default function useGbtSingleTrackingEvent(eventName: string, model?: Record<string, unknown>) {
    const {
        cookiesConsent,
        bawApiUrl,
        eventToken,
    } = useAppSelector((state) => state.page)
    const currentLocale = useLocale() as LocaleType

    const bawBiSessionId = getCookie("bawbisid", false)
    const bawBiVisitorId = getCookie("bawbivid", false)

    return useSingleTrackingEvent({
        eventName,
        cookiesConsent,
        alwaysDispatchTrackingEvents: true,
        currentLocale,
        bawApiUrl,
        eventToken,
        model: {
            ...model,
            visitor_id: bawBiVisitorId,
            session_id: bawBiSessionId,
        },
    })
}
