import PassengerPickerOption from "./PassengerPickerOption.tsx"

interface PassengerPickerOptionsProps {
    setIsDropdownOpen: (value?: boolean) => void
}

function PassengerPickerOptions({setIsDropdownOpen}: PassengerPickerOptionsProps) {
    const paxOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] // TODO replace dummy data with the store data

    return (
        <>
            {paxOptions.map((option) => (
                <PassengerPickerOption
                    key={option}
                    setIsDropdownOpen={setIsDropdownOpen}
                    option={option}
                />
            ))}
        </>
    )
}

export default PassengerPickerOptions
