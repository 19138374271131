import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import rootExtractor from "../utils/dataset/rootExtractor";
import {LocaleType} from "../types/Locale.type";
import {getCookie} from "@getbybus/gbb-green";
import {GbtCookiesType} from "../features/cookies/types/GbtCookies.type.ts";

interface ScreenSizesType {
    isMobile: boolean,
    isSmallTablet: boolean,
    isTablet: boolean,

}

interface PageSliceStateType extends ScreenSizesType {
    locale: LocaleType;
    _token: string;
    siteUrl: string;
    cookiesConsent: boolean,
    performanceCookies: boolean,
    isCookieModalOpen: boolean,
    userCanSkipCookies: boolean,
    vehicleBookingPath: string | undefined,
    bawApiUrl: string,
    eventToken: string,
}

const initialState: PageSliceStateType = {
    locale: rootExtractor('locale'),
    _token: document.getElementById('_token').value,
    bawApiUrl: rootExtractor('bawApiUrl'),
    eventToken: rootExtractor('tokenBawBi'),
    siteUrl: window.location.host,
    isMobile: window.innerWidth <= 680,
    isSmallTablet: window.innerWidth >= 680 && window.innerWidth <= 768,
    isTablet: window.innerWidth >= 680 && window.innerWidth <= 992,
    cookiesConsent: getCookie("cookiesConsent"),
    performanceCookies: getCookie("performanceCookies") ?? true,
    isCookieModalOpen: false,
    // should be set depending on which page user is on
    userCanSkipCookies: true,
    // set on vehicle select page if user did NOT accept cookies
    vehicleBookingPath: undefined,
}

const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        setLocale: (state, action) => ({...state, locale: action.payload}),
        setPageLayout: (state, action: PayloadAction<ScreenSizesType>) => ({
            ...state,
            ...action.payload,
        }),
        setCookieState: (state, action: PayloadAction<{ value: boolean, name: GbtCookiesType }>) => ({
            ...state,
            [action.payload.name]: action.payload.value,
        }),
        toggleIsCookieModalOpen: (state, action: PayloadAction<boolean | undefined>) => ({
            ...state,
            isCookieModalOpen: action?.payload ?? !state.isCookieModalOpen,
        }),
        setUserCanSkipCookies: (state, action: PayloadAction<boolean>) => ({
            ...state,
            userCanSkipCookies: action.payload,
        }),
        setBookingNavigatePath: (state, action: PayloadAction<string | undefined>) => ({
            ...state,
            vehicleBookingPath: action.payload,
        }),
        clear: () => initialState,
    },
})

export const {
    setLocale,
    setPageLayout,
    setCookieState,
    toggleIsCookieModalOpen,
    setUserCanSkipCookies,
    setBookingNavigatePath,
} = pageSlice.actions

export default pageSlice.reducer