import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "../../../../types/Icon.type";


function IconGroupOfPeople(props: IconType) {
    const {
        width = '132',
        height = '85',
        viewBox = '0 0 132 85',
        title = 'IconGroupOfPeople',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <g fillRule="nonzero" transform="translate(0 5)">
                        <path
                            d="m21.5946844 77.7302632c-2.5238788 0-4.5888704-2.0427632-4.5888704-4.5394737v-31.7763158h9.1777408v31.7763158c0 2.4967105-2.0649917 4.5394737-4.5888704 4.5394737z"
                            fill="#fff"/>
                        <path
                            d="m23.1243079 22.5h-12.2369879c-4.20646452 0-7.64811734 3.1770405-7.64811734 7.0600899v21.1802697c2.52387874 0 4.58887043 1.9062243 4.58887043 4.236054v18.144431c0 2.3298297 1.91202935 4.3772558 4.43590811 4.3772558 2.6003599.0706009 4.7418328-1.8356234 4.7418328-4.236054v-22.5216868h9.1777408 4.5888704v-21.1802697c0-3.8830494-3.4416528-7.0600899-7.6481173-7.0600899z"
                            fill="#fff"/>
                        <path
                            d="m21.5946844 80c-3.8240587 0-6.8833057-2.8571429-6.8833057-6.4285714v-22.8571429c0-1.2142857.9942553-2.1428571 2.2944353-2.1428571 1.3001799 0 2.2944352.9285714 2.2944352 2.1428571v22.8571429c0 1.2142857.9942552 2.1428571 2.2944352 2.1428571 1.3001799 0 2.2944352-.9285714 2.2944352-2.1428571v-22.8571429c0-1.2142857.9942553-2.1428571 2.2944352-2.1428571h2.2944352v-19.2857143c0-2.7857143-2.3709164-5-5.3536821-5h-12.2369879c-2.98276574 0-5.35368213 2.2142857-5.35368213 5v21.4285714c0 1.2142857-.99425526 2.1428572-2.29443521 2.1428572-1.30017996 0-2.29443522-.9285715-2.29443522-2.1428572v-21.4285714c0-5.1428572 4.43590809-9.2857143 9.94255256-9.2857143h12.2369879c5.5066445 0 9.9425526 4.1428571 9.9425526 9.2857143v21.4285714c0 1.2142857-.9942553 2.1428572-2.2944353 2.1428572h-2.2944352v20.7142857c0 3.5714285-3.0592469 6.4285714-6.8833056 6.4285714z"
                            fill="#070c63"/>
                        <ellipse cx="17.005814" cy="8.585526" fill="#fff" rx="7.648117" ry="7.565789"/>
                        <g transform="translate(7.55814)">
                            <path
                                d="m8.69973353 17.2121711c-4.81831396 0-8.69973353-3.8396382-8.69973353-8.60608557s3.88141957-8.60608553 8.69973353-8.60608553c4.81831397 0 8.69973357 3.83963816 8.69973357 8.60608553s-3.8814196 8.60608557-8.69973357 8.60608557z"
                                fill="#070c63"/>
                            <path
                                d="m8.69973353 3.97203947c-2.60992006 0-4.6844719 2.0522204-4.6844719 4.63404606 0 2.58182567 2.07455184 4.63404607 4.6844719 4.63404607 2.60992007 0 4.68447187-2.0522204 4.68447187-4.63404607 0-2.58182566-2.0745518-4.63404606-4.68447187-4.63404606z"
                                fill="#fff"/>
                        </g>
                        <path
                            d="m12.4169435 80c-3.82405867 0-6.88330563-2.8947368-6.88330563-6.5131579v-18.8157895c0-1.2302631.99425526-2.1710526 2.29443522-2.1710526s2.29443521.9407895 2.29443521 2.1710526v18.8157895c0 1.2302632.9942553 2.1710526 2.2944352 2.1710526 1.30018 0 2.2944352-.9407894 2.2944352-2.1710526s.9942553-2.1710526 2.2944353-2.1710526c1.3001799 0 2.2944352.9407894 2.2944352 2.1710526 0 3.6184211-3.059247 6.5131579-6.8833057 6.5131579z"
                            fill="#070c63"/>
                    </g>
                    <g fillRule="nonzero" transform="matrix(-1 0 0 1 61.724806 1)">
                        <path
                            d="m21.5946844 82.7302632c-2.5238788 0-4.5888704-2.0427632-4.5888704-4.5394737v-31.7763158h9.1777408v31.7763158c0 2.4967105-2.0649917 4.5394737-4.5888704 4.5394737z"
                            fill="#fff"/>
                        <path
                            d="m23.1243079 23.7171053h-12.2369879c-4.20646452 0-7.64811734 3.4046052-7.64811734 7.5657894v22.6973685c2.52387874 0 4.58887043 2.0427631 4.58887043 4.5394736v19.444079c0 2.4967105 1.91202935 4.6907895 4.43590811 4.6907895 2.6003599.0756579 4.7418328-1.9671053 4.7418328-4.5394737v-24.1348684h9.1777408 4.5888704v-22.6973685c0-4.1611842-3.4416528-7.5657894-7.6481173-7.5657894z"
                            fill="#fff"/>
                        <path
                            d="m21.5946844 85c-3.8240587 0-6.8833057-3.0263158-6.8833057-6.8092105v-24.2105263c0-1.2861843.9942553-2.2697369 2.2944353-2.2697369 1.3001799 0 2.2944352.9835526 2.2944352 2.2697369v24.2105263c0 1.2861842.9942552 2.2697368 2.2944352 2.2697368 1.3001799 0 2.2944352-.9835526 2.2944352-2.2697368v-24.2105263c0-1.2861843 3.1327347-1.5685175 3.8342264-2.1192889.7014918-.5507714.754644-2.0956505.754644-3.0682518 0-3.8910728 0-9.7276821 0-17.5098278 0-2.9506579-2.3709164-5.2960526-5.3536821-5.2960526h-12.2369879c-2.98276574 0-5.35368213 2.3453947-5.35368213 5.2960526v22.6973685c0 1.2861842-.99425526 2.2697368-2.29443521 2.2697368-1.30017996 0-2.29443522-.9835526-2.29443522-2.2697368v-22.6973685c0-5.4473684 4.43590809-9.8355263 9.94255256-9.8355263h12.2369879c5.5066445 0 9.9425526 4.3881579 9.9425526 9.8355263v22.6973685c0 1.2861842-1.4076243 1.7140843-2.9982474 2.2697368-1.0604154.370435-1.5906231 1.2268762-1.5906231 2.5693237v19.3714658c0 3.7828947-3.0592469 6.8092105-6.8833056 6.8092105z"
                            fill="#070c63"/>
                        <ellipse cx="17.005814" cy="8.585526" fill="#fff" rx="7.648117" ry="7.565789"/>
                        <g transform="translate(7.063261)">
                            <path
                                d="m9.32114306 18.4416118c-5.16247923 0-9.32114306-4.113898-9.32114306-9.22080588 0-5.10690789 4.15866383-9.22080592 9.32114306-9.22080592 5.16247924 0 9.32114304 4.11389803 9.32114304 9.22080592 0 5.10690788-4.1586638 9.22080588-9.32114304 9.22080588z"
                                fill="#070c63"/>
                            <path
                                d="m9.32114306 4.25575658c-2.79634291 0-5.01907703 2.19880756-5.01907703 4.96504934s2.22273412 4.96504938 5.01907703 4.96504938c2.79634294 0 5.01907704-2.1988076 5.01907704-4.96504938s-2.2227341-4.96504934-5.01907704-4.96504934z"
                                fill="#fff"/>
                        </g>
                        <path
                            d="m12.4169435 85c-3.82405867 0-6.88330563-3.0263158-6.88330563-6.8092105v-19.6710527c0-1.2861842.99425526-2.2697368 2.29443522-2.2697368s2.29443521.9835526 2.29443521 2.2697368v19.6710527c0 1.2861842.9942553 2.2697368 2.2944352 2.2697368 1.30018 0 2.2944352-.9835526 2.2944352-2.2697368s.9942553-2.2697369 2.2944353-2.2697369c1.3001799 0 2.2944352.9835527 2.2944352 2.2697369 0 3.7828947-3.059247 6.8092105-6.8833057 6.8092105z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(78.100775 1)">
                        <path
                            d="m45.0898468 22.5h-18.3800211c-2.758371 0-23.58913043 10.3420435-24.9349648 12.5233506s0 3.4876788 3.39699161 5.2315182c3.3969916 1.7438395 21.32441289-8.9665399 21.32441289-8.1964064 0 1.4325056.2135603 24.4459698.2135603 26.9426803l1.2045844 20.9988573c0 2.4967105 15.7112517 2.5723684 15.7112517 0l3.059247-25.9868421h4.5888704v-22.6973684c0-4.1611842-1.9774678-8.8157895-6.1839324-8.8157895z"
                            fill="#fff" fillRule="nonzero" transform="matrix(-1 0 0 1 52.53347 0)"/>
                        <path d="m16.74815 78.191903.257664-26.316903" stroke="#070c63" strokeLinecap="round"
                              strokeLinejoin="round" strokeWidth="4.375"/>
                        <g fillRule="nonzero" transform="translate(7.55814)">
                            <path
                                d="m9.32114306 18.4416118c-5.16247923 0-9.32114306-4.113898-9.32114306-9.22080588 0-5.10690789 4.15866383-9.22080592 9.32114306-9.22080592 5.16247924 0 9.32114304 4.11389803 9.32114304 9.22080592 0 5.10690788-4.1586638 9.22080588-9.32114304 9.22080588z"
                                fill="#070c63"/>
                            <path
                                d="m9.32114306 4.25575658c-2.79634291 0-5.01907703 2.19880756-5.01907703 4.96504934s2.22273412 4.96504938 5.01907703 4.96504938c2.79634294 0 5.01907704-2.1988076 5.01907704-4.96504938s-2.2227341-4.96504934-5.01907704-4.96504934z"
                                fill="#fff"/>
                        </g>
                        <g stroke="#070c63" strokeLinecap="round" strokeLinejoin="round">
                            <path
                                d="m49.2381181 33.7394285-24.6322019-12.1769285-7.8577661 6.049533-7.44362226-6.049533h-2.83729203c-4.65226391.6496711-6.46723581 5.040128-6.46723581 9.6289064v19.2631391"
                                strokeWidth="4.375"/>
                            <path
                                d="m49.2381181 33.7394285c1.6668341 1.4305666 2.1885425 3.0864438 1.5651251 4.9676316-.6234173 1.8811879-2.1416661 2.7288345-4.5547465 2.5429399l-19.7950083-8.2321914-.4008105 43.44339c0 2.4014286-2.0823533 4.3472638-4.6522639 4.3472638s-4.6522639-1.9458352-4.6522639-4.3472638"
                                strokeWidth="4.375"/>
                            <path d="m0 50.4545455c0 2.0404829 1.66551048 3.6931818 3.72181113 3.6931818"
                                  strokeWidth="4.25"/>
                            <path
                                d="m17.1775899 78.8853855c0 1.0623077-2.0823534 1.9230769-4.652264 1.9230769-2.56991055 0-4.65226388-.8607692-4.65226388-1.9230769v-20.5769231"
                                strokeWidth="4.375"/>
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconGroupOfPeople;
