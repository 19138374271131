import CookiesNotice from "./CookiesNotice.tsx";
import ManageCookiesModal from "../features/manageCookiesModal/components/ManageCookiesModal.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useHandleManageCookiesFooterClick from "../hooks/useHandleManageCookiesFooterClick.ts";
import useTriggerMustAcceptCookiesModal from "../hooks/useTriggerMustAcceptCookiesModal.ts";

function Cookies() {
    const {
        cookiesConsent: cookiesConsentAccepted,
        isCookieModalOpen,
    } = useAppSelector((state) => (state.page))

    useHandleManageCookiesFooterClick()
    useTriggerMustAcceptCookiesModal()

    return (
        <>
            {!cookiesConsentAccepted && !isCookieModalOpen && (
                <CookiesNotice/>
            )}
            {isCookieModalOpen && (
                <ManageCookiesModal/>
            )}
        </>
    )
}

export default Cookies