import BookingStripeCashDisplay from "./BookingStripeCashDisplay.tsx"
import BookingStripePrepaymentPriceDisplay from "./BookingStripePrepaymentPriceDisplay.tsx"
import BookingStripeCardPriceDisplay from "./BookingStripeCardPriceDisplay.tsx"
import {PaymentMethods} from "../../../../../constants/PaymentMethods.ts";

interface BookingStripePriceDisplayProps {
    paymentMethod: number
    displayPrice: number
    depositPrice: number,
    amenitiesDepositPrice: number,
}

const BookingStripePriceDisplay = (props: BookingStripePriceDisplayProps) => {
    const {
        paymentMethod,
        displayPrice,
        depositPrice,
        amenitiesDepositPrice
    } = props

    if (paymentMethod === PaymentMethods.CASH) {
        return <BookingStripeCashDisplay displayPrice={displayPrice} />
    } else if (paymentMethod === PaymentMethods.PREPAYMENT) {
        return (
            <BookingStripePrepaymentPriceDisplay
                depositPrice={depositPrice}
                amenitiesDepositPrice={amenitiesDepositPrice}
                totalPrice={displayPrice}
            />
        )
    }

    return <BookingStripeCardPriceDisplay displayPrice={displayPrice} />
}

export default BookingStripePriceDisplay
