import {useAppDispatch} from "./useAppDispatch.ts";
import {useAppSelector} from "../selector/useAppSelector.ts";
import isEqual from "lodash/isEqual";
import {setPageLayout} from "../../reducers/pageSlice.ts";
import useDebouncedEventListener from "../EventListeners/useDebouncedEventListener.ts";

export default function useSetPageLayout() {
    const dispatch = useAppDispatch()

    const {
        isMobile,
        isSmallTablet,
        isTablet,
    } = useAppSelector((state) => (state?.page))

    const currentState = {
        isMobile,
        isSmallTablet,
        isTablet,
    }

    const checkSizes = () => ({
        isMobile: window.innerWidth <= 680,
        isTablet: window.innerWidth >= 680 && window.innerWidth <= 992,
        isSmallTablet: window.innerWidth >= 680 && window.innerWidth <= 768,
    })

    const updateLayout = () => {
        if (!isEqual(currentState, checkSizes())) {

            dispatch(setPageLayout(checkSizes()))
        }
    }

    useDebouncedEventListener("resize", updateLayout)
}