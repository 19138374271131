import useDismissAllOverlays from "../hooks/useDismissAllOverlays";
import MobileOverlay from "./MobileOverlay";
import {useDispatch} from "react-redux";
import {setSingleCalendar} from "../../../reducers/mobileSlice.ts";
import useTranslation from "../../translation/hooks/useTranslation.ts";
import useDateSelectHandlers from "../../../layout/form/features/dates/hooks/useDateSelectHandlers.ts";
import DateTimeSelect from "../../dateTime/components/DateTimeSelect.tsx";
import useIsOverlaySubtreeActive from "../hooks/useIsOverlaySubtreeActive.ts";
import {addHours, startOfHour} from "date-fns";
import isBefore from "date-fns/isBefore";

function MobileDatePicker() {
    const {
        departure,
        setDeparture,
        setIsDropdownOpen,
        handleSelectDate
    } = useDateSelectHandlers()

    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()

    const dispatch = useDispatch();
    const dismissAllOverlays = useDismissAllOverlays();

    const backClick = () => {
        if (isOverlaySubtreeActive) {
            dispatch(setSingleCalendar(false))
        } else {
            dismissAllOverlays()
        }
    }

    const handleSelect = (value: Date) => {
        if (isOverlaySubtreeActive) {
            dispatch(setSingleCalendar(false))
        } else {
            dismissAllOverlays();
        }
        handleSelectDate(value);
    };

    const selectDateLabel = useTranslation("search.form.date") as string
    const selectDateSubtitle = useTranslation("search.form.text") as string


    return (
        <MobileOverlay title={selectDateLabel} backClick={backClick} subtitle={selectDateSubtitle}>
            <DateTimeSelect
                selectedDate={departure}
                selectedTime={departure}
                changeDate={(value: Date) => {
                    if (isBefore(value, addHours(new Date(), 1))) {
                        setDeparture(startOfHour(addHours(new Date(), 2)).toISOString())
                    } else {
                        setDeparture(value.toISOString())
                    }
                }}
                changeTime={(value: Date) => {
                    setDeparture(value.toISOString())
                }}
                submitSelect={handleSelect}
                dateLabel={'Date'}
                handleClose={() => {
                    setIsDropdownOpen(false)
                }}
                isClosable={true}
                timeLabel={'Time'}
                minDateTime={addHours(new Date(), 1)}
            />
        </MobileOverlay>
    );
}

export default MobileDatePicker;