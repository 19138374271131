import {RouterProvider} from "react-router-dom";
import {browserRouter} from "../constants/browserRouter.tsx";
import ErrorBoundary from "../../features/errors/features/errorBoundary/components/ErrorBoundary.js";
import ErrorBoundaryDefault from "../../features/errors/features/errorBoundary/components/ErrorBoundaryDefault.tsx";

function Router() {
    return (
        <ErrorBoundary fallback={<ErrorBoundaryDefault/>}>
            <RouterProvider
                router={browserRouter}
            />
        </ErrorBoundary>
    );
}

export default Router;