import Row from "../../../../../features/containers/components/Row.tsx"
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts"

interface BookingStripeCardPriceDisplayProps {
    displayPrice: number
}

const BookingStripeCardPriceDisplay = ({displayPrice}: BookingStripeCardPriceDisplayProps) => {
    const chargedLabel = useTranslation("booking.form.cardCharged") as string
    return (
        <>
            <Row
                justify
                style={{width: "100%"}}
            >
                <span>
                    {chargedLabel}: <b>&#8364; {(displayPrice || 0)?.toFixed(2)}</b>
                </span>
            </Row>
        </>
    )
}

export default BookingStripeCardPriceDisplay
