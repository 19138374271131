
// This function replaces placeholders in the FAQ data with the actual values of the stations and prices, since we pass only the translated text with the placeholders
export const replacePlaceholders = (data: Record<string, {question: string, answer: string}>, fromStation: string, toStation: string, cheapestPrice: number, mostExpensivePrice: number, journeyLength: string) => {
    return Object.entries(data).map((faq) => {
        if (faq[0] === 'cost') {
            faq[1] = {
                question: faq[1].question.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation),
                answer: faq[1].answer.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation).replace('%cheapestPPR%', `${cheapestPrice}€`).replace('%mostExpensivePPR%', `${mostExpensivePrice}€`)
            }
        } else if (faq[0] === 'how') {
            faq[1] = {
                question: faq[1].question.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation),
                answer: faq[1].answer.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation)
            }
        } else if (faq[0] === 'howLong') {
            faq[1] = {
                question: faq[1].question.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation),
                answer: faq[1].answer.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation).replace('%journeyLength%', journeyLength)
            }
        } else if (faq[0] === 'bestWay') {
            faq[1] = {
                question: faq[1].question.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation),
                answer: faq[1].answer.replace('%departureStation%', fromStation).replace('%destinationStation%', toStation)
            }
        }
        return faq[1]
    })
}