import IconPassengersAdd from "../../../../../components/generic/icons/IconPassengersAdd.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useQuantityAwareTranslation from "../../../../../features/translation/hooks/useQuantityAwareTranslation.ts";

function PassengerPickerInput() {
    const {numberOfPassengers} = useAppSelector((state) => state?.form)
    const passengersLabel = useQuantityAwareTranslation("search.form.passenger", numberOfPassengers)

    return (
        <>
            <IconPassengersAdd className='search__select--icon'/>
            <input
                type="text"
                name="passengers"
                className="gb--input-standalone"
                placeholder={`${numberOfPassengers} ${passengersLabel}`}
                value={`${numberOfPassengers} ${passengersLabel}`}
                readOnly
            />
        </>
    )
}

export default PassengerPickerInput