/**
 * Returns active class names based on props passed
 */

export default function classNamesAggregator(type: string, activeBooleans: object) {
    // @ts-ignore
    const isActive = (bool: string, className: string) => (activeBooleans?.[bool] ? className : '')
    const justifyClass = isActive('justify', `${type}-justify `)
    const centerClass = isActive('center', `${type}-middle `)
    const reverseClass = isActive('reverse', `${type}-reverse `)
    const wrapClass = isActive('wrap', `${type}-wrap `)
    const alignStartClass = isActive('alignStart', `${type}-start `)
    const alignEndClass = isActive('alignEnd', `${type}-end `)
    const gapClass = isActive('gap', `${type}-gap `)

    return `${type} ${justifyClass}${centerClass}${reverseClass}${wrapClass}${alignStartClass}${alignEndClass}${gapClass}`
}