import {AmenityType} from "../../../../types/Amenity.type.ts";
import {BookingAmenityType} from "../../reducers/types/BookingAmenity.type.ts";
import Row from "../../../../features/containers/components/Row.tsx";
import Column from "../../../../features/containers/components/Column.tsx";
import PriceDisplay from "./PriceDisplay.tsx";

interface BookingAmenityPriceBreakdownTogetherProps {
    amenities: AmenityType[]
    bookingAmenities: BookingAmenityType
}

const BookingAmenityPriceBreakdownTogether = (props: BookingAmenityPriceBreakdownTogetherProps) => {
    const {
        bookingAmenities,
        amenities,
    } = props;

    return (
        <Column
            justify
            style={{width: "100%"}}
        >

            {amenities?.map((amenity: AmenityType) => {
                if (bookingAmenities[amenity.id] && (bookingAmenities[amenity.id].count || (bookingAmenities[amenity.id].returnCount))) {
                    return (
                        <Row
                            key={amenity.id}
                        >
                            <Column>
                                {bookingAmenities[amenity.id].count + bookingAmenities[amenity.id].returnCount} x {amenity.name}
                            </Column>
                            <Column>
                                <PriceDisplay
                                    plus
                                    price={amenity.price * (bookingAmenities[amenity.id].count + bookingAmenities[amenity.id].returnCount)}
                                />
                            </Column>
                        </Row>
                    )
                }
            })}
        </Column>
    );
};

export default BookingAmenityPriceBreakdownTogether;