import BookingAmenityPriceBreakdown from "./BookingAmenityPriceBreakdown.tsx";
import {AmenityType} from "../../../../types/Amenity.type.ts";
import {BookingAmenityType} from "../../reducers/types/BookingAmenity.type.ts";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";

interface BookingAmenityPriceBreakdownPerLegProps {
    amenities: AmenityType[]
    bookingAmenities: BookingAmenityType
}

const BookingAmenityPriceBreakdownPerLeg = (props: BookingAmenityPriceBreakdownPerLegProps) => {
    const outboundTransfer = useTranslation('booking.title.outboundTransfer') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const {
        bookingAmenities,
        amenities,
    } = props;

    return (
        <>
            <BookingAmenityPriceBreakdown
                amenities={amenities}
                bookingAmenities={bookingAmenities}
                isReturn={false}
                label={outboundTransfer}
            />
            <BookingAmenityPriceBreakdown
                amenities={amenities}
                bookingAmenities={bookingAmenities}
                isReturn={true}
                label={returnTransfer}
            />
        </>
    );
};

export default BookingAmenityPriceBreakdownPerLeg;