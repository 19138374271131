import {ForwardedRef, ReactNode} from 'react';
import ClickOutsideAwareComponent from "./ClickOutsideAwareComponent";

interface DropdownSelectProps {
    children: ReactNode;
    field: ReactNode;
    required?: boolean; // TODO figure out how to handle this required
    additionalClasses?: string;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void,
    handleClickOutside?: () => void,
    onBlur?: () => any,
    onClick?: () => any,
    dropdownRef?: ForwardedRef<HTMLDivElement>,
    menuProps?: any,
    childrenWrapperClass?: string,
}

const CustomDropdown = (props: DropdownSelectProps) => {
    const {
        children,
        field,
        additionalClasses,
        isOpen,
        setIsOpen,
        handleClickOutside = () => {
            setIsOpen(false)
        },
        onClick = () => {
        },
        onBlur = () => {
        },
        dropdownRef,
        menuProps,
        childrenWrapperClass = 'gb--select-dropdown'
    } = props;

    return (
        <ClickOutsideAwareComponent onClickOutside={handleClickOutside}>
            <div
                ref={dropdownRef}
                onBlur={onBlur}
                className={`gb--select`}
                onClick={onClick}
                onFocus={() => {
                    if (!isOpen) {
                        setIsOpen(true)
                    }
                }}
            >
                <div className={`${additionalClasses}`}>
                    {field}
                </div>
                <div className={`${childrenWrapperClass} ${isOpen ? '' : 'gb--select-dropdown-hide'}`} {...menuProps}>
                    {children}
                </div>
            </div>
        </ClickOutsideAwareComponent>
    );
};

export default CustomDropdown;
