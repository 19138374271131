import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type.ts";

function IconArrowBack(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        viewBox = '0 0 20 20',
        title = 'IconArrowRight',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m20 9.03125v1.96875h-16.15625l5.578125 5.625-1.40625 1.40625-8.015625-8.015625 8.015625-8.015625 1.453125 1.40625-5.625 5.625z"
                />
            )}
        />

    );
}

export default IconArrowBack;
