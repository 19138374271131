import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import BookingAmenitySelector from "./BookingAmenitySelector.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useBookingAmenity} from "../hooks/useBookingAmenity.ts";
import {useGetAmenityObject} from "../hooks/useGetAmenityObject.ts";
import BookingAmenityField from "./BookingAmenityField.tsx";

interface BookingAmenityProps {
    id: number
    name: string
    price: number
    isReturn: boolean
    description?: string
    isIntegration: boolean
}

const BookingAmenity = (props: BookingAmenityProps) => {
    const {
        id,
        name,
        price,
        isReturn,
        description,
        isIntegration,
    } = props

    const isMobile = useIsMobile();

    const {
        handleIncrement,
        handleDecrement,
        handleReturnIncrement,
        handleReturnDecrement,
        handleInitialIncrement,
        handleIntegrationDecrement,
        handleSave,
        value,
        onChange
    } = useBookingAmenity(id)
    const {
        amenities
    } = useAppSelector((state) => state?.bookingForm)
    const {getAmenityObjectIcon} = useGetAmenityObject()
    const selected = value || (amenities[id] && (amenities[id].count !== 0 || amenities[id].returnCount !== 0))
    const AmenityIcon = getAmenityObjectIcon(id)

    const handleClick = () => {
        if (amenities[id].count === 0 && amenities[id].returnCount === 0) {
            handleInitialIncrement(id, isReturn)
        }
        if (amenities[id].count === 1 && isIntegration) {
            handleIntegrationDecrement(id, isReturn)
        }
        onChange()
    }



    return (
        <div
            key={id}
            className={`gb--amenities ${selected ? "gb--amenities__selected" : ''}`}
        >
            <BookingAmenityField
                id={id}
                name={name}
                value={value}
                price={price}
                isMobile={isMobile}
                selected={selected}
                amenities={amenities}
                handleClick={handleClick}
                AmenityIcon={AmenityIcon}
                description={description}
            />
            {(value && !isIntegration) && (
                <BookingAmenitySelector
                    price={price}
                    title={name}
                    quantity={amenities[id].count}
                    amenityId={id}
                    handleSave={handleSave}
                    handleClose={handleClick}
                    returnTrip={isReturn}
                    description={description}
                    returnQuantity={amenities[id].returnCount}
                    currentAmenities={amenities}
                    handleIncrement={() => {
                        handleIncrement(id)
                    }}
                    handleDecrement={() => {
                        handleDecrement(id)
                    }}
                    handleReturnIncrement={() => {
                        handleReturnIncrement(id)
                    }}
                    handleReturnDecrement={() => {
                        handleReturnDecrement(id)
                    }}
                />
            )}
        </div>
    );
};

export default BookingAmenity;