import {ForwardedRef, ReactNode} from 'react';
import classNamesAggregator from "../functions/classNamesAggregator";

interface RowProps {
    className?: string,
    children: ReactNode,
    style?: object,
    reverse?: boolean,
    justify?: boolean,
    center?: boolean,
    wrap?: boolean,
    scrollRef?: ForwardedRef<HTMLDivElement>,
    gap?: boolean,
    alignStart?: boolean,
    alignEnd?: boolean,
}

const Row = (props: RowProps) => {
    const {
        className = '',
        children,
        style,
        reverse = false,
        justify = false,
        center = false,
        wrap = false,
        gap = false,
        alignStart = false,
        alignEnd = false,
        scrollRef,
    } = props
    const activeClasses = classNamesAggregator("gb--row", {reverse, justify, center, wrap, gap, alignStart, alignEnd})

    return (
        <div className={`${activeClasses}${className}`} style={style} ref={scrollRef}>
            {children}
        </div>
    )
};

export default Row;