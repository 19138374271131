import {ReactNode} from 'react';

interface PriceDisplayProps {
    price: number
    plusIcon?: boolean
    additionalIcon?: ReactNode
}

const PriceDisplay = (props: PriceDisplayProps) => {
    const {
        price,
        plusIcon = false,
        additionalIcon,
        ...rest
    } = props
    const [num, fraction] = price.toFixed(2).toString().split(".")

    return (
        <b {...rest}>
            {plusIcon && (
                <span className={'gb--price-display-icon'}>+</span>
            )}
            {additionalIcon && (
                additionalIcon
            )}
            <span className={'gb--price-display-number'}>{num},</span>
            <sup className={'gb--price-display-fraction'}>{fraction}</sup>
            <span className={'gb--price-display-currency'}> &#8364;</span>
        </b>
    );
};

export default PriceDisplay;