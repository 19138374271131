import MobileMenu from "./MobileMenu.tsx";
import MobileOverlay from "../../../components/MobileOverlay.tsx";
import useDismissAllOverlays from "../../../hooks/useDismissAllOverlays.ts";

function MobileMenuOverlay() {
    const dismissAllOverlays = useDismissAllOverlays()
    return (
        <MobileOverlay
            title="Menu"
            slideInDirection="right"
            backClick={dismissAllOverlays}
        >
            <MobileMenu/>
        </MobileOverlay>
    );
}
export default MobileMenuOverlay;