import {useEffect} from "react";

export default function useScrollableModal() {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh'
        return () => {
            document.body.style.overflow = 'unset'
            document.body.style.height = 'auto'
        };
    }, []);
}