import {TransferFormPayloadType} from "../types/TransferFormPayload.type.ts";

const transferDateAndTimeFormPayload = {
    value: '',
    isValid: false,
    displayError: false,
}
export const transferFormPickUpDatePayload: TransferFormPayloadType = {
    ...transferDateAndTimeFormPayload,
    type: 'pickUpDate',
}

export const transferFormPickUpTimePayload: TransferFormPayloadType = {
    ...transferDateAndTimeFormPayload,
    type: 'pickUpTime',
}