import {useState} from "react";

export default function useDateAndTimeSelector(dateLabel: string, timeLabel: string) {
    const [tab, setTab] = useState<"date" | "time">("date")

    const tabs = [
        {
            id: "date",
            label: dateLabel,
            onClick: () => {
                setTab("date")
            },
            isActive: tab === "date",
        },
        {
            id: "time",
            label: timeLabel,
            onClick: () => {
                setTab("time")
            },
            isActive: tab === "time",
        }
    ]

    return {
        tab,
        setTab,
        tabs,
    }
}