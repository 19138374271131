import {CSSProperties} from "react";

export default function createStyle(alignment: "center" | "left" | "right"): CSSProperties {
    const determineAlignment = () => {
        if (alignment === "center") {
            return undefined
        }
        return alignment === "left" ? "end" : "start"
    }

    return {
        alignItems: determineAlignment()
    }
}