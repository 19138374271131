import {dismissAllOverlays} from "../../../reducers/mobileSlice";
import {useDispatch} from "react-redux";
/**
 * Dismisses all modals & mobile overlays
 * @return {function(): void}
 */
export default function useDismissAllOverlays() {
    const dispatch = useDispatch()
    return () => {
        dispatch(dismissAllOverlays())
    }
}