import IconAirplanePlot from "../../../../../components/generic/icons/filledOutline/IconAirplanePlot";
import IconCarPlot from "../../../../../components/generic/icons/filledOutline/IconCarPlot";
import IconDevices from "../../../../../components/generic/icons/filledOutline/IconDevices";

const HeroVectorSmall = () => {
    return (
        <div className="hero__icons">
            <IconDevices/>
            <IconCarPlot style={{position: 'absolute', left: '15rem' }}/>
            <div/>
            <IconAirplanePlot style={{position: 'absolute', right: '15rem', bottom: '3rem', transform: 'rotate(-5deg)'}}/>
        </div>
    );
}

export default HeroVectorSmall;