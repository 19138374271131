import useCachedData from "../../../../../pages/searchResults/hooks/useCachedData.ts";

export default function usePrepareIsAirportFlag(isPageRerenderOrRedirect: boolean) {
    const {
        fetchedGbtData,
    } = useCachedData(isPageRerenderOrRedirect)

    const isAirport = fetchedGbtData?.data?.pickupInfo.isAirport || fetchedGbtData?.data?.dropOffInfo.isAirport

    return {
        isAirport,
    }
}