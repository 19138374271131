import {flightNumberPayload, returnFlightNumberPayload} from "./flightNumberFormPayload.ts";
import {FlightTransferFormType, ReturnFlightTransferFormType} from "../types/FlightTransferForm.type.ts";

export const flightTransferFormInitialState: FlightTransferFormType = {
    flightNumber: {
        ...flightNumberPayload,
        errorMessage: 'booking.form.flightError'
    }
}

export const returnFlightTransferFormInitialState: ReturnFlightTransferFormType = {
    returnFlightNumber: {
        ...returnFlightNumberPayload,
        errorMessage: 'booking.form.flightError'
    }
}