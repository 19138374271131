import {ReactNode} from "react";
import {createPortal} from "react-dom";

interface PortalProps {
    children: ReactNode,
    domNodeId?: string
}

export default function Portal(props: PortalProps) {
    const {
        domNodeId,
        children
    } = props;

    const id = document.getElementById(`${domNodeId}`)

    if (!!id) {
        return (
            <>
                {createPortal(
                    children,
                    id
                )}
            </>
        );
    }

    return null
}