import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const overlays = {
    mobileSearchFormOverlayActive: false,
    singleCalendarActive: false,
    returnCalendarActive: false,
    stsCalendarActive: false,
    mobileLogInActive: false,
    mobileLanguagesActive: false,
    mobileMenuActive: false,
    mobileMyTicketsActive: false,
    mobilePassengerPickerActive: false,
    sortingOptionsActive: false,
    currencyPickerActive: false,
    mobileOverlayActive: false,
    departurePlaceInputActive: false,
    destinationPlaceInputActive: false,
    filtersOverlayActive: false,
    singleBookingSingleCalendarActive: false,
    incompleteSearchFormDataOverlayActive: false,
    stripeMobileOverlay: false,
    staticCalendarActive: false,
}
const initialState = {
    additionalInfoActive: null,
    scrollLocation: 0,
    formAnimationActive: false,
    ...overlays,
}
const mobileSlice = createSlice({
    name: 'mobile',
    initialState,
    reducers: {
        setDeparturePlaceInput: (state, action: PayloadAction<boolean>) => ({
            ...state,
            departurePlaceInputActive: action.payload,
        }),
        setDestinationPlaceInput: (state, action: PayloadAction<boolean>) => ({
            ...state,
            destinationPlaceInputActive: action.payload,
        }),
        toggleDeparturePlaceInput: (state, action: PayloadAction<boolean | undefined>) => ({
            ...state,
            mobileOverlayActive: action?.payload ?? !state.mobileOverlayActive,
            departurePlaceInputActive: action?.payload ?? !state.departurePlaceInputActive,
        }),
        toggleDestinationPlaceInput: (state, action: PayloadAction<boolean | undefined>) => ({
            ...state,
            mobileOverlayActive: action?.payload ?? !state.mobileOverlayActive,
            destinationPlaceInputActive: action?.payload ?? !state.destinationPlaceInputActive,
        }),
        setSingleCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            singleCalendarActive: action.payload,
        }),
        toggleSingleCalendar: (state, action: PayloadAction<boolean | undefined>) => ({
            ...state,
            singleCalendarActive: action?.payload ?? !state.singleCalendarActive,
            mobileOverlayActive: action?.payload ?? !state.mobileOverlayActive,
        }),
        setReturnCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            returnCalendarActive: action.payload,
        }),
        activateReturnCalendar: (state, action: PayloadAction<boolean>) => ({
            ...state,
            returnCalendarActive: action.payload,
            mobileOverlayActive: action.payload,
        }),
        toggleMobileLanguages: (state) => ({
            ...state,
            mobileLanguagesActive: !state.mobileLanguagesActive,
        }),
        activateMobileMenu: (state, action: PayloadAction<boolean>) => ({
            ...state,
            mobileMenuActive: action.payload,
            mobileOverlayActive: action.payload,
        }),
        toggleMobilePassengerPicker: (state) => ({
            ...state,
            mobilePassengerPickerActive: !state.mobilePassengerPickerActive,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),
        setMobilePassengerPicker: (state, action: PayloadAction<boolean>) => ({
            ...state,
            mobilePassengerPickerActive: action.payload,
        }),
        toggleMobileOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
        }),
        dismissAllOverlays: (state) => ({
            ...state,
            additionalInfoActive: null,
            ...overlays,
        }),
        toggleIncompleteSearchFormDataOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            incompleteSearchFormDataOverlayActive: !state.incompleteSearchFormDataOverlayActive,
        }),
        toggleMobileSearchFormOverlay: (state) => ({
            ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            mobileSearchFormOverlayActive: !state.mobileSearchFormOverlayActive,
        }),
        toggleStripeMobileOverlay: (state) => ({
           ...state,
            mobileOverlayActive: !state.mobileOverlayActive,
            stripeMobileOverlay: !state.stripeMobileOverlay,
        }),
        toggleStaticCalendar: (state) => ({
            ...state,
            staticCalendarActive: !state.staticCalendarActive
        }),
    },
})
export const {
    setDeparturePlaceInput,
    setDestinationPlaceInput,
    setSingleCalendar,
    setReturnCalendar,
    toggleSingleCalendar,
    activateReturnCalendar,
    toggleMobileLanguages,
    activateMobileMenu,
    toggleMobilePassengerPicker,
    setMobilePassengerPicker,
    toggleMobileOverlay,
    toggleStripeMobileOverlay,
    toggleDeparturePlaceInput,
    toggleDestinationPlaceInput,
    dismissAllOverlays,
    toggleIncompleteSearchFormDataOverlay,
    toggleMobileSearchFormOverlay
} = mobileSlice.actions
export default mobileSlice.reducer