import {ReactNode} from 'react';

interface BookingDepositBreakdownPriceDisplayProps {
    price: number
    plus?: boolean
    additionalIcon?: ReactNode
}

const BookingDepositBreakdownPriceDisplay = (props: BookingDepositBreakdownPriceDisplayProps) => {
    const {
        price,
    } = props
    const [num, fraction] = price.toFixed(2).toString().split(".");

    return (
        <div className={'gb--price-display'}>
            <span className={'gb--price-display-number__prepayment'}>{num},</span>
            <sup className={'gb--price-display-fraction__prepayment'}>{fraction}</sup>
            <span className={'gb--price-display-currency__prepayment'}> &#8364;</span>
        </div>
    );
};

export default BookingDepositBreakdownPriceDisplay;