import {Outlet, useMatches} from "react-router";
import MainContent from "../../mainContent/MainContent.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import BookingNavigationItemList from "./BookingNavigationItemList.tsx";
import useNavigateToHome from "../../../routing/navigation/useNavigateToHome.ts";
import ExpiredResults from "./ExpiredResults.tsx";

function BookingNavigation() {
    const matches = useMatches();
    const {navigateToHome} = useNavigateToHome(true)

    const {
        id
    } = useAppSelector((state) => state?.vehicleType);

    const {
        isBooked
    } = useAppSelector((state) => state?.bookingForm)

    if ((matches[matches.length - 1]?.id === 'successful' && !id) || (matches[matches.length - 1]?.id === 'booking-info' && isBooked)) {
        navigateToHome()
    }

    return (
        <MainContent className="booking-container">
            <ExpiredResults/>
            <BookingNavigationItemList/>
            <Outlet/>
        </MainContent>
    );
}


export default BookingNavigation;