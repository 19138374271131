import {useLazyGetFlightNumberQuery} from "../../../../../api/reducers/apiSlice.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import {ChangeEvent, useEffect, useState} from "react";
import useBookingInfoInput from "../../bookingInfoInputField/hooks/useBookingInfoInput.ts";
import {FlightType} from "../../../reducers/features/flight/types/Flight.type.ts";

export const useFlightNumber = (formType: string, field: string, value: string, data: any) => {
    const [query, response] = useLazyGetFlightNumberQuery()
    const {
        departureDate,
        returnDate
    } = useAppSelector((state) => state?.form)
    const {
        currentSearch: {
            pickUpDateTime
        }
    } = useAppSelector((state) => state?.searchResults)
    const {
        isIntegration
    } = useAppSelector((state) => state?.vehicleType);
    const [flightNumberPlaceholder, setFlightNumberPlaceholder] = useState<string>(value || "");
    const {value: isDropdownOpen, set: setIsDropdownOpen} = useToggle();
    const {displayError, errorMessage, updateStore, clearStore} = useBookingInfoInput({
        formType: formType,
        field: field,
        fieldValidationMethod: () => isIntegration ? value > 0 : true,
    });
    const errorMessageString = useTranslation(errorMessage) as string

    useEffect(() => {
        if (!isIntegration) {
            updateStore('', true, false)
        } else {
            updateStore('', false, false)
        }
    }, []);

    const handleChange = (event: ChangeEvent) => {
        setIsDropdownOpen(true)
        setFlightNumberPlaceholder(event.target.value);
        if (data?.integrations?.mozio?.isMozioEnabled) {
            getFlights(event.target.value, data);
        }
        if (!event.target.value.length && isIntegration) {
            clearStore();
        }
        if (!isIntegration) {
            updateStore({flightNumber: event.target.value, flightInfo: event.target.value}, true);
        }
    };

    const handleSelect = (option: FlightType) => {
        updateStore(option, true)
        setIsDropdownOpen(false)
        setFlightNumberPlaceholder(option.flightInfo)
    }

    function separateFlightNumber(flightNumber) {
        const matches = flightNumber.match(/^([A-Za-z]+)(\d*)$/);
        if (matches) {
            const [, airlineCode, flightNum] = matches;
            return { airlineCode, flightNum: flightNum || '' }; // Set flightNum to null if it's an empty string
        } else {
            return null; // Invalid format
        }
    }

    const getFlightDirection = (pickUpInfo, dropOffInfo) => {
        if (pickUpInfo.isAirport && dropOffInfo.isAirport) {
            return {
                direction: 'arriving',
                iataCode: pickUpInfo.iataCode
            }
        } else if (pickUpInfo.isAirport) {
            return {
                direction: 'arriving',
                iataCode: pickUpInfo.iataCode
            }
        } else if (dropOffInfo.isAirport) {
            return {
                direction: 'departing',
                iataCode: dropOffInfo.iataCode
            }
        } else {
            return null;
        }
    }

    const getFlights = (searchTerm: string, data: any, isReturn: boolean = false) => {
        if (data) {
            // const { airlineCode, flightNum } = separateFlightNumber(searchTerm);

            const {direction, iataCode} = isReturn
                ? getFlightDirection(data.dropOffInfo, data.pickupInfo)
                : getFlightDirection(data.pickupInfo, data.dropOffInfo)

            const datetime = isReturn
                ? returnDate
                : departureDate || pickUpDateTime

            // if (airlineCode) {
                query({
                    // airline_code: airlineCode,
                    flight_number: searchTerm,
                    airport_code: iataCode,
                    flight_direction: direction,
                    pickup_datetime: datetime
                })
            // }
        }
    }

    return {
        response,
        getFlights,
        handleSelect,
        handleChange,
        setIsDropdownOpen,
        flightNumberPlaceholder,
        displayError,
        errorMessageString,
        isDropdownOpen,
        isIntegration
    }
}