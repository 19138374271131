interface BackgroundMaskLineProps {
    width?: string,
    height: string | undefined,
    backgroundColor: string | undefined,
}

function BackgroundMaskLine({width, height, backgroundColor}: BackgroundMaskLineProps) {
    return (
        <div className="background-mask" style={{width, height, backgroundColor}}/>
    )
}

export default BackgroundMaskLine