import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts";
import {useEffect, useState} from "react";
import BookingAmenityPriceBreakdownPerLeg from "./BookingAmenityPriceBreakdownPerLeg.tsx";
import BookingAmenityPriceBreakdownTogether from "./BookingAmenityPriceBreakdownTogether.tsx";

const BookingAmenitiesBreakdown = () => {
    const [seperateAmenityBreakdown, setSeperateAmenityBreakdown] = useState<boolean>(false);

    const {
        amenities: bookingAmenities
    } = useAppSelector((state) => state?.bookingForm)

    const {
        amenities: vehicleAmenities
    } = useAppSelector((state) => state?.vehicleType)

    useEffect(() => {
        let sameAmenityFound = false;
        vehicleAmenities.map((amenity) => {
            if (bookingAmenities[amenity.id] && bookingAmenities[amenity.id].count > 0 && bookingAmenities[amenity.id].returnCount > 0) {
                sameAmenityFound = true
            }
        })
        setSeperateAmenityBreakdown(sameAmenityFound)
    }, [bookingAmenities]);

    return ( seperateAmenityBreakdown
        ? <BookingAmenityPriceBreakdownPerLeg amenities={vehicleAmenities} bookingAmenities={bookingAmenities}/>
        : <BookingAmenityPriceBreakdownTogether amenities={vehicleAmenities} bookingAmenities={bookingAmenities}/>
    );
};

export default BookingAmenitiesBreakdown;