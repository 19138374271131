import IconTimes from "../../../../../components/generic/icons/IconTimes.tsx";
import BookingStripePaymentInfo from "./BookingStripePaymentInfo.tsx";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../../../../../features/stripe/components/CheckoutForm.tsx";
import {useBookingStripe} from "../hooks/useBookingStripe.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";

interface BookingStripeBodyProps {
    paymentMethodLabel: string
    handleCancel: (event: any) => void
    stripePromise: any
    options: object
}

const BookingStripeBody = () => {
    const {clientSecret} = useAppSelector((state) => (state?.stripe))
    const {paymentMethodLabel, isLoading, handleCancel, stripePromise, options} = useBookingStripe({
        clientSecret: clientSecret || '',
    })

    return (!isLoading && (
        <div className="gb--stripe__overlay">
            <div className="gb--stripe__info">
                <span>{paymentMethodLabel}</span>
                {/*{!isMobile && (*/}
                    <div>
                        <button onClick={handleCancel}>
                            <IconTimes fill="#ff6144"/>
                        </button>
                    </div>
                {/*)}*/}
            </div>
            <BookingStripePaymentInfo/>
            <Elements
                stripe={stripePromise}
                options={options}
            >
                <CheckoutForm/>
            </Elements>
        </div>
    ))
};

export default BookingStripeBody;