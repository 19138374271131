import IncompleteSearchFormDataOverlay from "./IncompleteSearchFormDataOverlay.tsx";
import MobileDatePicker from "../../../components/MobileDatePicker.tsx";
import MobilePlaceInputOverlay from "../../../components/MobilePlaceInputOverlay.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

function IncompleteSearchFormDataOverlayWrapper() {
    const {
        singleCalendarActive,
        departurePlaceInputActive,
        destinationPlaceInputActive
    } = useAppSelector((state) => (state.mobile))

    const overlays = {
        [`${singleCalendarActive}`]: <MobileDatePicker/>,
        [`${departurePlaceInputActive || destinationPlaceInputActive}`]: <MobilePlaceInputOverlay searchStrict/>,
    }

    return (
        <>
            <IncompleteSearchFormDataOverlay/>
            {overlays?.true}
        </>
    )
}

export default IncompleteSearchFormDataOverlayWrapper