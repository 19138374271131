import Row from "../../../../../features/containers/components/Row.tsx"

interface BookingStripeCashPriceDisplayProps {
    displayPrice: number
}

const BookingStripeCashPriceDisplay = ({displayPrice}: BookingStripeCashPriceDisplayProps) =>  (
    <Row
        justify
        style={{width: "100%"}}
    >
        <span>
            displayString: <b>{(displayPrice || 0)?.toFixed(2)} EUR</b>
        </span>
    </Row>
)

export default BookingStripeCashPriceDisplay
