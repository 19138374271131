import TopBar from "../features/topBar/components/TopBar.tsx";
import {useInitializeDataFromParams} from "../hooks/useInitializeDataFromParams.ts";
import ScrollToTop from "../../../features/scroll/components/ScrollToTop.tsx";
import useToggleDOMElements from "../hooks/useToggleDOMElements.js";

function Header() {
    useInitializeDataFromParams()
    useToggleDOMElements();

    return (
        <>
            <ScrollToTop/>
            <TopBar/>
        </>
    )
}

export default Header;