import {PagesType} from "../types/Pages.type.ts";
import {ReactNode} from "react";
import IconInfo from "../../../../../components/generic/icons/IconInfo.tsx";
import IconType from "../../../../../types/Icon.type.ts";
import IconHome from "../../../../../components/generic/icons/IconHome.tsx";
import IconQuestion from "../../../../../components/generic/icons/menuIcons/IconQuestion.tsx";
import {DisplayPagesType} from "../types/DisplayPages.type.ts";

interface MobileMenuIconsProps {
    tag: PagesType,
}

function MobileMenuIcons({tag}: MobileMenuIconsProps) {
    const components: Record<DisplayPagesType, (props: IconType) => ReactNode> = {
        homePage: IconHome,
        contact: IconInfo,
        faq: IconQuestion,
        terms: IconInfo,
    };

    const IconMenu = components[tag ?? ''];

    return (
        <IconMenu
            width="18"
            height="18"
            fill="#9b9b9b"
            style={{
                verticalAlign: 'text-top',
                marginRight: '1rem',
            }}
        />
    );
}

export default MobileMenuIcons;