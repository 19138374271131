import GenericSvgIcon from "./GenericSvgIcon.tsx";
import IconType from "../../../types/Icon.type.ts";

function IconNoResults(props: IconType) {
    const {
        height = "74",
        viewBox = "0 0 188 74",
        width = "188",
        ...rest
    } = props
    return (
        <GenericSvgIcon
            viewBox={viewBox}
            width={width}
            height={height}
            {...rest}
            svgIconPath={(
                <>
                    <filter id="a">
                        <feColorMatrix
                            in="SourceGraphic"
                            type="matrix"
                            values="0 0 0 0 0.933333 0 0 0 0 0.949020 0 0 0 0 0.984314 0 0 0 1.000000 0"
                        />
                    </filter>
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(0 51.5)">
                            <path
                                d="m187.312687 22.1605579-93.6563433-22.1605579-93.6563437 22.1605579s62.9430431-.2593038 94.2807195 0c31.3376765.2593038 93.0319675 0 93.0319675 0z"
                                fill="#c7c9dd"
                            />
                            <g fill="#f6f8fd">
                                <path
                                    d="m111.216908 21.8718283-17.5605643-21.8718283 5.8744927 21.9507055c3.9264356-.0133893 7.8210346-.039224 11.6860716-.0788772z"
                                />
                                <path
                                    d="m87.7818509 21.9507055 5.8744928-21.9507055-17.5605645 21.8718283c3.8650373.0396532 7.7596365.0654879 11.6860717.0788772z"
                                />
                            </g>
                        </g>
                        <path
                            d="m11.1458333 0c-1.58854163 0-3.0208333.97005222-3.61111108 2.44574667l-2.19835055 5.49262166c-.25390611-.10199666-.52734389-.16059055-.81163223-.16059055h-.97439222c-1.35416666 0-2.51736111.98524333-2.74088555 2.31987832l-.35373223 2.1245661h2.56944445l-.16059056.2430556c-.78125 1.1805555-1.19791666 2.5672744-1.19791666 3.984375v11.8836805c0 .9136284.75303833 1.6666667 1.66666666 1.6666667h2.77777778c.91362833 0 1.66666667-.7530383 1.66666667-1.6666667v-1.1111111h24.44444442v1.1111111c0 .9136284.7530384 1.6666667 1.6666667 1.6666667h2.7777778c.9136283 0 1.6666666-.7530383 1.6666666-1.6666667v-11.8836805c0-1.4171006-.4166666-2.8016495-1.1979166-3.984375l-.16059-.2430556h2.5694444l-.3537328-2.1245661c-.2213544-1.33463499-1.3867189-2.31987832-2.7408855-2.31987832h-.9743922c-.2842884 0-.5555556.06076389-.8116323.16059055l-2.1983505-5.49479166c-.5902778-1.47352445-2.0225695-2.44357667-3.6111111-2.44357667z"
                            fill="#ff6144"
                            fillOpacity=".55"
                            fillRule="nonzero"
                            transform="translate(74.357143 28.571429)"
                        />
                        <g filter="url(#a)">
                            <g fill="#f2f5f7" transform="translate(33)">
                                <path
                                    d="m73.7956473 7.16194196c-.7071428 0-1.3754464.13928572-2.0159598.34118304-2.0290179-2.91662946-5.3946429-4.83415179-9.2169643-4.83415179s-7.1879464 1.91752233-9.2169643 4.83415179c-.6405134-.20189732-1.3088169-.34118304-2.0159598-.34118304-3.7222098 0-6.7396205 3.01741074-6.7396205 6.73962054h35.9447544c.0003349-3.7222098-3.0170759-6.73962054-6.7392857-6.73962054z"
                                />
                                <path
                                    d="m91.6489955 19.1991071c-.335491 0-.6525669.0659599-.95625.1617188-.9622768-1.3834822-2.5590401-2.293192-4.3720982-2.293192-1.813058 0-3.4094866.9097098-4.3720982 2.293192-.303683-.0957589-.6207589-.1617188-.95625-.1617188-1.7655134 0-3.196875 1.4313616-3.196875 3.196875h17.0507813c-.0003349-1.7655134-1.4316965-3.196875-3.1972099-3.196875z"
                                />
                                <path
                                    d="m53.1452009 2.20290179c-.3354911 0-.652567.06595982-.95625.16171875-.9626116-1.38348215-2.5590402-2.29319197-4.3720982-2.29319197-1.8130581 0-3.4094866.90970982-4.3720982 2.29319197-.3036831-.09575893-.620759-.16171875-.95625-.16171875-1.7655134 0-3.196875 1.4313616-3.196875 3.196875h17.0507812c0-1.7655134-1.4313616-3.196875-3.1972098-3.196875z"
                                />
                                <path
                                    d="m114.532031 24.4337054c-.521317 0-1.013839.1024553-1.485937.2514509-1.495648-2.1502233-3.976674-3.5638393-6.794531-3.5638393-2.817858 0-5.298884 1.413616-6.7945317 3.5638393-.4720983-.1489956-.9649554-.2514509-1.4859375-.2514509-2.7438617 0-4.9684152 2.2242187-4.9684152 4.9684151h26.4977674c0-2.7438616-2.224553-4.9684151-4.968415-4.9684151z"
                                />
                                <path
                                    d="m25.9540179 17.9930804c-.6284599 0-1.2224331.1235491-1.7916295.3030133-1.8030134-2.5918526-4.7939732-4.2960937-8.1907366-4.2960937s-6.38772323 1.7042411-8.19073662 4.2960937c-.56919643-.1794642-1.16316964-.3030133-1.79162947-.3030133-3.30770089 0-5.98928571 2.6815848-5.98928571 5.9892857h31.9429688c.0003348-3.3077009-2.6809152-5.9892857-5.9889509-5.9892857z"
                                />
                                <path
                                    d="m14.5372768 24.0376116c0-3.8805803 3.1456473-7.0262277 7.0262277-7.0262277.5856026 0 1.1457589.0927456 1.6895089.2276786 1.8334821-1.9797991 4.4330357-3.2390625 7.344308-3.2390625 5.5436384 0 10.0376116 4.4939732 10.0376116 10.0376116z"
                                />
                                <path
                                    d="m84.1014509 6.17622768c0-2.38560268 1.9339286-4.31953125 4.3195312-4.31953125.3599331 0 .7044643.05691964 1.0386161.13995536 1.1270089-1.2170759 2.7251116-1.99118304 4.515067-1.99118304 3.4081473 0 6.1707588 2.76294643 6.1707588 6.17075893z"
                                />
                            </g>
                        </g>
                    </g>
                </>
            )}
        />
    )
}

export default IconNoResults