import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "../../../../types/Icon.type";

function IconDevices(props: IconType) {
    const {
        width = '98',
        height = '72',
        viewBox = '0 0 98 72',
        title = 'IconDevices',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <g transform="translate(0 .3)">
                        <path
                            d="m80.7142857 59.2857143v-50.00000001c0-3.92857143-3.2142857-7.14285715-7.1428571-7.14285715h-57.1428572c-3.9285714 0-7.14285711 3.21428572-7.14285711 7.14285715v50.00000001h-7.14285715v2.1428571c0 3.9285715 3.21428572 7.1428572 7.14285715 7.1428572h71.42857141c3.9285714 0 7.1428572-3.2142857 7.1428572-7.1428572v-2.1428571z"
                            fill="#fff"
                        />
                        <g transform="matrix(.90630779 .42261826 -.42261826 .90630779 36.560807 18.346015)">
                            <path
                                d="m20.9417075 2.86872705v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811h-16.35174423c-1.57779988 0-2.86872705 1.29092717-2.86872705 2.86872705v4.01621787c2.06548347 0 3.72934516 1.66386168 3.72934516 3.72934516 0 2.0654835-1.66386169 3.7293452-3.72934516 3.7293452v2.868727c0 1.5777999 1.29092717 2.8687271 2.86872705 2.8687271h16.35174423c0-.4876836.3729345-.8606181.8606181-.8606181v-1.1474909c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474908c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474909c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474908c-.4876836 0-.8606181-.37293454-.8606181-.86061813 0-.4876836.3729345-.86061812.8606181-.86061812v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811 0-.4876836.3729345-.86061812.8606181-.86061812v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811 0-.4876836.3729345-.86061812.8606181-.86061812z"
                                fill="#fff"
                            />
                            <g fill="#e6e6ef" transform="translate(7.171818 5.737454)">
                                <path
                                    d="m9.46679927 1.72123623h-8.60618115c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h8.60618115c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                                <path
                                    d="m9.46679927 7.45869033h-8.60618115c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h8.60618115c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                                <path
                                    d="m6.59807222 10.3274174h-5.7374541c-.4876836 0-.86061812-.37293454-.86061812-.86061813 0-.4876836.37293452-.86061812.86061812-.86061812h5.7374541c.48768359 0 .86061811.37293452.86061811.86061812 0 .48768359-.37293452.86061813-.86061811.86061813z"
                                />
                                <path
                                    d="m9.46679927 10.3274174c-.05737455 0-.11474909 0-.17212363-.0286873-.05737454 0-.11474908-.0286873-.17212362-.0573745-.05737454-.0286873-.08606181-.0573746-.14343635-.0860618-.05737454-.0286873-.08606181-.0573746-.14343636-.1147491-.14343635-.11474911-.22949816-.34424727-.22949816-.57374543 0-.22949817.08606181-.45899633.25818544-.60243268.31555997-.31555998.88930538-.31555998 1.20486531 0 .1721237.17212362.2581855.37293451.2581855.60243268 0 .22949816-.0860618.45899632-.2581855.60243263-.14343631.1721237-.37293447.2581855-.60243263.2581855z"
                                />
                                <path
                                    d="m3.72934517 4.58996328h-2.86872705c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h2.86872705c.48768359 0 .86061811.37293452.86061811.86061812 0 .48768359-.37293452.86061811-.86061811.86061811z"
                                />
                                <path
                                    d="m9.46679927 4.58996328h-2.86872705c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h2.86872705c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                            </g>
                            <path
                                d="m32.4166157 3.72934517c0-1.57779988-1.4845663-2.86872705-3.2990361-2.86872705h-8.9073975c0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061813-.9897109.86061813v1.1474908c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474909c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474908c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474909c.5608362 0 .9897109.3729345.9897109.8606181h8.9073975c1.8144698 0 3.2990361-1.2909272 3.2990361-2.8687271z"
                                fill="#7ed321"
                            />
                            <g fill="#070c63" stroke="#070c63" strokeWidth="1.627907">
                                <path
                                    d="m29.029924 21.8023256h-6.5343227c-.5554174 0-.9801484-.3729345-.9801484-.8606181s.424731-.8606181.9801484-.8606181h6.5343227c1.2741929 0 2.287013-.8893054 2.287013-2.008109v-14.34363523c0-1.11880355-1.0128201-2.00810894-2.287013-2.00810894h-6.5343227c-.5554174 0-.9801484-.37293452-.9801484-.86061811 0-.4876836.424731-.86061812.9801484-.86061812h6.5343227c2.3523562 0 4.2473098 1.66386169 4.2473098 3.72934517v14.34363523c0 2.0654835-1.8949536 3.7293452-4.2473098 3.7293452z"
                                />
                                <path
                                    d="m18.0729804 20.0810894h-14.34363523c-1.11880355 0-2.00810894-.8893054-2.00810894-2.008109v-2.0941707c2.12285802-.4016218 3.72934517-2.2662944 3.72934517-4.5039015s-1.60648715-4.10227968-3.72934517-4.50390147v-3.24166156c0-1.11880355.88930539-2.00810894 2.00810894-2.00810894h14.34363523c.4876836 0 .8606181-.37293452.8606181-.86061811 0-.4876836-.3729345-.86061812-.8606181-.86061812h-14.34363523c-2.06548348 0-3.72934517 1.66386169-3.72934517 3.72934517v4.01621787c0 .48768359.37293452.86061811.86061812.86061811 1.57779987 0 2.86872705 1.29092717 2.86872705 2.86872705 0 1.5777999-1.29092718 2.8687271-2.86872705 2.8687271-.4876836 0-.86061812.3729345-.86061812.8606181v2.868727c0 2.0654835 1.66386169 3.7293452 3.72934517 3.7293452h14.34363523c.4876836 0 .8606181-.3729345.8606181-.8606181s-.3729345-.8606181-.8606181-.8606181z"
                                />
                            </g>
                            <g fill="#fff">
                                <path
                                    d="m28.7589887 10.6142901h-3.8727815c-.3168709 0-.5737454-.2568746-.5737454-.5737454v-3.72934519c0-.31687084.2568745-.57374541.5737454-.57374541h3.8727815c.3168708 0 .5737454.25687457.5737454.57374541v3.72934519c0 .3168708-.2568746.5737454-.5737454.5737454z"
                                />
                                <path
                                    d="m28.3466092 16.3517442h-3.0480225c-.5181638 0-.9144068-.3729345-.9144068-.8606181s.396243-.8606181.9144068-.8606181h3.0480225c.5181638 0 .9144067.3729345.9144067.8606181s-.3962429.8606181-.9144067.8606181z"
                                />
                            </g>
                        </g>
                        <g transform="translate(26 18.406977)">
                            <path
                                d="m20.9417075 2.86872705v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811h-16.35174423c-1.57779988 0-2.86872705 1.29092717-2.86872705 2.86872705v4.01621787c2.06548347 0 3.72934516 1.66386168 3.72934516 3.72934516 0 2.0654835-1.66386169 3.7293452-3.72934516 3.7293452v2.868727c0 1.5777999 1.29092717 2.8687271 2.86872705 2.8687271h16.35174423c0-.4876836.3729345-.8606181.8606181-.8606181v-1.1474909c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474908c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474909c-.4876836 0-.8606181-.3729345-.8606181-.8606181s.3729345-.8606181.8606181-.8606181v-1.1474908c-.4876836 0-.8606181-.37293454-.8606181-.86061813 0-.4876836.3729345-.86061812.8606181-.86061812v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811 0-.4876836.3729345-.86061812.8606181-.86061812v-1.14749082c-.4876836 0-.8606181-.37293452-.8606181-.86061811 0-.4876836.3729345-.86061812.8606181-.86061812z"
                                fill="#fff"
                            />
                            <g fill="#e6e6ef" transform="translate(7.171818 5.737454)">
                                <path
                                    d="m9.46679927 1.72123623h-8.60618115c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h8.60618115c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                                <path
                                    d="m9.46679927 7.45869033h-8.60618115c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h8.60618115c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                                <path
                                    d="m6.59807222 10.3274174h-5.7374541c-.4876836 0-.86061812-.37293454-.86061812-.86061813 0-.4876836.37293452-.86061812.86061812-.86061812h5.7374541c.48768359 0 .86061811.37293452.86061811.86061812 0 .48768359-.37293452.86061813-.86061811.86061813z"
                                />
                                <path
                                    d="m9.46679927 10.3274174c-.05737455 0-.11474909 0-.17212363-.0286873-.05737454 0-.11474908-.0286873-.17212362-.0573745-.05737454-.0286873-.08606181-.0573746-.14343635-.0860618-.05737454-.0286873-.08606181-.0573746-.14343636-.1147491-.14343635-.11474911-.22949816-.34424727-.22949816-.57374543 0-.22949817.08606181-.45899633.25818544-.60243268.31555997-.31555998.88930538-.31555998 1.20486531 0 .1721237.17212362.2581855.37293451.2581855.60243268 0 .22949816-.0860618.45899632-.2581855.60243263-.14343631.1721237-.37293447.2581855-.60243263.2581855z"
                                />
                                <path
                                    d="m3.72934517 4.58996328h-2.86872705c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h2.86872705c.48768359 0 .86061811.37293452.86061811.86061812 0 .48768359-.37293452.86061811-.86061811.86061811z"
                                />
                                <path
                                    d="m9.46679927 4.58996328h-2.86872705c-.4876836 0-.86061812-.37293452-.86061812-.86061811 0-.4876836.37293452-.86061812.86061812-.86061812h2.86872705c.48768359 0 .86061813.37293452.86061813.86061812 0 .48768359-.37293454.86061811-.86061813.86061811z"
                                />
                            </g>
                            <path
                                d="m32.4166157 3.72934517c0-1.57779988-1.4845663-2.86872705-3.2990361-2.86872705h-8.9073975c0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061811-.9897109.86061811v1.14749082c.5608362 0 .9897109.37293452.9897109.86061812 0 .48768359-.4288747.86061813-.9897109.86061813v1.1474908c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474909c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474908c.5608362 0 .9897109.3729345.9897109.8606181s-.4288747.8606181-.9897109.8606181v1.1474909c.5608362 0 .9897109.3729345.9897109.8606181h8.9073975c1.8144698 0 3.2990361-1.2909272 3.2990361-2.8687271z"
                                fill="#7ed321"
                            />
                            <g fill="#070c63" stroke="#070c63" strokeWidth="1.627907">
                                <path
                                    d="m29.029924 21.8023256h-6.5343227c-.5554174 0-.9801484-.3729345-.9801484-.8606181s.424731-.8606181.9801484-.8606181h6.5343227c1.2741929 0 2.287013-.8893054 2.287013-2.008109v-14.34363523c0-1.11880355-1.0128201-2.00810894-2.287013-2.00810894h-6.5343227c-.5554174 0-.9801484-.37293452-.9801484-.86061811 0-.4876836.424731-.86061812.9801484-.86061812h6.5343227c2.3523562 0 4.2473098 1.66386169 4.2473098 3.72934517v14.34363523c0 2.0654835-1.8949536 3.7293452-4.2473098 3.7293452z"
                                />
                                <path
                                    d="m18.0729804 20.0810894h-14.34363523c-1.11880355 0-2.00810894-.8893054-2.00810894-2.008109v-2.0941707c2.12285802-.4016218 3.72934517-2.2662944 3.72934517-4.5039015s-1.60648715-4.10227968-3.72934517-4.50390147v-3.24166156c0-1.11880355.88930539-2.00810894 2.00810894-2.00810894h14.34363523c.4876836 0 .8606181-.37293452.8606181-.86061811 0-.4876836-.3729345-.86061812-.8606181-.86061812h-14.34363523c-2.06548348 0-3.72934517 1.66386169-3.72934517 3.72934517v4.01621787c0 .48768359.37293452.86061811.86061812.86061811 1.57779987 0 2.86872705 1.29092717 2.86872705 2.86872705 0 1.5777999-1.29092718 2.8687271-2.86872705 2.8687271-.4876836 0-.86061812.3729345-.86061812.8606181v2.868727c0 2.0654835 1.66386169 3.7293452 3.72934517 3.7293452h14.34363523c.4876836 0 .8606181-.3729345.8606181-.8606181s-.3729345-.8606181-.8606181-.8606181z"
                                />
                            </g>
                            <g fill="#fff">
                                <path
                                    d="m28.7589887 10.6142901h-3.8727815c-.3168709 0-.5737454-.2568746-.5737454-.5737454v-3.72934519c0-.31687084.2568745-.57374541.5737454-.57374541h3.8727815c.3168708 0 .5737454.25687457.5737454.57374541v3.72934519c0 .3168708-.2568746.5737454-.5737454.5737454z"
                                />
                                <path
                                    d="m28.3466092 16.3517442h-3.0480225c-.5181638 0-.9144068-.3729345-.9144068-.8606181s.396243-.8606181.9144068-.8606181h3.0480225c.5181638 0 .9144067.3729345.9144067.8606181s-.3962429.8606181-.9144067.8606181z"
                                />
                            </g>
                        </g>
                        <g fill="#070c63">
                            <path
                                d="m52.1428571 61.4285714h-14.2857142c-1.2142858 0-2.1428572-.9285714-2.1428572-2.1428571s.9285714-2.1428572 2.1428572-2.1428572h14.2857142c1.2142858 0 2.1428572.9285715 2.1428572 2.1428572s-.9285714 2.1428571-2.1428572 2.1428571z"
                            />
                            <path
                                d="m80.7142857 70.7142857h-71.42857141c-5.14285715 0-9.28571429-4.1428571-9.28571429-9.2857143v-2.1428571c0-1.2142857.92857143-2.1428572 2.14285714-2.1428572h24.99999996c1.2142858 0 2.1428572.9285715 2.1428572 2.1428572s-.9285714 2.1428571-2.1428572 2.1428571h-22.85714281c0 2.7857143 2.21428571 5 5 5h71.42857141c2.7857143 0 5-2.2142857 5-5h-22.8571428c-1.2142858 0-2.1428572-.9285714-2.1428572-2.1428571s.9285714-2.1428572 2.1428572-2.1428572h25c1.2142857 0 2.1428571.9285715 2.1428571 2.1428572v2.1428571c0 5.1428572-4.1428571 9.2857143-9.2857143 9.2857143z"
                            />
                            <path
                                d="m80.7142857 53.5714286c-1.2142857 0-2.1428571-.9285715-2.1428571-2.1428572v-42.14285711c0-2.78571429-2.2142857-5-5-5h-57.1428572c-2.7857143 0-5 2.21428571-5 5v42.14285711c0 1.2142857-.9285714 2.1428572-2.14285711 2.1428572-1.21428572 0-2.14285715-.9285715-2.14285715-2.1428572v-42.14285711c0-5.14285715 4.14285716-9.28571429 9.28571426-9.28571429h57.1428572c5.1428571 0 9.2857143 4.14285714 9.2857143 9.28571429v42.14285711c0 1.2142857-.9285715 2.1428572-2.1428572 2.1428572z"
                            />
                        </g>
                    </g>
                    <g transform="translate(71.428571 31.014286)">
                        <path
                            d="m20.2664399 38.3503401h-16.21315192c-1.71485261 0-3.11791383-1.4030612-3.11791383-3.1179138v-31.17913832c0-1.71485261 1.40306122-3.11791383 3.11791383-3.11791383h16.21315192c1.7148526 0 3.1179138 1.40306122 3.1179138 3.11791383v31.17913832c0 1.7148526-1.4030612 3.1179138-3.1179138 3.1179138z"
                            fill="#fff"
                        />
                        <path
                            d="m21.8589744 8.10657596h-4.5761382c-.8643816 0-1.7796093 1.1373417-2.1609541 3.22542814-.2542299 1.3920576-3.0083871 10.6464479-8.26247167 27.763171l16.52494327.1905392v-27.9537102c0-1.82774263-.6609977-3.22542814-1.5253793-3.22542814z"
                            fill="#e9eef4"
                        />
                        <path
                            d="m20.2664399 38.3503401h-16.21315192c-1.71485261 0-3.11791383-.1875-3.11791383-.4166666v-4.1666667c0-.2291667 1.40306122-.4166667 3.11791383-.4166667h16.21315192c1.7148526 0 3.1179138.1875 3.1179138.4166667v4.1666667c0 .2291666-1.4030612.4166666-3.1179138.4166666z"
                            fill="#4a79e2"
                        />
                        <path
                            d="m20.2664399 0h-16.21315192c-2.24489796 0-4.05328798 1.80839002-4.05328798 4.05328798v31.17913832c0 2.244898 1.80839002 4.053288 4.05328798 4.053288h16.21315192c2.244898 0 4.053288-1.80839 4.053288-4.053288v-31.17913832c0-2.24489796-1.80839-4.05328798-4.053288-4.05328798zm2.1825397 35.2324263c0 1.2159864-.9665533 2.1825397-2.1825397 2.1825397h-16.21315192c-1.21598639 0-2.18253968-.9665533-2.18253968-2.1825397v-31.17913832c0-1.21598639.96655329-2.18253968 2.18253968-2.18253968h2.49433107v.93537415c0 .53004535.4053288.93537415.93537415.93537415h9.3537415c.5300453 0 .9353741-.4053288.9353741-.93537415v-.93537415h2.4943311c1.2159864 0 2.1825397.96655329 2.1825397 2.18253968z"
                            fill="#070c63"
                            stroke="#070c63"
                            strokeLinejoin="round"
                            strokeWidth="1.428571"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconDevices;
