import SearchResultsSliceType from "../types/SearchResultsSlice.type.ts";
import SearchResultsSlice from "../functions/searchResultsSlice.ts";
import {searchFormInitialState} from "../../../../reducers/constants/searchFormInitialState.ts";

export const searchResultsSliceInitialState: SearchResultsSliceType = {
    refreshResults: false,
    unsuccessfulMozioFetchCount: 0,
    successfulMozioFetchCount: 0,
    shouldUseStationParams: true,
    searchedWithStations: false,
    selectedVehicleCategory: 0,
    categoryPrices: {},
    preselectCategory: true,
    partnerId: 0,
    latestSearchFormUsed: searchFormInitialState
}