import React from 'react';
import useDateSelectHandlers from "../../../../../layout/form/features/dates/hooks/useDateSelectHandlers.ts";
import {add} from "date-fns";
import DateTimeSelect from "../../../../../features/dateTime/components/DateTimeSelect.tsx";

const DateTimeIncomplete = ({maxDateTime, closeClick}: {maxDateTime?: string, closeClick?: () => void}) => {
    const {
        departure,
        setDeparture,
        handleSelectDate,
    } = useDateSelectHandlers()

    return (
        <DateTimeSelect
            selectedDate={departure}
            selectedTime={departure}
            changeDate={(value: Date) => {
                setDeparture(value.toISOString())
            }}
            changeTime={(value: Date) => {
                setDeparture(value.toISOString())
            }}
            submitSelect={handleSelectDate}
            dateLabel={'Date'}
            handleClose={() => {
                closeClick()
            }}
            isClosable={true}
            timeLabel={'Time'}
            minDateTime={add(new Date(), {hours: 1})}
            maxDateTime={maxDateTime ? new Date(maxDateTime) : undefined}
        />
    );
};

export default DateTimeIncomplete;