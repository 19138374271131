import useGbtSingleTrackingEvent from "./useGbtSingleTrackingEvent.ts";
import {
    getUniqueAttributeArrayFromArrayOfObjects
} from "../../../utils/array/getUniqueAttributeArrayFromArrayOfObjects.ts";
import VehicleType from "../../../types/VehicleType.type.ts";
import useCachedData from "../../../pages/searchResults/hooks/useCachedData.ts";

export default function useSearchTripsEvent(vehicles: VehicleType[]) {
    const {
        fetchedGbtData,
        fetchedGbtOriginalArgs,
    } = useCachedData();

    const {
        id: departureId,
        label: departureLabel,
        countryShortCode: departureCountryShortCode,
    } = fetchedGbtData?.data?.dropOffInfo.dropOffStation || {};

    const {
        id: destinationId,
        label: destinationName,
        countryShortCode: destinationCountryShortCode,
    } = fetchedGbtData?.data?.pickupInfo.pickUpStation || {};

    const numOfResults = vehicles.length;

    const vehicleTypes: string[] = [
        ...getUniqueAttributeArrayFromArrayOfObjects(fetchedGbtData?.data?.gbt?.vehicleGroups, "type"),
    ];

    const prices: number[] = [
        ...getUniqueAttributeArrayFromArrayOfObjects(vehicles, "price"),
    ];


    const model = {
        // Stations info
        pick_up_station_id: departureId,
        pick_up_station_name: departureLabel,
        fromCountry: departureCountryShortCode,
        drop_off_station_id: destinationId,
        drop_off_station_name: destinationName,
        toCountry: destinationCountryShortCode,
        return_pick_up_station_id: destinationId,
        return_drop_off_station_id: departureId,

        // Search results request info
        pick_up_datetime: fetchedGbtOriginalArgs?.pickUpDateTime,
        passengers: fetchedGbtOriginalArgs.numberOfPeople,

        // Search results response info
        num_of_results: numOfResults,
        transfer_classes_available: vehicleTypes.join(" | "),
        min_price: Math.min(...prices),
        max_price: Math.max(...prices),

        // Transfer info for both legs
        pick_up_location_id: fetchedGbtData?.data?.pickupInfo.pickUpPlaceId,
        pick_up_location_name: fetchedGbtData?.data?.pickupInfo.pickUpPlace,
        drop_off_location_id: fetchedGbtData?.data?.dropOffInfo.dropOffPlaceId,
        drop_off_location_name: fetchedGbtData?.data?.dropOffInfo.dropOffPlace,
        return_pick_up_location_id: fetchedGbtData?.data?.dropOffInfo.dropOffPlaceId,
        return_pick_up_location_name: fetchedGbtData?.data?.dropOffInfo.dropOffPlace,
        return_drop_off_location_id: fetchedGbtData?.data?.pickupInfo.pickUpPlaceId,
        return_drop_off_location_name: fetchedGbtData?.data?.pickupInfo.pickUpPlace,
        return_pick_up_datetime: fetchedGbtOriginalArgs?.returnDateTime,
    }

    return useGbtSingleTrackingEvent("search_trips", model)
}