import {ReactNode} from "react"
import useBookingInfoInput from "../hooks/useBookingInfoInput.ts"
import {BookingInfoInputType} from "../types/BookingInfoInput.type.ts"
import FloatingLabelInputField from "../../../../../components/generic/formElements/FloatingLabelInputField.tsx"
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts"

type BookingInputFieldProps = BookingInfoInputType & {
    id: string
    label: string
    icon: ReactNode
    maxLength?: number
    type: "text" | "number" | "email" | "phone"
}

function BookingInfoInputField(props: BookingInputFieldProps) {
    const {id, label, icon, type, maxLength, ...rest} = props
    const {onChange, displayError, errorMessage, onBlur, value} = useBookingInfoInput(rest)
    const errorMessageString = useTranslation(errorMessage) as string

    return (
        <FloatingLabelInputField
            type={type}
            id={id}
            label={label}
            value={value!}
            icon={icon}
            onBlur={onBlur}
            onChange={onChange}
            maxLength={maxLength}
            displayError={displayError}
            errorMessage={errorMessageString}
            background={'white'}
        />
    )
}

export default BookingInfoInputField
