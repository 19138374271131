import UrlObjectType from "../../../../../types/UrlObject.type.ts";
import {Link} from "react-router-dom";

interface MenuItemProps {
    urlObject:  UrlObjectType,
    currentRoute: string,
    visible?: boolean,
    reload?: boolean
}

function MenuItem({urlObject, currentRoute, visible = false, reload = false}: MenuItemProps) {
    const visibleClassName = visible ? "gb--menu" : "select"

    return (
        <li
            key={urlObject.routeName}
            className={
                urlObject.url === currentRoute
                    ? `${visibleClassName}__item ${visibleClassName}__item--selected`
                    : `${visibleClassName}__item`
            }
        >
            <Link
                to={urlObject.url}
                reloadDocument={reload}
            >
                {urlObject.text}
            </Link>
        </li>
    )
}

export default MenuItem