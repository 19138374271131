import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconSkis(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconSkis',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(0 -1)">
                    <g fill="#58627c" transform="matrix(.9961947 .08715574 -.08715574 .9961947 1.659376 1.397806)">
                        <path
                            d="m1.43589744 3.69230769c0 .22666667.18358974.41025641.41025641.41025641.22666666 0 .41025641-.18358974.41025641-.41025641v-3.07692307c0-.22666667-.18358975-.41025641-.41025641-.41025641-.22666667 0-.41025641.18358974-.41025641.41025641z"/>
                        <path
                            d="m1.84615385 14.7692308c-.11323077 0-.20512821-.0916923-.20512821-.2051282v-10.4615385c0-.11343589.09189744-.2051282.20512821-.2051282s.2051282.09169231.2051282.2051282v10.4615385c0 .1134359-.09189743.2051282-.2051282.2051282z"/>
                        <path d="m2.15384615 13.6410256h-.61538461l-.20512821-2.4615384h1.02564103z"/>
                        <path
                            d="m2.15384615 13.7435897h-.61538461c-.05333333 0-.09784616-.0408205-.10215385-.0939487l-.2051282-2.4615384c-.00246154-.028718.00738461-.0568205.02666666-.0781539.01948718-.0209231.04676923-.0330256.07548718-.0330256h1.02564103c.02871795 0 .056.0121025.07548718.0330256.01928205.0213334.0291282.0494359.02666667.0781539l-.20512821 2.4615384c-.00430769.0531282-.04882051.0939487-.10215385.0939487zm-.52102564-.2051282h.42666667l.18810256-2.2564102h-.80266666z"/>
                        <path
                            d="m1.84615385 4.30769231c-.33928206 0-.61538462-.27610257-.61538462-.61538462v-3.07692307c0-.33928206.27610256-.61538462.61538462-.61538462.33928205 0 .61538461.27610256.61538461.61538462v3.07692307c0 .33928205-.27610256.61538462-.61538461.61538462zm0-3.8974359c-.11302564 0-.20512821.09189744-.20512821.20512821v3.07692307c0 .11323077.09210257.20512821.20512821.20512821s.2051282-.09189744.2051282-.20512821v-3.07692307c0-.11323077-.09210256-.20512821-.2051282-.20512821z"/>
                        <path
                            d="m2.76923077 11.2820513h-1.84615385c-.05661538 0-.1025641-.0459487-.1025641-.1025641s.04594872-.1025641.1025641-.1025641h1.84615385c.05661538 0 .1025641.0459487.1025641.1025641s-.04594872.1025641-.1025641.1025641z"/>
                        <path
                            d="m2.35897436 13.7435897h-1.02564103c-.05661538 0-.1025641-.0459487-.1025641-.1025641 0-.0566153.04594872-.1025641.1025641-.1025641h1.02564103c.05661538 0 .1025641.0459488.1025641.1025641 0 .0566154-.04594872.1025641-.1025641.1025641z"/>
                        <path
                            d="m.51282051 2.25641026c-.13702564 0-.26584615-.05333334-.36266666-.15015385-.2-.2-.2-.52533333 0-.7251282.16943589-.16964103 1.08902564-.55856411 1.19323077-.60225641.03815384-.01620513.08266666-.00758975.11220512.02194871.02953847.02953846.03815385.07364103.02194872.11220513-.04369231.10441026-.43261538 1.02338462-.60225641 1.19302564-.09682051.09702564-.2254359.15035898-.36246154.15035898zm.67425641-1.1868718c-.36553846.16082051-.80533333.37025641-.8916923.45661539-.12.12-.12.31507692 0 .43507692.11610256.11610256.31897435.11610256.43507692 0 .08635897-.08635898.29558974-.52635898.45661538-.89169231z"/>
                    </g>
                    <g fill="#58627c" transform="matrix(.9961947 -.08715574 .08715574 .9961947 1.480245 1.659273)">
                        <path
                            d="m1.43589744 3.69230769c0 .22666667-.18358975.41025641-.41025641.41025641-.22666667 0-.41025641-.18358974-.41025641-.41025641v-3.07692307c0-.22666667.18358974-.41025641.41025641-.41025641.22666666 0 .41025641.18358974.41025641.41025641z"/>
                        <path
                            d="m1.02564103 14.7692308c-.11323077 0-.20512821-.0916923-.20512821-.2051282v-10.4615385c0-.11343589.09189744-.2051282.20512821-.2051282.11323076 0 .2051282.09169231.2051282.2051282v10.4615385c0 .1134359-.09189744.2051282-.2051282.2051282z"/>
                        <path d="m.71794872 13.6410256h.61538461l.20512821-2.4615384h-1.02564103z"/>
                        <path
                            d="m1.33333333 13.7435897h-.61538461c-.05333334 0-.09784616-.0408205-.10215385-.0939487l-.2051282-2.4615384c-.00246154-.028718.00738461-.0568205.02666666-.0781539.01948718-.0209231.04676923-.0330256.07548718-.0330256h1.02564103c.02871795 0 .056.0121025.07548718.0330256.01928205.0213334.0291282.0494359.02666666.0781539l-.2051282 2.4615384c-.00430769.0531282-.04882051.0939487-.10215385.0939487zm-.52102564-.2051282h.42666667l.18810256-2.2564102h-.80266666z"/>
                        <path
                            d="m1.02564103 4.30769231c-.33928206 0-.61538462-.27610257-.61538462-.61538462v-3.07692307c0-.33928206.27610256-.61538462.61538462-.61538462.33928205 0 .61538461.27610256.61538461.61538462v3.07692307c0 .33928205-.27610256.61538462-.61538461.61538462zm0-3.8974359c-.11302564 0-.20512821.09189744-.20512821.20512821v3.07692307c0 .11323077.09210257.20512821.20512821.20512821s.2051282-.09189744.2051282-.20512821v-3.07692307c0-.11323077-.09210256-.20512821-.2051282-.20512821z"/>
                        <path
                            d="m1.94871795 11.2820513h-1.84615385c-.05661538 0-.1025641-.0459487-.1025641-.1025641s.04594872-.1025641.1025641-.1025641h1.84615385c.05661538 0 .1025641.0459487.1025641.1025641s-.04594872.1025641-.1025641.1025641z"/>
                        <path
                            d="m1.53846154 13.7435897h-1.02564103c-.05661538 0-.1025641-.0459487-.1025641-.1025641 0-.0566153.04594872-.1025641.1025641-.1025641h1.02564103c.05661538 0 .1025641.0459488.1025641.1025641 0 .0566154-.04594872.1025641-.1025641.1025641z"/>
                        <path
                            d="m2.35897436 2.25641026c-.13702564 0-.26564103-.05333334-.36266667-.15015385-.16964102-.16964103-.5585641-1.08882051-.60225641-1.19302564-.01620513-.0385641-.00738461-.08266667.02194872-.11220513.02953846-.02974359.07364103-.03815384.11220513-.02194872.10420513.04369231 1.02379487.43241026 1.19323077.60225641.2.2.2.52533334 0 .72512821-.09661539.09661538-.2254359.14994872-.36246154.14994872zm-.67425641-1.1868718c.16102564.36533333.37025641.80533333.45661538.89169231.11610257.11610256.31897436.11610256.43507693 0 .12-.12.12-.31507692 0-.43507692-.08635898-.08635898-.52615385-.29579488-.89169231-.45661539z"/>
                    </g>
                    <g transform="matrix(.98480775 .17364818 -.17364818 .98480775 11.302362 1.694592)">
                        <path
                            d="m3.28205128 2.35897436c0-.73641026-.82051282-2.15384615-1.33333333-2.15384615s-1.33333333 1.41743589-1.33333333 2.15384615c0 .35897436.20123077 2.0254359.2051282 4.92307692.00389744 2.99733332-.2051282 5.58974362-.2051282 5.94871792 0 .7364103.59692307 1.3333334 1.33333333 1.3333334s1.33333333-.5969231 1.33333333-1.3333334c0-.3589743-.20902564-2.9513846-.2051282-5.94871792.00389743-2.89764102.2051282-4.56410256.2051282-4.92307692z"
                            fill="#58627c"/>
                        <path
                            d="m1.94871795 14.7692308c-.84841026 0-1.53846154-.6900513-1.53846154-1.5384616 0-.0974359.01353846-.3337436.03425641-.691282.06071795-1.0588718.17394872-3.02994874.1708718-5.25723079-.0028718-2.13066667-.11179488-3.57948718-.17025641-4.35774359-.02092308-.27917949-.0348718-.46379487-.0348718-.56553846 0-.77969231.84615385-2.35897436 1.53846154-2.35897436s1.53846154 1.57928205 1.53846154 2.35897436c0 .10174359-.01394872.28635897-.0348718.56553846-.05846154.77825641-.16738461 2.22707692-.17025641 4.35774359-.00307692 2.22707692.11015385 4.19835899.1708718 5.25723079.02071795.3575384.03425641.5938461.03425641.691282 0 .8484103-.69005128 1.5384616-1.53846154 1.5384616zm0-14.35897439c-.33723077 0-1.12820513 1.25846154-1.12820513 1.94871795 0 .08635897.01415385.27446154.03384615.53476923.0588718.78338462.16861539 2.24205128.17128206 4.38810256.00307692 2.23917949-.11076923 4.21805125-.17169231 5.28102565-.01948718.3380513-.0334359.582359-.0334359.6678974 0 .6219487.50605128 1.1282052 1.12820513 1.1282052.62215384 0 1.12820513-.5062565 1.12820513-1.1282052 0-.0855384-.01394872-.3298461-.0334359-.6678974-.06092308-1.0629744-.17476923-3.04184616-.17169231-5.28102565.0028718-2.14605128.11241026-3.60451282.17128205-4.38810256.01969231-.26030769.03384616-.44841026.03384616-.53476923 0-.69025641-.79097436-1.94871795-1.12820513-1.94871795z"
                            fill="#58627c"/>
                        <g fill="#b5b7d1">
                            <path
                                d="m3.38461538 7.8974359h-2.87179487v-.82051282c0-.33989744.27548718-.61538462.61538462-.61538462h1.64102564c.33989744 0 .61538461.27548718.61538461.61538462z"/>
                            <path
                                d="m3.38461538 8h-2.87179487c-.05661538 0-.1025641-.04594872-.1025641-.1025641v-.82051282c0-.39589744.32205128-.71794872.71794872-.71794872h1.64102564c.39589744 0 .71794872.32205128.71794872.71794872v.82051282c0 .05661538-.04594872.1025641-.10256411.1025641zm-2.76923076-.20512821h2.66666666v-.71794871c0-.2828718-.23015384-.51282052-.51282051-.51282052h-1.64102564c-.28266667 0-.51282051.22994872-.51282051.51282052z"/>
                            <path d="m1.33333333 9.53846154h1.23076923l-.41025641 1.43589746h-.41025641z"/>
                            <path
                                d="m2.15384615 11.0769231h-.41025641c-.04574359 0-.08594871-.030359-.09866666-.0742564l-.41025641-1.43589747c-.00882052-.03097436-.00266667-.06420513.01682051-.08984615.01928205-.02605129.04964103-.04102564.08184615-.04102564h1.23076923c.03220513 0 .06256411.01497435.08184616.04082051.01928205.02564102.02564102.05887179.01682051.08984615l-.41025641 1.4358974c-.01271795.0441026-.05292308.0744616-.09866667.0744616zm-.33292307-.2051282h.25558974l.35158974-1.23076926h-.95876923z"/>
                            <path
                                d="m1.94871795 13.9487179c-.39589744 0-.71794872-.3220512-.71794872-.7179487 0-.0566154.04594872-.1025641.1025641-.1025641.05661539 0 .10256411.0459487.10256411.1025641 0 .2828718.23015384.5128205.51282051.5128205s.51282051-.2299487.51282051-.5128205c0-.0566154.04594872-.1025641.1025641-.1025641.05661539 0 .10256411.0459487.10256411.1025641 0 .3958975-.32205129.7179487-.71794872.7179487z"/>
                        </g>
                    </g>
                    <g transform="matrix(.98480775 .17364818 -.17364818 .98480775 7.36313 1)">
                        <path
                            d="m3.28205128 2.35897436c0-.73641026-.82051282-2.15384615-1.33333333-2.15384615s-1.33333333 1.41743589-1.33333333 2.15384615c0 .35897436.20123077 2.0254359.2051282 4.92307692.00389744 2.99733332-.2051282 5.58974362-.2051282 5.94871792 0 .7364103.59692307 1.3333334 1.33333333 1.3333334s1.33333333-.5969231 1.33333333-1.3333334c0-.3589743-.20902564-2.9513846-.2051282-5.94871792.00389743-2.89764102.2051282-4.56410256.2051282-4.92307692z"
                            fill="#58627c"/>
                        <path
                            d="m1.94871795 14.7692308c-.84841026 0-1.53846154-.6900513-1.53846154-1.5384616 0-.0974359.01353846-.3337436.03425641-.691282.06071795-1.0588718.17394872-3.02994874.1708718-5.25723079-.0028718-2.13066667-.11179488-3.57948718-.17025641-4.35774359-.02092308-.27917949-.0348718-.46379487-.0348718-.56553846 0-.77969231.84615385-2.35897436 1.53846154-2.35897436s1.53846154 1.57928205 1.53846154 2.35897436c0 .10174359-.01394872.28635897-.0348718.56553846-.05846154.77825641-.16738461 2.22707692-.17025641 4.35774359-.00307692 2.22707692.11015385 4.19835899.1708718 5.25723079.02071795.3575384.03425641.5938461.03425641.691282 0 .8484103-.69005128 1.5384616-1.53846154 1.5384616zm0-14.35897439c-.33723077 0-1.12820513 1.25846154-1.12820513 1.94871795 0 .08635897.01415385.27446154.03384615.53476923.0588718.78338462.16861539 2.24205128.17128206 4.38810256.00307692 2.23917949-.11076923 4.21805125-.17169231 5.28102565-.01948718.3380513-.0334359.582359-.0334359.6678974 0 .6219487.50605128 1.1282052 1.12820513 1.1282052.62215384 0 1.12820513-.5062565 1.12820513-1.1282052 0-.0855384-.01394872-.3298461-.0334359-.6678974-.06092308-1.0629744-.17476923-3.04184616-.17169231-5.28102565.0028718-2.14605128.11241026-3.60451282.17128205-4.38810256.01969231-.26030769.03384616-.44841026.03384616-.53476923 0-.69025641-.79097436-1.94871795-1.12820513-1.94871795z"
                            fill="#58627c"/>
                        <g fill="#b5b7d1">
                            <path
                                d="m3.38461538 7.8974359h-2.87179487v-.82051282c0-.33989744.27548718-.61538462.61538462-.61538462h1.64102564c.33989744 0 .61538461.27548718.61538461.61538462z"/>
                            <path
                                d="m3.38461538 8h-2.87179487c-.05661538 0-.1025641-.04594872-.1025641-.1025641v-.82051282c0-.39589744.32205128-.71794872.71794872-.71794872h1.64102564c.39589744 0 .71794872.32205128.71794872.71794872v.82051282c0 .05661538-.04594872.1025641-.10256411.1025641zm-2.76923076-.20512821h2.66666666v-.71794871c0-.2828718-.23015384-.51282052-.51282051-.51282052h-1.64102564c-.28266667 0-.51282051.22994872-.51282051.51282052z"/>
                            <path d="m1.33333333 9.53846154h1.23076923l-.41025641 1.43589746h-.41025641z"/>
                            <path
                                d="m2.15384615 11.0769231h-.41025641c-.04574359 0-.08594871-.030359-.09866666-.0742564l-.41025641-1.43589747c-.00882052-.03097436-.00266667-.06420513.01682051-.08984615.01928205-.02605129.04964103-.04102564.08184615-.04102564h1.23076923c.03220513 0 .06256411.01497435.08184616.04082051.01928205.02564102.02564102.05887179.01682051.08984615l-.41025641 1.4358974c-.01271795.0441026-.05292308.0744616-.09866667.0744616zm-.33292307-.2051282h.25558974l.35158974-1.23076926h-.95876923z"/>
                            <path
                                d="m1.94871795 13.9487179c-.39589744 0-.71794872-.3220512-.71794872-.7179487 0-.0566154.04594872-.1025641.1025641-.1025641.05661539 0 .10256411.0459487.10256411.1025641 0 .2828718.23015384.5128205.51282051.5128205s.51282051-.2299487.51282051-.5128205c0-.0566154.04594872-.1025641.1025641-.1025641.05661539 0 .10256411.0459487.10256411.1025641 0 .3958975-.32205129.7179487-.71794872.7179487z"/>
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconSkis;












