import Header from "../../layout/header/components/Header.tsx";
import {Outlet} from "react-router-dom";
import Cookies from "../../features/cookies/components/Cookies.tsx";
import useGbtLandingEvents from "../../features/events/hooks/useGbtLandingEvents.ts";

function Layout() {
    useGbtLandingEvents()

    return (
        <>
            <Header/>
            <Outlet/>
            <Cookies/>
        </>
    );
}

export default Layout