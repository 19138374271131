import LanguageFlag from "./LanguageFlag";
import Language from "./Language";
import {setLocale} from "../../../../../../../reducers/pageSlice.ts";
import {useDispatch} from "react-redux";
import IconCheckmark from "../../../../../../../components/generic/icons/IconCheckmark.tsx";
import {LocaleType} from "../../../../../../../types/Locale.type.ts";

interface LanguageListItemProps {
    linkActiveLanguage: string,
    isActive: boolean,
    lang: string,
    langKey: LocaleType,
}

function LanguageListItem(props: LanguageListItemProps) {
    const dispatch = useDispatch()
    const {
        linkActiveLanguage,
        isActive,
        lang,
        langKey,
    } = props;

    function setClassName() {
        let className = 'select__item '
        if (isActive) {
            className += 'select__item--selected'
        }
        if (!linkActiveLanguage) {
            className += 'select__item--disabled'
        }
        return className
    }

    return (
        <li
            onClick={() => dispatch(setLocale(langKey))}
            key={linkActiveLanguage}
            className={setClassName()}
        >
            <Language linkActiveLanguage={linkActiveLanguage}>
                {
                    <span>
                        <LanguageFlag
                            lang={lang}
                            langKey={langKey}
                        />
                    </span>
                }
                {isActive && (
                    <IconCheckmark
                        width="16"
                        height="16"
                        fill="#7ed321"
                    />
                )}
            </Language>
        </li>
    );
}

export default LanguageListItem