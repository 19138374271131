import useFormDataFromLastSearch from "../../../hooks/useFormDataFromLastSearch.ts";
import {useParams} from "react-router";
import {SearchParamsType} from "../../../../../types/SearchParams.type.ts";
import getCityFromSlug from "../../../functions/getCityFromSlug.ts";
import {getDateRequestFormat} from "../../../functions/getDateRequestFormat.ts";
import handleDisplayDate from "../../../../bookingForm/functions/handleDisplayDate.ts";
import useIsPageRerenderOrRedirect
    from "../../../../../features/mobileOverlays/features/incompleteSearchFormDataOverlay/hooks/useIsPageRerenderOrRedirect.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function useSearchResultsTripInfo(duration: number) {
    const {
        currentSearch: {
            pickUpDateTime,
        },
        latestSearchFormUsed: {
            fromPlace,
            toPlace,
            departureDate,
        }
    } = useAppSelector((state) => state?.searchResults);

    const {
        fromSlug,
        toSlug,
        date: pickUpDateFromSlug,
    } = useParams<SearchParamsType>()

    const isPageRerenderOrRedirect = useIsPageRerenderOrRedirect()

    const departure = fromPlace?.placeName ?? getCityFromSlug(fromSlug!, "display")
    const destination = toPlace?.placeName ?? getCityFromSlug(toSlug!, "display")

    const date = !!departureDate
        ? departureDate
        : getDateRequestFormat(pickUpDateFromSlug ?? "")

    const startDateDisplay = !!departureDate ? handleDisplayDate(date!, date!) : handleDisplayDate(date!)
    const returnDateDisplay = !!departureDate ? handleDisplayDate(date!, date!, duration) : handleDisplayDate(date!)

    return {
        departure,
        destination,
        isPageRerenderOrRedirect,
        startDateDisplay,
        returnDateDisplay,
    }
}