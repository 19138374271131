import IconTimes from "../../../../../components/generic/icons/IconTimes.tsx";
import ManageCookiePreferences from "./ManageCookiePreferences.tsx";
import Modal from "../../../../../components/generic/modal/Modal.tsx";
import useTranslation from "../../../../translation/hooks/useTranslation.ts";
import useManageCookies from "../hooks/useManageCookies.ts";
import useHandleIsManageCookiesModalOpen from "../hooks/useHandleIsManageCookiesModalOpen.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useAcceptCookiesAndContinueToBooking from "../hooks/useAcceptCookiesAndContinueToBooking.ts";

function ManageCookiesModal() {
    const {
        cookiePolicy: cookiePolicyLabel,
        confirmAndProceed: confirmAndProceedLabel,
    } = useTranslation("cookies") as Record<string, string>

    const {userCanSkipCookies} = useAppSelector((state) => state.page)

    const {
        closeCookieModal,
        closeCookieModalIfAllowed
    } = useHandleIsManageCookiesModalOpen()

    const {
        goFromVehicleSelectToBooking,
        removeVehicleSelectNavigateData,
    } = useAcceptCookiesAndContinueToBooking()

    const {
        toggledConsentCookie,
        setToggledConsentCookie,
        toggledPerformancesCookie,
        setToggledPerformancesCookie,
        acceptCurrentCookieState,
        rejectAll,
        acceptAll,
    } = useManageCookies(closeCookieModal, goFromVehicleSelectToBooking)

    const closeIconAction = () => {
        removeVehicleSelectNavigateData()
        closeCookieModalIfAllowed()
    }

    return (
        <Modal
            className="modal__dialog-cookies"
            title={cookiePolicyLabel}
            closeAction={closeIconAction}
            closeIcon={<IconTimes/>}
            message={(
                <ManageCookiePreferences
                    rejectAll={rejectAll}
                    acceptAll={acceptAll}
                    toggledConsentCookie={toggledConsentCookie}
                    setToggledConsentCookie={setToggledConsentCookie}
                    toggledPerformancesCookie={toggledPerformancesCookie}
                    setToggledPerformancesCookie={setToggledPerformancesCookie}
                />
            )}
            handleClick={acceptCurrentCookieState}
            buttonText={confirmAndProceedLabel}
            mainActionButtonProps={{disabled: !userCanSkipCookies && !toggledConsentCookie}}
        />
    )
}

export default ManageCookiesModal