import useLocale from "../../../hooks/selector/useLocale.ts";
import en from "date-fns/esm/locale/en-GB";
import de from "date-fns/locale/de"
import es from "date-fns/locale/es"
import it from "date-fns/locale/it"
import fr from "date-fns/locale/fr"

export default function useDatePickerLocale(){
    const locale = useLocale()

    const allLocales = {
        [`${locale === 'en'}`]: en,
        [`${locale === 'de'}`]: de,
        [`${locale === 'es'}`]: es,
        [`${locale === 'it'}`]: it,
        [`${locale === 'fr'}`]: fr,
    }

    return allLocales.true || en
}