import {ReactNode} from "react";

interface ContainerProps {
    className?: string,
    heading?: string,
    children: ReactNode
}

function MainContent(props:ContainerProps) {
    const {
        className,
        heading,
        children,
    } = props;

    return (
        <div className={`gb-container page__main-content ${className}`}>
            {heading && (
                <h1>
                    {heading}
                </h1>
            )}
            {children}
        </div>
    )
}

export default MainContent;