import IconNoResults from "../../../components/generic/icons/IconNoResults.tsx";
import {Link} from "react-router-dom";
import Column from "../../../features/containers/components/Column.tsx";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import Translation from "../../../features/translation/components/Translation.tsx";
import useCachedData from "../hooks/useCachedData.ts";
import getCityFromSlug from "../functions/getCityFromSlug.ts";
import {useParams} from "react-router";

function NoResults() {
    const isDesktop = useIsDesktop()
    const {
        shouldUseStationParams
    } = useAppSelector((state) => state?.searchResults);

    const {
        fromSlug,
        toSlug
    } = useParams();

    const {
        fetchedGbtOriginalArgs
    } = useCachedData();

    const fromDisplay = shouldUseStationParams ? getCityFromSlug(fromSlug!, 'display') : fetchedGbtOriginalArgs.startLocation;
    const toDisplay = shouldUseStationParams ? getCityFromSlug(toSlug!, 'display') : fetchedGbtOriginalArgs.destinationLocation;

    return (
        <Column
            center
            justify
            className="gb--vehicle__results-no-results"
        >
            <IconNoResults
                height={isDesktop ? "148" : "74"}
                width={isDesktop ? "396" : "188"}
            />
            <h2 className="gb--vehicle__results-no-results__title">
                <Translation translationKey="booking.search.noResults.title"/>
            </h2>
            <p>
                <Translation
                    translationKey="booking.search.noResults.description"
                    translationVariables={{fromPlace: fromDisplay, toPlace: toDisplay}}
                />
            </p>
            <p>
                <Translation translationKey="booking.search.noResults.updateYourSearchOrContact"/>
                &nbsp;
                <Link to={"/contact"}>
                    <Translation translationKey="booking.search.noResults.customerSupportTeam"/>
                </Link>
            </p>
        </Column>
    )
}

export default NoResults