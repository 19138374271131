import {useState} from 'react';
import {AmenityType} from "../../../../types/Amenity.type.ts";
import {BookingAmenityType} from "../../reducers/types/BookingAmenity.type.ts";
import Column from "../../../../features/containers/components/Column.tsx";
import Row from "../../../../features/containers/components/Row.tsx";
import PriceDisplay from "./PriceDisplay.tsx";

interface BookingAmenityPriceBreakdownProps {
    amenities: AmenityType[]
    bookingAmenities: BookingAmenityType
    isReturn: boolean
    label: string
}

const BookingAmenityPriceBreakdown = (props: BookingAmenityPriceBreakdownProps) => {
    const {
        amenities,
        bookingAmenities,
        isReturn,
        label
    } = props
    const [show, setShow] = useState<boolean>(false)
    const direction = isReturn ? 'returnCount' : 'count'

    return (
        <Column
            justify
            style={{width: "100%"}}
        >
            {show && (
                <Row>
                    {label}:
                </Row>
            )}

            {amenities?.map((amenity: AmenityType) => {
                if (bookingAmenities[amenity.id] && bookingAmenities[amenity.id][direction] && (bookingAmenities[amenity.id][direction] > 0)) {
                    if (!show) {
                        setShow(true)
                    }
                    return (
                        <Row
                            style={{paddingLeft: '2rem'}}
                            key={amenity.id}
                        >
                            <Column>
                                {bookingAmenities[amenity.id][direction]} x {amenity.name}
                            </Column>
                            <Column>
                                <PriceDisplay
                                    plus
                                    price={amenity.price * (bookingAmenities[amenity.id][direction])}
                                />
                            </Column>
                        </Row>
                    )
                }
            })}
        </Column>
    );
};

export default BookingAmenityPriceBreakdown;