import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconLuggageAssistance(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconLuggageAssistance',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} fillRule="evenodd" stroke="#58627c" strokeLinecap="round" strokeLinejoin="round"
                   transform="translate(1 2)">
                    <path
                        d="m13.4806198 7.45833333c0 .29927084-.2432248.54166667-.5435271.54166667h-9.23852691c-.30030232 0-.54352712-.24239583-.54352712-.54166667v-5.41666666c0-.29927084.2432248-.54166667.54352712-.54166667h9.23881371c.3000155 0 .5432403.24239583.5432403.54166667z"/>
                    <circle cx="12.333333" cy="11.186046" r="1.147287"/>
                    <circle cx="3.44186" cy="11.186046" r="1.147287"/>
                    <path
                        d="m13.7674415 9.46511602s-9.71837959 0-10.46899198 0c-1.17424803 0-1.57751933-.6703023-1.57751933-1.72093018s0-5.18344172 0-5.73643396c0-.55299223-.3714341-1.14728679-1.171093-1.14728679h-.54983719"/>
                    <path
                        d="m9.75193772 1.14728679s0-.54180619 0-.59658913c0-.304031-.25699224-.55069766-.57364339-.55069766-.31665116 0-1.40427904 0-1.72093019 0s-.5736434.24666666-.5736434.55069766v.59658913"/>
                </g>
            )}
        />

    );
}

export default IconLuggageAssistance;












