import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type.ts";

function IconFire(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#ff6144',
        viewBox = '0 0 16 16',
        title = 'IconFire',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={'none'} stroke={fill} strokeWidth={1.166667} fillRule={'evenodd'} strokeLinecap={'round'} strokeLinejoin={'round'} transform="translate(2 1)">
                    <path
                        d="m8.75 14c1.49975-.6084167 2.9166667-2.5188333 2.9166667-4.88483333 0-2.58241667-1.98508337-5.90275-6.72408337-9.11516667-.37975 4.25483333-4.94258333 5.59008333-4.94258333 9.72241667 0 2.80525003 2.47916667 4.27758333 4.08333333 4.27758333"/>
                    <path
                        d="m4.08333333 11.6666667s-2.39225-1.2635-.3045-4.0804167c1.51025 2.32866667 2.98958334 1.09258333 2.26391667-3.094 2.28491667 1.88358333 3.49766667 4.49458333 1.54058333 7.1744167"/>
                </g>
            )}
        />

    );
}

export default IconFire;
