import {MutableRefObject} from "react";

/**
 * Scrolls to passed ref element
 */

interface ScrollToRefAttributes {
    ref: MutableRefObject<any>
    behavior?: 'smooth' | 'instant' | 'auto',
    block?: 'start' | 'center' | 'end' | 'nearest',
}

export const scrollToRef = ({ref, behavior = "auto", block = "center"}: ScrollToRefAttributes) => (
    ref.current.scrollIntoView({behavior, block, inline: "nearest"})
)