import MainMenu from "./MainMenu";
import LanguageSwitcher from "../features/languageSwitcher/components/LanguageSwitcher.tsx";


function Navbar() {
    return (
        <ul className="gb--menu gb--menu__nav gb--row gb--row-middle gb--row-end">
            <MainMenu/>
            <LanguageSwitcher/>
        </ul>
    )
}

export default Navbar;