import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconDrink(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconDrink',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd" stroke="#58627c" strokeWidth=".9375" transform="translate(4 .5)">
                    <path
                        d="m5 1.875h-2.5c-.1875 0-.3125-.125-.3125-.3125v-.9375c0-.34375.28125-.625.625-.625h1.875c.34375 0 .625.28125.625.625v.9375c0 .1875-.125.3125-.3125.3125z"/>
                    <path
                        d="m6.875 6.875c.34375 0 .625-.28125.625-.625v-.90625c0-1.375-.96875-2.5-2.25-2.78125-.34375-.0625-.5625-.34375-.5625-.6875h-1.875c0 .34375-.21875.625-.5625.6875-1.28125.28125-2.25 1.40625-2.25 2.78125v.90625c0 .34375.28125.625.625.625v4.375c-.34375 0-.625.28125-.625.625v1.875c0 .6875.5625 1.25 1.25 1.25h5c.6875 0 1.25-.5625 1.25-1.25v-1.875c0-.34375-.28125-.625-.625-.625z"/>
                    <g strokeLinecap="round" strokeLinejoin="round">
                        <path d="m6.875 6.875h-4.6875"/>
                        <path d="m6.875 11.328125h-4.6875"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconDrink;












