import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {dismissAllOverlays} from "../../../../../reducers/mobileSlice.ts";

interface MobileMenuItemProps {
    text: string,
    icon: ReactNode,
    to: string,
    reload?: boolean
}

function MobileMenuItem({text, icon, to, reload = false}: MobileMenuItemProps) {
    const dispatch = useAppDispatch();

    const handleClick = () => {
        dispatch(dismissAllOverlays());
    }

    return (
        <Link
            className="gb--menu__item gb--row gb--row-middle gb--row-justify"
            to={to}
            reloadDocument={reload}
            onClick={handleClick}
        >
            <span>
                {icon}
                {text}
            </span>
        </Link>
    )
}

export default MobileMenuItem