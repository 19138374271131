import {ReactNode} from "react";

interface TagProps {
    tagContent?: string,
    icon?: ReactNode,
    tagAppearance?: string,
    onClick?: (any?) => (any)
}

export default function Tag(props: TagProps) {
    const {
        tagContent,
        icon,
        tagAppearance,
        onClick
    } = props

    return (
        <span className={`gb__tag gb__tag-${tagAppearance}`} onClick={onClick}>
            {icon} {tagContent}
        </span>
    )
}