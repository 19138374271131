import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import PillLabel from "../../../../../components/generic/PillLabel.tsx";
import IconPax from "../../../../../components/generic/icons/IconPax.tsx";
import IconSuitcaseV2 from "../../../../../components/generic/icons/IconSuitcaseV2.tsx";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface VehicleInfoProps {
    vehicleType: VehicleType;
}

const VehicleInfo = (props: VehicleInfoProps) => {
    const { vehicleType } = props;
    const noAmenities = !vehicleType.amenities?.length ? 'gb--vehicle__info__noAmenities' : '';
    const isMobile = useIsMobile();
    const premiumLabel = useTranslation("booking.chooseVehicle.premium") as string;

    const handleImageError = (e) => {
        e.target.src = '/public/car_loader.png';
    };

    return (
        <Column className='gb--vehicle__container'>
            <div className={`gb--vehicle__info ${noAmenities}`}>
                <Column className='gb--vehicle__image'>
                    <img
                        src={vehicleType.imagePath}
                        alt={vehicleType.type}
                        onError={handleImageError}
                    />
                </Column>
                <Column className='gb--vehicle__name'>
                    <Row className='gb--vehicle__name-wrapper' wrap>
                        <p>
                            {vehicleType.type}
                        </p>
                        {vehicleType.isPremium && (
                            <PillLabel label={premiumLabel}/>
                        )}
                    </Row>
                    {!isMobile && (
                        <div className='gb--vehicle__pax__start'>
                            <span>{vehicleType.minPax} - {vehicleType.maxPax}</span><IconPax/>
                            <span>{vehicleType.maxPax}</span><IconSuitcaseV2/>
                        </div>
                    )}
                </Column>
            </div>
        </Column>
    );
};

export default VehicleInfo;