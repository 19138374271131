import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

interface IconChevronProps extends IconType {
    chevronOrientation?: 'up' | 'right' | 'down' | 'left',
}

function IconChevron(props: IconChevronProps) {
    const {
        width = '20',
        height = '20',
        fill = '#070c63',
        viewBox = '0 0 20 20',
        title = 'IconChevron',
        chevronOrientation = 'down',
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "up"}`]: "-180deg",
        [`${chevronOrientation === "down"}`]: "0deg",
        [`${chevronOrientation === "right"}`]: "-90deg",
        [`${chevronOrientation === "left"}`]: "90deg",
    }

    const style = {verticalAlign: "middle", transform: `rotate(${orientation.true})`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            style={style}
            svgIconPath={(
                <path
                    d="m3.46989141 5.5c-.25765029 0-.49234223.09382121-.67784725.27180061-.11126328.11759777-.18171975.23276503-.22781842.36053635-.04201404.11644996-.06422574.23484222-.06422574.35385089 0 .12641767.02149227.25013985.06422574.36858385.03064918.0849501.07216796.16461896.12418202.2378114l.06296019.07872276 6.60100681 7.02382534c.18516512.1973929.42712907.3048688.69599644.3048688.2015504 0 .3909739-.0605796.5531032-.1784221l.1106569-.0951052 6.5143329-6.94148462c.1795885-.19144771.2735358-.43181035.2735358-.69566948s-.0939473-.50422177-.2735358-.69566948c-.1855243-.19777545-.4274883-.30525133-.6963556-.30525133-.2015505 0-.3909739.06057961-.5531033.17842208l-.1106568.09510526-5.8183485 6.19807487-5.88175298-6.26948164c-.20401337-.19669715-.43870532-.29051836-.69635561-.29051836z"
                    fill={fill}
                />
            )}
        />
    );
}

export default IconChevron;
