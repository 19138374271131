import {
    transferDropOffAddressLocationFormPayload,
    transferPickUpAddressLocationFormPayload
} from "./transferLocationFormPayload.ts";
import {transferFormPickUpDatePayload, transferFormPickUpTimePayload} from "./transferDateAndTimeFormPayload.ts";
import {TransferFormType} from "../types/TransferForm.type.ts";

export const singleTransferFormInitialState: TransferFormType = {
    pickUpAddress: {
        ...transferPickUpAddressLocationFormPayload,
        isValid: true,
        errorMessage: 'booking.form.pickUpAddressError'
    },
    pickUpTime: {
        ...transferFormPickUpTimePayload,
        errorMessage: 'booking.form.timeError'
    },
    returnPickUpTime: {
        ...transferFormPickUpTimePayload,
        errorMessage: 'booking.form.timeError'
    },
    dropOffAddress: {
        ...transferDropOffAddressLocationFormPayload,
        isValid: true,
        errorMessage: 'booking.form.dropOffAddressError'
    },
}