import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {toggleIsCookieModalOpen} from "../../../../../reducers/pageSlice.ts";

export default function useHandleIsManageCookiesModalOpen() {
    const {userCanSkipCookies} = useAppSelector((state) => state.page)
    const dispatch = useAppDispatch()

    const closeCookieModal = () => {
        dispatch(toggleIsCookieModalOpen(false))
    }

    const closeCookieModalIfAllowed = () => {
        if (userCanSkipCookies) {
            dispatch(toggleIsCookieModalOpen())
        }
    }

    return {
        closeCookieModal,
        closeCookieModalIfAllowed,
    }
}