import {SyntheticEvent, useState} from "react";
import {useElements, useStripe} from "@stripe/react-stripe-js";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import getConfirmPaymentElement from "../functions/getConfirmPaymentElement.ts";
import useGetStripeResponseHandler from "./useGetStripeResponseHandler.ts";

export default function useStripeCheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const isoCode = useAppSelector((state) => state?.bookingForm.isoCode);
    const [loader, setLoader] = useState(false);

    const {
        handleStripeResponse,
        stripeError,
    } = useGetStripeResponseHandler({setLoader})

    const handleSubmit = async (event: SyntheticEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setLoader(true);
        // TODO add check for isoCode (remove "!")
        const {
            error,
            paymentIntent,
        } = await stripe.confirmPayment(getConfirmPaymentElement(elements, isoCode!));

        handleStripeResponse(error, paymentIntent)
    };

    return {
        loader,
        stripeError,
        handleSubmit,
    }
}