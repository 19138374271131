import SuccessfulBookingInfo from "./SuccessfulBookingInfo.tsx";
import SuccessfulBookingVehicleInfo from "./SuccessfulBookingVehicleInfo.tsx";

const SuccessfulBookingCard = () => (
    <div className='gb--card gb--success-booking__card'>
        <SuccessfulBookingInfo/>
        <SuccessfulBookingVehicleInfo/>
    </div>

);

export default SuccessfulBookingCard;