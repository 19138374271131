import {LineWidthType} from "../types/LineWidth.type.ts";
import {isPredefinedLineWidth} from "./isPredefinedLineWidth.ts";
import {lineWidths} from "../constants/lineWidths.ts";

export default function createLineLengths(count: number | undefined, repeatedElements: (LineWidthType | number)[] | undefined) {
    let lines: number[] = []

    if (!!repeatedElements) {
        lines = repeatedElements.map((element: LineWidthType | number) => {
            if (isPredefinedLineWidth(element)) {
                return lineWidths[element]
            }
            if (element > 0 && element < 100) {
                return element
            }
            return lineWidths.full
        })
    }

    if(!count && !repeatedElements) {
        return [lineWidths.full]
    }

    if (lines.length === 0) {
        lines = Array(count).fill(lineWidths.full)
    }

    if (lines.length < count) {
        let i = 0;
        while (lines.length < count) {
            lines.push(lines[i])
            i += 1
        }
    }

    return lines
}