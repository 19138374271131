import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import useDatePickerLocale from "../hooks/useDatePickerLocale.ts";
import {getTodayServerDate} from "../../../utils/dateTime/getTodayServerDate.ts";

function DatePickerWrapper(props: ReactDatePickerProps) {
    const {
        className = 'search-datepicker__outbound gb--input-standalone',
        calendarClassName = "react-search-datepicker",
        dateFormat = "yyyy-MM-dd",
        minDate,
        ...rest
    } = props

    const locale = useDatePickerLocale()
    const today = getTodayServerDate()

    return (
        <DatePicker
            className={className}
            calendarClassName={calendarClassName}
            dateFormat={dateFormat}
            minDate={minDate || today}
            locale={locale}
            {...rest}
        />
    )
}

export default DatePickerWrapper