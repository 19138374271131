import PickUpAddress from "./PickUpAddress.tsx";
import DropOffAddress from "./DropOffAddress.tsx";
import CollapsableComponent from "../../../../../components/generic/CollapsableComponent.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import Column from "../../../../../features/containers/components/Column.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import ReturnTimePicker from "./ReturnTimePicker.tsx";
import SingleTimePicker from "./SingleTimePicker.tsx";

interface TransferDetailsProps {
    isReturn: boolean,
    fromStationLabel: string,
    toStationLabel: string,
    isPickUpAirport: boolean,
    isDropOffAirport: boolean,
    pickUpAddress: string,
    dropOffAddress: string
}

const TransferDetails = (props: TransferDetailsProps) => {
    const transferDetails = useTranslation("booking.form.transferDetails") as string
    const {
        isReturn,
        pickUpAddress,
        dropOffAddress,
        isPickUpAirport,
        isDropOffAirport,
        toStationLabel,
        fromStationLabel
    } = props;
    const description = `${fromStationLabel} - ${toStationLabel}`;

    return (
        <div>
            <CollapsableComponent
                label={transferDetails}
                description={description}
                hrClassName={'gb--divider__hr'}
            >
                <Row
                    justify
                    className="gb--booking__input"
                >
                    <Column className="gb--booking__container">
                        <PickUpAddress isAirport={isPickUpAirport} address={pickUpAddress}/>
                    </Column>
                    <Column className="gb--booking__container">
                        <DropOffAddress isAirport={isDropOffAirport} address={dropOffAddress}/>
                    </Column>
                </Row>
                <Row
                    justify
                    className="gb--booking__input"
                >
                    <Column className="gb--booking__container">
                        <SingleTimePicker returnTrip={isReturn}/>
                    </Column>
                    <Column className="gb--booking__container">
                        {isReturn && (
                            <ReturnTimePicker/>
                        )}
                    </Column>
                </Row>
            </CollapsableComponent>
        </div>
    )
}
export default TransferDetails