import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useDispatch} from "react-redux";
import {setDepartureDate, setReturnDate} from "../../../../../reducers/formSlice.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import {toggleSingleCalendar} from "../../../../../reducers/mobileSlice.ts";
import {addHours, isAfter, startOfHour} from "date-fns";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useState} from "react";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";

export default function useDateSelectHandlers(){
    const {departureDate, returnDate} = useAppSelector((state) => state?.form)
    const dispatch = useDispatch();
    const {value: isDropdownOpen, set: setIsDropdownOpen} = useToggle()
    const [departure, setDeparture] = useState<string>(departureDate || startOfHour(addHours(new Date(), 2)).toISOString())
    const activeDate = isDropdownOpen ? departure || departureDate : departureDate
    // This +2 hours was added so the client cannot make a booking with pickup time close to current time
    // https://getbybus.atlassian.net/browse/GBT-799, also updated in the DateTimeFields (Mobile/Desktop)
    const dateDisplay = activeDate
        ? format(departureDate ? new Date(departureDate) : startOfHour(addHours(new Date(), 2)), 'd MMM | HH:mm')
        : '';
    const isMobile = useIsMobile()
    const {
        formErrors: {
            dateError,
        },
        submitTimestamp,
    } = useAppSelector((state) => (state.form))

    const {
        departingOn: departingOnLabel,
        date: dateLabel,
    } = useTranslation('search.form') as Record<string, string>

    const handleToggle = (value: boolean) => {
        setIsDropdownOpen(value)
        if (!value) {
            setDeparture(null)
        } else {
            setDeparture(departureDate || new Date().toISOString())
        }
    }
    const handleSelectDate = (value: Date) => {
        dispatch(setDepartureDate(format(value, "yyyy-MM-dd\'T\'HH:mm:ss")));

        if (isAfter(value, new Date(returnDate))) {
            dispatch(setReturnDate(null))
        }
    };

    const activateMobileOverlayDatePicker = () => {
        if (isMobile) {
            dispatch(toggleSingleCalendar(true))
        }
    }

    return {
        departure,
        setDeparture,
        departingOnLabel,
        isDropdownOpen,
        setIsDropdownOpen,
        departureDate,
        dateDisplay,
        dateError,
        dateLabel,
        handleToggle,
        submitTimestamp,
        handleSelectDate,
        activateMobileOverlayDatePicker,
    }
}