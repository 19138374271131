import IconCalendar from "../../../../../components/generic/icons/IconCalendar.tsx";
import useDateSelectHandlers from "../hooks/useDateSelectHandlers.ts";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import DateTimeSelect from "../../../../../features/dateTime/components/DateTimeSelect.tsx";
import {add, addHours, startOfHour} from "date-fns";
import ValidPlaceIndicator from "../../stations/components/ValidPlaceIndicator.tsx";
import isBefore from "date-fns/isBefore";

interface DateTimeFieldProps {
    inputRef: HTMLInputElement,
    maxDateTime?: string,
    additionalOnClick?: () => void,
    showIsValid?: boolean,
    isValid?: boolean
}

function DateTimeField(props: DateTimeFieldProps) {
    const {
        inputRef,
        maxDateTime,
        additionalOnClick,
        showIsValid = false,
        isValid = false
    } = props;

    const {
        departure,
        setDeparture,
        departingOnLabel,
        dateError,
        submitTimestamp,
        dateDisplay,
        dateLabel,
        isDropdownOpen,
        setIsDropdownOpen,
        handleToggle,
        activateMobileOverlayDatePicker,
        handleSelectDate,
    } = useDateSelectHandlers()


    const handleClickOutside = () => {
        if (isDropdownOpen) {
            if (additionalOnClick) {
                additionalOnClick()
            }
            handleToggle(false)
        }
    }

    const handleSelect = (value: Date) => {
        if (isBefore(value, addHours(new Date(), 1))) {
            setDeparture(startOfHour(addHours(new Date(), 2)).toISOString())
        } else {
            setDeparture(value.toISOString())
        }
    }

    const handleOpenDropdown = () => {
        if (additionalOnClick) {
            additionalOnClick()
        }

        handleToggle(true)
    }

    return (
        <div className='search__select--datepicker-input' onClick={activateMobileOverlayDatePicker}>
            <FadingError
                isError={dateError}
                showError={submitTimestamp}
                errorMessageTranslationKey={'search.form.dateError'}
            />
            <CustomDropdown
                field={(
                    <>
                        <IconCalendar className='search__select--icon'/>
                        <input
                            ref={inputRef}
                            type="text"
                            name="passengers"
                            className="gb--input-standalone"
                            placeholder={`${dateLabel}`}
                            value={dateDisplay}
                            readOnly
                        />
                        {showIsValid && (
                            <ValidPlaceIndicator isValid={isValid}/>
                        )}
                    </>
                )}
                isOpen={isDropdownOpen}
                handleClickOutside={handleClickOutside}
                setIsOpen={handleOpenDropdown}
                required
                childrenWrapperClass={'gb--select-dropdown-time'}
            >
                <DateTimeSelect
                    selectedDate={departure}
                    selectedTime={departure}
                    changeDate={handleSelect}
                    changeTime={(value: Date) => {
                        setDeparture(value.toISOString())
                    }}
                    submitSelect={handleSelectDate}
                    dateLabel={'Date'}
                    handleClose={() => {
                        setIsDropdownOpen(false)
                    }}
                    isClosable={true}
                    timeLabel={'Time'}
                    minDateTime={startOfHour(add(new Date(), {hours: 2})).toISOString()}
                    maxDateTime={maxDateTime ? new Date(maxDateTime).toISOString() : undefined}
                />
            </CustomDropdown>
            <span
                className="search__select--label gb--emphasize gb--emphasize-default"
            >
                {departingOnLabel}
            </span>
        </div>
    );
}

export default DateTimeField;