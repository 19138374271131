import {CountryCode, parsePhoneNumber, isPossiblePhoneNumber} from "libphonenumber-js/max";
import {CountryType} from "@getbybus/gbb-green";

export const validatePhone = (phone: string, countryPhone: CountryType): boolean => {
    return <boolean>(
        countryPhone.isoCode &&
        phone.includes(countryPhone.dialCode) &&
        phone.length > 5 &&
        phone.length < 22 &&
        isPossiblePhoneNumber(phone, countryPhone.isoCode as CountryCode)
    );
};