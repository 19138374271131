import {Column, Row} from "@getbybus/gbb-green";
import {LineWidthType} from "../types/LineWidth.type.ts";
import {isLastElement} from "../../../utils/array/isLastElement.ts";
import createLineLengths from "../functions/createLineLengths.ts";
import createStyle from "../functions/createStyle.ts";
import BackgroundMaskLine from "./BackgroundMaskLine.tsx";

interface LinePlaceholderProps {
    // number of repeating lines
    count?: number,
    // length of each placeholder line, either presented with predefined name, or by percentage of total width
    // if count exists, these elements will repeat the same pattern until count is satisfied
    elements?: (LineWidthType | number)[],
    // alignment of elements
    alignment?: "left" | "right" | "center",
    // height of line elements
    height?: string,
    backgroundColor?: string,
    lineColor?: "white" | "gray",
    placeholderClassName?: string,
}

function LinePlaceholder(props: LinePlaceholderProps) {
    const {
        count,
        elements,
        alignment = "left",
        height,
        lineColor = "gray",
        backgroundColor,
        placeholderClassName = "",
    } = props

    const lines = createLineLengths(count, elements)

    return (
        <Column className={`animated-background--${lineColor} ${placeholderClassName}`} style={createStyle(alignment)}>
            {lines.map((line, index) => (
                alignment === "center" ? (
                        <div key={index}>
                            <Row justify>
                                <BackgroundMaskLine
                                    width={`${(100 - line) / 2}%`}
                                    height={height}
                                    backgroundColor={backgroundColor}
                                />
                                <BackgroundMaskLine
                                    width={`${(100 - line) / 2}%`}
                                    height={height}
                                    backgroundColor={backgroundColor}
                                />
                            </Row>
                            {!isLastElement(lines, index) && (
                                <BackgroundMaskLine
                                    height={height}
                                    backgroundColor={backgroundColor}
                                />
                            )}
                        </div>
                    )
                    : (
                        <div key={index}>
                            <BackgroundMaskLine
                                width={`${100 - line}%`}
                                height={height}
                                backgroundColor={backgroundColor}
                            />
                            {!isLastElement(lines, index) && (
                                <BackgroundMaskLine
                                    height={height}
                                    backgroundColor={backgroundColor}
                                />
                            )}
                        </div>
                    )
            ))}
        </Column>
    )
}

export default LinePlaceholder