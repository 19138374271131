import Checkbox from "../../../../../components/generic/Checkbox.tsx";
import {CSSProperties} from "react";
import useTranslation from "../../../../translation/hooks/useTranslation.ts";
import {Notification} from "@getbybus/gbb-green";
import ScrollToElement from "../../../../scroll/components/ScrollToElement.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import {getTranslatedRoute} from "../../../../../routing/functions/getTranslatedRoute.ts";
import {MouseEvent} from "react";

interface TermsAndConditionsCheckboxProps {
    onChange: (e: MouseEvent<HTMLButtonElement>) => void | null,
    isChecked?: boolean,
    displayError?: boolean,
    errorMessageString?: string,
    additionalClassName?: string,
    errorStyle?: CSSProperties,
}

function TermsAndConditionsCheckbox(props: TermsAndConditionsCheckboxProps) {
    const {
        submitTimestamp
    } = useAppSelector((state) => state?.bookingForm);
    const locale = useLocale();
    const {getPath} = getTranslatedRoute();
    const termsPath = getPath("termsAndConditions");
    const {
        onChange,
        isChecked,
        displayError,
        errorMessageString,
        errorStyle,
        additionalClassName = "",
    } = props
    const termsAndConditions = useTranslation('booking.form.TermsAndConditions') as string;
    const defaultErrMsg = useTranslation('booking.form.termsError') as string
    const replacedTerms = termsAndConditions.replace(/:locale/g, locale).replace(/:path/g, termsPath);

    return (
        <>
            <Checkbox
                className={`md-checkbox ${additionalClassName}`}
                onChange={onChange}
                isChecked={isChecked}
            >
                <span style={{display: "inline"}} dangerouslySetInnerHTML={{ __html: replacedTerms }} />
            </Checkbox>
            {displayError && (
                <ScrollToElement condition={submitTimestamp}>
                    <Notification
                        style={errorStyle}
                        isLarge
                        notificationType={"red"}
                    >
                        {errorMessageString ?? defaultErrMsg}
                    </Notification>
                </ScrollToElement>
            )}
        </>
    )
}

export default TermsAndConditionsCheckbox