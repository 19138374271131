
export default function rootExtractor(data: string, parse: boolean = false) {
    const rootElement = document.getElementById('root');

    if (parse) {
        // @ts-ignore
        return JSON.parse(rootElement?.dataset?.[data]) ?? null
    }

    return rootElement?.dataset?.[data] || null
}