 /* error codes with generic msg https://stripe.com/docs/declines/codes */
 export const errorUnknownCodes = [
    'authentication_required',
    'call_issuer',
    'card_velocity_exceeded',
    'do_not_honor',
    'do_not_try_again',
    'generic_decline',
    'new_account_information_available',
    'no_action_taken',
    'not_permitted',
    'restricted_card',
    'revocation_of_all_authorizations',
    'revocation_of_authorization',
    'security_violation',
    'service_not_allowed',
    'stop_payment_order',
    'transaction_not_allowed',
    'try_again_later',
    'lost_card',
    'stolen_card',
];