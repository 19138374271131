import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconExchange(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#f5a623',
        viewBox = '0 0 16 16',
        title = 'IconExchange',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            {...rest}
            svgIconPath={(
                <path
                    d="m14 0c1.1045695 0 2 .8954305 2 2v12c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-12c0-1.1045695.8954305-2 2-2zm-9.64285433 7.92856658c-.0535715 0-.11383942.0200893-.15401804.06026792l-2.1428597 2.1428597c-.04017862.0401786-.06026793.0937501-.06026793.154018 0 .0535715.02008931.107143.06026793.1473216l2.13616326 2.1428597c.04687506.0401787.10044655.0669644.16071448.0669644.12053586 0 .21428597-.1004465.21428597-.214286v-1.2857158h9.21429666c.1138395 0 .214286-.1004465.214286-.2142859v-1.28571587c0-.12053586-.1004465-.21428597-.214286-.21428597h-9.21429666v-1.28571582c0-.12053585-.10044655-.21428596-.21428597-.21428596zm7.28572293-4.2857194c-.1205358 0-.2142859.09375011-.2142859.21428597v1.28571582h-9.21429673c-.11383942 0-.21428597.10044655-.21428597.21428597v1.28571582c0 .11383942.10044655.21428597.21428597.21428597h9.21429673v1.28571581c0 .11383943.1004465.21428597.2142859.21428597.0535715 0 .1138395-.02008931.1540181-.06026792l2.1428597-2.1428597c.0401786-.04017862.0602679-.10044655.0602679-.15401804s-.0200893-.11383942-.0602679-.15401804l-2.1361633-2.13616326c-.046875-.04017862-.1004465-.06696437-.1607145-.06696437z"
                    fillRule="evenodd"
                />
            )}
        />

    );
}

export default IconExchange;
