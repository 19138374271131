import DatePickerWrapper from "./DatePickerWrapper.tsx";
import Row from "../../containers/components/Row.tsx";
import Column from "../../containers/components/Column.tsx";
import TimeDisplay from "./TimeDisplay.tsx";
import {endOfDay, isAfter} from "date-fns";
import useTranslation from "../../translation/hooks/useTranslation.ts";
import {useEffect} from "react";


interface TimeSelectProps {
    selectedTime: string
    changeTime: (value: Date) => void
    minTime: string
    handleSubmit: (value: Date) => void
    isMobile: boolean
    maxTime: string
}

const TimeSelect = (props: TimeSelectProps) => {
    const {
        selectedTime,
        changeTime,
        minTime,
        handleSubmit,
        isMobile,
        maxTime
    } = props

    const confirmDateTime = useTranslation("search.form.confirmDateTime") as string

    const maxTimeSelect = isAfter(new Date(selectedTime), new Date(maxTime)) ? new Date(maxTime) : new Date(minTime) ? endOfDay(new Date(minTime)) : undefined

    return (
        <Column style={{width: "100%"}}>
            <TimeDisplay
                selectedTime={selectedTime}
                additionalClass={'gb--datetime-time__bottom'}
            />
            <Row>
                <DatePickerWrapper
                    selected={new Date(selectedTime)}
                    onChange={changeTime}
                    closeOnScroll
                    inline
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeFormat={'HH:mm'}
                    minTime={minTime ? new Date(minTime) : undefined}
                    maxTime={maxTimeSelect}
                />
            </Row>
            {isMobile && (
                <div
                    onClick={() => {
                        handleSubmit(new Date(selectedTime))
                    }}
                    className={"btn btn--blue-darker btn--mobile gb--datetime-submit"}
                >
                    {confirmDateTime}
                </div>

            )}

        </Column>

    );
};

export default TimeSelect;