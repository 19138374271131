import Row from "../../../features/containers/components/Row.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";

const SuccessfulBookingThankYou = () => {
    const {
        contactInformationForm
    } = useAppSelector((state) => state?.bookingForm);

    const thankYou = useTranslation('booking.confirmation.thankYouForBooking') as string;
    const details = useTranslation('booking.confirmation.detailsSentToEmail') as string;
    const shortly = useTranslation('booking.confirmation.shortly') as string;
    const feelFree = useTranslation('booking.confirmation.feelFree') as string;

    return (
        <div className='gb--success-booking__thankYou'>
            <Row className='gb--success-booking__header'>
                <span>{thankYou}</span>
            </Row>
            <Row>
                <span>
                    {details} <b>{contactInformationForm.email.value}</b> {shortly}
                </span>
            </Row>
            <Row>
                <span>
                    <div dangerouslySetInnerHTML={{__html: feelFree}}/>
                </span>
            </Row>
        </div>
    );
};

export default SuccessfulBookingThankYou;