import IconBusPlot from "./IconBusPlot";
import IconFerryPlot from "./IconFerryPlot";
import IconTrainPlot from "./IconTrainPlot";
import IconCarPlot from "./IconCarPlot";
import IconAirplanePlot from "./IconAirplanePlot";

interface TransportationIconsProps {
    viewBox: string,
    width: string,
    preserveAspectRatio: string,
}

function TransportationIcons({viewBox, width, preserveAspectRatio}: TransportationIconsProps) {
    const transportTypeKeyword = 'transfer'

    const components = [
        {
            transportType: 'bus',
            icon: <IconBusPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'train',
            icon: <IconTrainPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'ferry',
            icon: <IconFerryPlot width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
        {
            transportType: 'transfer',
            icon: <IconCarPlot viewBox={viewBox} width={width} preserveAspectRatio={preserveAspectRatio}/>,
        },
    ]

    const setClassName = (component: any) => {
        let className = 'hero__icons--transport'
        if (transportTypeKeyword === component.transportType) {
            return className += ` hero__icons--transport-main ${component.transportType}__main`
        }
        if (component.transportType === 'ferry') {
            return className += '-ferry'
        }
        return className
    }

    const TransportTypeIcon = ({components}: any) => (
        components.map((component: any) => (
            <span className={setClassName(component)} key={component.transportType}>
                {component.icon}
            </span>
        ))
    )

    return (
        <>
            <TransportTypeIcon
                components={components}
            />
            <span className="hero__icons--transport">
                <IconAirplanePlot viewBox="0 0 155 85" width="100%" preserveAspectRatio="xMinYMax meet"/>
            </span>
        </>
    )
}

export default TransportationIcons;