import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import {useEffect, useRef, useState} from "react";
import useCachedData from "../../../hooks/useCachedData.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import PoolMozioSearchResultsResponseType from "../../../../../api/types/PoolMozioSearchResultsResponse.type.ts";
import {maxConsequentFailedMozioFetchCount} from "../../mozioResults/constants/maxConsequentFailedMozioFetchCount.ts";

export const useFilterCategory = (isMozioLoading: boolean, isMozioUninitialized: boolean, isMozioMoreComing: boolean, mozioData: PoolMozioSearchResultsResponseType) => {
    const MAX_CATEGORIES = 4;
    const MIN_CATEGORIES = 0;
    const isMobile = useIsMobile();
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const {
        fetchedGbtData,
    } = useCachedData();

    const {
        categoryPrices,
        successfulMozioFetchCount,
        unsuccessfulMozioFetchCount,
    } = useAppSelector((state) => state?.searchResults);

    const isLoading =
        (fetchedGbtData?.data?.integrations.mozio.moreComing && isMozioUninitialized)
        && successfulMozioFetchCount < 10
        && unsuccessfulMozioFetchCount < maxConsequentFailedMozioFetchCount
        && Object.keys(categoryPrices).length < MAX_CATEGORIES;


    // Commented out since decision was to deploy is without the preselect
    // useEffect(() => {
    //     if (!!Object.keys(categoryPrices)[0]) {
    //         if (preselectCategory) {
    //             dispatch(setSelectedVehicleCategory(Number(Object.keys(categoryPrices)[0])));
    //         }
    //     }
    // }, [categoryPrices]);

    const handleScroll = (offset) => {
        containerRef.current.scrollLeft = containerRef.current.scrollLeft + offset;
    };


    // useEffect on load that sets the event listener so we can determine and set the scroll position
    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            const handleScrollUseEffect = () => {
                setScrollPosition(container.scrollLeft)
            };

            // Add the scroll event listener to the container element
            container.addEventListener('scroll', handleScrollUseEffect);
        }
    }, []);

    return {
        isMobile,
        handleScroll,
        isLoading,
        scrollPosition,
        containerRef,
        categoryPrices,
        fetchedGbtData
    }
}