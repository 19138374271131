import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import {useEffect, useState} from "react";
import TimeSelect from "../../../../../features/dateTime/components/TimeSelect.tsx";
import IconClock from "../../../../../components/generic/icons/IconClock.tsx";
import FloatingLabelInputField from "../../../../../components/generic/formElements/FloatingLabelInputField.tsx";
import {useTimeSelect} from "../hooks/useTimeSelect.ts";

interface SingleTimePickerProps {
    returnTrip: boolean;
}

const SingleTimePicker = (props: SingleTimePickerProps) => {
    const {
        updateReturnPickUp,
        displayPickUpTime,
        isDropdownOpen,
        setIsDropdownOpen,
        selectedPickUpTime,
        minPickUpTime,
        pickUpTimeLabel,
        handleSelectPickUpTime,
        pickUpErrorMessageString,
        pickUpErrorDisplay,
    } = useTimeSelect();

    const {
        returnTrip
    } = props

    useEffect(() => {
        if (!returnTrip){
            updateReturnPickUp('', true, false)
        }
    }, [returnTrip]);

    return (
        <CustomDropdown
            field={
                <FloatingLabelInputField
                    id="time-input"
                    label={pickUpTimeLabel}
                    background={'white'}
                    value={displayPickUpTime}
                    icon={<IconClock />}
                    displayError={pickUpErrorDisplay}
                    errorMessage={pickUpErrorMessageString}
                    type="text"
                    readOnly
                    container={false}
                    autoComplete={"off"}

                />
            }
            isOpen={isDropdownOpen}
            setIsOpen={setIsDropdownOpen}
            childrenWrapperClass={'gb--select-dropdown-single-item'}
            children={
                <TimeSelect
                    selectedTime={selectedPickUpTime}
                    minTime={minPickUpTime}
                    handleSubmit={(value: Date) => {
                        handleSelectPickUpTime(value)
                        setIsDropdownOpen(false)
                    }}
                    changeTime={(value: Date) => {
                        handleSelectPickUpTime(value)
                        setIsDropdownOpen(false)
                    }}
                    isMobile={false}
                />
            }
        />
    )
}
export default SingleTimePicker