import {useLazyGetPlacesQuery} from "../../../api/reducers/apiSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useDebounce from "../../../hooks/effects/useDebounce.ts";
import {ChangeEvent} from "react";

interface UseGetPlacesProps {
    _token: string;
    searchStrict?: boolean,
    stationId?: number,
    coordinates?: number[]
}

export const useGetPlaces = (props: UseGetPlacesProps) => {
    const {
        _token,
        searchStrict,
        stationId,
        coordinates
    } = props;

    const [query, response] = useLazyGetPlacesQuery();
    const {
        locale,
    } = useAppSelector((state) => state?.bookingForm);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 2) {
            query({
                locale,
                searchTerm: event.target.value,
                _token: _token,
                strict: searchStrict,
                stationId,
                lat: coordinates ? coordinates[0] : undefined,
                lng: coordinates ? coordinates[1] : undefined
            })
        }
    }

    const {
        debounce: debounceRequest,
    } = useDebounce(handleInput, undefined, [stationId])

    return {
        debounceRequest,
        response
    }
}