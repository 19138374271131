import {useEffect} from "react";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function useUpdatePlaceholderOnIncompleteDataCompletion(name: "departure" | "destination", setPlacePlaceholder: (value: string) => void) {
    const {
        successfulSubmitTimestamp,
        toPlace,
        fromPlace,
    } = useAppSelector((state) => (state.form))

    useEffect(() => {
        if (!!successfulSubmitTimestamp) {
            if (name === "departure" && !!fromPlace) {
                setPlacePlaceholder(fromPlace.placeName)
            }
            if (name !== "departure" && !!toPlace) {
                setPlacePlaceholder(toPlace.placeName)
            }
        }
    }, [successfulSubmitTimestamp]);
}