import {useState, ChangeEvent, useEffect} from "react"
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useBookingInfoInput from "../../bookingInfoInputField/hooks/useBookingInfoInput.ts";
import FloatingLabelInput from "../../../../../components/generic/formElements/FloatingLabelInput.tsx";
import IconLocation from "../../../../../components/generic/icons/bookingIcons/IconLocation.tsx";

interface PickUpAddressProps {
    isAirport: boolean,
    address: string,
}

const PickUpAddress = (props: PickUpAddressProps) => {
    const pickUpAddressLabel = useTranslation("booking.form.start") as string
    const {
        singleTransferForm,
    } = useAppSelector((state) => state?.bookingForm);
    const {
        isAirport,
        address
    } = props;

    const {updateStore} = useBookingInfoInput({
        formType: "singleTransferForm",
        field: "pickUpAddress",
        fieldValidationMethod: () => true,
    });

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateStore(event.target.value, true, false)
    };

    useEffect(() => {
        if (isAirport) {
            updateStore(address, true, false)
        }
    }, []);

    return (
        <FloatingLabelInput
            maxLength={50}
            value={isAirport ? address : singleTransferForm.pickUpAddress.value }
            icon={<IconLocation />}
            id="pickup"
            label={pickUpAddressLabel}
            type="text"
            onChange={handleChange}
            readOnly={isAirport}
            autoComplete={"off"}
            background={!isAirport ? "white" : "disabled"}
        />
    )
}

export default PickUpAddress;