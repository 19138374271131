export const homepageRouteId = 'homepage'
export const staticPagesRouteId = 'staticPages'
export const stationToStationRouteId = 'stationToStation'
export const bookingRouteId = 'booking'
export const vehicleRouteId = 'vehicle'
export const bookingInfoRouteId = 'booking-info'
export const successfulRouteId = 'successful'
export const contactRouteId = 'contact'
export const faqRouteId = 'faq'
export const transferRouteId = 'transfer'
export const privacyPolicyRouteId = 'privacyPolicy'
export const termsAndConditionsRouteId = 'termsAndConditions'