import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";
interface IconDoubleChevronProps extends IconType {
    chevronOrientation?: 'up' | 'right' | 'down' | 'left',
}

function IcounDoubleChevron(props: IconDoubleChevronProps) {
    const {
        width = '16',
        height = '16',
        fill = '#4a69e2',
        viewBox = '0 0 16 16',
        title = 'IcounDoubleChevron',
        chevronOrientation,
        ...rest
    } = props

    const orientation = {
        [`${chevronOrientation === "left"}`]: "-180deg",
        [`${chevronOrientation === "right"}`]: "0deg",
        [`${chevronOrientation === "down"}`]: "-90deg",
        [`${chevronOrientation === "up"}`]: "90deg",
    }

    const style = {verticalAlign: "middle", transform: `rotate(${orientation.true})`};

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            style={style}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m13.9613 8.0526c0 .14006-.0515.26011-.1544.36015l-7.23898 7.03795c-.1029.1-.22638.15-.37044.15s-.26754-.05-.37044-.15c-.1029-.1001-.15435-.2201-.15435-.3602 0-.14.05145-.2601.15435-.3601l6.86856-6.6778-6.97661-6.78282c-.09261-.10005-.13891-.2201-.13891-.360153 0-.140059.0463-.260109.13891-.36015.05145-.050021.11062-.087537.17751-.112547.06688-.025011.13119-.037516.19293-.037516.07203 0 .14149.012505.20838.037516.06688.02501.12605.062526.1775.112547l7.33159 7.142973c.1029.10004.1544.22009.1544.36015z"/>
                    <path
                        d="m10.4183 8.0526c0 .14006-.0514.26011-.1543.36015l-7.23905 7.03795c-.1029.1-.22638.15-.37044.15s-.26754-.05-.37044-.15c-.1029-.1001-.15435-.2201-.15435-.3602 0-.14.05145-.2601.15435-.3601l6.86857-6.6778-6.97662-6.78282c-.09261-.10005-.13891-.2201-.13891-.360153 0-.140059.0463-.260109.13891-.36015.05145-.050021.11062-.087537.17751-.112547.06688-.025011.13119-.037516.19293-.037516.07203 0 .14149.012505.20838.037516.06688.02501.12605.062526.1775.112547l7.33166 7.142973c.1029.10004.1543.22009.1543.36015z"/>
                </g>
            )}
        />

    );
}

export default IcounDoubleChevron;












