import {useParams} from "react-router";
import {generatePath} from "react-router-dom";
import {getTranslatedRoute} from "../../../routing/functions/getTranslatedRoute.ts";
import useGoTo from "../../../routing/hooks/useGoTo.ts";

export const useNavigateBackOnBookingForm = () => {
    const params = useParams();
    const {goTo} = useGoTo();
    const {getPath} = getTranslatedRoute();
    const translatedBooking = getPath("booking");

    const navigateBackOnBookingForm = () => {
        const path = generatePath(`/:locale/${translatedBooking}/:fromSlug/:toSlug/:date/:passengers`, params);
        goTo(path);
    }

    return {
        navigateBackOnBookingForm,
    }
}