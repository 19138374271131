import GetByApsLogomark from "../../../../../components/generic/icons/logotypes/GetByApsLogomark.tsx";
import useLocale from "../../../../../hooks/selector/useLocale.ts";
import GetByApsLogo from "../../../../../components/generic/icons/logotypes/GetByApsLogo.tsx";
import {NavLink} from "react-router-dom";

function Logo() {
    const locale = useLocale()

    return (
        <span className="logo">
            <NavLink
                to={`/${locale}`}
                reloadDocument
            >
                <GetByApsLogomark style={{verticalAlign: 'middle', marginRight: '.5rem'}}/>
                <GetByApsLogo style={{verticalAlign: 'middle'}}/>
            </NavLink>
        </span>
    );
}

export default Logo;