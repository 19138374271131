import Translation from "../../../../../features/translation/components/Translation.tsx";

interface IncompleteDialogHeaderProps {
    title: string
    description: string
}

const IncompleteDialogHeader = (props: IncompleteDialogHeaderProps) => {
    const {
        title,
        description
    } = props;

    return (
            <div>
                <h2>
                    {title}
                </h2>
                <p>
                    {description}
                </p>
            </div>
    );
};

export default IncompleteDialogHeader;