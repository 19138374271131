import Column from "../../../../../features/containers/components/Column.tsx";
import Row from "../../../../../features/containers/components/Row.tsx";
import PillLabel from "../../../../../components/generic/PillLabel.tsx";
import VehicleType from "../../../../../types/VehicleType.type.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";


interface BookingMobileVehiclePreviewProps {
    vehicleType: VehicleType;
}

const BookingMobileVehiclePreview = (props: BookingMobileVehiclePreviewProps) => {
    const { vehicleType } = props;
    const selected = useTranslation("booking.chooseVehicle.selected") as string

    return (
        <Column className='gb--booking-vehicle__container'>
            <div className='gb--booking-vehicle__info'>
                <Column className='gb--booking-vehicle__name'>
                    <p>
                        {vehicleType.type}
                    </p>
                    <Row>
                        <PillLabel label={selected}/>
                    </Row>
                </Column>
                <Column className='gb--booking-vehicle__image'>
                    <img
                        src={vehicleType.imagePath}
                        alt={vehicleType.type}
                        width="300"
                    />
                </Column>
            </div>
        </Column>
    );
};

export default BookingMobileVehiclePreview;