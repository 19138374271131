import {useMemo} from "react";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function useIsPageRerenderOrRedirect() {
    const {
        toPlace,
        fromPlace,
        successfulSubmitTimestamp

    } = useAppSelector((state) => (state.form))

    // We detect page rerender/ redirect on SR by checking the existence of placeId-s
    return useMemo(() => (!toPlace?.placeId || !fromPlace?.placeId), [successfulSubmitTimestamp])
}