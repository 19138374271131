import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "../../../../types/Icon.type";

function GetByApsLogomark(props: IconType) {
    const {
        width = '38',
        height = '38',
        fill = '#fff',
        viewBox = '0 0 38 38',
        title = 'GetByApsLogomark',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path d="m34.9678193 26.6600814c.6070791.3479734.8171257 1.1221964.4691523 1.7292754-3.3562016 5.8552739-9.5868836 9.5461228-16.4613496 9.5486399-4.7001824 0-9.13224642-1.7127259-12.57663708-4.7624709-.52388963-.4638643-.57254979-1.2645971-.10868551-1.7884867s1.26459704-.5725498 1.78848667-.1086855c2.98526532 2.6432245 6.82143982 4.1256761 10.89637162 4.1256751 5.8973583-.0021594 11.2461611-3.1357 14.1753651-8.1230957l.0880212-.1516993c.3479734-.6070791 1.1221963-.8171257 1.7292753-.4691523zm-20.4852936-24.86676646c.222258.65564437-.1229691 1.36672873-.7721082 1.59933825l-.021045.0073369c-6.61552506 2.24260812-11.15540524 8.47212471-11.15540448 15.57430761.00093588 2.9712349.79031495 5.823981 2.26519948 8.3264892.35528493.6028292.15461067 1.3795341-.44821852 1.7348191-.60282919.3552849-1.37953416.1546106-1.73481909-.4482185-1.7029626-2.8894993-2.61505052-6.1857044-2.61612989-9.6126906 0-8.1965711 5.2392918-15.38580512 12.8758506-17.9745352.6626943-.22464787 1.3820272.13045893 1.6066751.79315324zm4.4863244.74065314c-.064552.00000057-.1290576.00037268-.1935149.00111578-.6996895.0080664-1.273439-.55260488-1.2815913-1.25229435-.0079805-.69968946.5526908-1.27343896 1.2523803-1.28150536l.111316-.0009628.1115579-.00032135c10.4762888 0 18.9689989 8.49271011 18.9689989 18.968999 0 1.3059728-.1321666 2.5956888-.3922429 3.8550377-.1415207.6852753-.8117713 1.1260756-1.4970466.984555-.678062-.140031-1.1167686-.7977193-.9888309-1.4754119l.0042759-.0216347c.2253063-1.0909848.3398764-2.2089897.3398764-3.3425461 0-8.98604879-7.2117855-16.28769478-16.1632476-16.43282909z" fill="#7ed321"/>
                    <path d="m31.465082 14.7303027c.0964561.2824334.1836561.5688522.2613892.8589559l.0548516.211529c1.7449496 6.9617863-2.4127969 14.0613356-9.371252 15.925848-4.6932984 1.2575655-9.6238007-.1686851-12.92661702-3.6112305-.48443466-.5049292-.46782058-1.306967.0371086-1.7914017.49912542-.4788665 1.28856862-.4681363 1.77457212.0198908l.0168296.0172178c2.6693027 2.7822303 6.6505445 3.9338875 10.4422675 2.9178984 5.6201869-1.5059246 8.9783047-7.24007 7.5689484-12.8629475l-.044303-.1708494c-.0540322-.2016129-.1136486-.4009071-.1787613-.597744l-.0330139-.098213c-.2261478-.662184.1273293-1.3823192.7895132-1.608467.662184-.2261478 1.3823191.1273293 1.608467.7895132zm-3.2657209-5.16518859c.501418.48806809.5122402 1.29020499.0241722 1.79162299-.4827047.4959078-1.2726135.5119431-1.7749846.0400815l-.0166384-.0159094c-2.6712377-2.6001179-6.5195535-3.64245143-10.1861991-2.65997671-5.6770813 1.52116931-9.04588114 7.35646761-7.5245617 13.03410891.02360142.0880546.04826862.175669.07399448.2628318l.02607849.0870119c.20370134.6694298-.17384612 1.3772424-.84327587 1.5809437-.66942974.2037013-1.37724234-.1738461-1.58094368-.8432759-.04348114-.1428933-.08464178-.2867312-.12345341-.4315782-1.88354665-7.0294918 2.28742537-14.25428011 9.31632249-16.13766742 4.5382494-1.21602025 9.304684.07498989 12.6094891 3.29180683z" fill="#f5a623"/>
                    <g fill="#4a79e2">
                        <path d="m18.9984883 14.1517142c-.34904.0001039-.6924741.0368881-1.026758.1089846l-.0625702.0139315c-.6819822.1566227-1.3618058-.2692654-1.5184285-.9512476-.1566226-.6819822.2692654-1.3618058.9512476-1.5184285.5186682-.1191163 1.0513081-.1820925 1.5909629-.1869093l.0670293-.000299c4.0770906 0 7.3822256 3.305135 7.3822256 7.3822255 0 4.0770906-3.305135 7.3822256-7.3822256 7.3822256-4.0770905 0-7.3822255-3.305135-7.3822255-7.3822256 0-1.0787452.2323414-2.1270952.6749969-3.0874191.2929186-.6354755 1.0455307-.9131728 1.6810062-.6202542.6286425.289769.9071594 1.0293987.6295055 1.6604671l-.0092513.0205392c-.2901075.6293768-.4422892 1.3160376-.4422892 2.026667 0 2.6776187 2.1706388 4.8482575 4.8482574 4.8482575 2.6776187 0 4.8482575-2.1706388 4.8482575-4.8482575 0-2.6508424-2.1274431-4.804794-4.7680827-4.8476079z"/>
                        <path d="m33.7152122 17.6648718c.7867774 0 1.4245865.637809 1.4245865 1.4245864s-.6378091 1.4245864-1.4245865 1.4245864c-.7867773 0-1.4245864-.637809-1.4245864-1.4245864s.6378091-1.4245864 1.4245864-1.4245864z"/>
                    </g>
                </g>
            )}
        />

    );
}

export default GetByApsLogomark;
