import {useAppSelector} from "./useAppSelector";
import {LocaleType, UpperCasedLocaleType} from "../../types/Locale.type";

/**
 * Returns locale string from page state
 */
export default function useLocale(upperCased: boolean = false): LocaleType | UpperCasedLocaleType {
    const locale: LocaleType = useAppSelector((state) => state?.page.locale)

    return upperCased ? locale.toUpperCase() as UpperCasedLocaleType : locale ?? 'en'
}