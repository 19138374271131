import MenuItem from "./MenuItem";
import UrlObjectType from "../../../../../types/UrlObject.type.ts";
import rootExtractor from "../../../../../utils/dataset/rootExtractor.ts";
import {
    shouldBeInMenu
} from "../../../../../features/mobileOverlays/features/mobileMenuOverlay/functions/shouldBeInMenu.ts";
import {PagesType} from "../../../../../features/mobileOverlays/features/mobileMenuOverlay/types/Pages.type.ts";
import {LocaleType} from "../../../../../types/Locale.type.ts";

function MainMenu() {
    const path = window.location.pathname;
    const locale = rootExtractor('locale');
    const urlObjects= rootExtractor('urlsObject', true) as Record<LocaleType, UrlObjectType[]>;

    return (
        <>
            {Object.entries(urlObjects[locale]).map(([key, urlObject]) => (
                shouldBeInMenu(key as PagesType, urlObject) && (
                    <MenuItem
                        urlObject={urlObject}
                        currentRoute={path}
                        visible
                        reload={urlObject.routeName === 'app_homepage'}
                        key={urlObject.routeName}
                    />
                )
            ))}
        </>
    );
}

export default MainMenu;
