import DatePickerWrapper from "./DatePickerWrapper.tsx";
import TimeDisplay from "./TimeDisplay.tsx";
import Column from "../../containers/components/Column.tsx";

interface DateSelectType {
    selectedDate: string
    changeDate: (value: Date) => void
    toggleActive: (tag: "time" | "date") => void
    minDateTime?: string
    selectedTime?: string
    maxDateTime?: string
}

const DateSelect = (props: DateSelectType) => {
    const {
        selectedDate,
        selectedTime,
        minDateTime,
        changeDate,
        toggleActive,
        maxDateTime
    } = props

    return (
        <Column>
            <DatePickerWrapper
                selected={new Date(selectedDate)}
                onChange={(value: Date) => {
                    changeDate(value)
                    toggleActive('time')
                }}
                useWeekdaysShort
                inline
                minDate={minDateTime ? new Date(minDateTime) : null}
                maxDate={maxDateTime ? new Date(maxDateTime) : null}
            />
            <TimeDisplay
                selectedTime={selectedTime}
                toggleActive={toggleActive}
                additionalClass={'gb--datetime-time__top'}
                maxTime={maxDateTime}
            />
        </Column>
    );
};

export default DateSelect;