import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useFormDataFromLastSearch from "./useFormDataFromLastSearch.ts";
import {PlaceType} from "../../../types/Place.type.ts";

export default function useFormPlaceValidator() {
    const {
        toPlace,
        fromPlace,
    } = useAppSelector((state) => (state.form));

    const {
        latestSearchFormUsed: {
            toPlace: lastSearchToPlace,
            fromPlace: lastSearchFromPlace,
        },
    } = useAppSelector((state) => state.searchResults);

    const isInvalid = (place: PlaceType | null) => (!place?.placeId || !place?.isStrict)

    return {
        areSame: toPlace?.placeId === fromPlace?.placeId,
        invalidDeparture: isInvalid(fromPlace),
        invalidDestination: isInvalid(toPlace),
        lastSearchInvalidDeparture: isInvalid(lastSearchFromPlace),
        lastSearchInvalidDestination: isInvalid(lastSearchToPlace)
    }
}