import {Notification} from "@getbybus/gbb-green"
import ScrollToElement from "../../../../../features/scroll/components/ScrollToElement.tsx"
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts"

type ErrorNotificationProps = {
    errorMessage: string
}

const ErrorNotification = ({errorMessage}: ErrorNotificationProps) => {
    const {submitTimestamp} = useAppSelector((state) => state?.bookingForm)

    return (
        <ScrollToElement condition={submitTimestamp}>
            <Notification
                isLarge
                notificationType="red"
            >
                {errorMessage}
            </Notification>
        </ScrollToElement>
    )
}

export default ErrorNotification
