import CustomDropdown from "../../../../../components/generic/CustomDropdown.tsx";
import PassengerPickerOptions from "./PassengerPickerOptions.tsx";
import PassengerPickerInput from "./PassengerPickerInput.tsx";
import useTogglePassengerPicker from "../hooks/useTogglePassengerPicker.ts";
import {useToggle} from "../../../../../hooks/dispatch/useToggle.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";
import FadingError from "../../../../../features/errors/components/FadingError.tsx";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

function PassengerPicker() {
    const toggleMobileOverlayPassengerPicker = useTogglePassengerPicker()
    const {
        set: setIsDropdownOpen,
        value: isDropdownOpen,
    } = useToggle()

    const {
        formErrors: {
            passengerError,
        },
        submitTimestamp,
    } = useAppSelector((state) => (state.form))

    return (
        <div className='search__select search__select--pax'>
            <CustomDropdown
                field={<PassengerPickerInput/>}
                onClick={toggleMobileOverlayPassengerPicker}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
                required
            >
                <PassengerPickerOptions
                    setIsDropdownOpen={setIsDropdownOpen}
                />
            </CustomDropdown>
            <FadingError
                isError={passengerError}
                showError={submitTimestamp}
            />
            <span className="search__select--label gb--emphasize gb--emphasize-default">
                <Translation translationKey='search.form.passengers'/>
            </span>
        </div>
    );
}

export default PassengerPicker;