import {PrivacyFormPayloadType} from "../types/PrivacyFormPayload.type.ts";

const privacyFormPayload = {
    value: false,
    isValid: false,
    displayError: false,
}

export const privacyFormPrivacyPolicyPayload: PrivacyFormPayloadType = {
    ...privacyFormPayload,
    type: 'privacyPolicy',
}