import Column from "../../../../features/containers/components/Column.tsx";
import PriceDisplay from "./PriceDisplay.tsx";
import Row from "../../../../features/containers/components/Row.tsx";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";

interface BookingTotalPriceDisplayProps {
    isReturn: boolean
    totalPrice: number
}

const BookingTotalPriceDisplay = ({isReturn, totalPrice}: BookingTotalPriceDisplayProps) => {
    const oneWayLabel = useTranslation("booking.form.oneWay") as string
    const returnLabel = useTranslation("booking.form.return") as string
    const trip = isReturn ? returnLabel : oneWayLabel
    const total = useTranslation("booking.form.total") as string
    return (
        <Row
            justify
            style={{width: "100%"}}
        >
            <Column>
                {trip} {total}
            </Column>
            <Column>
                <PriceDisplay price={(totalPrice || 0)}/>
            </Column>
        </Row>
    );
};

export default BookingTotalPriceDisplay;