import PlaceSelectors from "../features/stations/components/PlaceSelectors.tsx";
import PassengerPicker from "../features/passengers/components/PassengerPicker.tsx";
import DateTimeField from "../features/dates/components/DateTimeField.tsx";
import HeroBackgroundMain from "../features/hero/components/HeroBackgroundMain.tsx";
import useFormMethods from "./../hooks/useFormMethods";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import MobilePlaceInputButton from "../features/stations/components/MobilePlaceInputButton.tsx";
import Translation from "../../../features/translation/components/Translation.tsx";
import LoaderButton from "../../../components/generic/loaders/LoaderButton.tsx";
import useSubmitSearch from "../hooks/useSubmitSearch.ts";
import ReturnDateTimeField from "../features/dates/components/ReturnDateTimeField.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useRef} from "react";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import {allRouteIds} from "../../../routing/constants/allRouteIds.ts";


function SearchForm() {
    const isMobile = useIsMobile();
    const {setClassName} = useFormMethods();
    const {submitForm, isFetching} = useSubmitSearch();
    const fromInput = useRef();
    const toInput = useRef();
    const dateInput = useRef();
    const isBooking = useRouteMatch(allRouteIds.vehicle);

    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form));

    return (
        <div className={setClassName()}>
            <HeroBackgroundMain/>
            <div className="gb--search-form">
                {isMobile ? (
                    <>
                        <MobilePlaceInputButton
                            direction="departure"
                            showIsIncomplete={isBooking && !!fromPlace && !fromPlace.placeId}
                        />
                        <MobilePlaceInputButton
                            direction="destination"
                            showIsIncomplete={isBooking && !!toPlace && !toPlace?.placeId}
                        />
                    </>
                ) : (
                    <PlaceSelectors
                        fromRef={fromInput}
                        toRef={toInput}
                        dateRef={dateInput}
                        showIsIncomplete={isBooking}
                    />
                )}
                <div className={"search__select search__select--datepicker gb--row gb--row-middle"}>
                    <DateTimeField inputRef={dateInput}/>
                    <ReturnDateTimeField/>
                </div>
                <PassengerPicker/>
                {isFetching ? (
                    <LoaderButton
                        style={{}}
                        className='btn btn--green search__select--action'
                    />
                ) : (
                    <button
                        style={{margin: 0}}
                        className='btn btn--green search__select--action'
                        onClick={submitForm}
                    >
                        <Translation
                            translationKey='search.form.button'
                            styleText="upperCase"
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default SearchForm;