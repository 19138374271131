import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts"
import BookingStripePriceDisplay from "./BookingStripePriceDisplay.tsx"

const BookingStripePaymentInfo = () => {
    const {price} = useAppSelector((state) => state?.vehicleType);
    const {
        paymentTransferForm,
        amenities: bookingAmenities
    } = useAppSelector((state) => state?.bookingForm);

    const {
        amenities: vehicleAmenities,
        depositPrice
    } = useAppSelector((state) => state?.vehicleType);

    const totalPrice = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id].count + bookingAmenities[amenity.id].returnCount) * Number(amenity.price);
        return acc + subtotal;
    }, Number(price));

    const amenitiesPrepayment = vehicleAmenities.reduce((acc, amenity) => {
        const subtotal = (bookingAmenities[amenity.id]?.count + bookingAmenities[amenity.id]?.returnCount) * Number(amenity?.depositPrice);
        return acc + subtotal;
    }, 0);

    return (
        <div className="gb--stripe__price">
            <BookingStripePriceDisplay
                displayPrice={Number(totalPrice)}
                depositPrice={Number(depositPrice)}
                paymentMethod={paymentTransferForm.paymentMethod.value}
                amenitiesDepositPrice={amenitiesPrepayment}
            />
            <hr className="dashed" />
        </div>
    )
};

export default BookingStripePaymentInfo
