import Row from "../../../features/containers/components/Row.tsx";
import Column from "../../../features/containers/components/Column.tsx";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useParams} from "react-router";


const BookingNavigationResultsInfo = () => {
    const {} = useParams()
    const {
        fromPlace,
        toPlace
    } = useAppSelector((state) => state?.form)

    const fromPlaceName = fromPlace?.placeName ? fromPlace.placeName : ''
    const toPlaceName = toPlace?.placeName ? toPlace.placeName : ''

    const fromDisplay = fromPlaceName.length > 30 ? `${fromPlaceName.substring(0, 30)}...` : fromPlaceName
    const toDisplay = toPlaceName.length > 30 ? `${toPlaceName.substring(0, 30)}...` : toPlaceName

    return (
        <Column className='gb--booking-navigation__trip'>
            <Row className="gb--booking-navigation__transfer">
                <Column>
                    <div className="gb--booking-navigation__location">
                        <span>
                            {fromDisplay}
                        </span>
                    </div>
                </Column>
            </Row>
            <hr className="gb--booking-navigation__connection"/>
            <Row className="gb--booking-navigation__transfer">
                <Column>
                    <div className="gb--booking-navigation__location">
                        <span>
                            {toDisplay}
                        </span>
                    </div>
                </Column>
            </Row>
        </Column>
    );
};

export default BookingNavigationResultsInfo;