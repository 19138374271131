import {ReactNode, useState} from "react";
import MobileSwiper from "../../mobileSwiper/components/MobileSwiper.tsx";
import SlideInContainer from "../../containers/components/SlideInContainer.tsx";
import getSlideInContainerClassName from "../../containers/functions/getSlideInContainerClassName.ts";
import setActionSheetSwipeClassName from "../functions/setActionSheetSwipeClassName.ts";
import {ActionSheetPositionType} from "../types/ActionSheetPosition.type.ts";
import getActionSheetPositionAdjuster from "../functions/getActionSheetPositionAdjuster.ts";

interface MobileOverlayContentWrapperProps {
    isActionSheet: boolean,
    children: ReactNode,
    wrapperClassName?: string,
    slideInDirection: "up" | "down" | "left" | "right",
}

function MobileOverlayContentWrapper(props: MobileOverlayContentWrapperProps) {
    const {
        isActionSheet,
        children,
        wrapperClassName = "",
        slideInDirection
    } = props

    const [
        position,
        setPosition,
    ] = useState<ActionSheetPositionType>(0)

    const {adjustPosition} = getActionSheetPositionAdjuster(setPosition)

    if (isActionSheet) {
        return (
            <div className="gb--mobile-overlay gb--mobile-overlay__fit-content">
                <MobileSwiper
                    className={`${getSlideInContainerClassName(slideInDirection)} gb--mobile-overlay__content gb--mobile-overlay__content-bottom ${setActionSheetSwipeClassName(position)} ${wrapperClassName}`}
                    onSwipe={adjustPosition}
                >
                    {children}
                </MobileSwiper>
            </div>
        )
    }
    return (
        <SlideInContainer
            slideInDirection={slideInDirection}
            className={`gb--mobile-overlay gb--mobile-overlay__content ${wrapperClassName}`}
        >
            {children}
        </SlideInContainer>
    )
}

export default MobileOverlayContentWrapper