import {useEffect, useState} from "react"
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts"
import {useGetStripePublishableKeyQuery} from "../../../../../api/reducers/apiSlice.ts"
import {useDispatch} from "react-redux"
import {loadStripe, StripeElementsOptions} from "@stripe/stripe-js"
import {clear} from "../../../../../reducers/stripeSlice.ts"

interface BookingStripeProps {
    clientSecret: string
}

export const useBookingStripe = (props: BookingStripeProps) => {
    const [stripePromise, setStripePromise] = useState(null)
    const {clientSecret} = props
    const {paymentTransferForm} = useAppSelector((state) => state?.bookingForm)
    const {paymentMethods} = useAppSelector((state) => state?.vehicleType)
    const {data, isLoading} = useGetStripePublishableKeyQuery(null)
    const dispatch = useDispatch()
    const paymentMethod = paymentTransferForm.paymentMethod.value
    const paymentMethodLabel = paymentMethods[paymentMethod]

    useEffect(() => {
        if (!isLoading) {
            setStripePromise(loadStripe(data.data.key))
        }
    }, [data, isLoading])

    const handleCancel = (event) => {
        event.preventDefault()
        dispatch(clear())
    }

    const options: StripeElementsOptions = {
        clientSecret: clientSecret,
        appearance: {
            theme: "flat",
            variables: {
                colorPrimary: "#4a79e2",
                colorText: "#4a4a4a",
                colorSuccess: "#7ed321",
                colorWarning: "#f5a623",
                colorDanger: "#f03a47",
                borderRadius: "4px",
                fontFamily: "fsjoey-medium, sans-serif",
                fontSizeBase: "16px",
            },
            rules: {
                ".Input--empty": {
                    border: "1px solid #dde3e8",
                    boxShadow: "none",
                },
                ".Input:focus": {
                    border: "1px solid var(--colorPrimary)",
                    boxShadow: "0 0 7px #e4ebfb",
                },
                ".AccordionItem": {
                    backgroundColor: "#fafbfc",
                    borderBottom: "1px solid #ddd",
                },
                ".AccordionItem--selected": {
                    color: "var(--colorPrimary)",
                    border: "1px solid #fff",
                    boxShadow: "none",
                },
            },
        },
    }

    return {
        paymentMethod,
        paymentMethodLabel,
        isLoading,
        handleCancel,
        stripePromise,
        options,
    }
}
