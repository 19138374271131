import {useState} from "react";
import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import useUpdatePlaceholderOnPageRefresh from "./useUpdatePlaceholderOnPageRefresh.ts";
import useUpdatePlaceholderOnIncompleteDataCompletion from "./useUpdatePlaceholderOnIncompleteDataCompletion.ts";
import {usePlacePlaceholderUpdateOnInitialSearch} from "./usePlacePlaceholderUpdateOnInitialSearch.ts";

export default function usePlacePlaceholder(name: "departure" | "destination") {
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state?.form))

    const [placePlaceholder, setPlacePlaceholder] = useState<string>(
        (name === 'departure'
            ? fromPlace?.placeName
            : toPlace?.placeName) ?? "",
    )

    useUpdatePlaceholderOnPageRefresh(name, placePlaceholder, setPlacePlaceholder)
    useUpdatePlaceholderOnIncompleteDataCompletion(name, setPlacePlaceholder)
    usePlacePlaceholderUpdateOnInitialSearch(name, setPlacePlaceholder)

    return {
        placePlaceholder,
        setPlacePlaceholder,
    }
}