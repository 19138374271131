import Row from "../../../../../features/containers/components/Row.tsx"
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts"

interface BookingStripePrepaymentPriceDisplayProps {
    totalPrice: number
    depositPrice: number,
    amenitiesDepositPrice: number,
}

const BookingStripePrepaymentPriceDisplay = (props: BookingStripePrepaymentPriceDisplayProps) => {
    const {
        depositPrice,
        amenitiesDepositPrice,
        totalPrice
    } = props

    const chargedLabel = useTranslation("booking.form.cardCharged") as string
    const remainingLabel = useTranslation("booking.form.remainingAmountToPay") as string

    const prepaymentPrice = Number((amenitiesDepositPrice + depositPrice)).toFixed(2);
    const remainingPrice = Number(((totalPrice || 0) - (prepaymentPrice))).toFixed(2);

    return (
        <>
            <Row
                justify
                style={{width: "100%"}}
            >
                <span>
                    {chargedLabel}: <b>&#8364; {prepaymentPrice}</b>
                </span>
            </Row>
            <Row
                justify
                style={{width: "100%"}}
            >
                <span>
                    {remainingLabel}: <b>&#8364; {remainingPrice}</b>
                </span>
            </Row>
        </>
    )
}
export default BookingStripePrepaymentPriceDisplay
