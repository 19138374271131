import Router from "./routing/components/Router";
import useSetPageLayout from "./hooks/dispatch/useSetPageLayout.ts";

function App() {
    useSetPageLayout()

    return (
        <>
            <Router/>
        </>
    );
}

export default App
