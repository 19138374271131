import {ReactNode, useEffect, useRef} from "react";
import useHandleSwipe from "../hooks/useHandleSwipe.ts";

interface MobileSwiperProps {
    className?: string,
    children: ReactNode,
    onSwipe: (deltaX: number, deltaY: number) => void,
}

function MobileSwiper({children, onSwipe, className = ""}: MobileSwiperProps) {
    const wrapperRef = useRef<HTMLDivElement>(null)
    useHandleSwipe(wrapperRef, onSwipe)
    useEffect(() => {
        wrapperRef?.current.scrollIntoView()
    })

    return (
        <div ref={wrapperRef} className={className}>
            {children}
        </div>
    )
}

export default MobileSwiper