import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";
import {setMobilePassengerPicker, toggleMobilePassengerPicker} from "../../../../../reducers/mobileSlice.ts";
import useIsOverlaySubtreeActive from "../../../../../features/mobileOverlays/hooks/useIsOverlaySubtreeActive.ts";

export default function useTogglePassengerPicker() {
    const dispatch = useAppDispatch()
    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()
    const isMobile = useIsMobile()
    return () => {
        if (isMobile) {
            if (isOverlaySubtreeActive) {
                dispatch(setMobilePassengerPicker(true))
            } else {
                dispatch(toggleMobilePassengerPicker())
            }
        }
    }
}