import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

interface IconHourglassTimeoutProps extends IconType {
    backgroundFill?: string,
}

function IconHourglassTimeout(props: IconHourglassTimeoutProps) {
    const {
        width = '97',
        height = '69',
        fill = 'none',
        backgroundFill = '#f5f5f5',
        viewBox = '0 0 97 69',
        title = 'IconHourglassTimeout',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m92.4465373 42.798c.3371311-.616.636054-1.264.8945212-1.943 2.9588874-7.77-1.5159662-17.078-10.9376571-19.325-1.018136-11.384-10.008299-19.193-20.1615644-20.526-11.6209095-1.524-22.2663863 5.045-25.8085105 15.486-4.2692037-1.346-8.4294017-1.182-12.3502365.857-1.7789285.732-3.406148 1.812-4.8659258 3.233-2.1430301 2.086-3.5365053 4.719-4.1040094 7.495-.8405802.118-1.6665515.236-2.4453243.484-4.54003237 1.449-7.40452307 4.431-8.19003857 8.923-.48884012 2.797.49783028 5.587 1.04847776 6.714 2.17449569 4.455 7.21685341 6.98 12.34012251 6.312.170064-.022 2.507656 2.2221667 7.0127762 6.7325 37.7427068-.1603333 59.1341489-.2405 64.1743265-.2405 3.5573226 0 7.2756691-4.78 7.2914019-5.989.043827-3.256-1.4519114-6.223-3.8983595-8.213z"
                        fill="#c8d6f6" transform="matrix(-1 0 0 1 100.691668 0)"/>
                    <g transform="translate(53 8)">
                        <path
                            d="m20.474 19.17h10.151 1.118c2.765 0 4.993-2.295 4.885-5.085-.077-1.964-1.387-3.696-3.227-4.387-.963-.361-1.877-.373-2.717-.188-.119-1.82-1.63-3.261-3.48-3.261-1.011 0-1.919.432-2.557 1.119.14-.688.155-1.422.014-2.177-.438-2.356-2.405-4.195-4.784-4.486-3.253-.398-6.033 2.004-6.27 5.116-.924-1.223-2.386-2.017-4.037-2.017-2.796 0-5.063 2.267-5.063 5.063 0 .213.017.422.043.628-2.218.463-3.884 2.429-3.884 4.784 0 2.7 2.189 4.889 4.888 4.889h11.762"
                            fill="#fff"/>
                        <path
                            d="m31.743 19.67h-14.243c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h14.243c1.205 0 2.328-.479 3.164-1.348.834-.868 1.269-2.011 1.221-3.218-.067-1.729-1.234-3.312-2.903-3.938-.795-.299-1.591-.352-2.433-.167-.14.031-.291 0-.408-.089-.117-.087-.189-.221-.199-.366-.103-1.566-1.412-2.794-2.981-2.794-.838 0-1.616.341-2.19.959-.152.164-.395.209-.595.104-.199-.102-.306-.324-.262-.544.132-.651.137-1.319.013-1.987-.397-2.137-2.188-3.814-4.353-4.08-1.407-.172-2.778.222-3.86 1.109-1.071.878-1.746 2.172-1.85 3.549-.016.207-.158.383-.357.441-.199.06-.415-.013-.54-.178-.874-1.155-2.2-1.818-3.638-1.818-2.516 0-4.562 2.047-4.562 4.563 0 .191.016.38.039.565.032.259-.139.499-.394.552-2.02.422-3.486 2.229-3.486 4.295 0 2.42 1.969 4.389 4.389 4.389h11.762c.276 0 .5.224.5.5s-.224.5-.5.5h-11.764c-2.971 0-5.389-2.417-5.389-5.389 0-2.388 1.594-4.491 3.848-5.164-.004-.082-.007-.165-.007-.248 0-3.067 2.495-5.563 5.562-5.563 1.395 0 2.702.51 3.714 1.426.322-1.242 1.034-2.37 2.038-3.193 1.295-1.062 2.935-1.533 4.616-1.328 2.594.317 4.739 2.328 5.215 4.89.072.388.107.774.106 1.16.59-.33 1.26-.51 1.944-.51 1.907 0 3.528 1.359 3.905 3.182.839-.101 1.667-.001 2.468.299 2.041.766 3.467 2.709 3.55 4.835.058 1.481-.475 2.884-1.5 3.95s-2.404 1.654-3.883 1.654z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(56 17)">
                        <path d="m.576 1.07c1.699-.801 3.664-.234 4.389 1.267.09.186.157.379.201.574" fill="#fdfcef"/>
                        <path
                            d="m5.166 3.161c-.114 0-.217-.078-.244-.194-.041-.18-.103-.355-.182-.521-.665-1.375-2.485-1.892-4.058-1.148-.124.057-.274.005-.333-.12s-.005-.273.12-.333c1.821-.855 3.937-.236 4.721 1.385.096.199.17.411.219.627.031.135-.054.269-.188.3-.018.002-.037.004-.055.004z"
                            fill="#472b29"/>
                    </g>
                    <g transform="translate(0 37)">
                        <path
                            d="m21.818 15.534h3.54c2.11 0 3.821-1.674 3.821-3.739 0-1.802-1.302-3.305-3.035-3.66.02-.158.034-.317.034-.48 0-2.139-1.772-3.873-3.957-3.873-1.29 0-2.433.607-3.155 1.543-.185-2.381-2.358-4.218-4.9-3.913-1.859.223-3.397 1.629-3.739 3.431-.11.578-.098 1.139.011 1.665-.498-.525-1.208-.856-1.998-.856-1.446 0-2.627 1.102-2.72 2.494-.657-.142-1.371-.133-2.123.143-1.438.528-2.462 1.853-2.522 3.356-.085 2.133 1.657 3.889 3.818 3.889h.874 7.934m1.64 0h.36"
                            fill="#fff"/>
                        <path
                            d="m25.358 16.035h-3.54c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3.54c1.831 0 3.321-1.453 3.321-3.239 0-1.524-1.108-2.858-2.636-3.17-.256-.053-.429-.294-.396-.554.018-.137.03-.275.03-.417 0-1.859-1.551-3.372-3.457-3.372-1.092 0-2.098.491-2.76 1.348-.126.164-.34.23-.539.174-.198-.06-.339-.234-.355-.44-.079-1.02-.565-1.944-1.369-2.604-.829-.68-1.884-.979-2.973-.853-1.646.197-3.006 1.442-3.307 3.027-.094.495-.091.99.009 1.472.045.219-.06.441-.258.545-.197.103-.44.063-.594-.1-.428-.451-1.009-.7-1.635-.7-1.169 0-2.145.891-2.221 2.027-.01.146-.082.278-.198.366-.116.087-.263.12-.406.089-.64-.138-1.244-.098-1.846.125-1.262.463-2.144 1.631-2.195 2.905-.035.876.283 1.709.896 2.346.635.661 1.495 1.024 2.422 1.024h8.808c.276 0 .5.224.5.5s-.224.5-.5.5h-8.808c-1.185 0-2.331-.485-3.144-1.331-.803-.835-1.219-1.929-1.173-3.078.067-1.675 1.212-3.204 2.849-3.806.61-.225 1.24-.304 1.88-.244.344-1.396 1.629-2.424 3.135-2.424.493 0 .977.113 1.414.323.009-.242.037-.484.083-.727.381-2.009 2.096-3.585 4.17-3.833 1.364-.167 2.685.218 3.726 1.071.748.613 1.278 1.409 1.546 2.302.791-.647 1.785-1.006 2.842-1.006 2.458 0 4.457 1.961 4.457 4.372 0 .034 0 .068-.001.103 1.764.555 3.0030004 2.188 3.0030004 4.038.0009996 2.339-1.9370004 4.241-4.3200004 4.241z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(32 19)">
                        <path
                            d="m23.456 24.218s-3.059-2.924-4-3.822 0-1.826 0-1.826 1.378-1.317 4.291-4.101c2.731-2.611 3.385-5.937 3.378-6.794 0-.032.006-.059.006-.091l.001-4.564c0-.356-.273-.653-.628-.683l-12.231-1.018c-.038-.003-.076-.003-.114 0l-12.231 1.018c-.355.03-.628.326-.628.682 0 0 .006 4.623.005 4.654-.005.831.619 4.182 3.353 6.795 2.913 2.784 4.291 4.101 4.291 4.101s.941.927 0 1.826-4 3.822-4 3.822-3.084 2.924-3.034 7.588v4.519c0 .378.307.685.685.685h11.603 11.629c.378 0 .685-.307.685-.685v-4.519c.023-4.663-3.061-7.587-3.061-7.587z"
                            fill="#fafbfc"/>
                        <path
                            d="m25.83 37.71h-23.231c-.764 0-1.385-.621-1.385-1.385v-4.519c-.053-4.906 3.118-7.969 3.253-8.097l3.998-3.82c.2-.191.201-.321.202-.364.001-.203-.177-.425-.21-.458l-4.283-4.093c-2.984-2.852-3.574-6.488-3.569-7.307 0-.103-.005-4.647-.005-4.647 0-.716.558-1.322 1.271-1.381l12.229-1.017c.079-.006.151-.007.226-.001l12.235 1.019c.713.059 1.271.665 1.2700013 1.381v4.563c0 .025-.0050013.096-.0070013.121.0070013.775-.584 4.394-3.594 7.27l-4.291 4.101c-.043.046-.206.26-.202.455.001.042.004.17.202.359l4 3.822c.163.155 3.302 3.21 3.276 8.099v4.515c.001.763-.621 1.384-1.385 1.384zm-23.845-34.675c.014.014.02 4.62.019 4.65-.004.641.541 3.797 3.137 6.278l4.29 4.101c.073.071.641.655.635 1.474-.003.349-.115.868-.635 1.364l-3.999 3.822c-.03.028-2.864 2.79-2.818 7.075v4.526l23.216-.015-.015-4.503c.022-4.291-2.814-7.055-2.843-7.082l.484-.506-.485.505-3.999-3.822c-.52-.496-.632-1.016-.635-1.364-.006-.818.562-1.402.626-1.466l4.298-4.108c2.621-2.504 3.167-5.641 3.162-6.282 0-.027.004-.1.007-.127v-4.534l-12.217-1.003z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(33 20)">
                        <path
                            d="m24.873 35.088-.214-.175-10.521-8.596v-10.965c-.021-2.542 1.493-4.147 2.046-4.663l5.449-4.924c1.908-1.723 1.936-4.505 1.938-4.505h-20.972c0 .027.068 2.814 1.938 4.505l5.507 4.978c.495.461 1.654 2.066 1.634 4.608l-.033 10.959-10.349 8.778z"
                            fill="#f5a623"/>
                        <path
                            d="m24.873 35.438h-23.578c-.147 0-.278-.091-.329-.229-.05-.138-.01-.292.102-.387l10.226-8.674.032-10.796c.019-2.304-.987-3.854-1.521-4.352l-5.504-4.975c-1.997-1.807-2.053-4.735-2.053-4.765 0-.193.157-.35.35-.35h20.972c.193 0 .35.156.35.35v.017c-.01.32-.147 3.026-2.052 4.748l-5.449 4.924c-.584.544-1.951 2.069-1.931 4.4v10.801l10.608 8.667c.114.094.157.249.107.389-.05.139-.182.232-.33.232zm-22.624-.699h21.644l-9.976-8.15c-.082-.067-.129-.166-.129-.271v-10.965c-.021-2.605 1.502-4.309 2.158-4.919l5.452-4.928c1.383-1.249 1.717-3.104 1.797-3.896h-20.219c.084.774.427 2.658 1.795 3.896l5.507 4.979c.662.616 1.77 2.33 1.749 4.871l-.033 10.955c0 .103-.045.199-.124.266z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(28 14)">
                        <path
                            d="m31.836 8.184h-27.857c-1.303 0-2.37-1.066-2.37-2.37v-2.641c0-1.303 1.066-2.37 2.37-2.37h27.857c1.303 0 2.37 1.066 2.37 2.37v2.641c0 1.304-1.067 2.37-2.37 2.37z"
                            fill="#ff6144"/>
                        <path
                            d="m31.836 8.884h-27.857c-1.692 0-3.07-1.378-3.07-3.07v-2.641c0-1.692 1.377-3.07 3.07-3.07h27.857c1.692 0 3.0690005 1.378 3.0690005 3.07v2.641c.0009995 1.692-1.3760005 3.07-3.0690005 3.07zm-27.857-7.381c-.92 0-1.669.749-1.669 1.67v2.641c0 .921.749 1.67 1.669 1.67h27.857c.92 0 1.669-.749 1.669-1.67v-2.641c0-.921-.749-1.67-1.669-1.67z"
                            fill="#070c63"/>
                    </g>
                    <g transform="translate(28 52)">
                        <path
                            d="m32.601 8.931h-28.772c-1.221 0-2.22-.999-2.22-2.22v-2.941c0-1.221.999-2.22 2.22-2.22h28.772c1.221 0 2.22.999 2.22 2.22v2.941c0 1.221-.999 2.22-2.22 2.22z"
                            fill="#ff6144"/>
                        <path
                            d="m32.601 9.631h-28.773c-1.61 0-2.919-1.31-2.919-2.92v-2.941c0-1.61 1.31-2.92 2.919-2.92h28.772c1.61 0 2.9200006 1.31 2.9200006 2.92v2.941c.0009994 1.611-1.3090006 2.92-2.9190006 2.92zm-28.773-7.381c-.838 0-1.519.682-1.519 1.52v2.941c0 .838.681 1.52 1.519 1.52h28.772c.838 0 1.52-.682 1.52-1.52v-2.941c0-.838-.682-1.52-1.52-1.52z"
                            fill="#070c63"/>
                    </g>
                    <path d="m44.062 16.956h1.23" fill="#fff"/>
                    <path d="m40.372 16.956h2.46" fill="#fff"/>
                    <g transform="translate(48 39)">
                        <circle cx="15.381" cy="14.956" fill="#7ed321" r="14.309"/>
                        <path
                            d="m15.381 29.765c-8.166 0-14.809-6.644-14.809-14.81 0-8.165 6.643-14.809 14.809-14.809s14.809 6.644 14.809 14.809c0 8.166-6.643 14.81-14.809 14.81zm0-28.618c-7.614 0-13.809 6.194-13.809 13.809s6.194 13.81 13.809 13.81 13.809-6.196 13.809-13.811-6.195-13.808-13.809-13.808z"
                            fill="#070c63"/>
                        <ellipse cx="15.42" cy="14.946" fill="#fff" rx="12.254" ry="12.326"/>
                        <path d="m1.222.963c.071.29.131.583.181.877" fill="#fdfcee" transform="translate(26 11)"/>
                        <g transform="translate(8 9)">
                            <path
                                d="m8.939 3.547-7.569 7.615c-.189.19-.419.502-.229.692.189.19.499-.04.688-.231l7.569-7.615c.324.085.911.041 1.164-.214l.688-.692c.378-.381.378-1.004 0-1.385s-.998-.381-1.376 0l-.688.692c-.253.256-.332.812-.247 1.138z"
                                fill="#f5a623"/>
                            <path
                                d="m10.562 1.432c.249 0 .499.095.688.286.378.381.378 1.004 0 1.385l-.688.692c-.177.178-.517.253-.817.253-.129 0-.25-.014-.347-.04l-7.569 7.615c-.139.14-.344.302-.517.302-.062 0-.121-.021-.171-.071-.189-.19.04-.502.229-.692l7.569-7.615c-.085-.326-.006-.882.247-1.137l.688-.692c.189-.19.438-.286.688-.286m0-.5c-.395 0-.765.154-1.043.433l-.688.692c-.319.321-.45.863-.422 1.313l-7.394 7.438c-.597.6-.51 1.115-.229 1.397.14.141.327.219.525.219.282 0 .575-.151.872-.449l7.39-7.435c.056.005.114.007.172.007.232 0 .812-.039 1.171-.401l.688-.692c.573-.576.573-1.514 0-2.09-.277-.278-.648-.432-1.042-.432z"
                                fill="#070c63"/>
                        </g>
                        <g fill="#f27e97">
                            <path d="m23.846 6.322-1.014 1.02"/>
                            <path d="m7.736 22.53-1.014 1.02"/>
                            <path d="m24.059 23.664-1.014-1.021"/>
                            <path d="m7.736 7.18-1.014-1.021"/>
                        </g>
                        <path
                            d="m21.743 15.192h-5.071c-.193 0-.35-.156-.35-.35s.157-.35.35-.35h5.071c.193 0 .35.156.35.35s-.157.35-.35.35z"
                            fill="#070c63"/>
                        <path
                            d="m15.222 14.106c-.193 0-.35-.156-.35-.35v-8.332c0-.193.157-.35.35-.35s.35.156.35.35v8.332c0 .194-.157.35-.35.35z"
                            fill="#070c63"/>
                        <g transform="translate(13 13)">
                            <circle cx="2.222" cy="1.843" fill="#f5a623" r="1.268"/>
                            <path
                                d="m2.222 3.46c-.892 0-1.618-.726-1.618-1.618s.726-1.617 1.618-1.617 1.618.726 1.618 1.617c0 .893-.726 1.618-1.618 1.618zm0-2.536c-.506 0-.918.412-.918.918 0 .507.412.919.918.919s.918-.412.918-.919c0-.506-.412-.918-.918-.918z"
                                fill="#070c63"/>
                        </g>
                    </g>
                </g>
            )}
        />

    );
}

export default IconHourglassTimeout;
