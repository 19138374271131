
import BookingCardPriceDisplay from "../../bookingForm/features/bookingPriceInfo/BookingCardPriceDisplay.tsx";
import BookingPrepaymentPriceDisplay
    from "../../bookingForm/features/bookingPriceInfo/BookingPrepaymentPriceDisplay.tsx";
import BookingCashPriceDisplay from "../../bookingForm/features/bookingPriceInfo/BookingCashPriceDisplay.tsx";
import {PaymentMethods} from "../../../constants/PaymentMethods.ts";

interface SuccessfulBookingPriceProps {
    displayPrice: number,
    paymentMethod: number;
    amenitiesDepositPrice: number;
    depositPrice: number;
}

const SuccessfulBookingPrice = (props: SuccessfulBookingPriceProps) => {
    const {
        paymentMethod,
        displayPrice,
    } = props;

    if ( paymentMethod === PaymentMethods.CARD) {
        return (
            <BookingCardPriceDisplay displayPrice={displayPrice}/>
        );
    } else if (paymentMethod === PaymentMethods.PREPAYMENT) {
        return (
            <BookingPrepaymentPriceDisplay displayPrice={displayPrice}/>
        );
    } else if (paymentMethod === PaymentMethods.CASH) {
        return (
            <BookingCashPriceDisplay displayPrice={displayPrice}/>
        )
    }
};

export default SuccessfulBookingPrice;