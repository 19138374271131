import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {useEffect} from "react";
import {getCookie} from "@getbybus/gbb-green";
import {setUserCanSkipCookies, toggleIsCookieModalOpen} from "../../../reducers/pageSlice.ts";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import useFormPlaceValidator from "../../../pages/searchResults/hooks/useFormPlaceValidator.ts";

export default function useTriggerMustAcceptCookiesModal() {
    const dispatch = useAppDispatch()
    // @ts-ignore TODO fix bloody mess with routes, create better solution for types and consistency
    const isBookingInfo = useRouteMatch("booking-info")
    const isSearchResults = useRouteMatch("vehicle")
    const {lastSearchInvalidDeparture, lastSearchInvalidDestination} = useFormPlaceValidator()

    const isSearchResultsWithIncompleteAddresses = isSearchResults && (lastSearchInvalidDeparture || lastSearchInvalidDestination)

    useEffect(() => {
        if (!getCookie("cookiesConsent") && (isBookingInfo || isSearchResultsWithIncompleteAddresses)) {
            dispatch(setUserCanSkipCookies(false))
            dispatch(toggleIsCookieModalOpen(true))
        }
    }, [isBookingInfo, isSearchResultsWithIncompleteAddresses])
}