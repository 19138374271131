import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import MobileDatePicker from "../../../components/MobileDatePicker.tsx";
import MobilePlaceInputOverlay from "../../../components/MobilePlaceInputOverlay.tsx";
import ReturnMobileDatePicker from "../../../components/ReturnMobileDatePicker.tsx";
import MobileSearchFormOverlay from "./MobileSearchFormOverlay.tsx";
import MobilePassengerPickerOverlay from "../../../components/MobilePassengerPickerOverlay.tsx";

function MobileSearchFormOverlayWrapper() {
    const {
        singleCalendarActive,
        returnCalendarActive,
        departurePlaceInputActive,
        destinationPlaceInputActive,
        mobilePassengerPickerActive,
    } = useAppSelector((state) => (state.mobile))

    const overlays = {
        [`${singleCalendarActive}`]: <MobileDatePicker/>,
        [`${returnCalendarActive}`]: <ReturnMobileDatePicker/>,
        [`${departurePlaceInputActive || destinationPlaceInputActive}`]: <MobilePlaceInputOverlay/>,
        [`${mobilePassengerPickerActive}`]: <MobilePassengerPickerOverlay/>
    }

    return (
        <>
            <MobileSearchFormOverlay/>
            {overlays?.true}
        </>
    )
}

export default MobileSearchFormOverlayWrapper