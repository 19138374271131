import {useAppSelector} from "../../hooks/selector/useAppSelector.ts";
import {getTranslatedRoute} from "../functions/getTranslatedRoute.ts";

export const useUrls = () => {
    const {
        locale,
        siteUrl
    } = useAppSelector((state) => state?.page);

    const {
        getPath
    } = getTranslatedRoute();

    const translatedBooking = getPath("booking");

    return {
        homepage: `/${locale}`,
        bookingInfo: `/${locale}/${translatedBooking}/booking-info`,
        successfulBooking: `/${locale}/${translatedBooking}/successful-booking`,
        verifyBooking: `https://${siteUrl}/api/v1/verify-booking`,
        destinationApi: `${siteUrl}en/api/destination?term=`
    }
}
