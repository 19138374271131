import {setMobilePassengerPicker, toggleMobilePassengerPicker} from "../../../reducers/mobileSlice.ts";
import MobileOverlay from "./MobileOverlay.tsx";
import PassengerPickerOptions
    from "../../../layout/form/features/passengers/components/PassengerPickerOptions.tsx";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import useIsOverlaySubtreeActive from "../hooks/useIsOverlaySubtreeActive.ts";


function MobilePassengerPickerOverlay() {
    const dispatch = useAppDispatch()
    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()
    const backClick = () => {
        if (isOverlaySubtreeActive) {
            dispatch(setMobilePassengerPicker(false))
        } else {
            dispatch(toggleMobilePassengerPicker());
        }
    }

    return (
        <MobileOverlay title='Select Passenger Number' backClick={backClick}>
            <PassengerPickerOptions setIsDropdownOpen={backClick}/>
        </MobileOverlay>
    );
}

export default MobilePassengerPickerOverlay;