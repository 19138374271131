import { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, fallback: props.fallback };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        const { sendLog, matches } = this.props;

        if (sendLog) {
            sendLog.sendLog({
                trace: matches[matches.length - 1].id,
                url: window.location.pathname,
                message: error.message,
                info: {
                    trace: error.stack
                }
            });
        }
    }

    render() {
        if (this.state.hasError) {
            return this.state.fallback || '';
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
