
type Match = {
    data: unknown
    handle: unknown
    id: string
    pathname: string
    params: object
}

export const handleBookingNavigationItemClass = (id: string, matches: Match[]) => {
    const className = "gb--booking-navigation"
    const steps = {
        "vehicle": [],
        "booking-info": ['vehicle'],
        "successful": ['vehicle', 'booking-info']
    }
    const routeIds: string[] = matches.map((route: Match) => route.id)

    if (routeIds[routeIds.length - 1] === id) {
        return `${className} ${className}__selected`
    } else if (steps[routeIds[routeIds.length - 1]].find((routeId) => routeId === id)) {
        return `${className} ${className}__completed`
    }

    return className
}
