export default function stringReplacer(string: string, variableReplacements: object, exact = false, repeat = false) {
    let updatedString = string
    const entries = Object.entries(variableReplacements)
    const hasEntries = entries.length > 0
    const replace = (entries: [string, any][]) => entries.map(((entry) => {
        const toReplace = exact ? entry[0] : `%${entry[0]}%`
        updatedString = updatedString.replace(toReplace, entry[1])
    }))

    if (hasEntries) {
        replace(entries)
        if (repeat) {
            replace(entries)
        }
    }

    return updatedString
}