import {useDispatch} from "react-redux";
import {
    setFromPlace,
    setFromStation,
    setNumberOfPassengers,
    setToPlace, setToStation
} from "../../../reducers/formSlice.ts";
import {setNumberOfPeople} from "../../../pages/bookingForm/reducers/functions/bookingSlice.ts";
import {useMatches, useParams} from "react-router";
import {handleBookingDateFormat} from "../../../pages/searchResults/functions/handleBookingDateFormat.ts";
import {isBefore, isValid, startOfDay} from "date-fns";
import {SearchParamsType} from "../../../types/SearchParams.type.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {getDateRequestFormat} from "../../../pages/searchResults/functions/getDateRequestFormat.ts";
import useNavigateToSearchResults from "../../../routing/navigation/useNavigateToSearchResults.ts";
import getCityFromSlug from "../../../pages/searchResults/functions/getCityFromSlug.ts";
import useEffectOnParamsChanged from "../../../hooks/effects/useEffectOnParamsChange.ts";
import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import {allRouteIds} from "../../../routing/constants/allRouteIds.ts";

export function useInitializeDataFromParams() {
    const matches = useMatches();
    const dispatch = useDispatch();
    const params = useParams<SearchParamsType>();
    const {navigateToSearchResults} = useNavigateToSearchResults()
    const isBooking = matches.find((match) => match.id === allRouteIds.booking);

    const {
        departureDate,
        fromPlace,
        toPlace,
        fromStation,
        toStation
    } = useAppSelector((state) => (state?.form))

    const setSearchResultsParams = () => {
        if (params?.passengers) {
            dispatch(setNumberOfPassengers(Number(params.passengers)))
            dispatch(setNumberOfPeople(Number(params.passengers)))
        }

        if (params?.date && !departureDate) {
            const date = getDateRequestFormat(params.date) as string
            const isDateValid = isValid(new Date(date)) && !isBefore(new Date(date), startOfDay(new Date()))

            // Check if the param date is in the past, and if is not redirect user to todays date
            if (!isDateValid) {
                const date = handleBookingDateFormat(new Date().toISOString(), '-');
                navigateToSearchResults(params?.fromSlug!, params?.toSlug!, date, params?.passengers!)
            }
            // else {
            //     dispatch(setDepartureDate(new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString()))
            // }
        }
        if (params?.fromSlug && !fromPlace?.placeId) {
            if (!(params.fromSlug.includes('undefined') || params.fromSlug.includes('unknown'))) {
                dispatch(setFromPlace({
                    placeId: "",
                    placeName: getCityFromSlug(params.fromSlug, "display"),
                    isStrict: false,
                }))
            }
        }

        if (params?.fromSlug &&!fromStation) {
            dispatch(setFromStation({
                id: parseInt(params.fromSlug.split("-")[params.fromSlug.split("-").length - 1], 10),
                slug: params.fromSlug,
                label: null,
                countryShortCode: null,
            }))
        }

        if (params?.toSlug && !toPlace?.placeId) {
            if (!(params.toSlug.includes('undefined') || params.toSlug.includes('unknown'))) {
                dispatch(setToPlace({
                    placeId: "",
                    placeName: getCityFromSlug(params.toSlug, "display"),
                    isStrict: false,
                }))
            }
        }

        if (params?.toSlug && !toStation) {
            dispatch(setToStation({
                id: parseInt(params.toSlug.split("-")[params.toSlug.split("-").length - 1], 10),
                slug: params.toSlug,
                label: null,
                countryShortCode: null,
            }))
        }
    }

    // can be expanded for booking, for contact form or whatever might need this logic in the future
    const initializeData = () => {
        if (isBooking) {
            setSearchResultsParams()
        }
    }

    useEffectOnParamsChanged(initializeData)
}