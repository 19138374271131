import Row from "../../../../features/containers/components/Row.tsx";
import BookingDepositBreakdownPriceDisplay from "./BookingDepositBreakdownPriceDisplay.tsx";
import Column from "../../../../features/containers/components/Column.tsx";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";

interface BookingPrepaymentDetailsProps {
    totalPrice: number
    depositPrice: number,
    amenitiesDepositPrice: number,
}

const BookingPrepaymentDetails = (props: BookingPrepaymentDetailsProps) => {
    const {
        depositPrice,
        amenitiesDepositPrice,
        totalPrice
    } = props;

    const prepaymentPrice = Number((amenitiesDepositPrice + depositPrice).toFixed(2));
    const remainingPrice = Number(((totalPrice || 0) - (prepaymentPrice))?.toFixed(2));

    const prepaymentLabelString = useTranslation("booking.form.paymentMethodDeposit") as string;
    const prepaymentLabelSplit = prepaymentLabelString.split(" + ");

    return (
        <Column className={'gb--booking__prepayment'}>
            <Row alignEnd>
                {prepaymentLabelSplit[0]}: {<BookingDepositBreakdownPriceDisplay price={prepaymentPrice}/>}
            </Row>
            <Row alignEnd>
                {prepaymentLabelSplit[1]}: {<BookingDepositBreakdownPriceDisplay price={remainingPrice}/>}
            </Row>
        </Column>
    );

};

export default BookingPrepaymentDetails;