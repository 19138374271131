import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {toggleMobileLanguages} from "../../../../../reducers/mobileSlice.ts";
import LanguageFlag
    from "../../../../../layout/header/features/topBar/features/languageSwitcher/components/LanguageFlag.tsx";
import MobileMenuItemsList from "./MobileMenuItemsList.tsx";
import IconTranslation from "../../../../../components/generic/icons/menuIcons/IconTranslation.tsx";
import useTranslation from "../../../../translation/hooks/useTranslation.ts";


function MobileMenu() {
    const dispatch = useAppDispatch()

    const {
        locale,
    } = useAppSelector((state) => (state.page))

    const activateMobileLanguages = () => {
        dispatch(toggleMobileLanguages())
    }

    const lang = useTranslation(`base.language.${locale}`) as string

    return (
        <ul className="gb--menu gb--menu-mobile">
            <button
                className="gb--menu__item gb--row gb--row-middle gb--row-justify"
                onClick={() => activateMobileLanguages()}
            >
                <div>
                    <IconTranslation
                        style={{
                            verticalAlign: 'text-top',
                            marginRight: '1rem',
                        }}
                    />
                    {/* #TODO translation */}
                    Language
                </div>
                <span>
                    <LanguageFlag
                        langKey={locale}
                        lang={lang}
                    />
                </span>
            </button>
            <MobileMenuItemsList/>
        </ul>
    )
}

export default MobileMenu