import useGbtSingleTrackingEvent from "./useGbtSingleTrackingEvent.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useEffect, useState} from "react";
import {AmenityType} from "../../../types/Amenity.type.ts";

export default function useLandingOnSuccessfulPaymentEvent() {
    const {bookingId} = useAppSelector((state) => (state?.stripe))
    const {amenities} = useAppSelector((state) => (state?.bookingForm))
    const {amenities: vehicleAmenities} = useAppSelector((state) => (state?.vehicleType));
    const [singleAmenities, setSingleAmenities] = useState();

    useEffect(() => {
        if (vehicleAmenities.length) {
            setSingleAmenities(vehicleAmenities.reduce((acc, amenity: AmenityType) => {
                if (amenities[amenity.id] && amenities[amenity.id]?.count) {
                    acc[amenity.id] = amenities[amenity.id].count + amenities[amenity.id]?.returnCount;
                }
                return acc;
            }, {}));
        }
    }, [amenities]);

    return useGbtSingleTrackingEvent(
        "successful_payment",
        {
            booking_id: bookingId,
            bookedAt: new Date().toString(),
            amenities_selected: singleAmenities,
        }
    )
}