import {generatePath} from "react-router-dom"
import {useAppSelector} from "../../hooks/selector/useAppSelector.ts"
import {getTranslatedRoute} from "../functions/getTranslatedRoute.ts"
import useGoTo from "../hooks/useGoTo.ts";

export default function useNavigateToSearchResults() {
    const {locale} = useAppSelector((state) => state?.page)
    const {goTo} = useGoTo()

    const {getPath} = getTranslatedRoute()
    const translatedBooking = getPath("booking")

    const navigateToSearchResults = (fromSlug: string, toSlug: string, date: string, passengers: string) => {
        const path = generatePath(`/:locale/${translatedBooking}/:fromSlug/:toSlug/:date/:passengers`, {
            locale,
            fromSlug,
            toSlug,
            date,
            passengers,
        })
        goTo(path)
    }

    return {
        navigateToSearchResults,
    }
}
