import BookingCashPriceDisplay from "./BookingCashPriceDisplay.tsx"
import BookingPrepaymentPriceDisplay from "./BookingPrepaymentPriceDisplay.tsx"
import BookingCardPriceDisplay from "./BookingCardPriceDisplay.tsx"
import {PaymentMethods} from "../../../../constants/PaymentMethods.ts";

interface BookingPriceDisplayProps {
    paymentMethod: number
    displayPrice: number
    isReturn: boolean
}

const BookingPriceDisplay = (props: BookingPriceDisplayProps) => {
    const {paymentMethod, displayPrice, isReturn} = props

    if (paymentMethod === PaymentMethods.CASH) {
        return <BookingCashPriceDisplay displayPrice={displayPrice} />
    } else if (paymentMethod === PaymentMethods.PREPAYMENT) {
        return <BookingPrepaymentPriceDisplay isReturn={isReturn} displayPrice={displayPrice} />
    } else if (paymentMethod === PaymentMethods.CARD) {
        return <BookingCardPriceDisplay displayPrice={displayPrice} />
    }
}

export default BookingPriceDisplay
