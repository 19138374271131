import {ChangeEvent} from "react"
import Column from "../../../../containers/components/Column.tsx"
import Row from "../../../../containers/components/Row.tsx"
import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx"
import ToggleSwitch from "../../../../../components/generic/ToggleSwitch.tsx"
import CookiesByGroupList from "./CookiesByGroupList.tsx"
import CookieType from "../../../types/Cookie.type.ts"
import {useToggle} from "@getbybus/gbb-green"
import useTranslation from "../../../../translation/hooks/useTranslation";

interface CookiesByGroupProps {
    id: string
    labelText: string
    toggleText?: string
    cannotBeToggled?: boolean
    toggleOptCookies: (e: ChangeEvent<HTMLInputElement>) => void
    optCookies: boolean
    descriptionCollapsed?: boolean
    cookieDescription: string
    cookiesList: CookieType[]
}

function CookiesByGroup(props: CookiesByGroupProps) {
    const {
        id,
        labelText,
        toggleText,
        cannotBeToggled,
        optCookies,
        toggleOptCookies,
        descriptionCollapsed = false,
        cookieDescription,
        cookiesList,
    } = props

    const {value: showCookieDescription, onChange: handleShow} = useToggle(descriptionCollapsed)

    const {
        showDescription: showDescriptionLabel,
        hideDescription: hideDescriptionLabel
    } = useTranslation("cookies") as Record<string, string>

    return (
        <Column
            justify
            className="cookies-consent__models"
        >
            <Row
                justify
                className="cookies-consent__models-item"
            >
                <Column>
                    {labelText}
                    <small onClick={handleShow}>
                        {!showCookieDescription ? showDescriptionLabel : hideDescriptionLabel}
                        <IconChevron
                            width="14"
                            chevronOrientation={!showCookieDescription ? "down" : "up"}
                        />
                    </small>
                </Column>
                <ToggleSwitch
                    id={id}
                    isChecked={optCookies}
                    onChange={toggleOptCookies}
                    label={toggleText}
                    disabled={cannotBeToggled}
                    minimize
                />
            </Row>
            <hr />
            {showCookieDescription && (
                <>
                    {cookieDescription}
                    <CookiesByGroupList data={cookiesList} />
                </>
            )}
        </Column>
    )
}

export default CookiesByGroup
