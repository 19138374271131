import LanguageListItem from "./LanguageListItem";
import useIsDesktop from "../../../../../../../hooks/selector/useIsDesktop.ts";
import {LocaleType} from "../../../../../../../types/Locale.type.ts";
import useTranslation from "../../../../../../../features/translation/hooks/useTranslation.ts";
import Translation from "../../../../../../../features/translation/components/Translation.tsx";
import {useMatches} from "react-router";
import rootExtractor from "../../../../../../../utils/dataset/rootExtractor.ts";
import UrlObjectType from "../../../../../../../types/UrlObject.type.ts";
import {useAppSelector} from "../../../../../../../hooks/selector/useAppSelector.ts";
import {generateLanguageLink} from "../functions/generateLanguageLink.ts";
import {allRouteIds} from "../../../../../../../routing/constants/allRouteIds.ts";

function LanguageDropdown() {
    // TODO Refactor the handling of the current route and language redirect, this was a temporary fix
    const {
        locale,
    } = useAppSelector((state) => state?.page)
    const isDesktop = useIsDesktop();
    const activeLanguages = useTranslation("base.language")
    const className = `select__list select__list--opened select__list--lang ${!isDesktop && 'gb--menu-mobile'}`
    const matches = useMatches();
    const staticPages = ['contact', 'faq', 'termsAndConditions', 'privacyPolicy'];
    const urlObjects: UrlObjectType[] = rootExtractor('urlsObject', true)

    return (
        <ul className={className}>
            {isDesktop && (
                <li className="select__item select__item--title">
                    <span className="gb--emphasize-default">
                        <Translation translationKey="base.title.chooseLang"/>
                    </span>
                </li>
            )}

            {Object.entries(activeLanguages as object).map(([langKey, langTranslation]: [string, string]) => {
                // We are generating the link for the language selection here,
                // if the current page is a static page, we need to get the url from the urlObjects
                // Future on we will add this kind of logic to the booking flow also, needs twig changes
                let route = `/${langKey}`;
                if (staticPages.includes(matches[matches.length-1].id)){
                    // TODO the above comment is targeted for this HELL right here :(
                    route = urlObjects[langKey][matches[matches.length-1].id].url;
                } else if (matches[matches.length-1].id === allRouteIds.staticPages) {
                    route = generateLanguageLink(langKey)
                }


                return (
                    <LanguageListItem
                        key={langKey}
                        linkActiveLanguage={`${route}`}
                        isActive={langKey === locale}
                        langKey={langKey as LocaleType}
                        lang={langTranslation}
                    />
                )
            })}
        </ul>
    );
}

export default LanguageDropdown;