import Column from "../../../features/containers/components/Column.tsx";
import LinePlaceholder from "../../../features/placeholders/components/LinePlaceholder.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import VehicleTypeCardLoader from "../../../components/generic/loaders/VehicleTypeCardLoader";
import {Notification} from "@getbybus/gbb-green";
import useIsDesktop from "../../../hooks/selector/useIsDesktop";
import FilterCategoryLoader from "../../../components/generic/loaders/FilterCategoryLoader";

const SearchResultsLoader = () => {
    const isDesktop = useIsDesktop()

    return (
        <>
            <div className={"gb--booking-background"}>
                <Row justify>
                    {isDesktop && (
                        <div className={"gb--card gb--booking__wrapper gb--booking__preview"}>
                            <div>
                                <div className='gb--row gb--row-justify' key={"vehicle-loader"}>
                                    <div className="gb--column gb--column-center card-width">
                                        <Column>
                                            <Row className="gb--booking__info">
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder height={"20px"}/>
                                                    </div>
                                                </Column>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <LinePlaceholder
                                                        placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                        height={"14px"}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                </Column>
                                            </Row>
                                        </Column>
                                        <div
                                            style={{paddingTop: "1rem"}}
                                            className={"gb--row-full_width gb--booking__contact"}
                                        >
                                            <LinePlaceholder height={"50px"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{paddingTop: '1rem'}}>
                                <div className='gb--row gb--row-justify' key={"vehicle-loader"}>
                                    <div className="gb--column gb--column-center card-width">
                                        <Column>
                                            <Row className="gb--booking__info">
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder height={"20px"}/>
                                                    </div>
                                                </Column>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <LinePlaceholder
                                                        placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                        height={"14px"}
                                                    />
                                                </div>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                    <div>
                                                        <LinePlaceholder
                                                            placeholderClassName={"gb--booking-vehicle-loader__shortText"}
                                                            height={"14px"}
                                                        />
                                                    </div>
                                                </Column>
                                            </Row>
                                            <div
                                                style={{paddingTop: "1rem"}}
                                                className={"gb--row-full_width gb--booking__contact"}
                                            >
                                                <LinePlaceholder height={"50px"}/>
                                            </div>
                                        </Column>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={"gb--vehicle__results gb--booking__fields"}>
                        <FilterCategoryLoader/>
                        <Notification
                            notificationType="redBorder"
                            additionalClass="gb--vehicle__results-fixed-prices-notification gb--card"
                        >
                            <LinePlaceholder height={'30px'}/>
                        </Notification>
                        <VehicleTypeCardLoader/>
                        <VehicleTypeCardLoader/>
                        <VehicleTypeCardLoader/>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default SearchResultsLoader;