import {useAppSelector} from "../../hooks/selector/useAppSelector.ts";
import useGoTo from "../hooks/useGoTo.ts";
import {getTranslatedRoute} from "../functions/getTranslatedRoute.ts";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {generatePath} from "react-router-dom";

export default function useNavigateToContactForm() {
    const {locale} = useAppSelector((state) => state?.page)
    const {goTo} = useGoTo()

    const {getPath} = getTranslatedRoute()
    const contact = getPath("contact")

    const navigateToContactForm = (error?: FetchBaseQueryError) => {
        const path = generatePath(`/${locale}/${contact}`)

        goTo(path, {state: error?.data})
    }

    return {
        navigateToContactForm,
    }
}