import IconMinus from "../../../../../components/generic/icons/IconMinus.tsx";
import IconPlus from "../../../../../components/generic/icons/IconPlus.tsx";
import IconCabCar from "../../../../../components/generic/icons/IconCabCar.tsx";
import PriceDisplay from "../../bookingPriceInfo/PriceDisplay.tsx";
import useIsMobile from "../../../../../hooks/selector/useIsMobile.ts";

interface BookingAmenityCounterProps {
    description: string
    handleIncrement: () => void
    handleDecrement: () => void
    quantity: number
    carRotation: 'left' | 'right'
    price: number
}

const BookingAmenityCounter = (props: BookingAmenityCounterProps) => {
    const {
        description,
        handleDecrement,
        handleIncrement,
        quantity,
        carRotation,
        price
    } = props

    const isMobile = useIsMobile()

    return (
        <div>
            <div className={"gb--amenities-selector"}>
                <div className={"gb--amenities-counter"}>
                    <div
                        className={"gb--amenities-counter__image"}
                        onClick={handleDecrement}
                    >
                        <IconMinus style={{pointer: "cursor"}} width={"20"} height={"20"} backgroundFill={'white'}
                                   fill={"#9b9b9b"}/>
                    </div>
                    <div className={"gb--amenities-counter__number"}>
                        {quantity}
                    </div>
                    <div
                        className={"gb--amenities-counter__image"}
                        onClick={handleIncrement}
                    >
                        <IconPlus style={{pointer: "cursor"}} width={"20"} height={"20"} backgroundFill={'white'}
                                  fill={"#9b9b9b"}/>
                    </div>
                    {!isMobile && (
                        <div className={"gb--amenities-direction"}>
                            <div>
                                <IconCabCar chevronOrientation={carRotation} width={'20'} height={'15'}/>
                            </div>

                                <div>
                                    {description}
                                </div>
                        </div>
                    )}
                </div>
                <div>
                    {isMobile ? (
                        <div className={"gb--amenities-direction"}>
                            <div>
                                <IconCabCar chevronOrientation={carRotation} width={'25'} height={'20'}/>
                            </div>

                            <div>
                                {description}
                            </div>
                        </div>
                    ) : (
                        <PriceDisplay plusIcon price={price * quantity}/>
                    )}

                </div>

            </div>
        </div>
    );
};

export default BookingAmenityCounter;