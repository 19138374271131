import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconChevronDown(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#999',
        viewBox = '0 0 20 20',
        title = 'IconChevronDown',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m8.08203125 11.6074219c.12760417 0 .23697917-.0455729.328125-.1367188l6.41210935-6.41210935c.0911459-.09114583.1367188-.20052083.1367188-.328125s-.0455729-.23697917-.1367188-.328125c-.0911458-.09114583-.2005208-.13671875-.328125-.13671875-.1276041 0-.2369791.04557292-.328125.13671875l-6.08398435 6.08398435-6.1796875-6.17968748c-.09114583-.08203124-.20052083-.12304687-.328125-.12304687s-.23697917.04101563-.328125.12304687c-.04557292.04557292-.0797526.09798178-.10253906.15722657s-.03417969.11621093-.03417969.17089843c0 .06380209.01139323.12532553.03417969.18457032s.05696614.11165364.10253906.15722656l6.5078125 6.4941406c.09114583.0911459.20052083.1367188.328125.1367188z"
                />
            )}
        />

    );
}

export default IconChevronDown;
