import useBookingInfoInput from "../../bookingInfoInputField/hooks/useBookingInfoInput.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import isBefore from "date-fns/isBefore";
import format from "date-fns/format";
import { useEffect, useState } from "react";
import { addHours, isToday, startOfDay, startOfHour } from "date-fns";
import useCachedData from "../../../../searchResults/hooks/useCachedData.ts";
import {toLower} from "lodash";

export const useTimeSelect = () => {
    const {fetchedGbtOriginalArgs: originalArgs} = useCachedData(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const {
        value: pickUpTime,
        updateStore: updatePickUp,
        errorMessage: pickUpErrorMessage,
        displayError: pickUpErrorDisplay
    } = useBookingInfoInput({
        formType: "singleTransferForm",
        field: "pickUpTime",
        fieldValidationMethod: () => pickUpTime !== null,
    });

    const {
        value: returnPickUpTime,
        updateStore: updateReturnPickUp,
        errorMessage: returnPickUpErrorMessage,
        displayError: returnPickUpErrorDisplay,
        clearStore,
    } = useBookingInfoInput({
        formType: "singleTransferForm",
        field: "returnPickUpTime",
        fieldValidationMethod: () => returnPickUpTime !== null,
    });

    const pickUpErrorMessageString = useTranslation(pickUpErrorMessage) as string;
    const returnPickUpErrorMessageString = useTranslation(returnPickUpErrorMessage) as string;

    const pickUpTimeLabel = useTranslation("booking.form.pickUpTime") as string;
    const returnPickUpTimeLabel = useTranslation('booking.form.return') as string + ' ' + toLower(useTranslation("booking.form.pickUpTime") as string);

    const [selectedPickUpTime, setSelectedPickUpTime] = useState<string>(
        isToday(new Date(originalArgs.pickUpDateTime))
            ? startOfHour(addHours(new Date(originalArgs.pickUpDateTime), 2)).toISOString()
            : startOfDay(new Date(originalArgs.pickUpDateTime)).toISOString()
    );

    const [selectedReturnPickUpTime, setSelectedReturnPickUpTime] = useState<string>(selectedPickUpTime);

    const [displayPickUpTime, setDisplayPickUpTime] = useState<string>('');
    const [displayReturnPickUpTime, setDisplayReturnPickUpTime] = useState<string>('');

    const [minPickUpTime, setMinPickUpTime] = useState<string>(
        isToday(new Date(originalArgs.pickUpDateTime))
            ? startOfHour(addHours(new Date(), 2)).toISOString()
            : startOfDay(new Date(originalArgs.pickUpDateTime)).toISOString()
    );

    const [minReturnPickUpTime, setMinReturnPickUpTime] = useState<string>(minPickUpTime);

    useEffect(() => {
        setSelectedPickUpTime(
            isToday(new Date(originalArgs.pickUpDateTime))
                ? startOfHour(addHours(new Date(originalArgs.pickUpDateTime), 2)).toISOString()
                : startOfDay(new Date(originalArgs.pickUpDateTime)).toISOString()
        );

        if (originalArgs.returnPickUpDateTime) {
            setSelectedReturnPickUpTime(
                isBefore(startOfDay(new Date(originalArgs.pickUpDateTime)), startOfDay(new Date(originalArgs.returnPickUpDateTime)))
                    ? startOfDay(new Date(originalArgs.returnPickUpDateTime)).toISOString()
                    : startOfHour(addHours(new Date(originalArgs.pickUpDateTime), 2)).toISOString()
            );
        }

        setMinPickUpTime(
            isToday(new Date(originalArgs.pickUpDateTime))
                ? startOfHour(addHours(new Date(), 2)).toISOString()
                : startOfDay(new Date(originalArgs.pickUpDateTime)).toISOString()
        );

        if (originalArgs.returnPickUpDateTime) {
            setMinReturnPickUpTime(
                isBefore(startOfDay(new Date(originalArgs.pickUpDateTime)), startOfDay(new Date(originalArgs.returnPickUpDateTime)))
                    ? startOfDay(new Date(originalArgs.returnPickUpDateTime)).toISOString()
                    : startOfHour(addHours(new Date(originalArgs.pickUpDateTime), 2)).toISOString()
            );
        }
    }, [pickUpTime, returnPickUpTime]);

    useEffect(() => {
        if (isDropdownOpen) {
            setDisplayPickUpTime(pickUpTime ? format(new Date(pickUpTime), "yyyy-MM-dd HH:mm") : format(new Date(selectedPickUpTime), "yyyy-MM-dd HH:mm"));
            setDisplayReturnPickUpTime(returnPickUpTime ? format(new Date(returnPickUpTime), "yyyy-MM-dd HH:mm") : format(new Date(selectedReturnPickUpTime), "yyyy-MM-dd HH:mm"));
        } else {
            setDisplayPickUpTime(pickUpTime ? format(new Date(pickUpTime), "yyyy-MM-dd HH:mm") : '');
            setDisplayReturnPickUpTime(returnPickUpTime ? format(new Date(returnPickUpTime), "yyyy-MM-dd HH:mm") : '');
        }
    }, [isDropdownOpen, pickUpTime, returnPickUpTime]);

    const handleSelectReturnPickUpTime = (value: Date) => {
        if (isBefore(returnPickUpTime, value)) {
            clearStore();
        }
        updateReturnPickUp(format(value, "yyyy-MM-dd'T'HH:mm:ss"), true, false);
    };

    const handleSelectPickUpTime = (value: Date) => {
        if (isBefore(returnPickUpTime, value)) {
            clearStore();
        }
        updatePickUp(format(value, "yyyy-MM-dd'T'HH:mm:ss"), true, false);
    };

    return {
        updateReturnPickUp,
        isDropdownOpen,
        setIsDropdownOpen,
        selectedPickUpTime,
        selectedReturnPickUpTime,
        minPickUpTime,
        minReturnPickUpTime,
        handleSelectPickUpTime,
        handleSelectReturnPickUpTime,
        pickUpErrorMessageString,
        returnPickUpErrorMessageString,
        displayPickUpTime,
        displayReturnPickUpTime,
        pickUpTimeLabel,
        returnPickUpTimeLabel,
        pickUpErrorDisplay,
        returnPickUpErrorDisplay
    };
};