import FormErrorsType from "../types/FormErrors.type.ts";
import isDate from "date-fns/isDate";
import parseDate from "../../../utils/dateTime/parseDate.ts";
import {isBeforeToday} from "../../../utils/dateTime/isBeforeToday.ts";

interface ValidateEachFormValueAttributes {
    validatePlace: (direction: "to" | "from") => ({
        toAndFromMatching: boolean,
        stationNotSelected: boolean
        isError: boolean
    }),
    departureDate: string,
    numberOfPassengers: number,
}

export default function validateEachFormValue(params: ValidateEachFormValueAttributes): FormErrorsType {
    const {
        validatePlace,
        departureDate,
        numberOfPassengers,
    } = params

    return {
        departureError: validatePlace("from").isError,
        destinationError: validatePlace("to").isError,
        dateError: isBeforeToday(departureDate) || !isDate(parseDate(departureDate)) || departureDate === "",
        passengerError: numberOfPassengers < 1 || numberOfPassengers > 15,
    }

}