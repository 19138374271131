import {useNavigate} from "react-router";
import {useAppSelector} from "../../hooks/selector/useAppSelector.ts";
import {generatePath} from "react-router-dom";

export default function useGoBack() {
    const navigate = useNavigate()
    const {
        locale
    } = useAppSelector((state) => state?.page);

    const goBack = () => {
        // If user has landed directly on additional info screen there
        // will not be any history to go back to so navigate to previous route in stack
        if (history.length > 3) {
            navigate(-1)
        } else {
            window.location.href = generatePath(`/:locale`, { locale });
        }
    }

    return {
        goBack,
    }
}