import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import Column from "../../../features/containers/components/Column.tsx";
import Row from "../../../features/containers/components/Row.tsx";
import IconCheckMarkCircle from "../../../components/generic/icons/IconCheckMarkCircle.tsx";
import IconPax from "../../../components/generic/icons/IconPax.tsx";
import IconSuitcaseV2 from "../../../components/generic/icons/IconSuitcaseV2.tsx";
import useTranslation from "../../../features/translation/hooks/useTranslation.ts";

const SuccessfulBookingVehicleInfo = () => {
    const vehicleType = useAppSelector((state) => state?.vehicleType)
    const upTo = useTranslation('booking.chooseVehicle.upTo') as string;
    const suitcases = useTranslation('booking.chooseVehicle.suitcases') as string;

    return (
        <div>
            <Row className='gb--success-booking__info'>
                <Column>
                    <Row className='gb--success-booking__vehicleContainer'>
                        <Column className='gb--success-booking__name'>
                            <p>
                                {vehicleType.type}
                            </p>
                        </Column>
                        {/*<Column>*/}
                        {/*    <Row center>*/}
                        {/*        {!isMobile &&*/}
                        {/*            <div className='gb--success-booking__divider'>*/}
                        {/*                |*/}
                        {/*            </div>*/}
                        {/*        }*/}

                        {/*        <IconInfo className='gb--success-booking__class'/>*/}

                        {/*        {!isMobile &&*/}
                        {/*            <div>*/}
                        {/*                Vehicle class*/}
                        {/*            </div>*/}
                        {/*        }*/}
                        {/*    </Row>*/}
                        {/*</Column>*/}
                    </Row>
                    <Row className='gb--success-booking__capacity'>
                        <Column className='gb--success-booking__pax'>
                            <Row justify>
                                <IconPax className='gb--success-booking__icon'/>
                                {upTo} {vehicleType.maxPax} pax
                            </Row>
                        </Column>
                        <Column>
                            <Row justify>
                                <IconSuitcaseV2 className='gb--success-booking__icon'/>
                                {vehicleType.maxPax} {suitcases}
                            </Row>
                        </Column>
                    </Row>
                </Column>
                <Column className='gb--success-booking__icons'>
                    <IconCheckMarkCircle width='5rem' height='5rem'/>
                    <div className='gb--success-booking__vehicle'>
                        <img
                            src={vehicleType.imagePath}
                            alt={vehicleType.type}
                        />
                    </div>
                </Column>
            </Row>
            {/*<VehicleCardVehicleInfo vehicleType={vehicleType}/>*/}
        </div>
    );
};
export default SuccessfulBookingVehicleInfo;