import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx"
import Column from "../../../../features/containers/components/Column.tsx"
import Row from "../../../../features/containers/components/Row.tsx"
import useDisplayDuration from "../../../searchResults/features/tripInfo/hooks/useDisplayDuration.ts";
import handleDisplayDate from "../../functions/handleDisplayDate.ts";
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts";
import getCityFromSlug from "../../../searchResults/functions/getCityFromSlug.ts";

interface BookingTransferInfoProps {
    startDate: string
    pickUpTime?: string
    departure: string
    destination: string
    departureSubtitle?: string
    destinationSubtitle?: string
    label: string
    duration: number
}
const BookingTransferInfo = (props: BookingTransferInfoProps) => {
    const {
        label,
        duration,
        departure,
        destination,
        departureSubtitle,
        destinationSubtitle,
    } = props
    const durationLabel = useDisplayDuration(duration);
    const {
        currentSearch: {
            pickUpDateTime,
        }
    } = useAppSelector((state) => state?.searchResults);
    const {
        singleTransferForm: {
            pickUpTime: {
                value: singlePickUpTime
            },
        },
    } = useAppSelector((state) => state?.bookingForm);

    const {isIntegration} = useAppSelector((state) => state?.vehicleType)
    const nativeStartDateTime = singlePickUpTime ? handleDisplayDate(singlePickUpTime, singlePickUpTime) : handleDisplayDate(pickUpDateTime);
    const nativeEndDateTime = singlePickUpTime ? handleDisplayDate(singlePickUpTime, singlePickUpTime, duration) : handleDisplayDate(pickUpDateTime);

    const displayStartDate = isIntegration ? handleDisplayDate(pickUpDateTime, pickUpDateTime) : nativeStartDateTime
    const displayEndDate = isIntegration ? handleDisplayDate(pickUpDateTime, pickUpDateTime, duration) : nativeEndDateTime


    return (
        <CollapsableComponent
            label={label}
            collapsable
            wrapper
            hrClassName={'gb--divider__hr'}
        >
            <Column>
                <Row className="gb--booking__info">
                    <Column>
                        <div className="gb--booking__date">{displayStartDate}</div>
                        <div className="gb--booking__location">{departure}</div>
                        {!isIntegration && (
                            <div className="gb--booking__location">{departureSubtitle}</div>

                        )}
                    </Column>
                </Row>
                <Row className="gb--booking__info">
                    <div className="gb--booking__direction">
                        <hr className={`gb--booking__connection ${!isIntegration ? 'gb--booking__connection-subtitle' : ''}`} />
                    </div>
                    <div className="gb--booking__estimate">
                        {durationLabel}
                    </div>
                </Row>
                <Row>
                    <Column>
                        <div className="gb--booking__date">{displayEndDate}</div>
                        <div className="gb--booking__location">{destination}</div>
                        {!isIntegration && (
                            <div className="gb--booking__location">{destinationSubtitle}</div>
                        )}
                    </Column>
                </Row>
            </Column>
        </CollapsableComponent>
    )
}

export default BookingTransferInfo
