import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconLock(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconLock',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path
                    d="m13 7.25h-1.0625v-3.5c0-1.10469-.8953-2-2-2h-3.875c-1.10469 0-2 .89531-2 2v3.5h-1.0625c-.27656 0-.5.22344-.5.5v6c0 .2766.22344.5.5.5h10c.2766 0 .5-.2234.5-.5v-6c0-.27656-.2234-.5-.5-.5zm-7.8125-3.5c0-.48281.39219-.875.875-.875h3.875c.4828 0 .875.39219.875.875v3.5h-5.625zm7.1875 9.375h-8.75v-4.75h8.75zm-4.8125-2.1719v.8281c0 .0688.05625.125.125.125h.625c.06875 0 .125-.0562.125-.125v-.8281c.12898-.0926.22525-.2237.27495-.3745.04971-.1508.05028-.3135.00164-.4646-.04864-.15119-.14398-.283-.2723-.37651-.12833-.09351-.28301-.14389-.44179-.14389s-.31346.05038-.44179.14389c-.12832.09351-.22366.22532-.2723.37651-.04864.1511-.04807.3138.00164.4646.0497.1508.14597.2819.27495.3745z"
                    fill="#888e9f"
                />
            )}
        />

    );
}

export default IconLock;
