import useGbtSingleTrackingEvent from "./useGbtSingleTrackingEvent.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import useCachedData from "../../../pages/searchResults/hooks/useCachedData.ts";

export default function useLandingOnCheckorderEvent() {
    const {
        fetchedGbtData,
        fetchedGbtOriginalArgs,
    } = useCachedData();

    const {
        id: departureId,
        label: departureLabel,
    } = fetchedGbtData?.data?.dropOffInfo.dropOffStation || {};

    const {
        id: destinationId,
        label: destinationName,
    } = fetchedGbtData?.data?.pickupInfo.pickUpStation || {};

    const {
        type: vehicleType,
        price,
    } = useAppSelector((state) => (state.vehicleType))

    const model = {
        pick_up_station_id: departureId,
        pick_up_station_name: departureLabel,
        drop_off_station_id: destinationId,
        drop_off_station_name: destinationName,
        passengers: fetchedGbtOriginalArgs.numberOfPeople,
        transfer_class: vehicleType,
        total_price: price,
        return_price: price,
    }

    return useGbtSingleTrackingEvent("view_checkorder_page", model)
}