import {useState} from "react";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {GbtCookiesType} from "../types/GbtCookies.type.ts";
import {setCookieState} from "../../../reducers/pageSlice.ts";
import {getCookie, setCookie} from "@getbybus/gbb-green";

/**
 * Handles cookie state interaction
 * initiallyActive {boolean} - applied if there is no cookie
 */
export default function useCookie(cookie: GbtCookiesType, initiallyActive = true) {
    const isActive = useAppSelector((state) => state?.page?.[cookie])
    const dispatch = useAppDispatch()

    const cookieValue = getCookie(cookie)

    const setReduxState = (value: boolean) => dispatch(setCookieState({name: cookie, value}))
    const [cookieActive, setCookieActive] = useState(cookieValue ?? initiallyActive)

    const set = (value: boolean) => {
        setReduxState(value)
        setCookieActive(value)
        setCookie(cookie, value)
    }

    const toggle = () => set(!cookieActive)

    return {
        cookieActive,
        toggle,
        set,
        isActive,
    }
}