import useGbtSingleTrackingEvent from "./useGbtSingleTrackingEvent.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import add from "date-fns/add"
import parseDate from "../../../utils/dateTime/parseDate.ts";
import {PlaceType} from "../../../types/Place.type.ts";
import {AmenityType} from "../../../types/Amenity.type.ts";
import useCachedData from "../../../pages/searchResults/hooks/useCachedData.ts";
import {useEffect, useState} from "react";

export default function useClickBuyEvent() {
    const {
        flightTransferForm: {
            flightNumber: {
                value: {
                    flightNumber,
                },
            }
        },
        contactInformationForm: {
            firstName: {
                value: name,
            },
            lastName: {
                value: lastName,
            },
            email: {
                value: email,
            },
            phoneNumber: {
                value: phone,
            },
        },
        paymentTransferForm: {
            paymentMethod: {
                value: paymentMethod,
            },
        },
        returnFlightTransferForm: {
            returnFlightNumber: {
                value: {
                    returnFlightNumber
                },
            }
        },
        amenities,
        operatorId,
    } = useAppSelector((state) => (state?.bookingForm))

    const {
        duration,
        price,
        type,
        amenities: vehicleAmenities,
    } = useAppSelector((state) => (state?.vehicleType))

    const [singleAmenities, setSingleAmenities] = useState();

    const {
        fromPlace,
        toPlace,
        departureDate,
        returnDate,
    } = useAppSelector((state) => (state.form))

    useEffect(() => {
        if (vehicleAmenities.length) {
            setSingleAmenities(vehicleAmenities.reduce((acc, amenity: AmenityType) => {
                if (amenities[amenity.id] && amenities[amenity.id]?.count) {
                    acc[amenity.id] = amenities[amenity.id].count + amenities[amenity.id]?.returnCount;
                }
                return acc;
            }, {}));
        }
    }, [amenities]);

    const {
        fetchedGbtData,
        fetchedGbtOriginalArgs,
    } = useCachedData();

    const {
        id: pickUpStationId,
        label: pickUpStationName,
        countryShortCode: pickUpCountryShortCode,
    } = fetchedGbtData?.data?.dropOffInfo.dropOffStation || {};

    const {
        id: dropOffStationId,
        label: dropOffStationName,
        countryShortCode: dropOffCountryShortCode,
    } = fetchedGbtData?.data?.pickupInfo.pickUpStation || {};

    const {
        placeId: singlePickupLocationId = '',
        placeName: singlePickUpLocationName = '',
    } = fromPlace as PlaceType

    const {
        placeId: singleDropOffLocationId = '',
        placeName: singleDropOffLocationName = '',
    } = toPlace as PlaceType

    const model = {
        operatorId,
        passengers: fetchedGbtOriginalArgs.numberOfPeople,
        email,
        total_price: price,
        transfer_class: type,
        flight_number: flightNumber,
        first_name: name,
        last_name: lastName,
        phone_number: phone,
        payment_method: paymentMethod,
        return_flight_number: returnFlightNumber,
        is_return: fetchedGbtData?.data?.isRoundTrip,
        /* Stations */
        pick_up_station_id: pickUpStationId,
        pick_up_station_name: pickUpStationName,
        fromCountry: pickUpCountryShortCode,
        drop_off_station_id: dropOffStationId,
        drop_off_station_name: dropOffStationName,
        toCountry: dropOffCountryShortCode,
        return_pick_up_station_id: dropOffStationId,
        return_drop_off_station_id: pickUpStationId,
        /* Outbound Leg */
        pick_up_location_id: singlePickupLocationId,
        pick_up_location_name: singlePickUpLocationName,
        drop_off_location_id: singleDropOffLocationId,
        drop_off_location_name: singleDropOffLocationName,
        pick_up_datetime: departureDate,
        drop_off_datetime:
            (!!duration && departureDate)
                ? add(parseDate(departureDate as string) as Date, {seconds: duration})
                : "",
        /* Return Leg */
        return_pick_up_location_id: singleDropOffLocationId,
        return_pick_up_location_name: singleDropOffLocationName,
        return_drop_off_location_id: singlePickupLocationId,
        return_drop_off_location_name: singlePickUpLocationName,
        return_pick_up_datetime: returnDate,
        // Amenities
        amenities_selected: singleAmenities,
    }
    return useGbtSingleTrackingEvent("click_buy", model)
}