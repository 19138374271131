import IconCheckmark from "../../../../../components/generic/icons/IconCheckmark.tsx";
import IconWarningCircle from "../../../../../components/generic/icons/IconWarningCircle.tsx";

interface ValidPlaceIndicatorProps {
    isValid: boolean,
}

function ValidPlaceIndicator({isValid}: ValidPlaceIndicatorProps) {
    return (
        <div className="search__select__validity-checkmark">
            {isValid ? (
                <div
                    className="search__select__validity-checkmark-icon search__select__validity-checkmark-icon-valid">
                    <IconCheckmark height="15" fill="#7ed321"/>
                </div>
            ) : (
                <div
                    className="search__select__validity-checkmark-icon search__select__validity-checkmark-icon-invalid">
                    <IconWarningCircle height={20} width={20}/>
                </div>
            )}
        </div>
    )
}

export default ValidPlaceIndicator