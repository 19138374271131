import {ReactNode} from "react";

interface PillLabelProps {
    label: string | ReactNode
    color?: 'green' | 'red' | 'blue'
    onClick?: () => void
}
const PillLabel = (props: PillLabelProps) => {
    const {
        label,
        color = 'green',
        onClick
    } = props

    return (
        <div
            onClick={onClick}
            className={`gb--pill-label gb--pill-label__${color}`}
            style={{cursor: onClick ? 'pointer' : "default"}}
        >
            {label}
        </div>
    );
};

export default PillLabel;