import Column from "../../../../containers/components/Column.tsx"
import Row from "../../../../containers/components/Row.tsx"
import IconTimes from "../../../../../components/generic/icons/IconTimes.tsx"
import IconCheckmark from "../../../../../components/generic/icons/IconCheckmark.tsx"
import Tag from "../../../../../components/generic/Tag.tsx"
import CookiesByGroup from "./CookiesByGroup.tsx"
import {requiredCookies} from "../constants/requiredCookies.ts"
import {performanceCookies} from "../constants/performanceCookies.ts"
import useTranslation from "../../../../translation/hooks/useTranslation.ts"

interface ManageCookiePreferencesProps {
    rejectAll: () => void
    acceptAll: () => void
    toggledConsentCookie: boolean
    setToggledConsentCookie: (value: boolean) => void
    toggledPerformancesCookie: boolean
    setToggledPerformancesCookie: (value: boolean) => void
}

function ManageCookiePreferences(props: ManageCookiePreferencesProps) {
    const {
        acceptAll,
        rejectAll,
        setToggledPerformancesCookie,
        setToggledConsentCookie,
        toggledPerformancesCookie,
        toggledConsentCookie,
    } = props

    const toggleCookies = (setCookiesAccepted: (value: boolean) => void, cookiesAccepted: boolean) => () => {
        setCookiesAccepted(!cookiesAccepted)
    }

    const {
        requiredCookies: requiredCookiesTitleLabel,
        performanceCookies: performanceCookiesTitleLabel,
        cookiePolicyDescription,
        requiredCookiesDescription,
        performanceCookiesDescription,
        rejectAll: rejectAllLabel,
        acceptCookies: acceptCookiesLabel,
        alwaysActive: alwaysActiveLabel,
    } = useTranslation("cookies") as Record<string, string>

    return (
        <Column className="cookies-consent__content">
            <Row alignStart>{cookiePolicyDescription}</Row>
            <Row
                className="cookies-consent__actions"
                alignStart
            >
                <Tag
                    tagContent={rejectAllLabel}
                    tagAppearance="default"
                    icon={<IconTimes fill="#070c63" />}
                    onClick={rejectAll}
                />
                <Tag
                    tagContent={acceptCookiesLabel}
                    tagAppearance="default"
                    icon={<IconCheckmark fill="#070c63" />}
                    onClick={acceptAll}
                />
            </Row>
            <Column>
                <CookiesByGroup
                    labelText={requiredCookiesTitleLabel}
                    toggleText={alwaysActiveLabel}
                    id="requiredCookies"
                    optCookies={toggledConsentCookie}
                    toggleOptCookies={toggleCookies(setToggledConsentCookie, toggledConsentCookie)}
                    descriptionCollapsed={true}
                    cannotBeToggled={true}
                    cookieDescription={requiredCookiesDescription}
                    cookiesList={requiredCookies}
                />
                <CookiesByGroup
                    labelText={performanceCookiesTitleLabel}
                    id="performanceCookies"
                    optCookies={toggledPerformancesCookie}
                    toggleOptCookies={toggleCookies(setToggledPerformancesCookie, toggledPerformancesCookie)}
                    cookieDescription={performanceCookiesDescription}
                    cookiesList={performanceCookies}
                />
            </Column>
        </Column>
    )
}

export default ManageCookiePreferences
