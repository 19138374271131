import CookieType from "../../../types/Cookie.type.ts";

interface CookieProps {
    cookie: CookieType,
}

// currently "useless", but if any extra modification of cookie list is needed, it further separates logic (for redirects or whatevs)
function Cookie({cookie}: CookieProps) {
    return (
        <li>
            <small>{cookie.label}</small>
        </li>
    )
}

export default Cookie