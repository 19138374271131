import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import validateEachFormValue from "../functions/validateEachFormValue.ts";
import {setFormErrors} from "../../../reducers/formSlice.ts";
import useCheckValidityOfFromAndToStations from "../features/stations/hooks/useCheckValidityOfFromAndToStations.ts";


export default function useValidateForm() {
    const {
        formErrors,
        departureDate,
        numberOfPassengers,
    } = useAppSelector((state) => (state.form))
    const dispatch = useAppDispatch()
    const {validatePlace} = useCheckValidityOfFromAndToStations()

    const checkForErrors = () => {
        const formErrors = validateEachFormValue({
            validatePlace,
            departureDate,
            numberOfPassengers,
        })

        dispatch(setFormErrors(formErrors))

        return Object.values(formErrors).every((field) => (!field))
    }

    const checkValidForm = () => {
        return Object.values(formErrors).every((field) => (!field))
    }

    return {checkForErrors, checkValidForm}
}