import {useAppSelector} from "../../../hooks/selector/useAppSelector";
import useIsMobile from "../../../hooks/selector/useIsMobile";
import useIsSmallTablet from "../../../hooks/selector/useIsSmallTablet";
import useRouteMatch from "../../../routing/hooks/useRouteMatch";
import {useMatches} from "react-router";

export default function useToggleDOMElements() {
    const {
        mobileOverlayActive
    } = useAppSelector((state) => state?.mobile);

    const matches = useMatches();

    const isMobile = useIsMobile()
    const isSmallTablet = useIsSmallTablet()
    const isBooking = !!matches.find(match => match.id === 'booking');
    const isContact = useRouteMatch('contact');
    const isBookingInfo = useRouteMatch('bookingInfo');
    const isFAQ = useRouteMatch('faq')

    const isForm = isBooking || isContact || isFAQ;

    const hide = (isMobile || isSmallTablet) && (mobileOverlayActive)

    const footer = document.getElementById('footer');
    const popularTransfers = document.getElementById('popular-transfers');
    const station = document.getElementById('station');
    const stationToStation = document.getElementById('station-to-station');
    const privacyPolicy = document.getElementById('privacy-policy-content');
    const termsAndConditions = document.getElementById('terms-and-conditions-content');
    const error = document.getElementsByClassName('page-error')[0];

    if (hide || isForm) {
        if (!!footer && isBookingInfo) {
            footer.classList.add('hide')
        } else {
            footer?.classList.remove('hide');
        }
        if (!!station) {
            station.classList.add('hide')
        }
        if (!!stationToStation) {
            stationToStation.classList.add('hide');
        }
        if (!!popularTransfers) {
            popularTransfers.classList.add('hide');
        }
        if (!!privacyPolicy) {
            privacyPolicy.classList.add('hide');
        }
        if (!!termsAndConditions) {
            termsAndConditions.classList.add('hide');
        }
        if (!!error) {
            error.classList.add('hide');
        }
    } else {
        if (!!footer) {
            footer.classList.remove('hide')
        }
        if (!!station) {
            station.classList.remove('hide')
        }
        if (!!stationToStation) {
            stationToStation.classList.remove('hide')
        }
        if (!!popularTransfers) {
            popularTransfers.classList.remove('hide')
        }
        if (!!privacyPolicy) {
            privacyPolicy.classList.remove('hide');
        }
        if (!!termsAndConditions) {
            termsAndConditions.classList.remove('hide');
        }
        if (!!error) {
            error.classList.remove('hide');
        }
    }
}