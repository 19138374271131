import {ReactNode} from 'react';
import useClickOutside from "../../features/hooks/useClickOutside";

interface ClickOutsideAwareComponentProps {
    className?: string,
    children: ReactNode,
    onClickOutside: () => void,
}

function ClickOutsideAwareComponent({children, onClickOutside, className}: ClickOutsideAwareComponentProps) {
    const ref = useClickOutside(onClickOutside)

    return (
        <div ref={ref} className={className}>
            {children}
        </div>
    );
}

export default ClickOutsideAwareComponent;