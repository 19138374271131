import useRouteMatch from "../../../routing/hooks/useRouteMatch.ts";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";

export default function useFormMethods() {
    const isMobile = useIsMobile()
    const isHomepage = useRouteMatch('homepage');

    const setClassName = () => {
        let className = 'main-hero';

        if (!isHomepage) {
            className += '--small';
        }

        className += ' main-hero--vector'

        if(isMobile) {
            className += " gb--mobile-header-overhead"
        }

        return className;
    };

    return {
        setClassName,
    }
}