import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconWheelchair(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#58627c',
        viewBox = '0 0 16 16',
        title = 'IconWheelchair',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <path xmlns="http://www.w3.org/2000/svg" d="m12.7255985 12.9636364c.0798036 0 .1596071-.0159091.2314304-.0556819l1.5873617-.7911138c.2492225-.1242083.3505666-.4269338.2263584-.6761563-.0007195-.0014438-.001446-.002884-.0021793-.0043208-.1281732-.2511118-.4347094-.3520983-.6870398-.2263411l-1.124501.560432-1.8195212-3.61931814c-.0798036-.16704545-.2633518-.27840909-.4548803-.27840909h-3.63106202l-.1276857-1.01818182h2.738914c.28116315 0 .50909092-.22792776.50909092-.5090909 0-.28116315-.22792777-.50909091-.50909092-.50909091h-2.8665997l-.29527317-2.29886364c.73419276.07954545 1.37262124-.54090909 1.37262124-1.26477273 0-.7-.57458564-1.27272727-1.27685697-1.27272727-.56660528 0-1.07734806.35795455-1.22897483.90681818-.02394107.07954546-.05586249.25454546-.04788213.33409091l.76611418 6.19659091c.03192142.25454545.25537139.45340909.51074278.45340909h3.76672802l1.9073051 3.79431821c.0798036.1670454.2633518.2784091.4548803.2784091zm-6.12891342 2.0363636c1.99508901 0 3.76672802-1.2965909 4.38121552-3.1897727l-.8139964-1.6227273c.1436464 2.0443182-1.51626761 3.7943182-3.56721912 3.7943182-1.97114794 0-3.57519951-1.5988637-3.57519951-3.5636364 0-1.41590907.83793739-2.68863635 2.13873542-3.26136362l-.13566605-1.04204545c-1.81154082.66022727-3.02455494 2.37840909-3.02455494 4.30340907 0 2.5215909 2.06691222 4.5818182 4.59668508 4.5818182z"
                      fill={fill}/>
            )}
        />

    );
}

export default IconWheelchair;
