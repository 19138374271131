import {configureStore} from '@reduxjs/toolkit'
import pageSlice from "./reducers/pageSlice";
import formSlice from "./reducers/formSlice";
import {apiSlice} from "./api/reducers/apiSlice.ts";
import bookingSlice from "./pages/bookingForm/reducers/functions/bookingSlice.ts";
import VehicleTypeSlice from "./reducers/vehicleTypeSlice";
import stripeSlice from "./reducers/stripeSlice.ts";
import mobileSlice from "./reducers/mobileSlice.ts";
import searchResultsSlice from "./pages/searchResults/reducers/functions/searchResultsSlice.ts";

const store = configureStore({
    reducer: {
        page: pageSlice,
        form: formSlice,
        stripe: stripeSlice,
        mobile: mobileSlice,
        searchResults: searchResultsSlice,
        bookingForm: bookingSlice,
        vehicleType: VehicleTypeSlice,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {        
        return getDefaultMiddleware().concat(
            apiSlice.middleware
        )
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;