import {ReactNode, useState} from 'react';
// import IconTimes from "@Icons/IconTimes";
import notificationClassAggregator from "../functions/notificationClassAggregator";
import IconTimes from "../../../components/generic/icons/IconTimes.tsx";

interface NotificationProps {
    displayCondition?: boolean,
    isDismissible?: boolean,
    notificationType?: 'orange' | 'blue' | 'red' | 'large' | 'green' | 'suggestion',
    notificationIcon?: ReactNode,
    children: ReactNode,
    style?: object,
    isBorder?: boolean,
    isRow?: boolean,
    additionalClass?: string,
    isLarge?: boolean,
    isFullWidth?: boolean,
}

function Notification(props: NotificationProps) {
    const {
        isDismissible = false,
        notificationIcon,
        notificationType = 'blue',
        children,
        style = {},
        isRow = false,
        isBorder = false,
        additionalClass = "",
        isLarge = false,
        isFullWidth = false,
    } = props;

    const [display, toggleDisplay] = useState(true);
    const notificationTypeClass = notificationClassAggregator({
        type: notificationType,
        isRow,
        isBorder,
        isLarge,
        isFullWidth,
        additionalClass,
    })

    if (display) {
        return (
            <div className={notificationTypeClass} style={style}>
                {notificationIcon && (
                    <>{notificationIcon}</>
                )}
                {children}
                {isDismissible && (
                    <button
                        type="submit"
                        className="gb--notification-dismiss"
                        onClick={() => toggleDisplay(false)}
                    >
                        <IconTimes width="14" height="14"/>
                    </button>
                )}
            </div>
        )
    }

    return null
}

export default Notification;