import {flattenAndConvertToArray} from "../../utils/object/flattenAndConvertToArray.ts"
import {clientsideRoutingSupportedRouteIds} from "../constants/clientsideRoutingSupportedRouteIds.ts"
import {useMatches} from "react-router"

export default function useIsClientsideRoutingSupported() {
    const clientSupportedRouteIds = flattenAndConvertToArray(clientsideRoutingSupportedRouteIds)
    const matches = useMatches()

    const activeMatchedIds = matches.map(({id}) => id)
    return !!activeMatchedIds.find((id) => clientSupportedRouteIds.includes(id))
}
