import {ButtonHTMLAttributes} from "react";


interface ModalFooterProps {
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
    buttonText: string,
    alternativeActionUrl?: string,
    alternativeActionText?: string,
    mainActionButtonProps?: ButtonHTMLAttributes<HTMLButtonElement>
}

function ModalFooter(props: ModalFooterProps) {
    const {
        handleClick,
        buttonText,
        mainActionButtonProps = {},
        alternativeActionUrl,
        alternativeActionText,
    } = props;

    return (
        <div className="modal-footer gb-modal-footer">
            <div className="gb-modal-footer--actions">
                <button
                    type="button"
                    onClick={handleClick}
                    className="btn btn-default btn--confirm"
                    {...mainActionButtonProps}
                >
                    {buttonText}
                </button>
            </div>
            {!!alternativeActionText && (
                <span className="gb-modal-footer--actions-alternative">
                    <a href={alternativeActionUrl} target="_blank" rel="noreferrer">
                        {alternativeActionText}
                    </a>
                </span>
            )}
        </div>
    );
}

export default ModalFooter;
