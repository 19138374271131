import {useRef} from "react";
import useEventListener from "../../hooks/EventListeners/useEventListener";

/**
 * Adds event listener to execute onClickOutside on clicking the element outside returned ref
 */
export default function useClickOutside(onClickOutside: () => void) {
    const ref = useRef<any>()
    const handleClick = ({target}: any) => {
        if (!ref.current.contains(target) && onClickOutside) {
            onClickOutside()
        }
    }

    useEventListener('mousedown', handleClick)

    return ref
}