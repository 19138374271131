import {PaymentFormPayloadType} from "../types/PaymentFormPayload.type.ts";

const paymentFormPayload = {
    value: 0,
    isValid: false,
    displayError: false,
}

export const paymentFormPaymentMethodPayload: PaymentFormPayloadType = {
    ...paymentFormPayload,
    type: 'paymentMethod',
}