import BookingAmenityCounter from "./BookingAmenityCounter.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface BookingAmenitySelectorDesktopProps {
    price: number
    quantity: number
    returnTrip: boolean
    returnQuantity: number
    handleIncrement: () => void
    handleDecrement: () => void
    handleReturnIncrement: () => void
    handleReturnDecrement: () => void
}

const BookingAmenitySelectorDesktop = (props: BookingAmenitySelectorDesktopProps) => {
    const outboundTransfer = useTranslation('booking.title.outboundTransfer') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const {
        price,
        quantity,
        returnTrip,
        returnQuantity,
        handleIncrement,
        handleDecrement,
        handleReturnIncrement,
        handleReturnDecrement
    } = props

    return (
        <>
            <BookingAmenityCounter
                price={price}
                carRotation={'right'}
                quantity={quantity}
                handleDecrement={handleDecrement}
                handleIncrement={handleIncrement}
                description={outboundTransfer}
            />
            {returnTrip && (
                <BookingAmenityCounter
                    price={price}
                    description={returnTransfer}
                    handleIncrement={handleReturnIncrement}
                    handleDecrement={handleReturnDecrement}
                    quantity={returnQuantity}
                    carRotation={'left'}
                />
            )}
        </>


    );
};

export default BookingAmenitySelectorDesktop;