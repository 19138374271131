import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";
import useIsDesktop from "../../../../hooks/selector/useIsDesktop.ts";
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts";
import {activateReturnCalendar} from "../../../../reducers/mobileSlice.ts";
import BookingAddAdditionalNoteButton from "./BookingAddAdditionalNoteButton.tsx";
import BookingAdditionalNoteField from "./BookingAdditionalNoteField.tsx";
import {useToggle} from "@getbybus/gbb-green";

const BookingAdditionalNoteSection = () => {
    //TODO insert translation
    const {value, onChange} = useToggle(false)
    const additionalNoteLabel = useTranslation("booking.form.additionalNotes") as string

    const descriptionLabel = useTranslation("booking.form.includeAnyAdditionalInstructionsForYourDriver") as string

    const isSmallScreen = !useIsDesktop()

    const dispatch = useAppDispatch()

    const handleClick = () => {
        isSmallScreen ? dispatch(activateReturnCalendar(true)) : onChange()
    }

    return (
            <div className={'gb--booking-item'}>
                {value ? (
                    <BookingAdditionalNoteField handleClick={onChange}/>
                ) : (
                    <div
                        className="gb--return"
                        onClick={onChange}
                    >
                        <div className="gb--return__info">
                            <h3>{additionalNoteLabel}</h3>
                            <p>
                                {descriptionLabel}
                            </p>
                        </div>
                        <BookingAddAdditionalNoteButton handleClick={onChange}/>
                    </div>
                )}
            </div>
    )
}

export default BookingAdditionalNoteSection
