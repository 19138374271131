import {ReactNode, useRef} from "react";
import IconChevron from "../../../components/generic/icons/IconChevron";
import Column from "../../../features/containers/components/Column";

interface AccordionItemProps {
    question: ReactNode,
    answer: ReactNode,
    isOpen: boolean,
    onClick: () => void
}
const AccordionItem = ({question, answer, isOpen, onClick}: AccordionItemProps) => {
    const contentHeight = useRef<HTMLDivElement>(null);
    
    return (
        <Column className="accordion accordion__container">
            <button
                className={`accordion__container-toggle ${isOpen ? "active" : ""}`}
                onClick={onClick}
            >
                <IconChevron chevronOrientation={isOpen ? "right" : "down"}/>
                <span className="accordion__container-toggle-content">{question}</span>
            </button>

            <div
                ref={contentHeight}
                className="accordion__container-collapse"
                style={
                    isOpen
                        ? {height: contentHeight?.current?.scrollHeight}
                        : {height: "0px"}
                }
            >
                <p className="accordion__container-collapse-content">{answer}</p>
            </div>
        </Column>
    );
}

export default AccordionItem;