import {ReactNode, useEffect, RefObject} from 'react';
import {useScroll} from "../hooks/useScroll";

/**
 * Container element that will get scrolled to if it's mounted
 */

interface ScrollToElementProps {
    condition?: any,
    children: ReactNode,
}

function ScrollToElement({condition, children}: ScrollToElementProps) {
    const {executeScroll, elRef} = useScroll({})
    useEffect(executeScroll, [condition])

    return (
        <div ref={elRef as RefObject<HTMLDivElement>} style={{width: "100%"}}>
            {children}
        </div>
    )
}

export default ScrollToElement;