import {useState} from "react";
import useCookies from "../../../hooks/useCookies.ts";

export default function useManageCookies(closeCookieModal: () => void, goFromVehicleSelectToBooking: () => void) {
    const [toggledConsentCookie, setToggledConsentCookie] = useState(true)
    const [toggledPerformancesCookie, setToggledPerformancesCookie] = useState(false)
    const {
        acceptAllCookies,
        rejectAllCookies,
        cookiesConsent,
        hotjarCookie,
    } = useCookies()

    const acceptCurrentCookieState = () => {
        cookiesConsent.set(toggledConsentCookie)
        hotjarCookie.set(toggledPerformancesCookie)
        goFromVehicleSelectToBooking()
        closeCookieModal()
    }

    // reject all rejects all unnecessary cookies, accepts all the necessary ones
    const rejectAll = () => {
        rejectAllCookies()
        goFromVehicleSelectToBooking()
        closeCookieModal()
    }

    const acceptAll = () => {
        acceptAllCookies()
        goFromVehicleSelectToBooking()
        closeCookieModal()
    }

    return {
        toggledConsentCookie,
        setToggledConsentCookie,
        toggledPerformancesCookie,
        setToggledPerformancesCookie,
        acceptCurrentCookieState,
        rejectAll,
        acceptAll,
        acceptAllCookies,
    }
}