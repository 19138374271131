import useFetchDataOnPageRerender from "./useFetchDataOnPageRerender.ts";
import useCachedData from "./useCachedData.ts";

export default function useGetSearchResultsVehiclesData(getLocationsFromParams = false) {
    const {
        fetchedGbtData,
        fetchedGbtIsUninitialized,
        fetchedGbtIsLoading,
        fetchedGbtIsError,
        fetchedGbtError,
        fetchedGbtStatus,
        fetchedGbtOriginalArgs
    } = useCachedData()

    const {
        pageRerenderFetchedGbtData,
        pageRerenderFetchedGbtIsUninitialized,
        pageRerenderFetchedIsLoading,
        pageRerenderFetchedGbtIsError,
        pageRerenderFetchedError,
        pageRerenderFetchedStatus,
        pageRerenderFetchedOriginalArgs
    } = useFetchDataOnPageRerender(fetchedGbtData)

    if (!fetchedGbtData) {
        return {
            data: pageRerenderFetchedGbtData,
            isLoading: pageRerenderFetchedIsLoading,
            isError: pageRerenderFetchedGbtIsError,
            error: pageRerenderFetchedError,
            isUninitialized: pageRerenderFetchedGbtIsUninitialized,
            status: pageRerenderFetchedStatus,
            originalArgs: pageRerenderFetchedOriginalArgs
        }
    }

    return {
        data: fetchedGbtData,
        isLoading: fetchedGbtIsLoading,
        isError: fetchedGbtIsError,
        error: fetchedGbtError,
        isUninitialized: fetchedGbtIsUninitialized,
        status: fetchedGbtStatus,
        originalArgs: fetchedGbtOriginalArgs
    }
}