import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import {StripePaymentElementOptions} from "@stripe/stripe-js";

export default function useGetPaymentElementOptions() {
    const isMobile = useIsMobile();
    const layoutType = !isMobile ? 'accordion' : 'tabs';

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: {
            type: layoutType,
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false,
        },
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    // postalCode: 'never',
                },
            },
        },
    }

    return {
        paymentElementOptions,
    }
}