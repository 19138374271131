import IconChevron from "../../../../../components/generic/icons/IconChevron.tsx";

interface CategoryScrollCtaProps {
    handleClick: (offset: number) => void
    chevronOrientation: "left" | "right"
    offset: number;
}

const CategoryScrollCta = (props: CategoryScrollCtaProps) => {
    const {
        chevronOrientation,
        handleClick,
        offset
    } = props;

    return (
        <div
            onClick={() => {
                handleClick(offset)
            }}
            className={`gb--card-enlarge__small gb--category-chevron gb--category-chevron__${chevronOrientation}`}
        >
            <IconChevron
                fill={'#b5b7d1'}
                height={'60'}
                width={'60'}
                chevronOrientation={chevronOrientation}
            />
        </div>
    );
};

export default CategoryScrollCta;