import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type.ts";

function IconSearch(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#b5b7d1',
        viewBox = "0 0 16 16",
        title = 'SearchIcon',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(.5 .5)">
                    <path
                        d="m14.2714286 14.3c-.3571429.3571429-.9428572.3571429-1.3142857 0l-1.6428572-1.6428571c-.3571428-.3571429-.3571428-.9571429 0-1.3142858.3571429-.3571428.9428572-.3571428 1.3142857 0l1.6428572 1.6428572c.3714285.3571428.3714285.9571428 0 1.3142857z"
                        fill="#7ed321"
                    />
                    <g fill="#070c63">
                        <path
                            d="m5.35714286 10.7142857c-2.95686457 0-5.35714286-2.40406841-5.35714286-5.35136383 0-2.94729543 2.40027829-5.36292187 5.35714286-5.36292187 2.95686456 0 5.35714284 2.40406842 5.35714284 5.35136385 0 2.94729542-2.41187383 5.36292185-5.35714284 5.36292185zm0-10.02080442c-2.57421151 0-4.66141002 2.09200185-4.66141002 4.65788257s2.08719851 4.65788255 4.66141002 4.65788255c2.5742115 0 4.66141004-2.09200183 4.66141004-4.65788255s-2.09879409-4.65788257-4.66141004-4.65788257z"
                            stroke="#070c63"
                            strokeWidth=".625"
                        />
                        <path
                            d="m11.1351833 11.8571429c-.1841283 0-.3452405-.3263081-.4833367-.4734296l-1.70318658-1.81449893c-.27619242-.29424307-.27619242-.50738068 0-.7771035.27619241-.29424307.47625487-.29424307.72943125 0l1.70318653 1.81449893c.2761924.2942431.5134346.7601279.2372422 1.0298508-.1380962.1471215-.3222245.2206823-.4833367.2206823z"
                        />
                        <path
                            d="m13.6285714 15c-.3571428 0-.7-.1428571-.9571428-.4l-1.6428572-1.6428571c-.5285714-.5285715-.5285714-1.3857143 0-1.9142858.2571429-.2571428.6-.4.9571429-.4.3571428 0 .7.1428572.9571428.4l1.6428572 1.6428572c.5285714.5285714.5285714 1.3857143 0 1.9142857-.2571429.2571429-.6.4-.9571429.4zm-1.6428571-3.5c-.1285714 0-.2571429.0571429-.3571429.1428571-.2.2-.2.5142858 0 .7142858l1.6428572 1.6428571c.1857143.1857143.5142857.1857143.7 0 .2-.2.2-.5142857 0-.7142857l-1.6428572-1.6428572c-.0857143-.1-.2142857-.1428571-.3428571-.1428571z"
                        />
                    </g>
                </g>
            )}
        />

    );
}

export default IconSearch;
