import MobileOverlay from "./MobileOverlay.tsx";
import BookingStripeBody
    from "../../../pages/bookingForm/features/bookingStripe/bookingStripePaymentInfo/BookingStripeBody.tsx";
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {toggleStripeMobileOverlay} from "../../../reducers/mobileSlice.ts";

const MobileStripeOverlay = () => {
    const dispatch = useAppDispatch()
    return (
        <MobileOverlay
            title={''}
            isActionSheet
            wrapperClassName={'gb--stripe__wrapper'}
            backClick={() => {
                dispatch(toggleStripeMobileOverlay())
            }}
        >
            <BookingStripeBody/>
        </MobileOverlay>
    )
};

export default MobileStripeOverlay;