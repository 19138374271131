import Cookie from "./Cookie.tsx";
import CookieType from "../../../types/Cookie.type.ts";

interface CookiesListProps {
    data?: CookieType[]
}

const CookiesByGroupList = ({data}: CookiesListProps) => {
    return (
        <ul className="cookies-consent__list">
            {data?.map((cookie) => (
                <Cookie
                    key={cookie.id}
                    cookie={cookie}
                />
            ))}
        </ul>
    )
}

export default CookiesByGroupList;