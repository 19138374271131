import TimeSelect from "./TimeSelect.tsx";
import Row from "../../containers/components/Row.tsx";
import {isSameDay, startOfDay} from "date-fns";
import DateSelect from "./DateSelect.tsx";
import useIsMobile from "../../../hooks/selector/useIsMobile.ts";
import {useEffect} from "react";

interface DateTimeElementsProps {
    selectedDate: string
    selectedTime: string
    changeDate: (value: Date) => void
    changeTime: (value: Date) => void
    handleSubmit: (value: Date) => void
    isClosable?: boolean
    handleClose?: () => void
    toggleActive: (tag) => void
    active: boolean
    minDateTime: string
    maxDateTime?: string
}

const DateTimeElements = (props: DateTimeElementsProps) => {
    const {
        selectedDate,
        selectedTime,
        changeDate,
        changeTime,
        handleSubmit,
        toggleActive,
        active,
        minDateTime,
        maxDateTime
    } = props

    const isMobile = useIsMobile()

    const minTime = isSameDay(new Date(minDateTime), new Date(selectedTime))
        ? minDateTime
        : startOfDay(new Date(selectedTime)).toISOString()

    return (
        <Row style={{justifyContent: "center"}}>
            {!active ? (
                <DateSelect
                    selectedDate={selectedDate}
                    changeDate={changeDate}
                    toggleActive={toggleActive}
                    selectedTime={selectedTime}
                    minDateTime={minDateTime}
                    maxDateTime={maxDateTime}
                />
            ) : (
                <TimeSelect
                    isMobile={isMobile}
                    handleSubmit={handleSubmit}
                    selectedTime={selectedTime}
                    changeTime={(value: Date) => {
                        changeTime(value)
                        if (!isMobile) {
                            handleSubmit(value)
                            toggleActive('date')
                        }
                    }}
                    minTime={minTime}
                    maxTime={maxDateTime}
                />
            )
            }
        </Row>
    );
};

export default DateTimeElements;