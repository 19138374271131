import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "../../../../types/Icon.type";

function IconHamburgerMenu(props: IconType) {
    const {
        width = '22',
        height = '16',
        fill = '#9b9b9b',
        viewBox = '0 0 22 16',
        title = 'IconHamburgerMenu',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill}>
                    <path
                        d="m20.2132896 2.82479784h-18.56322514c-.7012774 0-1.23754835-.5606469-1.23754835-1.29380054 0-.73315363.53627095-1.29380053 1.23754835-1.29380053h18.56322514c.7012774 0 1.2375483.5606469 1.2375483 1.29380053 0 .73315364-.5362709 1.29380054-1.2375483 1.29380054z"/>
                    <path
                        d="m20.5505822 9.29380054h-13.50383635c-.51014493 0-.90025575-.5606469-.90025575-1.29380054s.39011082-1.29380054.90025575-1.29380054h13.50383635c.5101449 0 .9002557.5606469.9002557 1.29380054s-.3901108 1.29380054-.9002557 1.29380054z"/>
                    <path
                        d="m20.2132896 15.7628032h-18.56322514c-.7012774 0-1.23754835-.5606469-1.23754835-1.2938005s.53627095-1.2938005 1.23754835-1.2938005h18.56322514c.7012774 0 1.2375483.5606469 1.2375483 1.2938005s-.5362709 1.2938005-1.2375483 1.2938005z"/>
                </g>
            )}
        />

    );
}

export default IconHamburgerMenu;
