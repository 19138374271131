import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconStopwatch(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = 'none',
        viewBox = '0 0 16 16',
        title = 'IconStopwatch',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill} transform="translate(.5)">
                    <path
                        d="m11.904 4.736c-.16 0-.32-.064-.448-.192-.256-.256-.256-.656 0-.912l.752-.752c.256-.256.656-.256.912 0s.256.656 0 .912l-.752.752c-.128.128-.288.192-.464.192z"
                        fill="#58627c"/>
                    <path
                        d="m2.496 4.736c-.16 0-.336-.064-.448-.192l-.768-.752c-.256-.256-.256-.656 0-.912s.656-.256.912 0l.752.752c.256.256.256.656 0 .912-.128.128-.288.192-.448.192z"
                        fill="#58627c"/>
                    <path
                        d="m7.2 2.768c-.352 0-.64-.288-.64-.64v-1.488c0-.352.288-.64.64-.64s.64.288.64.64v1.488c0 .352-.288.64-.64.64z"
                        fill="#58627c"/>
                    <ellipse cx="7.2" cy="8.832" fill="#f7f7fb" rx="4.768" ry="4.8"/>
                    <ellipse cx="7.2" cy="8.832" fill="#eae6ec" rx="1" ry="1"/>
                    <g fill="#58627c">
                        <path
                            d="m7.2 16c-3.936 0-7.136-3.216-7.136-7.168s3.2-7.184 7.136-7.184 7.136 3.216 7.136 7.184-3.2 7.168-7.136 7.168zm0-13.392c-3.408 0-6.176 2.784-6.176 6.224s2.768 6.208 6.176 6.208 6.176-2.784 6.176-6.208-2.768-6.224-6.176-6.224z"/>
                        <path
                            d="m7.2 8.56c-.272 0-.48-.208-.48-.48v-2.976c0-.272.208-.48.48-.48s.48.208.48.48v2.976c0 .272-.208.48-.48.48z"/>
                        <path
                            d="m7.2 10.048c-.672 0-1.216-.544-1.216-1.216s.544-1.232 1.216-1.232 1.216.544 1.216 1.232c0 .672-.544 1.216-1.216 1.216zm0-1.488c-.144 0-.256.112-.256.272 0 .144.112.256.256.256s.256-.112.256-.256c0-.16-.112-.272-.256-.272z"/>
                        <path
                            d="m8.096 1.28h-1.792c-.352 0-.64-.288-.64-.64s.288-.64.64-.64h1.776c.352 0 .64.288.64.64s-.272.64-.624.64z"/>
                    </g>
                </g>
            )}
        />

    );
}

export default IconStopwatch;












