import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

function IconInfant(props: IconType) {
    const {
        width = '16',
        height = '16',
        fill = '#58627c',
        viewBox = '0 0 16 16',
        title = 'IconInfant',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill={fill}>
                    <g transform="translate(1.507937)">
                        <path
                            d="m6.98412698 12.3174603c-.91428571 0-1.65079365-.7365079-1.65079365-1.6507936 0-.1777778.02539683-.3428572.07619048-.5079365.06349206-.20317464.27936508-.30476195.48253968-.24126988.20317461.06349206.30476191.27936508.24126984.48253968-.02539682.0761905-.03809523.1777778-.03809523.2666667 0 .4952381.39365079.8888889.88888888.8888889.4952381 0 .88888889-.3936508.88888889-.8888889s-.39365079-.88888892-.88888889-.88888892c-.21587301 0-.38095238-.16507937-.38095238-.38095238 0-.21587302.16507937-.38095238.38095238-.38095238.91428572 0 1.65079365.73650793 1.65079365 1.65079368 0 .9142857-.73650793 1.6507936-1.65079365 1.6507936z"/>
                        <circle cx="5.079365" cy="6.730159" r="1"/>
                        <circle cx="8.888889" cy="6.730159" r="1"/>
                        <path
                            d="m4.31746032 11.4285714c-.06349207 0-.13968254-.0126984-.20317461-.0507936-1.04126984-.6476191-1.82857142-1.61269843-2.24761904-2.74285717-1.02857143-.02539682-1.86666667-.86349206-1.86666667-1.9047619s.83809524-1.87936508 1.86666667-1.9047619c.48253968-1.2952381 1.46031746-2.38730159 2.71746031-2.9968254.19047619-.08888889.41904762-.01269841.50793651.17777778s.01269841.41904762-.17777778.5079365c-1.15555555.55873016-2.04444444 1.6-2.41269841 2.81904762-.05079365.17777778-.22857143.2920635-.41904762.26666667-.07619047-.01269841-.12698412-.01269841-.17777778-.01269841-.63492063 0-1.14285714.50793651-1.14285714 1.14285714 0 .63492064.50793651 1.14285714 1.14285714 1.14285714.05079366 0 .10158731 0 .16507937-.01269841.19047619-.02539683.36825397.08888889.41904762.26666667.33015873 1.07936508 1.05396825 2.00634917 2.01904762 2.60317457.17777778.1142857.22857143.3428572.12698412.520635-.06349206.1142857-.19047619.1777777-.31746031.1777777z"/>
                        <path
                            d="m9.65079365 11.4285714c-.12698413 0-.25396825-.063492-.33015873-.1777777-.11428571-.1777778-.05079365-.4190477.12698413-.520635.96507935-.5968254 1.68888885-1.52380949 2.01904765-2.60317457.0507936-.17777778.2285714-.2920635.4190476-.26666667.063492.01269841.1142857.01269841.1650794.01269841.6349206 0 1.1428571-.5079365 1.1428571-1.14285714 0-.63492063-.5079365-1.14285714-1.1428571-1.14285714-.0507937 0-.1015874 0-.1650794.01269841-.1904762.02539683-.368254-.08888889-.4190476-.26666667-.6095238-1.98095238-2.41269845-3.3015873-4.48253972-3.3015873-.66031746 0-1.65079365-.44444444-1.65079365-1.65079365 0-.21587301.16507937-.38095238.38095238-.38095238.21587302 0 .38095239.16507937.38095239.38095238 0 .83809524.73650793.88888889.88888888.88888889 2.2984127 0 4.33015872 1.42222222 5.11746032 3.55555556 1.0285714.02539682 1.8666667.86349206 1.8666667 1.9047619s-.8380953 1.87936508-1.8666667 1.9047619c-.4190476 1.13015874-1.2063492 2.09523807-2.24761905 2.74285717-.06349206.0380952-.12698412.0507936-.2031746.0507936z"/>
                    </g>
                    <path
                        d="m11.031746 6.0952381c-.215873 0-.3809523-.16507937-.3809523-.38095239v-.9015873c0-.16507936.1015873-.31746031.2666666-.36825397 1.6634921-.52063492 2.1238095-1.6 3.1142857-3.00952381.0634921-.08888888.0888889-.19047619.0634921-.29206349s-.0888889-.19047619-.1777778-.24126984l-.0253968-.01269841c-.1650794-.08888889-.3809524-.03809524-.4952381.11428571-1.3333333 1.91746032-3.54285715 3.06031746-5.90476191 3.06031746s-4.57142857-1.14285714-5.91746032-3.06031746c-.11428571-.16507936-.33015873-.21587301-.49523809-.11428571l-.02539683.01269841c-.08888888.05079365-.15238095.13968254-.17777777.24126984-.02539683.1015873 0 .21587302.06349206.29206349.99047619 1.42222223 1.46349206 2.48888889 3.11428571 3.00952381.15238096.05079366.26666667.20317461.26666667.36825397v.9015873c0 .21587302-.16507936.38095239-.38095238.38095239s-.38095238-.16507937-.38095238-.38095239v-.62222222c-1.71428572-.60952381-2.21269841-1.73968254-3.25396825-3.21269841-.1904762-.26666667-.25396826-.5968254-.17777778-.9015873.07619047-.30476191.26666666-.58412699.54603174-.73650794l.02539683-.01269841c.50793651-.27936508 1.14285714-.13968254 1.48571428.34285714 1.21904762 1.71428572 3.2 2.73015873 5.30793651 2.73015873s4.08888891-1.01587301 5.29523811-2.73015873c.3428571-.48253968.9777778-.63492063 1.4857143-.34285714l.0253968.01269841c.2793651.15238095.4825397.41904762.5460317.73650794.0761905.31746032 0 .64761905-.1777777.9015873-1.0412699 1.47301587-1.5396826 2.61587302-3.2539683 3.21269841v.62222222c0 .21587302-.1650794.38095239-.3809524.38095239z"
                        transform="translate(1 9.904762)"/>
                </g>
            )}
        />

    );
}

export default IconInfant;












