import {ChangeEvent} from 'react';
import {useAppDispatch} from "../../../../hooks/dispatch/useAppDispatch.ts";
import useTranslation from "../../../../features/translation/hooks/useTranslation.ts";
import {Column} from "@getbybus/gbb-green";
import IconMessage from "../../../../components/generic/icons/IconMessage.tsx";
import {setComment} from "../../reducers/functions/bookingSlice.ts";
import {useAppSelector} from "../../../../hooks/selector/useAppSelector.ts";
import CollapsableComponent from "../../../../components/generic/CollapsableComponent.tsx";

const BookingAdditionalNoteField = () => {
    //TODO insert translation
    const dispatch = useAppDispatch()
    const commentLabel = useTranslation("booking.form.placeholder") as string
    const additionalComment = useTranslation("booking.form.additionalComment") as string


    const {comment} = useAppSelector((state) => state?.bookingForm)

    return (
        <CollapsableComponent
            label={additionalComment}
            collapsable
            hrClassName={'gb--divider__hr'}
            // customButton={<BookingRemoveAdditionalNoteButton handleClick={handleClick}/>}
            // description={'If you wish to add a note for your driver, you can do it here'}
        >
            <Column className="gb--additional__textarea-container">
                <div className="gb--additional__textarea">
                    <IconMessage />
                    <textarea
                        maxLength={500}
                        value={comment}
                        onInput={(event: ChangeEvent<HTMLTextAreaElement>) => dispatch(setComment(event.target.value))}
                        placeholder={commentLabel}
                    />
                </div>
            </Column>
        </CollapsableComponent>
    );
};

export default BookingAdditionalNoteField;