import Column from "../../containers/components/Column"
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts"
import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts"
import {toggleIsCookieModalOpen as toggleModal} from "../../../reducers/pageSlice.ts"
import useTranslation from "../../translation/hooks/useTranslation.ts"
import useCookies from "../hooks/useCookies.ts";

export default function CookiesNotice() {
    const {userCanSkipCookies} = useAppSelector((state) => state.page)
    const dispatch = useAppDispatch()

    const toggleModalOnButtonClick = () => {
        if (userCanSkipCookies) {
            dispatch(toggleModal())
        }
    }

    const {
        acceptAllCookies
    } = useCookies()

    const {
        websiteUsesCookies,
        youCanConsent,
        acceptCookies: acceptCookiesLabel,
        manageCookies: manageCookiesLabel,
    } = useTranslation("cookies") as Record<string, string>

    return (
        <Column className="cookies-consent__container ">
            <p>
                {websiteUsesCookies}
                <br />
                {youCanConsent}
            </p>
            <button
                type="button"
                className="btn btn--blue-darker btn--mobile"
                onClick={acceptAllCookies}
            >
                {acceptCookiesLabel}
            </button>
            <button
                type="button"
                className="btn btn--blue-darker-outline btn--mobile"
                onClick={toggleModalOnButtonClick}
            >
                {manageCookiesLabel}
            </button>
        </Column>
    )
}
