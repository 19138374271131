import CookieType from "../../../types/Cookie.type.ts";

export const requiredCookies: CookieType[] = [
    {
        id: "sessionCookie",
        label: "Session Cookie",
        url: "#"
    },
    {
        id: "googleAnalytics",
        label: "Google Analytics",
        url: "#"
    }
]