import Row from "../../../../../features/containers/components/Row.tsx"
import Column from "../../../../../features/containers/components/Column.tsx"
import VehicleType from "../../../../../types/VehicleType.type.ts"
import BookingMobileVehiclePreview from "./BookingMobileVehiclePreview.tsx";

const BookingMobileVehicleInfo = ({vehicleType}: {vehicleType: VehicleType}) => {

    return (
        <Column className="gb--booking-vehicle__column">
            <Row
                justify
                center
            >
                <Column
                    justify
                    className="gb--booking-vehicle__mobileInfo"
                >
                    <Row className="gb--booking-vehicle__info">
                        <Column>
                            <BookingMobileVehiclePreview vehicleType={vehicleType} />
                        </Column>
                    </Row>
                </Column>
            </Row>
        </Column>
    )
}


export default BookingMobileVehicleInfo
