import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";

export default function useCheckValidityOfFromAndToStations() {
    const {
        fromPlace,
        toPlace,
    } = useAppSelector((state) => (state.form))

    const validatePlace = (direction: "to" | "from") => {
        const toAndFromMatching = fromPlace?.placeId === toPlace?.placeId
        const stationNotSelected = direction === "to" ? !toPlace?.placeId : !fromPlace?.placeId


        return {
            toAndFromMatching,
            stationNotSelected,
            isError: toAndFromMatching || stationNotSelected,
        }
    }

    return {
        validatePlace,
    }
}