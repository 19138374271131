import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

export const getVehicleTextCTA = (isComplete: boolean, dateSelected: boolean, isIntegration: boolean) => {
    if (isIntegration) {
        if (!isComplete && !dateSelected) {
            return useTranslation('booking.chooseVehicle.confirmInfo') as string;
        } else if (!isComplete && dateSelected) {
            return useTranslation('booking.chooseVehicle.enterFullAddress') as string;
        } else if (isComplete && !dateSelected) {
            return useTranslation('booking.chooseVehicle.selectDate') as string;
        }
    }
    return useTranslation('booking.chooseVehicle.selectTrip') as string;
}
