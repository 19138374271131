import IconChevronDown from "../../../../../../../components/generic/icons/IconChevronDown.tsx";
import LanguageFlag from "./LanguageFlag";
import {ClickOutsideAwareComponent} from "@getbybus/gbb-green";
import LanguageDropdown from "./LanguageDropdown";
import {useToggle} from "../../../../../../../hooks/dispatch/useToggle.ts";
// import useSearchResults from "@SearchResults/hooks/useSearchResults";
import useLocale from "../../../../../../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../../../../../../types/Locale.type.ts";
import useTranslation from "../../../../../../../features/translation/hooks/useTranslation.ts";
import useIsDesktop from "../../../../../../../hooks/selector/useIsDesktop.ts";

function LanguageSwitcher() {
    const showLanguageDropdown = useToggle()
    // const searchResults = useSearchResults() TODO this needs to be revisited, since the backend side needs to handle this which will solve these dummy data as well
    const isNotDesktop = !useIsDesktop()
    const locale = useLocale() as LocaleType;
    const lang = useTranslation(`base.language.${locale}`) as string

    return (
        <li className={isNotDesktop ? 'gb--menu gb--menu-mobile gb--column' : 'gb--menu__item'}>
            <button
                onClick={showLanguageDropdown.onChange}
                className="gb--emphasize-default"
                type="submit"
            >
                {!isNotDesktop && (
                    <>
                        <LanguageFlag
                            lang={lang}
                            langKey={locale}
                        />
                        <IconChevronDown
                            style={{verticalAlign: 'middle', marginLeft: '.5rem'}}
                            stroke="#070c63"
                        />
                    </>
                )}
            </button>
            {(showLanguageDropdown.value) && (
                <ClickOutsideAwareComponent onClickOutside={() => showLanguageDropdown.set(false)}>
                    <LanguageDropdown/>
                </ClickOutsideAwareComponent>
            )}
        </li>
    );
}

export default LanguageSwitcher
