import PriceDisplay from "../../bookingPriceInfo/PriceDisplay.tsx";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";

interface BookingAmenityPriceProps {
    price: number
    isMobile: boolean
}

const BookingAmenityPrice = (props: BookingAmenityPriceProps) => {
    const addFor = useTranslation('booking.form.addFor') as string;
    const freeOfCharge = useTranslation('booking.form.freeOfCharge') as string;
    const free = useTranslation('booking.form.free') as string;


    const {
        price,
        isMobile
    } = props

    const displayString = price > 0 ?  (
        <PriceDisplay
            price={Number(price)}
            additionalIcon={!isMobile && (<span style={{paddingRight: '.5rem'}}>{addFor}</span>)}
        />
    ) : (
        <span style={{paddingRight: '.5rem', fontWeight: "bold"}}>{isMobile ? free : freeOfCharge}</span>
    )

    return (
        <div>
            {displayString}
        </div>
    );
};

export default BookingAmenityPrice;