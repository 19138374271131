import {
    contactFormFirstNamePayload,
    contactFormLastNamePayload,
    contactFormEmailPayload,
    contactFormPhoneNumberPayload
} from "./contactFormPayload.ts";
import {ContactInformationFormType} from "../types/ContactInformationForm.type.ts";

export const contactInformationFormInitialState: ContactInformationFormType = {
    firstName: {
        ...contactFormFirstNamePayload,
        errorMessage: 'booking.form.nameError'
    },
    lastName: {
        ...contactFormLastNamePayload,
        errorMessage: 'booking.form.surnameError'
    },
    email: {
        ...contactFormEmailPayload,
        errorMessage: 'booking.form.emailError'
    },
    phoneNumber: {
        ...contactFormPhoneNumberPayload,
        errorMessage: 'booking.form.mobileError'
    }
}