import Row from "../../../../../features/containers/components/Row.tsx"
import Column from "../../../../../features/containers/components/Column.tsx"
import IconCheckMarkCircle from "../../../../../components/generic/icons/IconCheckMarkCircle.tsx"
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts"
import VehicleType from "../../../../../types/VehicleType.type.ts"
import IconPax from "../../../../../components/generic/icons/IconPax.tsx";
import IconSuitcaseV2 from "../../../../../components/generic/icons/IconSuitcaseV2.tsx";

const BookingMobileVehiclePaymentInfo = ({vehicleType, returnTrip}: {vehicleType: VehicleType, returnTrip: boolean}) => {
    const oneWayTransfer = useTranslation('booking.chooseVehicle.oneWay') as string;
    const returnTransfer = useTranslation('booking.title.returnTransfer') as string;
    const [num, fraction] = vehicleType.price.toString().split(".")
    const trip = returnTrip ? returnTransfer : oneWayTransfer

    return (
        <Column className="gb--booking-vehicle__paymentInfo">
            <Row
                center
                justify
            >
                <Column>
                    <div className="gb--booking-vehicle__price">
                        <b>
                            <span style={{marginTop: "auto", fontSize: "1.2em"}}>{num},</span>
                            <sup style={{fontSize: ".7em", paddingBottom: ".5rem"}}>{fraction}</sup>
                            <span style={{marginTop: "auto", fontSize: "larger"}}> &#8364;</span>
                        </b>
                    </div>
                    <div className='gb--vehicle__pax'>
                        <span>{vehicleType.maxPax}</span><IconPax/>
                        <span>{vehicleType.maxPax}</span><IconSuitcaseV2/>
                    </div>
                    <span style={{fontSize: '14px'}} className="gb--emphasize-inactive ">{trip}</span>
                </Column>
            </Row>
            <Row>
                <IconCheckMarkCircle
                    width="5rem"
                    height="5rem"
                />
            </Row>
        </Column>
    )
}

BookingMobileVehiclePaymentInfo.propTypes = {}

export default BookingMobileVehiclePaymentInfo
