import GenericSvgIcon from "./GenericSvgIcon";
import IconStarOutline from "./IconStarOutline";
import IconType from "../../../types/Icon.type.ts";

interface IconStarRatingProps extends IconType {
    ratingValueWidth: number,
    outlined?: boolean,
    color?: string
    outlinedColor?: string
    outlinedStrokeWidth?: string
}

function IconStarRating(props: IconStarRatingProps) {
    const {
        width = '20',
        height = '20',
        fill = '#fff',
        viewBox = '0 0 20 20',
        title = 'IconStarPolygon',
        ratingValueWidth,
        outlined = false,
        outlinedColor = '#070c63',
        outlinedStrokeWidth = '.1',
        outlinedFill = '#070c63',
        color = '#f5a623',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <>
                    <defs>
                        <clipPath id="rating--star">
                            <polygon
                                points="15.194 18.947, 14.625 18.805, 10.028 16.375, 9.675 16.375, 5.077 18.805, 3.797 18.715, 3.312 17.527, 4.185 12.411, 4.055 12.011, 0.365 8.395, 0.060 7.153, 1.038 6.330, 6.131 5.586, 6.474 5.336, 8.760 0.679, 9.851 0.000, 9.852 0.000, 10.942 0.679, 13.228 5.335, 13.572 5.586, 18.666 6.330, 19.643 7.153, 19.339 8.393, 15.647 12.011, 15.517 12.410, 16.390 17.527, 15.906 18.715, 15.194 18.947"
                            />
                        </clipPath>
                    </defs>
                    <rect
                        width={`${100}%`}
                        height="20"
                        clipPath="url(#rating--star)"
                        fill={fill}
                    />
                    <rect
                        width={`${ratingValueWidth}%`}
                        height="20"
                        clipPath="url(#rating--star)"
                        fill={color}
                    />
                    {outlined &&
                        <IconStarOutline
                            stroke={outlinedColor}
                            strokeWidth={outlinedStrokeWidth}
                            fill={outlinedFill}
                            width={width + 2}
                            height={height + 2}
                        />
                    }

                </>
            )}
        />
    );
}

export default IconStarRating;
