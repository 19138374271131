import {useAppSelector} from "./useAppSelector";

/**
 * Returns combined values of isMobile, isTablet & isSmallTablet
 */
export default function useIsDesktop(): boolean {
    const {
        isMobile,
        isTablet,
        isSmallTablet,
    } = useAppSelector((state) => state?.page)

    return !isMobile && !isTablet && !isSmallTablet
}