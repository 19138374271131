import {ReactNode} from 'react';

interface CardProps {
    children: ReactNode;
    additionalClass?: string;
}
const Card = (props: CardProps) => {
    const {
        children,
        additionalClass
    } = props;

    return (
        <div className={`gb--card ${additionalClass}`}>
            {children}
        </div>
    );
};

export default Card;