import {ReactNode} from 'react';
import IconCheckMarkCircle from "../../../../../components/generic/icons/IconCheckMarkCircle.tsx";
import IconPlus from "../../../../../components/generic/icons/IconPlus.tsx";
import BookingAmenityInfo from "./BookingAmenityInfo.tsx";
import BookingAmenityPrice from "./BookingAmenityPrice.tsx";
import {BookingAmenityType} from "../../../reducers/types/BookingAmenity.type.ts";

interface BookingAmenityFieldProps {
    id: number
    selected: boolean
    handleClick: () => void
    value: boolean
    amenities: BookingAmenityType
    AmenityIcon: ReactNode
    name: string,
    description?: string
    price: number
    isMobile: boolean
}

const BookingAmenityField = (props: BookingAmenityFieldProps) => {

    const {
        id,
        name,
        price,
        value,
        isMobile,
        selected,
        amenities,
        AmenityIcon,
        description,
        handleClick
    } = props

    return (
        <div
            className={`gb--amenities-wrapper ${selected ? "gb--amenities-wrapper__selected" : ''}`}
            key={id}
            onClick={handleClick}
        >
            <div className={"gb--amenities-content"}>
                <div className={`gb--amenities-add ${selected ? "gb--amenities-add__selected" : ''}`}>
                    {(value || (amenities[id] && (amenities[id].count !== 0 || amenities[id].returnCount !== 0))) ? (
                        <IconCheckMarkCircle width={'24'} height={'23'} fill={"#7ed321"}/>
                    ) : (
                        <IconPlus backgroundFill={'white'} width={'21'} height={'20'} fill={"black"}/>
                    )}
                </div>
                <BookingAmenityInfo
                    name={name}
                    AmenityIcon={AmenityIcon}
                    description={description}
                />
            </div>
            <div>
                <BookingAmenityPrice
                    price={price}
                    isMobile={isMobile}
                />
            </div>
        </div>
    );
};

export default BookingAmenityField;