import {FlightFormPayloadType} from "../types/FlightFormPayload.type.ts";

const flightNumberFormPayload = {
    value: '',
    isValid: false,
    displayError: false,
}

export const flightNumberPayload: FlightFormPayloadType = {
    ...flightNumberFormPayload,
    type: 'flightNumber',
}

export const returnFlightNumberPayload: FlightFormPayloadType = {
    ...flightNumberFormPayload,
    type: 'returnFlightNumber',
}