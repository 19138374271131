import VehicleCardsPortal from "./components/VehicleCardsPortal";
import {FAQ} from "./components/FAQ.tsx";

const StationToStationPage = () => {
    return (
        <>
            {window.vehicles && (
                <VehicleCardsPortal />
            )}
            {document.getElementById('faq-portal') && (
                <FAQ/>
            )}
        </>
    )
}

export default StationToStationPage;