import MatchType from "../../../types/Match.type.ts";
import {ReactNode} from "react";
import BookingNavigationItemMobile from "./BookingNavigationItemMobile.tsx";
import BookingNavigationItemDesktop from "./BookingNavigationItemDesktop.tsx";
import useIsDesktop from "../../../hooks/selector/useIsDesktop.ts";

interface BookingNavigationItemProps {
    id: string
    matches: MatchType[]
    title: string
    subtitle: string
    children?: ReactNode
    order: number
    goBack?: () => void
}

const BookingNavigationItem = (props: BookingNavigationItemProps) => {
    const {
        id,
        matches,
        title,
        subtitle,
        children,
        order,
        goBack
    } = props
    const isDesktop = useIsDesktop()


    return ( !isDesktop ? (
            <BookingNavigationItemMobile
                id={id}
                matches={matches}
                title={title}
                subtitle={subtitle}
                goBack={goBack}
            >
                {children}
            </BookingNavigationItemMobile>
        ) : (
            <BookingNavigationItemDesktop
                title={title}
                id={id}
                matches={matches}
                order={order}
            >
                {children}
            </BookingNavigationItemDesktop>
        )
    );
};
export default BookingNavigationItem;