import {ReactNode} from 'react';

interface ModalContentProps {
    className?: string,
    children: ReactNode,
}

function ModalContent({className, children}: ModalContentProps) {
    return (
        <div className="modal gb-modal fade in" style={{display: 'block'}}>
            <div
                className={`modal-dialog gb-modal-dialog ${className}`}
                tabIndex={-1}
                role="dialog"
            >
                <div className="modal-content gb-card gb-modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default ModalContent;
