import lodash from "lodash";
import {useCallback} from "react";

export default function useDebounce(effect: (arg?: any) => void, time = 500, deps: any[] = []) {
    const lodashDebounce = lodash.debounce((event) => {
        effect(event)
    }, time);

    const debounce = useCallback((event: any = undefined) => lodashDebounce(event), deps)

    return {
        debounce,
    }
}