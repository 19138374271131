import GenericSvgIcon from "../GenericSvgIcon";
import IconType from "../../../../types/Icon.type";


function IconBusPlot(props: IconType) {
    const {
        width = '65',
        height = '85',
        viewBox = '0 0 65 85',
        title = 'IconBusPlot',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" transform="translate(0 11)">
                    <path
                        d="m57.738386 65.2127387h-55.36557562v-46.1379797c0-7.2502539 7.11843116-13.18227988 15.81873592-13.18227988h23.7281038c8.7003048 0 15.8187359 5.93202598 15.8187359 13.18227988z"
                        fill="#c5eb9b"
                    />
                    <path
                        d="m41.9196501 2.49936027h-23.7281038c-8.70030476 0-15.81873592 6.44099379-15.81873592 14.31331953v28.6266391h55.36557562v-28.6266391c0-7.87232574-7.1184311-14.31331953-15.8187359-14.31331953z"
                        fill="#b5b7d1"
                    />
                    <path
                        d="m41.9196501 2.56263522h-11.8640519v1.39222452h7.909368v2.78444904c0 1.18339085-1.0282179 2.08833679-2.3728104 2.08833679h-11.0731152c-1.3445925 0-2.3728103.90494594-2.3728103 2.08833683 0 1.1833908 1.0282178 2.0883367 2.3728103 2.0883367h5.5365576v52.2084196h27.6827878v-48.7278583c0-7.65723483-7.1184311-13.92224518-15.8187359-13.92224518z"
                        fill="#070c63"
                    />
                    <path
                        d="m57.738386 23.2930886-15.0277991-.4152418-13.4424102 22.8017191h-13.0149529c-1.6221762 0-2.8626639.8514929-2.8626639 1.9649836s1.2404877 1.9649836 2.8626639 1.9649836h8.0477593c.7775438 0 1.4078675.6303238 1.4078675 1.4078675 0 .2738184-.0798484.5416943-.2297674.7708249l-8.9491923 13.677613 41.2084951-.2530998z"
                        fill="#7ed321"
                    />
                    <g fill="#fff">
                        <path
                            d="m55.3655756 43.8574453h-50.61995483v-27.2858129c0-2.0648723 1.74006095-3.687272 3.95468397-3.687272h42.71058696c2.214623 0 3.9546839 1.6223997 3.9546839 3.687272z"
                        />
                        <path
                            d="m10.2821783 54.1396236c-.1581873 0-.31637468 0-.47456204-.0790937-.15818736 0-.31637472-.0790937-.47456208-.1581873-.15818736-.0790937-.23728104-.1581874-.3954684-.2372811-.15818736-.0790936-.23728104-.1581873-.31637472-.3163747-.47456207-.4745621-.71184311-1.0282178-.71184311-1.6609673 0-.1581873 0-.3163747.07909368-.474562 0-.1581874.07909368-.3163748.15818736-.4745621.07909368-.1581874.15818736-.3163747.23728104-.3954684.07909367-.1581874.15818735-.237281.31637471-.3954684s.23728104-.237281.31637472-.3163747c.15818736-.0790937.23728104-.1581874.3954684-.2372811.15818736-.0790936.31637472-.0790936.47456208-.1581873.79093676-.1581874 1.58187356.0790937 2.13552936.6327494.0790937.0790937.237281.2372811.3163747.3954684.0790937.1581874.1581874.2372811.237281.3954684.0790937.1581874.0790937.3163747.1581874.4745621 0 .1581873.0790937.3163747.0790937.4745621 0 .1581873 0 .3163747-.0790937.474562 0 .1581874-.0790937.3163748-.1581874.4745621-.0790936.1581874-.1581873.3163747-.237281.3954684-.0790937.1581874-.1581874.2372811-.3163747.3163747-.4745621.6327495-1.1073115.8700305-1.740061.8700305z"
                        />
                        <path
                            d="m49.8290181 54.1396236c-.6327495 0-1.2654989-.237281-1.6609673-.7118431-.4745621-.4745621-.7118431-1.0282178-.7118431-1.6609673 0-.1581873 0-.3163747.0790937-.4745621 0-.1581873.0790936-.3163747.1581873-.474562.0790937-.1581874.1581874-.3163747.2372811-.3954684.0790936-.1581874.1581873-.2372811.3163747-.3954684.5536557-.5536558 1.3445925-.7909368 2.1355293-.6327495.1581874 0 .3163747.0790937.4745621.1581874s.3163747.1581874.3954684.237281c.1581874.0790937.237281.1581874.3163747.3163748.0790937.0790936.2372811.237281.3163747.3954684.0790937.1581873.1581874.237281.2372811.3954683.0790937.1581874.0790937.3163748.1581873.4745621 0 .1581874.0790937.3163747.0790937.4745621 0 .6327494-.237281 1.2654989-.7118431 1.6609673-.0790937.0790937-.237281.237281-.3163747.3163747-.1581874.0790937-.2372811.1581873-.3954684.237281-.1581874.0790937-.3163747.0790937-.4745621.1581874-.3163747-.0790937-.4745621-.0790937-.6327494-.0790937z"
                        />
                    </g>
                    <path
                        d="m52.9195732 19.0062112h-7.017678c-1.3255614 0-2.729097.847369-3.3139035 2.4030842-.389871 1.0371434-4.6134735 7.9320666-12.6708074 20.6847695l25.3416149.1419599v-20.8267294c0-1.3617477-1.0136646-2.4030842-2.339226-2.4030842z"
                        fill="#e9eef4"
                    />
                    <path
                        d="m42.5879917 0c-.3623902 0-.7265706 0-1.0925412 0-6.8172014 0-14.2556022 0-22.3152024 0-.2375193 0-.5950122 0-1.0724787 0-9.99863788 0-18.1077694 7.18211649-18.1077694 16.0377358v48.8105005c0 1.1853978 1.02348262 2.0918785 2.36188297 2.0918785h2.36188297v4.8810501c0 1.1853979 1.02348262 2.0918786 2.36188296 2.0918786h3.9364716c1.3384004 0 2.361883-.9064807 2.361883-2.0918786v-4.8810501h33.0663615v4.8810501c0 1.1853979 1.0234827 2.0918786 2.361883 2.0918786h3.9364716c1.3384004 0 2.361883-.9064807 2.361883-2.0918786v-4.8810501h2.361883c1.3384003 0 2.3618829-.9064807 2.3618829-2.0918785v-48.8105005c0-7.32157501-4.4687929-16.0377358-17.2463768-16.0377358zm6.6873706 62.6501035h-38.0124223-6.33540377v-16.8944099h49.97929607v16.8944099zm5.63147-21.1180124h-49.97929607v-25.5632237c0-6.49444059 6.01863357-11.74526492 13.46273297-11.74526492h23.7577639c7.4440994 0 12.7587992 5.25082433 12.7587992 11.74526492z"
                        fill="#070c63"
                    />
                </g>
            )}
        />

    );
}

export default IconBusPlot;
