import useTranslation from "../hooks/useTranslation.ts";

interface TranslationProps {
    translationVariables?: object,
    translationKey: string,
    styleText?: 'upperCase' | 'lowerCase' | 'none',
    exact?: boolean,
}

const Translation = (props: TranslationProps) => {
    const {
        styleText = 'none',
        translationKey,
        translationVariables = {},
        exact = false,
    } = props

    const translated = useTranslation(translationKey, translationVariables, exact) as string
    const styledTranslation = {
        [`${styleText === 'upperCase'}`]: translated?.toUpperCase(),
        [`${styleText === 'lowerCase'}`]: translated?.toLowerCase(),
        [`${styleText === 'none'}`]: translated,
    }

    return (
        // react/jsx-no-useless-fragment
        <>
            {styledTranslation?.true ?? translationKey}
        </>
    )
}

export default Translation