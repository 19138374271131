import {toLower} from "lodash";
import useLocale from "../../hooks/selector/useLocale.ts";
import {LocaleType} from "../../types/Locale.type.ts";
import {generatePath} from "react-router-dom";
import useGoTo from "../hooks/useGoTo.ts";

export default function useNavigateToHome(forceRedirect: boolean = false) {
    const locale = toLower(useLocale()) as LocaleType;
    const {goTo} = useGoTo(forceRedirect)


    const navigateToHome = () => {
        const path = generatePath("/:locale", {locale})
        goTo(path)
    }

    return {
        navigateToHome,
    }
}