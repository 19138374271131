import IconSignpost from "../../../../../components/generic/icons/filledOutline/IconSignpost.tsx";
import IconPaxWithLuggage from "../../../../../components/generic/icons/filledOutline/IconPaxWithLuggage";
import TransportationIcons from "../../../../../components/generic/icons/filledOutline/TransportationIcons";
import useIsMobile from "../../../../../hooks/selector/useIsMobile";
import IconGroupOfPeople from "../../../../../components/generic/icons/filledOutline/IconGroupOfPeople";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import Translation from "../../../../../features/translation/components/Translation.tsx";

const HeroVectorLarge = () => {
    const mainTitleLabel = useTranslation('base.title.mainTagline') as string
    const isMobile = useIsMobile()
    const style = {fontSize: (!isMobile && mainTitleLabel.length > 30) ? '2vmax' : ''}
    return (
        <>
            <h1 className="hero__title hero__title--container" style={style}>
                {mainTitleLabel}
            </h1>
            <div className="hero__icons">
                <span>
                    <IconSignpost
                        viewBox="0 0 85 80"
                        width="100%"
                        preserveAspectRatio="xMinYMax meet"
                    />
                </span>
                <span>
                    <IconPaxWithLuggage
                        viewBox="0 0 85 85"
                        width="100%"
                        preserveAspectRatio="xMinYMax meet"
                    />
                </span>
                <span/>
                <span>
                    <IconGroupOfPeople
                        width="100%"
                        preserveAspectRatio="xMinYMax meet"
                        style={{transform: "scale(1.1)"}}
                    />
                </span>
                <TransportationIcons viewBox="0 0 85 80" width="100%" preserveAspectRatio="xMinYMax meet"/>
            </div>
            <h2 className="hero__subtitle hero__title--container">
                <Translation translationKey="base.title.subTagline"/>
            </h2>
        </>
    );
}

export default HeroVectorLarge;