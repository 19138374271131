import {useAppDispatch} from "../../../hooks/dispatch/useAppDispatch.ts";
import {
    clearData,
    setDestinationLocation,
    setNumberOfPeople,
    setStartLocation
} from "../../../pages/bookingForm/reducers/functions/bookingSlice.ts";
import {useAppSelector} from "../../../hooks/selector/useAppSelector.ts";

export default function useClearBookingFormOnNewSearch() {
    const dispatch = useAppDispatch()
    const {
        fromStation,
        toStation,
        numberOfPassengers,
    } = useAppSelector((state) => (state.form))

    const clearBookingFormOnNewSearch = () => {
        dispatch(clearData())
        dispatch(setStartLocation(fromStation?.label || ''));
        dispatch(setDestinationLocation(toStation?.label || ''));
        dispatch(setNumberOfPeople(numberOfPassengers));
    }

    return {
        clearBookingFormOnNewSearch
    }
}