import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type.ts";

function IconWarningCircle(props: IconType) {
    const {
        width = '20',
        height = '20',
        fill = '#f5a623',
        viewBox = '0 0 20 20',
        title = 'IconWarningCircle',
        backgroundColor = '#f5a623',
        ...rest
    } = props
    return (
        <GenericSvgIcon
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none">
                    <path
                        d="m0 10c0-5.52196695 4.47803305-10 10-10 5.5219669 0 10 4.47803305 10 10 0 5.5219669-4.4780331 10-10 10-5.52196695 0-10-4.4780331-10-10z"
                        fill="#fbe0b2"/>
                    <path
                        d="m9.04877066 10.6529625-.2821443-4.2281338c-.05239823-.8222491-.08061266-1.41475212-.08061266-1.77347844 0-.48770657.12898025-.87061668.38291012-1.14066908.2579605-.27408303.59250302-.41112455 1.01168888-.41112455.5078597 0 .8464328.17331721 1.0157194.52398227.1692866.35066505.2539299.85449415.2539299 1.5114873 0 .38694075-.0201532.78194276-.0604595 1.18097541l-.3788795 4.34905279c-.0403063.5199516-.1289802.9149537-.2660218 1.1930673-.1370415.2781137-.3587263.4151552-.669085.4151552-.31841999 0-.54010479-.1330109-.66102378-.4030633-.12494961-.2660217-.21362354-.6731157-.26602176-1.2172511zm.97944374 5.8041113c-.35872629 0-.67311565-.1168884-.93913741-.3466345-.26602177-.2337767-.40306329-.5562273-.40306329-.9754131 0-.3667876.12898025-.6771463.38291012-.9310762.2579605-.2579605.56831923-.3829101.93913748-.3829101.3708182 0 .6852075.1289803.9471987.3829101.2619911.2539299.3909714.5683192.3909714.9310762 0 .4111245-.1330109.7335752-.3990327.9713825-.2579605.2337767-.5683192.3506651-.9189843.3506651z"
                        fill="#f5a623"/>
                </g>
            )}
        />

    );
}

export default IconWarningCircle;
