import {useAppSelector} from "../../../../../hooks/selector/useAppSelector.ts";
import {useAppDispatch} from "../../../../../hooks/dispatch/useAppDispatch.ts";
import {
    setDeparturePlaceInput,
    setDestinationPlaceInput,
    toggleDeparturePlaceInput,
    toggleDestinationPlaceInput
} from "../../../../../reducers/mobileSlice.ts";
import useTranslation from "../../../../../features/translation/hooks/useTranslation.ts";
import useIsOverlaySubtreeActive from "../../../../../features/mobileOverlays/hooks/useIsOverlaySubtreeActive.ts";


export default function useMobileStationInput(direction: "departure" | "destination") {
    const {
        pickUpLocation: pickUpLocationLabel,
        destinationLocation: destinationLocationLabel,
    } = useTranslation("search.form") as Record<string, string>

    const {
        fromPlace,
        toPlace,
        formErrors: {
            departureError,
            destinationError
        },
        submitTimestamp
    } = useAppSelector((state) => (state?.form))

    const isOverlaySubtreeActive = useIsOverlaySubtreeActive()
    const dispatch = useAppDispatch()

    const isDeparture = direction === "departure"
    const directionLabel = isDeparture ? pickUpLocationLabel : destinationLocationLabel

    const className = isDeparture
        ? 'search__select search__select--from'
        : 'search__select search__select--to'

    const onClick = () => {
        if (isOverlaySubtreeActive) {
            isDeparture
                ? dispatch(setDeparturePlaceInput(true))
                : dispatch(setDestinationPlaceInput(true))
        } else {
            dispatch(isDeparture
                ? toggleDeparturePlaceInput()
                : toggleDestinationPlaceInput())
        }
    }

    const place = isDeparture ? fromPlace : toPlace

    const error = direction === 'departure' ? departureError : destinationError
    const errorMessage = direction === 'departure' ? 'search.form.departureError' : 'search.form.destinationError'

    return {
        directionLabel: directionLabel.toUpperCase(),
        place,
        className,
        onClick,
        direction: direction === "departure" ? 'from' : 'to',
        error,
        errorMessage,
        submitTimestamp
    }
}