import GenericSvgIcon from "./GenericSvgIcon";
import IconType from "../../../types/Icon.type";

interface IconTimesCircleProps extends IconType {
    backgroundFill?: string,
}

function IconTimesCircle(props: IconTimesCircleProps) {
    const {
        width = '20',
        height = '20',
        fill = '#9b9b9b',
        backgroundFill = '#f5f5f5',
        viewBox = '0 0 20 20',
        title = 'IconTimesCircle',
        ...rest
    } = props

    return (
        <GenericSvgIcon
            width={width}
            height={height}
            viewBox={viewBox}
            title={title}
            {...rest}
            svgIconPath={(
                <g fill="none" fillRule="evenodd">
                    <circle cx="10" cy="10" fill={backgroundFill} r="10"/>
                    <path
                        d="m14.0942029 14.6666667c.1594203 0 .294686-.0555556.4057971-.1666667s.1666667-.2463768.1666667-.4057971-.057971-.2922705-.1739131-.3985507l-3.6811594-3.6956522 3.6811594-3.69565217c.1159421-.1062802.1739131-.23913044.1739131-.39855073s-.0555556-.29468599-.1666667-.4057971-.2463768-.16666667-.4057971-.16666667-.2922705.05797102-.3985507.17391305l-3.6956522 3.68115942-3.69565217-3.68115942c-.1062802-.11594203-.23913044-.17391305-.39855073-.17391305s-.29468599.05555556-.4057971.16666667-.16666667.24637681-.16666667.4057971.05797102.29227053.17391305.39855073l3.68115942 3.69565217-3.68115942 3.6956522c-.11594203.1062802-.17391305.2391304-.17391305.3985507s.05555556.294686.16666667.4057971.24637681.1666667.4057971.1666667.29227053-.057971.39855073-.1739131l3.69565217-3.6956522 3.6956522 3.6956522c.1062802.1159421.2391304.1739131.3985507.1739131z"
                        fill={fill}
                        fillRule="nonzero"
                    />
                </g>
            )}
        />

    );
}

export default IconTimesCircle;
