import {LocationFormPayloadType} from "../types/LocationFormPayload.type.ts";

const transferLocationFormPayload = {
    value: '',
    isValid: false,
    displayError: false,
}

export const transferPickUpAddressLocationFormPayload: LocationFormPayloadType = {
    ...transferLocationFormPayload,
    type: 'pickUpAddress',
}

export const transferDropOffAddressLocationFormPayload: LocationFormPayloadType = {
    ...transferLocationFormPayload,
    type: 'dropOffAddress',
}
